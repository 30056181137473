import {FormControl} from '@material-ui/core';
import React, {useState, useEffect, useRef, useCallback} from 'react';
import Formsy from 'formsy-react';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useDispatch, useSelector} from 'react-redux';
import {appActions, merchantActions} from '../../../../_actions';
import {MDBCol, MDBContainer, MDBRow} from 'mdb-react-ui-kit';
import {ReactComponent as Lock} from '../../../../_assets/icon/lock.svg';
import TextInput from '../../../../_components/TextInput';
import {ReactComponent as Vector} from '../../../../_assets/icon/downarr.svg';
import {Link} from '@material-ui/core';
import {ReactComponent as Pen} from '../../../../_assets/icon/pen.svg';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const Password = () => {
   const dispatch = useDispatch();
   const auth = useSelector((s) => s.auth);
   const {data, changingPassword, changedPassword} = useSelector((s) => s.merchant);
   const [canSubmit, setCanSubmit] = useState(false);
   const [passError, setPassError] = useState('');
   const [oldPasswordValueError, setPasswordError] = useState('');
   const [newPasswordValueError, setNewPasswordError] = useState('');
   const [confirmNewPasswordValueError, setConfirmNewPasswordError] = useState('');

   const validatePassword = useCallback((val, type) => {
      if (val.length === 0) {
         setPasswordError('');
         // setPasswordSet(false);
         return;
      }

      if (!/^[a-zA-Z0-9]+$/.test(val)) {
         type === 'old'
            ? setPasswordError('Contains invalid character(s)!')
            : type === 'new'
            ? setNewPasswordError('Contains invalid character(s)!')
            : setConfirmNewPasswordError('Contains invalid character(s)!');
         // setPasswordSet(false);
         return;
      }

      if (!/[A-Z]/.test(val)) {
         type === 'old'
            ? setPasswordError('Should contain a capital letter!')
            : type === 'new'
            ? setNewPasswordError('Should contain a capital letter!')
            : setConfirmNewPasswordError('Should contain a capital letter!');
         // setPasswordError('Should contain a capital letter!');
         // setPasswordSet(false);
         return;
      }

      if (!/[a-z]/.test(val)) {
         type === 'old'
            ? setPasswordError('Should contain a small letter!')
            : type === 'new'
            ? setNewPasswordError('Should contain a small letter!')
            : setConfirmNewPasswordError('Should contain a small letter!');
         // setPasswordSet(false);
         return;
      }

      if (!/[0-9]/.test(val)) {
         type === 'old'
            ? setPasswordError('Should contain a number!')
            : type === 'new'
            ? setNewPasswordError('Should contain a number!')
            : setConfirmNewPasswordError('Should contain a number!');
         // setPasswordSet(false);
         return;
      }

      if (val.length < 8) {
         type === 'old'
            ? setPasswordError('Minimum of 8 characters required!')
            : type === 'new'
            ? setNewPasswordError('Minimum of 8 characters required!')
            : setConfirmNewPasswordError('Minimum of 8 characters required!');
         // setPasswordSet(false);
         return;
      }

      setPasswordError('');
      setNewPasswordError('');
      setConfirmNewPasswordError('');
      // setPasswordSet(true);
   }, []);

   const handleOldPasswordChange = useCallback((itemValue) => {
      console.log('GOTHERE!!!', itemValue);
      validatePassword(itemValue, 'old');
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleNewPasswordChange = useCallback((itemValue) => {
      console.log('GOTHERE!!!', itemValue);
      validatePassword(itemValue, 'new');
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleConfirmNewPasswordChange = useCallback((itemValue) => {
      console.log('GOTHERE!!!', itemValue);
      validatePassword(itemValue, 'confirm');
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
   const handleSubmit = useCallback((data) => {
      console.log('data', data);
      dispatch(merchantActions.changePassword(data));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const errorMessage = auth?.errorMessage;
   const oldPasswordError = errorMessage?.errors?.filter((error) => error.param === 'old_password');
   const passwordError = errorMessage?.errors?.filter((error) => error.param === 'new_password');

   const [oldPassword, setOldPassword] = useState(false);
   const [newPassword, setNewPassword] = useState(false);
   const [confirmPassword, setConfirmPassword] = useState(false);

   // const [passwordShown, setPasswordShown] = useState(false);
   // const [passwordShow, setPasswordShow] = useState(false);
   // // const [passwordError, setPasswordError] = useState('');
   // const togglePasswordVisiblity = () => {
   //    setPasswordShown(passwordShown ? false : true);
   // };
   // const togglePasswordVisible = () => {
   //    setPasswordShow(passwordShow ? false : true);
   // };
   return (
      <Formsy
         onValidSubmit={handleSubmit}
         onValid={() => setCanSubmit(true)}
         onInvalid={() => setCanSubmit(false)}>
         <div className="forms col-md-12" style={{marginLeft: '10px'}}>
            {/* {codeError && <h5 className="error">{codeError}</h5>}
            {!!resetPasswordSuccessMessage && (
               <h5 className="success">{resetPasswordSuccessMessage}</h5>
            )} */}
            <div
               id="textExample1"
               className="form-text"
               style={{color: '#3377ef', fontSize: '13px'}}>
               Old Password
            </div>
            <FormControl margin="normal" required fullWidth>
               <TextInput
                  name="old_password"
                  wrapperClassName="input-wrapper"
                  mainWrapperClassName="w-100 mb-3 mb-s-4"
                  placeholder="*****"
                  useFocusedError
                  type={oldPassword ? 'text' : 'password'}
                  className="input border-0 w-100"
                  validationError="Please provide your password!"
                  required
                  handleChange={handleOldPasswordChange}
                  // valError={passwordError}
               />
               <i onClick={() => setOldPassword(!oldPassword)}>
                  {oldPassword ? (
                     <Visibility style={{width: '1rem'}} />
                  ) : (
                     <VisibilityOff style={{width: '1rem'}} />
                  )}
               </i>
               {/* {errorMessage && <h5 claassName="error">{errorMessage?.message}</h5>} */}
            </FormControl>
         </div>
         <h5 style={{color: 'red'}}>{oldPasswordValueError}</h5>
         <h5 style={{color: 'red', fontSize: '11px'}}>
            {oldPasswordError && oldPasswordError[0] && oldPasswordError[0].msg}
         </h5>

         <div className="forms col-md-12" style={{marginLeft: '10px'}}>
            {/* {codeError && <h5 className="error">{codeError}</h5>}
            {!!resetPasswordSuccessMessage && (
               <h5 className="success">{resetPasswordSuccessMessage}</h5>
            )} */}
            <div
               id="textExample1"
               className="form-text"
               style={{color: '#3377ef', fontSize: '13px'}}>
               New Password
            </div>
            <FormControl margin="normal" required fullWidth>
               <TextInput
                  name="new_password"
                  wrapperClassName="input-wrapper"
                  mainWrapperClassName="w-100 mb-3 mb-s-4"
                  placeholder="*****"
                  useFocusedError
                  type={newPassword ? 'text' : 'password'}
                  className="input border-0 w-100"
                  validationError="Please provide your password!"
                  required
                  handleChange={handleNewPasswordChange}
                  // valError={passwordError}
               />
               <i onClick={() => setNewPassword(!newPassword)}>
                  {newPassword ? (
                     <Visibility style={{width: '1rem'}} />
                  ) : (
                     <VisibilityOff style={{width: '1rem'}} />
                  )}
               </i>
               {/* {errorMessage && <h5 claassName="error">{errorMessage?.message}</h5>} */}
            </FormControl>
         </div>
         <h5 style={{color: 'red'}}>{newPasswordValueError || passError}</h5>
         <div className="forms col-md-12" style={{marginLeft: '10px'}}>
            <div
               id="textExample1"
               className="form-text"
               style={{color: '#3377ef', fontSize: '13px'}}>
               Confirm Password
            </div>
            <FormControl margin="normal" required fullWidth>
               <TextInput
                  name="confirm_new_password"
                  wrapperClassName="input-wrapper"
                  mainWrapperClassName="w-100 mb-3 mb-s-4"
                  placeholder="*****"
                  useFocusedError
                  // value={confirmPassword}
                  type={confirmPassword ? 'text' : 'password'}
                  className="input border-0 w-100"
                  validations="equalsField:new_password"
                  validationError="Passwords don't match!"
                  required
                  handleChange={handleConfirmNewPasswordChange}
                  // required
               />
               <i onClick={() => setConfirmPassword(!confirmPassword)}>
                  {confirmPassword ? (
                     <Visibility style={{width: '1rem'}} />
                  ) : (
                     <VisibilityOff style={{width: '1rem'}} />
                  )}
               </i>
            </FormControl>
         </div>
         <h5 style={{color: 'red'}}>{confirmNewPasswordValueError || passError}</h5>
         <h5 style={{color: 'red', fontSize: '11px'}}>
            {passwordError && passwordError[0] && passwordError[0].msg}
         </h5>

         <button
            disabled={!canSubmit}
            onClick={handleSubmit}
            type="submit"
            className={classNames('signupbtn', {disabled: !canSubmit})}>
            {changingPassword ? <CircularProgress color="inherit" thickness={2} /> : 'Confirm'}
         </button>
      </Formsy>
   );
};
export default Password;
