import {stateConstants} from '../_constants';

const getStates = () => ({
   type: stateConstants.GET_STATES,
});

const getCities = (model) => ({
   type: stateConstants.GET_CITIES,
   data: model,
});

// const getAccountName = (model) => ({
//    type: bankConstants.GET_ACCOUNT_NAME,
//    data: model,
// });

export const stateActions = {
   getStates,
   getCities,
};
