import {call, put, takeLatest, all} from 'redux-saga/effects';
import {countryConstants} from '../../_constants';
import {createRequest, checkStatus} from '../../_helpers';

function* getCountrySaga() {
   yield put({type: countryConstants.REQUEST_COUNTRIES});

   try {
      // const user = yield call(getObjectFromStorage, userConstants.USER_KEY);
      let countriesUri = `${countryConstants.COUNTRY_URL}`;

      // const permissionsReq = createRequestWithToken(permissionsUri, {
      //    method: 'GET',
      // })(user?.token);
      const curriedReq = yield call(createRequest, countriesUri, {
         method: 'GET',
      });

      const response = yield call(fetch, curriedReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: countryConstants.REQUEST_COUNTRIES_SUCCESS,
         countries: jsonResponse,
      });
   } catch (error) {
      yield put({type: countryConstants.REQUEST_COUNTRIES_ERROR});
   }
}

function* getCountrySagaWatcher() {
   yield takeLatest(countryConstants.GET_COUNTRIES, getCountrySaga);
}

export default function* rootSaga() {
   yield all([getCountrySagaWatcher()]);
}
