import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBCard, MDBCardText, MDBCardBody, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import {Bar} from 'react-chartjs-2';
import {Link} from 'react-router-dom';
import WalletIcon from '../../../_assets/images/wallet.svg';
import ArrowIcon from '../../../_assets/images/Arrowdown.svg';
import Dem from '../../../_assets/images/ademola.png';
import LetteredAvatar from 'lettered-avatar';
// import Sofia from '../../../_assets/images/sofia.png';
// import Will from '../../../_assets/images/will.png';
import Loading from '../Loading/loading';
// import {ReactComponent as Ak} from '../../../_assets/icon/akogun.svg';
import {ReactComponent as Vector} from '../../../_assets/icon/Vector.svg';
// import Transaction from '../../../_assets/images/transaction.png';
import Chat from '../../../_assets/images/chat.png';
import Google from '../../../_assets/images/google2.png';
import Apple from '../../../_assets/images/apple2.png';
// import './index.scss';
// import classNames from 'classnames';
import './index.scss';
import {merchantActions, stateActions} from '../../../_actions';
import {readableDateString, numberWithCommas} from '../../../_helpers';
// import ModalController from '../../../_components/Modal/ModalController';

const selectOptions = [
   {label: 'Select Year', value: 0},
   {label: 'Jan - Jun 2021', value: 1},
   {label: 'Jul - Dec 2021', value: 2},
];

function Homeexist({history}) {
   const dispatch = useDispatch();
   // const merchant = useSelector((s) => s.merchant.merchant);
   // const statesList = useSelector((s) => s.state.statesList);
   // const info = useSelector((s) => s.merchant.info);
   // const addedStates = [{name: 'Select a state', code: '0'}, ...statesList];
   // const countriesList = useSelector((s) => s.country.CountriesList);
   // const addedCountries = [{name: 'Select a country', code: '0'}, ...countriesList];
   // const IsUpdatingMerchantInfo = useSelector((s) => s.merchant.IsUpdatingMerchantInfo);
   // const merchantData = useSelector((s) => s.merchant.data);
   const {createPinSuccess} = useSelector((s) => s.auth);

   const {
      merchantInfoUpdated,
      walletBalance,
      IsRequestingCompanyInfo,
      companyInfoSuccess,
      merchantCompanyCreated,
      companyId,
      IsGettingDashBoardData,
      companyInfo,
      settledToday,
      receivedToday,
      recentTransaction,
      userMerchants,
   } = useSelector((s) => s.merchant);
   // const [modalIsOpen, setModalIsOpen] = useState(
   //    merchant?.merchant_details === null ? true : false,
   // );
   // const [canSubmit, setCanSubmit] = useState(false);
   const [modalIsOpen, setModalIsOpen] = useState(false);
   const [secModalIsOpen, setSecModalIsOpen] = useState(false);
   // const [passwordShown, setPasswordShown] = useState(false);
   const [showTextOpen, setShowTextOpen] = useState(false);
   const [yearState, setYearState] = useState(0);
   const [select, setSelect] = useState(false);
   const [loader, setLoader] = useState(false);

   // const [state, setState] = useState(
   //    addedStates.find((el) => el.name === info?.state)?.code ?? '0',
   // );
   // const [country, setCountry] = useState(
   //    addedCountries.find((el) => el.name === info?.country)?.code ?? '0',
   // );
   // console.log('merchant data list', {
   //    addedStates,
   //    addedCountries,
   //    state: info?.state,
   //    country: info?.country,
   //    city: info?.city,
   //    email: info?.email,
   // });

   useEffect(() => {
      // launch modal in here
      // setModalIsOpen(true);
      setShowIsOpenToTrue(false);
      dispatch(stateActions.getStates());
      if (companyInfo) {
         dispatch(merchantActions.getDashBoard(companyInfo?._id));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (!merchantCompanyCreated && IsRequestingCompanyInfo) {
         setLoader(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [IsRequestingCompanyInfo]);

   useEffect(() => {
      if (companyInfoSuccess) {
         setLoader(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [companyInfoSuccess]);

   useEffect(() => {
      if (merchantInfoUpdated) {
         setSecModalIsOpen(false);
      }
   }, [merchantInfoUpdated]);

   useEffect(() => {
      if (createPinSuccess) {
         setSecModalIsOpen(true);

         dispatch(merchantActions.getDashBoard(companyId));
         if (userMerchants[0]?.role === 2) {
            dispatch(merchantActions.getMerchantCompanyInformation(companyId));
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [createPinSuccess]);

   useEffect(() => {
      if (userMerchants?.length === 0) {
         setModalIsOpen(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userMerchants]);

   const setShowIsOpenToTrue = () => {
      setShowTextOpen(false);
   };

   // const setSecModalIsOpenToTrue = () => {
   //    setSecModalIsOpen(true);
   //    setModalIsOpen(false);
   // };
   // const customStyles = {
   //    content: {
   //       // top: '40%',
   //       left: '50%',
   //       right: 'auto',
   //       bottom: 'auto',
   //       marginTop: '18%',
   //       marginRight: '-50%',
   //       transform: 'translate(-50%, -50%)',
   //       backgroundColor: '#ffffff',
   //       borderRadius: '20px',
   //       padding: '40px 40px',
   //       width: '38%',
   //       overflow: 'hidden',
   //       // height: '50%',
   //    },
   // };

   const data = {
      labels:
         yearState !== 2
            ? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
            : ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
         {
            label: 'Amount Per Month',
            data: yearState !== 2 ? [78, 45, 32, 60, 26, 45] : [30, 66, 24, 68, 70, 78],
            backgroundColor: '#3377ef',
            borderRadius: '4',
         },
      ],
   };

   const options = {
      plugins: {
         legend: {
            display: false,
            // position: 'bottom',
         },
         tooltips: {
            callbacks: {
               label: function (tooltipItem) {
                  return tooltipItem.yLabel;
               },
            },
         },
      },

      scales: {
         x: {
            display: true,
            responsive: true,
            stacked: true,
            ticks: {
               color: '#000',
               font: function (context) {
                  if (context.tick) {
                     return {
                        weight: 'bolder',
                     };
                  }
               },
            },
            grid: {
               display: false,
               drawBorder: false,
            },
         },
         y: {
            min: 0,
            max: 80,
            responsive: true,
            stacked: true,
            ticks: {
               stepSize: 20,
               callback: function (label, index, labels) {
                  return label.toFixed(0) + 'k';
               },
               color: '#000',
               font: function (context) {
                  if (context.tick) {
                     return {
                        weight: 'bolder',
                     };
                  }
               },
            },
            display: true,

            grid: {
               display: false,
               drawBorder: false,
            },
         },
      },
   };

   return (
      <section className="existinghome">
         {loader === true && <Loading />}
         <MDBRow className="first">
            <MDBCol className="col-xl-6 col-md-9 col-12 balance">
               <div className="content">
                  {' '}
                  {IsGettingDashBoardData || walletBalance === null ? (
                     <p className="emptybalance"></p>
                  ) : (
                     <p>Balance</p>
                  )}
                  {IsGettingDashBoardData || walletBalance === null ? (
                     <p className="no-image"></p>
                  ) : (
                     <img
                        src={WalletIcon}
                        alt=""
                        style={{width: '50px'}}
                        className="img-fluid image"
                     />
                  )}
               </div>
               <div className="text">
                  {IsGettingDashBoardData || walletBalance === null ? (
                     <h3 className="nomoney"></h3>
                  ) : (
                     <h3>₦ {walletBalance?.personal.toLocaleString('en-US')}</h3>
                  )}
               </div>

               <MDBCard className="cards" style={{height: '70px', width: '140px'}}>
                  {IsGettingDashBoardData || receivedToday === null ? (
                     <p className="no-received"></p>
                  ) : (
                     <MDBRow className="g-0">
                        <MDBCol md="3" className="leftside">
                           <img
                              src={ArrowIcon}
                              alt=""
                              style={{width: '17px'}}
                              className="img-fluid arrow"
                           />
                        </MDBCol>
                        <MDBCol md="8" className="rightside">
                           <MDBCardBody>
                              <p>Recieved Today</p>
                              <h5>₦ {receivedToday?.toLocaleString('en-US')}</h5>
                              <MDBCardText>
                                 <small className="text-muted"></small>
                              </MDBCardText>
                           </MDBCardBody>
                        </MDBCol>
                     </MDBRow>
                  )}
               </MDBCard>

               <MDBCard className="cardss" style={{height: '70px', width: '140px'}}>
                  {IsGettingDashBoardData || settledToday === null ? (
                     <p className="no-settled"></p>
                  ) : (
                     <MDBRow className="g-0">
                        <MDBCol md="3" className="leftside">
                           <img
                              src={ArrowIcon}
                              alt=""
                              style={{width: '17px'}}
                              className="img-fluid arrow"
                           />
                        </MDBCol>
                        <MDBCol md="8" className="rightside">
                           <MDBCardBody>
                              <p>Withdrawn Today</p>
                              <h5>₦ {settledToday?.toLocaleString('en-US')}</h5>
                              <MDBCardText>
                                 <small className="text-muted"></small>
                              </MDBCardText>
                           </MDBCardBody>
                        </MDBCol>
                     </MDBRow>
                  )}
               </MDBCard>
            </MDBCol>
            <MDBCol className="col-xl-5 col-md-9 col-12 year p-3">
               <div className="history" style={{marginLeft: '38px'}}>
                  <h4>
                     MERCHANT ID:{' '}
                     {companyInfo?.merchant_id || 'Account has not been verified by Admin'}
                  </h4>
               </div>
               {/* <div
                  className="duration-select d-flex align-items-center justify-content-between"
                  value={yearState}
                  onClick={(e) => {
                     setSelect((prev) => !prev);
                  }}>
                  <span>{selectOptions[yearState]?.label}</span>
                  <Vector className="" style={{marginLeft: '20px'}} />
                  {select && (
                     <ul className="position-absolute custom-selected p-0 m-0">
                        {selectOptions
                           .filter((item) => item.value !== 0)
                           .map((item) => (
                              <li onClick={() => setYearState(item.value)}>{item.label}</li>
                           ))}
                     </ul>
                  )}
               </div> */}
               {/* <div className="bar-chart" >
                  <Bar className="bar mt-5" data={data} height={140} options={options} />
                 
               </div> */}
            </MDBCol>
            {/* }> Steak
                  </option>
                  <option value="Bar">
                  <Bar
                     className="mt-4"
                     data={data}
                     height={180}
                     options={options}
                     />
                  </option>
                     </div>
                     {yearState} */}
         </MDBRow>

         <MDBRow className="second">
            <MDBCol className="col-xl-6 col-md-9 col-12 balance">
               <span>
                  {/* {IsGettingDashBoardData || recentTransaction === null ? (
                     <h5 className="no-transaction"></h5>
                  ) : ( */}
                  <h5 className="recent">Recent Transaction</h5>
                  {/* )} */}
                  <Link to="/merchant/history">
                     {/* {IsGettingDashBoardData || recentTransaction === null ? (
                        <p className="no-transaction"></p>
                     ) : ( */}
                     <p className="show">Show all</p>
                     {/* )} */}
                  </Link>
               </span>
               <div className="bal mt-4">
                  {IsGettingDashBoardData || recentTransaction?.length === 0 ? (
                     <ul className=" flex-container">
                        {' '}
                        <li className="flex-item">No recent transactions for this merchant</li>
                     </ul>
                  ) : (
                     recentTransaction?.map((row) => {
                        return (
                           <ul className="col text-truncate flex-container">
                              <li className="flex-item">
                                 <LetteredAvatar
                                    name={row.sender_name}
                                    options={{
                                       size: 20,
                                       twoLetter: false,
                                       //shape: 'square',
                                       bgColor: '',
                                       imgClass: 'image-responsive user-image',
                                       imgWidth: '3rem',
                                       imgHeight: '3rem',
                                    }}
                                 />{' '}
                              </li>
                              <li className="col-3 text-truncate flex-item" title={row.sender_name}>
                                 {row.sender_name}
                              </li>
                              <li className="col-3 text-truncate flex-item" title={row.narration}>
                                 {row.narration}
                              </li>
                              <li
                                 className="col-2 text-truncate flex-item"
                                 title={numberWithCommas(row.amount)}>
                                 ₦{numberWithCommas(row.amount)}
                              </li>
                              <li
                                 className="col-3 text-truncate flex-item"
                                 title={readableDateString(row.created_at)}>
                                 {readableDateString(row.created_at)}
                              </li>
                           </ul>
                        );
                     })
                  )}
               </div>
               {/* <div className="bal mt-3">
                  {' '}
                  {IsGettingDashBoardData || recentTransaction === null ? (
                     <ul className="no-recent-transaction"></ul>
                  ) : (
                     <ul className="flex-container">
                        <li className="flex-item">
                           <img
                              src={Sofia}
                              alt=""
                              style={{width: '40px'}}
                              className="img-fluid image"
                           />
                        </li>
                        <li className="flex-item" style={{marginLeft: '-5px'}}>
                           Sofia Alex
                        </li>
                        <li className="flex-item" style={{marginLeft: '40px'}}>
                           Sugar
                        </li>
                        <li className="flex-item">₦ 2,000</li>
                        <li
                           className="d-none d-sm-block flex-item"
                        >
                           5 min ago
                        </li>
                     </ul>
                  )}
               </div> */}
               {/* <div className="bal mt-3">
                  {' '}
                  {IsGettingDashBoardData || recentTransaction === null ? (
                     <ul className="no-recent-transaction"></ul>
                  ) : (
                     <ul className="flex-container">
                        <li className="flex-item">
                           <img
                              src={Will}
                              alt=""
                              style={{width: '40px'}}
                              className="img-fluid image"
                           />
                        </li>
                        <li className="flex-item" style={{marginLeft: '-5px'}}>
                           Ikechukwu Will
                        </li>
                        <li className="flex-item" style={{marginLeft: '15px'}}>
                           Petrol
                        </li>
                        <li className="flex-item">₦ 12,000</li>
                        <li className="d-none d-sm-block flex-item">9 min ago</li>
                     </ul>
                  )}
               </div>
               <div className="bal mt-3">
                  {' '}
                  {IsGettingDashBoardData || recentTransaction === null ? (
                     <ul className="no-recent-transaction"></ul>
                  ) : (
                     <ul className="flex-container">
                        <li className="flex-item">
                           <img
                              src={Ngozi}
                              alt=""
                              style={{width: '40px'}}
                              className="img-fluid image"
                           />
                        </li>
                        <li className="flex-item" style={{marginLeft: '-5px'}}>
                           Ngozi Iyke
                        </li>
                        <li className="flex-item" style={{marginLeft: '45px'}}>
                           Rice
                        </li>
                        <li className="flex-item">₦ 35,000</li>
                        <li className="d-none d-sm-block flex-item">20 min ago</li>
                     </ul>
                  )}
               </div> */}
            </MDBCol>
            <MDBCol className="col-xl-5 col-md-9 col-12 year p-3">
               <img src={Chat} alt="" style={{width: '30%'}} className="img-fluid chat" />
               <p>
                  Our mobile app will be <br />
                  available soon
               </p>
               <div className="store">
                  <img
                     src={Google}
                     alt=""
                     // style={{width: '15%'}}
                     className="img-fluid google"
                  />
                  <img
                     src={Apple}
                     alt=""
                     // style={{width: '20%'}}
                     className="img-fluid apple"
                  />
               </div>
            </MDBCol>
         </MDBRow>
      </section>
   );
}

export default Homeexist;
