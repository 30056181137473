import React from 'react';
import {useSelector} from 'react-redux';
import {MDBContainer, MDBCol} from 'mdb-react-ui-kit';
// import {Link} from 'react-router-dom';
import BlueYellow from '..//../..//_assets/images/blueandyellow.svg';
// import {ReactComponent as Bluetick} from '..//..//..//_assets/icon/bluetick.svg';
import {ReactComponent as Load} from '..//..//..//_assets/icon/verify.svg';
import './index.scss';

function Loader({location, history}) {
   const companyInfo = useSelector((s) => s.merchant.companyInfo);
   // const data = useSelector((s) => s.merchant.data);
   const data = useSelector((s) => s.merchant.companyData);
   // console.log('companyInfo', companyInfo);

   if (companyInfo) {
      if (location.state !== undefined) {
         data?.is_child === true
            ? history.push('/history')
            : history.push(location.state.from.pathname);
      } else {
         data?.is_child === true ? history.push('/history') : history.push('/merchant/home');
      }
   }

   return (
      <section className="load">
         <img src={BlueYellow} alt="" className="img-fluid image" />
         <MDBContainer className="align-items-center">
            <MDBCol className="smallbox2">
               <Load className="img-fluid tick" />
               <br />
               <br />
               <br />
               <p>
                  Please wait
                  <br />
                  While we get your company details
               </p>
               <br />
            </MDBCol>
         </MDBContainer>
      </section>
   );
}
export default Loader;
