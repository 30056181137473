import {appConstants} from './app.constant';
const state = 'state';
const city = 'cities';

export const stateConstants = {
   GET_STATES: 'GET_STATES',
   GET_CITIES: 'GET_CITIES',
   STATE: 'state',
   CITY: 'city',

   REQUEST_STATES: 'REQUEST_STATES',
   REQUEST_STATES_ERROR: 'REQUEST_STATES_ERROR',
   REQUEST_STATES_SUCCESS: 'REQUEST_STATES_SUCCESS',

   REQUEST_CITIES: 'REQUEST_CITIES',
   REQUEST_CITIES_ERROR: 'REQUEST_CITIES_ERROR',
   REQUEST_CITIES_SUCCESS: 'REQUEST_CITIES_SUCCESS',

   STATE_URL: `${appConstants.BASE_URI}${state}`,
   CITY_URL: `${appConstants.BASE_URI}${city}`,

   // REQUEST_ACCOUNT_NAME: 'REQUEST_ACCOUNT_NAME',
   // REQUEST_ACCOUNT_NAME_ERROR: 'REQUEST_ACCOUNT_NAME_ERROR',
   // REQUEST_ACCOUNT_NAME_SUCCESS: 'REQUEST_ACCOUNT_NAME_SUCCESS',
};
