import React, {useState} from 'react';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import {Link} from 'react-router-dom';
import BlueYellow from '..//../..//_assets/images/blueandyellow.svg';
import {ReactComponent as Bluetick} from '..//..//..//_assets/icon/bluetick.svg';
import './index.scss';

function Creation() {
   return (
      <section className="create">
         <img src={BlueYellow} alt="" className="img-fluid image" />
         <MDBContainer className="align-items-center">
            <MDBCol className="smallbox2">
               <Bluetick className="img-fluid tick" />
               <br />
               <br />
               <br />
               <h3>Account Created</h3>
               <p>
                  Your Registration has been made
                  <br />
                  successfully. Enjoy the experience
               </p><br />
               <Link to="/login">
                  <button
                     // to="/verify"
                     // disabled={!canSubmit}
                     type="submit"
                     className="signupbtn">
                    Proceed to Login
                  </button>
               </Link>
            </MDBCol>
         </MDBContainer>
      </section>
   );
}
export default Creation;
