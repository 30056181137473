import React, {useState, useEffect, useCallback} from 'react';
import {MDBContainer, MDBCol} from 'mdb-react-ui-kit';
// import {forgotPasswordConstants} from '../../../_constants';
import {forgotPasswordActions} from '../../../_actions';
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import TextInput from '../../../_components/TextInput';
import BlueYellow from '..//..//../_assets/images/blueandyellow.svg';
// import Customerone from '../../_assets/images/customerone.png';
import Formsy from 'formsy-react';
// import Customertwo from '../../_assets/images/customertwo.png';
// import Customerthree from '../../_assets/images/customerthree.png';
// import {Link, Redirect} from 'react-router-dom';
import './index.scss';
import {capitalizeFirstLetter} from '../../../_helpers';

function ResetpasswordPage() {
   const dispatch = useDispatch();
   const {IsSendingResetPasswordLink, errorMessage, successMessage} = useSelector(
      (s) => s.forgotPassword,
   );
   const [canSubmit, setCanSubmit] = useState(false);
   const [codeError, setCodeError] = useState('');
   const [emailError, setEmailError] = useState('');

   useEffect(() => {
      if (typeof errorMessage === 'string') {
         setCodeError(errorMessage);
      }
      if (errorMessage?.email) {
         setEmailError('Email is Invalid');
      }
   }, [errorMessage]);

   const handleSubmit = useCallback((data) => {
      setCodeError('');
      setEmailError('');
      dispatch(forgotPasswordActions.getResetPasswordLink(data));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
   // if (SendResetPasswordLinkSuccess) {
   //    return <Redirect to="/createnewpassword" />;
   // }

   return (
      <>
         <section className="reset">
            <img src={BlueYellow} alt="" className="img-fluid image" />
            <MDBContainer className="align-items-center">
               <div className="bigboxes">
                  <MDBCol className="text">
                     <h3>
                        Enter
                        <br />
                        Email Address
                     </h3>
                     <p>
                        New user? &nbsp; <br />
                        <a style={{fontWeight: 'bolder'}} href="/signup">
                           Sign up
                        </a>
                     </p>
                  </MDBCol>
                  <MDBCol className="smallbox">
                     <MDBCol className="smallbox2">
                        <Formsy
                           onValidSubmit={handleSubmit}
                           onValid={() => setCanSubmit(true)}
                           onInvalid={() => setCanSubmit(false)}>
                           <div className="forms col-md-10">
                              {codeError && (
                                 <h5 className="error">{capitalizeFirstLetter(codeError)}</h5>
                              )}
                              {!!successMessage && (
                                 <h5 className="success">
                                    {capitalizeFirstLetter(successMessage)}
                                 </h5>
                              )}
                              <div id="textExample1" className="form-text" style={{color: 'black'}}>
                                 Email Address
                              </div>
                              <FormControl margin="normal" required fullWidth>
                                 <TextInput
                                    name="email"
                                    wrapperClassName="input-wrapper"
                                    mainWrapperClassName="w-100 mb-3 mb-s-4"
                                    placeholder="kalu@gmail.com"
                                    useFocusedError
                                    className="input border-0 w-100"
                                    validations="isEmail"
                                    required
                                    valError={emailError}
                                    validationErrors={{
                                       isEmail: 'This is not a valid email',
                                       required: 'Required!',
                                    }}
                                 />
                                 {errorMessage && (
                                    <h5 claassName="error">{errorMessage?.message}</h5>
                                 )}
                              </FormControl>
                           </div>

                           <button
                              // to="/verify"
                              disabled={!canSubmit}
                              type="submit"
                              className={classNames('signupbtn', {
                                 disabled: !canSubmit,
                              })}>
                              {IsSendingResetPasswordLink ? (
                                 <CircularProgress color="inherit" thickness={1} />
                              ) : (
                                 'Submit'
                              )}
                           </button>
                        </Formsy>
                     </MDBCol>
                  </MDBCol>
               </div>
            </MDBContainer>
         </section>
      </>
   );
}

export default ResetpasswordPage;
