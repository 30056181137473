import React, {useCallback} from 'react';
// import Logo from '../../_assets/images/Logo.png';
import {Link} from 'react-router-dom';
import {dashboardData} from '../../_components/dashboardData';
import classNames from 'classnames';
// import {MDBCollapse, MDBRow} from 'mdb-react-ui-kit';
import {ReactComponent as LogoutIcon} from '../../_assets/images/Logout.svg';
import BuyLogo from '../../_assets/images/BuyGo.png';
import {useLocationCode} from '../../_navigation';
import './index.scss';
import {merchantActions} from '../../_actions';
import {useDispatch} from 'react-redux';

function Sidebar({sideBarOpen, closeSideBar}) {
   const code = useLocationCode();
   const dispatch = useDispatch();

   // const logout = () => {
   //    //history.push('/');
   //    logoutMerchant();
   // };

   const getActiveClass = useCallback(
      (num) => {
         switch (num) {
            case 1:
               return code === 1;
            case 2:
               return code === 2;
            case 3:
               return code === 3;
            case 4:
               return code === 4;
            case 5:
               return code === 5;
            default:
               return null;
         }
      },
      [code],
   );

   return code !== 7 ? (
      <>
         <div className={`sidebar-backdrop ${sideBarOpen ? 'open' : ''}`} onClick={() => {}} />
         <div className={`sidebar-wrapper d-flex flex-column ${sideBarOpen ? 'open' : ''}`}>
            <div id="sidebar">
               <div className="side-bar-logo">
                  <img
                     src={BuyLogo}
                     alt=""
                     className="buylogo"
                     style={{width: '80%', marginLeft: '-20px'}}
                  />
                  <i className="fa fa-times" id="sidebarIcon" onClick={() => closeSideBar()}></i>
               </div>
               <ul className="list">
                  {dashboardData.map((val, key) => (
                     <>
                        <li key={key}>
                           <Link
                              to={val.link}
                              className={classNames('list-item d-block', {
                                 active: getActiveClass(key + 1),
                              })}>
                              <div className="content m-0 align-items-center">
                                 <div id="icon">{val.icon}</div>
                                 <div id="title">{val.title}</div>
                              </div>
                           </Link>
                        </li>
                     </>
                  ))}
               </ul>
               <div onClick={() => dispatch(merchantActions.logout())} className="logout">
                  <img
                     src={LogoutIcon}
                     alt=""
                     style={{width: '20px', marginRight: '25px'}}
                     className="img-fluid image4"
                  />
                  {'Logout'}
               </div>
            </div>
         </div>
      </>
   ) : null;
}

export default Sidebar;
