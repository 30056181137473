import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import Background from '..//..//_assets/images/backgroundland.png';
import Ellipse from '..//..//_assets/images/Ellipse2.svg';
import Saleswoman from '..//..//_assets/images/salesWoman.svg';
import Eligible from '..//..//_assets/icon/eligible.svg';
import Guarantee from '..//..//_assets/icon/guarantee.svg';
import Approval from '..//..//_assets/icon/approval.svg';
import Whatweoffer1 from '..//..//_assets/images/whatweoffer1.png';
import Whatweoffer2 from '..//..//_assets/images/whatweoffer2.png';
import Whatweoffer3 from '..//..//_assets/images/whatweoffer3.png';
import Whatweoffer4 from '..//..//_assets/images/whatweoffer4.png';
import Carouselimage from '..//..//_assets/images/carousel1.png';
import Carousel1 from '..//..//_assets/images/carousel2.png';
import Carousel2 from '..//..//_assets/images/carousel1.png';
import Phones from '../../_assets/images/phones.png';
import {ReactComponent as Dash} from '../../_assets/icon/line.svg';
import {ReactComponent as Facebook} from '../../_assets/icon/facebook.svg';
import {ReactComponent as Twitter} from '../../_assets/icon/twitter.svg';
import {ReactComponent as Instagram} from '../../_assets/icon/instagram.svg';
import {ReactComponent as Linkedin} from '../../_assets/icon/linkdin.svg';
import {ReactComponent as Dot} from '../../_assets/icon/dot.svg';
import {ReactComponent as Arrowdown} from '..//..//_assets/icon/arrow-down.svg';
import {ReactComponent as Mission} from '..//..//_assets/icon/mission.svg';
import {ReactComponent as Vision} from '..//..//_assets/icon/vision.svg';
import {ReactComponent as Quote} from '..//..//_assets/icon/quote.svg';
import Whoweare from '..//..//_assets/images/whoweare.png';
import {ReactComponent as Left} from '..//..//_assets/icon/left.svg';
import {ReactComponent as Whiteleft} from '..//..//_assets/icon/left(white).svg';
import {ReactComponent as Whiteright} from '..//..//_assets/icon/right(white).svg';
import {ReactComponent as Right} from '..//..//_assets/icon/right.svg';
import Google from '..//..//_assets/images/google2.png';
import Apple from '..//..//_assets/images/apple2.png';
import Brand from '../../_assets/images/BuyGo.png';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import * as ReactBootstrap from 'react-bootstrap';
import {
   MDBBtn,
   MDBContainer,
   MDBRow,
   MDBCardImage,
   MDBCard,
   MDBCarousel,
   MDBCarouselInner,
   MDBCarouselItem,
   // MDBCarouselElement,
   // MDBAccordion,
   // MDBAccordionItem,
   MDBCarouselCaption,
   MDBCardBody,
   MDBCollapse,
   MDBCardTitle,
   MDBCardText,
   MDBCol,
} from 'mdb-react-ui-kit';
import './index.scss';

function LandingPage({history, location}) {
   const [isOpen, setIsOpen] = useState({
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
   });

   //    const [isOpen, setIsOpen] = useState(false);

   //   const toggleOpen = () => setIsOpen(!isOpen);

   const toggleOpen = (num) => {
      switch (num) {
         case 1:
            setIsOpen((prev) => ({
               ...prev,
               1: !prev[1],
               2: false,
               3: false,
               4: false,
               5: false,
               6: false,
            }));
            break;
         case 2:
            setIsOpen((prev) => ({
               ...prev,
               1: false,
               2: !prev[2],
               3: false,
               4: false,
               5: false,
               6: false,
            }));
            break;
         case 3:
            setIsOpen((prev) => ({
               ...prev,
               1: false,
               2: false,
               3: !prev[3],
               4: false,
               5: false,
               6: false,
            }));
            break;
         case 4:
            setIsOpen((prev) => ({
               ...prev,
               1: false,
               2: false,
               3: false,
               4: !prev[4],
               5: false,
               6: false,
            }));
            break;
         case 5:
            setIsOpen((prev) => ({
               ...prev,
               1: false,
               2: false,
               3: false,
               4: false,
               5: !prev[5],
               6: false,
            }));
            break;
         case 6:
            setIsOpen((prev) => ({
               ...prev,
               1: false,
               2: false,
               3: false,
               4: false,
               5: false,
               6: !prev[6],
            }));
            break;
         default:
            break;
      }
   };

   const {data, companyData, IsRequestingMerchant} = useSelector((s) => s.merchant);

   if (IsRequestingMerchant) {
      return null;
   }

   const handleSignup = () => {
      history.push('/signup');
   };

   // if (companyData) {
   //    console.log('companyDataLAND', companyData)
   //    companyData?.is_child === true ? history.push('/history') : history.push('/home');

   //    // if (location.state !== undefined) {
   //    //    data?.is_child === true
   //    //       ? history.push('/history')
   //    //       : history.push(location.state.from.pathname);
   //    // } else {
   //    //    // history.push('/home');
   //    // }
   // }

   return (
      <>
         <div className="landing">
            <ReactBootstrap.Navbar
               collapseOnSelect
               expand="xl"
               className="bg"
               bg="dark"
               variant="dark"
               fixed="top">
               <img src={Brand} alt="" className="brand img-fluid" />
               <ReactBootstrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
               <ReactBootstrap.Navbar.Collapse id="responsive-navbar-nav">
                  <ReactBootstrap.Nav className="navbar-links mr-auto ml-auto">
                     <ReactBootstrap.Nav.Link href="/">Home</ReactBootstrap.Nav.Link>
                     <ReactBootstrap.Nav.Link href="#services">Services</ReactBootstrap.Nav.Link>
                     <ReactBootstrap.Nav.Link href="#aboutus">About</ReactBootstrap.Nav.Link>
                     <ReactBootstrap.Nav.Link href="#!">Contact</ReactBootstrap.Nav.Link>
                  </ReactBootstrap.Nav>
                  <ReactBootstrap.Nav>
                     <ReactBootstrap.Nav.Link className="d-flex" eventKey={2} href="#memes">
                        <button
                           type="button"
                           className=" me-1 me-md-0 mt-2 mt-md-0 p-2 p-md-3 learn">
                           <a
                              href="/login"
                              className="link_learn m-0 mb-1"
                              style={{textDecoration: 'none'}}>
                              Merchant Login
                           </a>
                        </button>
                        <button
                           type="button"
                           className=" ms-1 mt-2 mt-md-0 mx-md-3 p-2 p-md-3 start">
                           <Link
                              to="/signup"
                              className="link_start m-0 mb-1"
                              style={{textDecoration: 'none'}}>
                              Get Started
                           </Link>
                        </button>
                     </ReactBootstrap.Nav.Link>
                  </ReactBootstrap.Nav>
               </ReactBootstrap.Navbar.Collapse>
            </ReactBootstrap.Navbar>

            <div className="main mb-3">
               <div className="woman d-flex flex-column justify-content-center aligns-item-center">
                  <div className="mx-3 mx-md-5">
                     <div className="bold-text">
                        <h2>Your preferred settlement platform</h2>
                     </div>
                     <div className="light-text">
                        <p>
                           We connect sellers with buyers, while providing a seamless and reliable
                           settlement arrangement for transactions through your loan or prefunded
                           wallet.
                        </p>
                     </div>
                     <div className="row d-flex flex-column flex-md-row m-0">
                        <button type="button" className="col-5 col-md-2 p-2 p-md-3 learn">
                           <a
                              href="#aboutus"
                              className="link_learn m-0 mb-1"
                              style={{textDecoration: 'none'}}>
                              Learn More
                           </a>
                        </button>
                        <button
                           type="button"
                           className="col-5 col-md-2 mx-0 mt-2 mt-md-0 mx-md-3 p-2 p-md-3 start">
                           <Link
                              to="/signup"
                              className="link_start"
                              style={{textDecoration: 'none'}}>
                              Get Started
                           </Link>
                        </button>
                     </div>
                     {/* <div className="d-flex flex-column mt-3">

                     <Link to="/">
                        {' '}
                        <div className="google">
                           <img src={Google} alt="" className="img-fluid image3" />
                        </div>
                     </Link>
                     <Link to="/">
                        {' '}
                        <div className="apple">
                           <img src={Apple} alt="" className="img-fluid image4" />
                        </div>
                     </Link>
                     
                  </div> */}
                  </div>
               </div>
               {/* <img
                  src={Background}
                  alt=""
                  className="img-fluid image"
                  style={{
                     width: '100%',
                  }}
               /> */}
            </div>
            <section className="service mb-0 py-5">
               <MDBContainer fluid className="align-items-center pb-2 px-0">
                  <div className="title py-5">
                     <Left className="img-fluid left" />
                     <h3>How Our Service Works</h3>
                     <Right className="img-fluid right" />
                  </div>
                  <MDBRow className="justify-content-center py-3 py-md-5">
                     <MDBCol className="col-md-3 col-10 mt-4 square p-4">
                        <div className="d-flex flex-column align-items-center align-items-md-start">
                           <img src={Eligible} alt="" className="img-fluid mb-4 eligible" />
                           <h5 className="mb-4">Eligibity</h5>
                           <p>
                              All duly registered users are eligible to fund their wallet. Loan can
                              also be available to individuals based on set criteria and on
                              compliance with the prerequisite loan terms and conditions.
                           </p>
                        </div>
                     </MDBCol>
                     <MDBCol className="col-md-3 col-10 mx-5 mt-4 square p-4">
                        <div className="d-flex flex-column align-items-center align-items-md-start">
                           <img src={Approval} alt="" className="img-fluid mb-4 eligible" />
                           <h5 className="mb-4">Loan Request & Approval</h5>
                           <p>
                              You can put in for a loan request by clicking on 'Get Loan' icon
                              above. One of our Customer Service Executives will engage you to
                              conclude on the process. A loan will be approved only when Terms and
                              Conditions have been met.
                           </p>
                        </div>
                     </MDBCol>
                     <MDBCol className="col-md-3 col-10 mt-4 square p-4">
                        <div className="d-flex flex-column align-items-center align-items-md-start">
                           <img src={Guarantee} alt="" className="img-fluid mb-4 eligible" />
                           <h5 className="mb-4">Your Wallet</h5>
                           <p>
                              You can fund your wallet as soon as you conclude on your registration
                              with us through this platform. We guarantee you ease of settlement for
                              transactions following three basic steps.
                           </p>
                        </div>
                     </MDBCol>
                  </MDBRow>
               </MDBContainer>
            </section>

            <section className="service2 py-5">
               <MDBContainer className="align-items-center">
                  <MDBRow className="sub pt-1">
                     <MDBCol className="woman col-md-6 col-10">
                        <img src={Saleswoman} alt="" className="saleswoman img-fluid" />
                     </MDBCol>
                     <MDBCol className="col-md-6 col-10 py-3">
                        <h5 className="py-4">Merchants on the Platform</h5>
                        <h4 className="mb-3">
                           Helping Your Business Reach
                           <br /> It's Full Potential
                        </h4>
                        <p className="py-2 mb-3">
                           We intend to help your business reach its full potential by channeling
                           sales to you. This would ride at the back of a seameless Settlement
                           arrangement that you only to have an adroid or IOS powered GSM.
                        </p>

                        <div>
                           <Link to="/signup" type="button" className="btn4">
                              Get Started
                           </Link>
                        </div>
                     </MDBCol>
                  </MDBRow>
               </MDBContainer>
            </section>
            <section className="service3 py-2">
               <MDBContainer className="align-items-center py-5" id="services">
                  <div className="title justify-content-center">
                     <Left className="img-fluid left" />
                     <h3>What We Offer</h3>
                     <Right className="img-fluid right" />
                  </div>
                  <MDBRow className="col-12 subtitle justify-content-center">
                     <p>
                        Real-time and online access to consumable goods and a seamless and secured
                        settlement arrangement for transactions. You may make payment for purchases
                        directly from your prefunded wallet or take advantage of our short term
                        loan. You are required to put in a request for the loan and secure approval
                        in line withthe applicable terms and conditions ahead of your purchase.
                     </p>
                  </MDBRow>
                  <MDBRow className="sub-titles mb-5 mt-0 pt-2">
                     <MDBCol className="col-10 col-lg-5 col-xl-3 mb-4">
                        <MDBCard className="cards" style={{maxWidth: '22rem'}}>
                           <div className="inner">
                              <MDBCardImage
                                 className="image"
                                 src={Whatweoffer1}
                                 position="top"
                                 alt="..."
                              />
                           </div>
                           <MDBCardBody>
                              <MDBCardTitle className="title">
                                 Easy Personal Loan based on Terms and Conditions
                              </MDBCardTitle>
                              <MDBCardText className="text">1.10%</MDBCardText>
                           </MDBCardBody>
                        </MDBCard>
                     </MDBCol>
                     <MDBCol className="col-lg-5 col-xl-3 col-10 mb-4">
                        <MDBCard className="cards" style={{maxWidth: '22rem'}}>
                           <div className="inner">
                              <MDBCardImage
                                 className="image"
                                 src={Whatweoffer2}
                                 position="top"
                                 alt="..."
                              />
                           </div>
                           <MDBCardBody>
                              <MDBCardTitle className="title">
                                 Secured Prefunded Wallet Accessible 24/7.
                              </MDBCardTitle>
                              <MDBCardText className="text">1.10%</MDBCardText>
                           </MDBCardBody>
                        </MDBCard>
                     </MDBCol>
                     <MDBCol className="col-lg-5 col-xl-3 col-10 mb-4">
                        <MDBCard className="cards" style={{maxWidth: '22rem'}}>
                           <div className="inner">
                              <MDBCardImage
                                 className="image"
                                 src={Whatweoffer3}
                                 position="top"
                                 alt="..."
                              />
                           </div>
                           <MDBCardBody>
                              <MDBCardTitle className="title">
                                 Secured & Seameless Payment for Goods & Services
                              </MDBCardTitle>
                              <MDBCardText className="text">1.10%</MDBCardText>
                           </MDBCardBody>
                        </MDBCard>
                     </MDBCol>
                     <MDBCol className="mb-4 col-lg-5 col-xl-3 col-10">
                        <MDBCard className="cards" style={{maxWidth: '22rem'}}>
                           <div className="inner">
                              <MDBCardImage
                                 className="image"
                                 src={Whatweoffer4}
                                 position="top"
                                 alt="..."
                                 style={{transition: 'all 1.5 ease;'}}
                              />
                           </div>
                           <MDBCardBody>
                              <MDBCardTitle className="title">Flexible loan repayment</MDBCardTitle>
                              <MDBCardText className="text">1.10%</MDBCardText>
                           </MDBCardBody>
                        </MDBCard>
                     </MDBCol>
                     <MDBCol className="col-10 justify-content-center pt-5">
                        <MDBBtn
                           // href="#"
                           onClick={handleSignup}
                           style={{border: '1px solid #3377ef'}}
                           className="mt-0 btn5"
                           color="primary">
                           Apply Now
                        </MDBBtn>
                     </MDBCol>
                  </MDBRow>
               </MDBContainer>
            </section>
            <section className="service4">
               <MDBContainer fluid className="align-items-center mt-5" id="aboutus">
                  <MDBRow className="sub-titles mx-5 pt-1">
                     <MDBCol className="col-lg-4 col-sm-10 col-md-11 col-10 box squares">
                        <h5 className="ml-sm-1 mt-2 py-5">Who We Are</h5>

                        <h4 className="">Know more about us and how we operate</h4>
                        <p className="mt-1 mb-3">
                           At Buy and Go, our priority is to enable our platform users have access
                           to and seamlessly make payment for goods and services. That is why we
                           have perfected the plan of connecting buyers with sellers and in the
                           process promoting seller-buyer relationship. We use technology and people
                           to drive this process and we are available to render supports every day
                           and time of the week. <br />
                           <br />
                           We have also put arrangements in place that would enable users access
                           loan, subject to terms and conditions that would be the purchase of
                           consumable items only. Our intention is to take the worries of basic
                           survival off you until your next salary. At Buy and Go, we cherish
                           enhanced life standards and we plan to make this a daily gift to our
                           esteemed partners.
                        </p>

                        <div></div>
                     </MDBCol>
                     <MDBCol className="mx-5 py-5 col-lg-6 col-sm-11 col-md-11 col-11">
                        <img src={Whoweare} alt="" className="my-4 mx-5 px-5 img-fluid whoweare" />
                     </MDBCol>
                  </MDBRow>
               </MDBContainer>
            </section>
            <section className="service5 mt-3">
               <MDBContainer fluid className="align-items-center mt-5">
                  <MDBRow>
                     <MDBContainer>
                        <MDBRow className="bigbox gx-5 p-5">
                           <MDBCol className="col-12 col-lg-5 smallbox3">
                              <Mission className="img-fluid smallbox-img" />
                              <h4>Our Mission</h4>
                              <p>
                                 Lorem ipsum dolor sit amet, consectetur
                                 <br /> elit, sed do eiusmod tempor incididunt ut labore
                                 <br /> dolore magna aliqua. Ut enim ad minim veniam,
                                 <br />
                                 nostrud exercitation ullamco laboris nisi ut aliquip
                                 <br />
                                 ea commondo consequat oris nisi ut aliquip ex ea.
                              </p>
                           </MDBCol>
                           <MDBCol
                              className="col-12 col-lg-5 smallbox2"
                              style={{backgroundColor: '#e2ecfd'}}>
                              <Vision className="img-fluid smallbox-img" />
                              <h4>Our Vision</h4>
                              <p style={{color: '#777679'}}>
                                 Lorem ipsum dolor sit amet, consectetur
                                 <br /> elit, sed do eiusmod tempor incididunt ut labore
                                 <br /> dolore magna aliqua. Ut enim ad minim veniam,
                                 <br />
                                 nostrud exercitation ullamco laboris nisi ut aliquip
                                 <br />
                                 ea commondo consequat oris nisi ut aliquip ex ea.
                              </p>
                           </MDBCol>
                        </MDBRow>
                     </MDBContainer>
                  </MDBRow>
               </MDBContainer>
            </section>
            <section className="service6">
               <MDBContainer className="align-items-center my-5 py-5">
                  <div className="title mt-3 justify-content-center">
                     <Whiteleft className="img-fluid mr-3 left" />
                     <h3>Our Testimonials</h3>
                     <Whiteright className="img-fluid ml-3 right" />
                  </div>
                  <MDBRow className="subtitle  mt-3 justify-content-center">
                     <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor
                        <br /> incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        quis nostrud
                     </p>
                  </MDBRow>
                  <MDBCarousel hideControls showIndicators>
                     {/* <MDBCarouselInner> */}
                     <Quote className="quote1 d-none d-md-flex" alt="" />
                     <Quote className="quote2 d-none d-md-flex" alt="" />
                     <MDBCarouselItem itemId={1}>
                        <div className="col-12 col-md carouseltext">
                           <MDBCarouselCaption className="caption d-block">
                              <p>
                                 Since the time wey I start to dey collect loan from this app, e don
                                 do me better plus including the chance to buy plenty plenty tins.
                              </p>

                              <br />
                              <h5 style={{color: '#fff', display: 'flex'}}>Mrs Angelina Okonkwo</h5>
                           </MDBCarouselCaption>
                        </div>
                        <div className="col-12 col-md-6 carouselimage">
                           {/* <MDBCol className=""> */}
                           <img
                              src={Carousel2}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              alt="carousel"
                              className="img-fluid w-100 image"
                           />
                           {/* </MDBCol> */}
                        </div>
                     </MDBCarouselItem>

                     <MDBCarouselItem itemId={2}>
                        <div className="col-12 col-md carouseltext">
                           <MDBCarouselCaption className="caption d-block">
                              <p>
                                 Since the time wey I start to dey collect loan from this app, e don
                                 do me better plus including the chance to buy plenty plenty tins.
                              </p>

                              <br />
                              <h5 style={{color: '#fff', display: 'flex'}}>Mrs Angelina Okonkwo</h5>
                           </MDBCarouselCaption>
                        </div>
                        <div className="col-12 col-md-6 carouselimage">
                           {/* <MDBCol className=""> */}
                           <img
                              src={Carousel2}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              alt="carousel"
                              className="img-fluid w-100 image"
                           />
                           {/* </MDBCol> */}
                        </div>
                     </MDBCarouselItem>
                     <MDBCarouselItem itemId={3}>
                        <div className="col-12 col-md carouseltext">
                           <MDBCarouselCaption className=" caption d-block">
                              <p>
                                 Since I started receiving this loan, it has been really awesome and
                                 stress free, including with the opportunity to buy things from the
                                 app.
                              </p>

                              <br />
                              <h5 style={{color: '#fff', display: 'flex'}}>Mrs Nkemdili Ann</h5>
                           </MDBCarouselCaption>
                        </div>
                        <div className="col-12 col-md-6 carouselimage">
                           {/* <MDBCol className=""> */}
                           <img
                              src={Carousel2}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              alt="carousel"
                              className="img-fluid w-100 image"
                           />
                           {/* </MDBCol> */}
                        </div>
                     </MDBCarouselItem>
                     {/* </MDBCarouselInner> */}
                  </MDBCarousel>
               </MDBContainer>
            </section>
            <section className="service7 mt-5">
               <MDBContainer className="questions">
                  <div className="header py-2">
                     <h3 className="title">Frequently Asked Questions</h3>
                  </div>
                  <MDBRow className="faq-wrapper mx-4" onClick={() => toggleOpen(1)}>
                     <MDBCol size="12">
                        <MDBRow className="faq-row align-items-center">
                           <div className="indicator-wrapper d-flex align-items-center w-auto">
                              <Dot className="indicator" />
                           </div>
                           <div className="col d-flex align-items-center">
                              <h5 className="m-0"> What does Buy and Go have to offer?</h5>
                           </div>
                           <div className="arrow-wrapper w-auto d-flex align-items-center">
                              <Arrowdown
                                 className={classNames(' arrow', {
                                    open: isOpen[1],
                                 })}
                              />
                           </div>
                        </MDBRow>
                     </MDBCol>
                     <MDBCollapse tag="div" open={isOpen[1]} className="collapse">
                        <p className="px-5 mx-5 pb-5">
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                           tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur
                           adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                           ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                           incididunt ut labore et.
                        </p>
                     </MDBCollapse>
                  </MDBRow>
                  <MDBRow className="faq-wrapper mx-4" onClick={() => toggleOpen(2)}>
                     <MDBCol size="12">
                        <MDBRow className="faq-row align-items-center">
                           <div className="indicator-wrapper d-flex align-items-center w-auto">
                              <Dot className="indicator" />
                           </div>
                           <div className="col d-flex align-items-center">
                              <h5 className="m-0"> How fast will the loan be disbursed?</h5>
                           </div>
                           <div className="arrow-wrapper w-auto d-flex align-items-center">
                              <Arrowdown
                                 className={classNames('arrow', {
                                    open: isOpen[2],
                                 })}
                              />
                           </div>
                        </MDBRow>
                     </MDBCol>
                     <MDBCollapse tag="div" open={isOpen[2]} className="collapse">
                        <p className="px-5 mx-5 pb-5">
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                           tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur
                           adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                           ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                           incididunt ut labore et.
                        </p>
                     </MDBCollapse>
                  </MDBRow>
                  <MDBRow className="faq-wrapper mx-4" onClick={() => toggleOpen(3)}>
                     <MDBCol size="12">
                        <MDBRow className="faq-row align-items-center">
                           <div className="indicator-wrapper d-flex align-items-center w-auto">
                              <Dot className="indicator" />
                           </div>
                           <div className="col d-flex align-items-center">
                              <h5 className="m-0"> What does Buy and Go have to offer?</h5>
                           </div>
                           <div className="arrow-wrapper w-auto d-flex align-items-center">
                              <Arrowdown
                                 className={classNames('arrow', {
                                    open: isOpen[3],
                                 })}
                              />
                           </div>
                        </MDBRow>
                     </MDBCol>
                     <MDBCollapse tag="div" open={isOpen[3]} className="collapse">
                        <p className="px-5 mx-5 pb-5">
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                           tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur
                           adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                           ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                           incididunt ut labore et.
                        </p>
                     </MDBCollapse>
                  </MDBRow>
                  <MDBRow className="faq-wrapper mx-4" onClick={() => toggleOpen(4)}>
                     <MDBCol size="12">
                        <MDBRow className="faq-row align-items-center">
                           <div className="indicator-wrapper d-flex align-items-center w-auto">
                              <Dot className="indicator" />
                           </div>
                           <div className="col d-flex align-items-center">
                              <h5 className="m-0"> How fast will the loan be disbursed?</h5>
                           </div>
                           <div className="arrow-wrapper w-auto d-flex align-items-center">
                              <Arrowdown
                                 className={classNames('arrow', {
                                    open: isOpen[4],
                                 })}
                              />
                           </div>
                        </MDBRow>
                     </MDBCol>
                     <MDBCollapse tag="div" open={isOpen[4]} className="collapse">
                        <p className="px-5 mx-5 pb-5">
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                           tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur
                           adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                           ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                           incididunt ut labore et.
                        </p>
                     </MDBCollapse>
                  </MDBRow>
                  <MDBRow className="faq-wrapper mx-4" onClick={() => toggleOpen(5)}>
                     <MDBCol size="12">
                        <MDBRow className="faq-row align-items-center">
                           <div className="indicator-wrapper d-flex align-items-center w-auto">
                              <Dot className="indicator" />
                           </div>
                           <div className="col d-flex align-items-center">
                              <h5 className="m-0"> How fast will the loan be disbursed?</h5>
                           </div>
                           <div className="arrow-wrapper w-auto d-flex align-items-center">
                              <Arrowdown
                                 className={classNames('arrow', {
                                    open: isOpen[5],
                                 })}
                              />
                           </div>
                        </MDBRow>
                     </MDBCol>
                     <MDBCollapse tag="div" open={isOpen[5]} className="collapse">
                        <p className="px-5 mx-5 pb-5">
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                           tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur
                           adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                           ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                           incididunt ut labore et.
                        </p>
                     </MDBCollapse>
                  </MDBRow>
                  <MDBRow className="faq-wrapper mx-4" onClick={() => toggleOpen(6)}>
                     <MDBCol size="12">
                        <MDBRow className="faq-row align-items-center">
                           <div className="indicator-wrapper d-flex align-items-center w-auto">
                              <Dot className="indicator" />
                           </div>
                           <div className="col d-flex align-items-center">
                              <h5 className="m-0"> How fast will the loan be disbursed?</h5>
                           </div>
                           <div className="arrow-wrapper w-auto d-flex align-items-center">
                              <Arrowdown
                                 className={classNames('arrow', {
                                    open: isOpen[6],
                                 })}
                              />
                           </div>
                        </MDBRow>
                     </MDBCol>
                     <MDBCollapse tag="div" open={isOpen[6]} className="collapse">
                        <p className="px-5 mx-5 pb-5">
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                           tempor incididunt ut labore et Lorem ipsum dolor sit amet, consectetur
                           adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                           ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                           incididunt ut labore et.
                        </p>
                     </MDBCollapse>
                  </MDBRow>
               </MDBContainer>
            </section>

            <section className="service8 mt-3">
               <MDBContainer className="title ">
                  <MDBRow className="subtitles">
                     <MDBCol size="6" className="text">
                        <h3 className="">Get the App Now!!</h3>
                        <p>Download the Buy and Go app for loans, Purchasing and Payments</p>
                        <div className="getapp">
                           <Link to="/">
                              <img src={Google} alt="" className="img-fluid  image3" />
                           </Link>
                           <Link to="/">
                              <img src={Apple} alt="" className="img-fluid image4" />
                           </Link>
                        </div>
                     </MDBCol>
                     <MDBCol size="6" className="image">
                        <img src={Phones} alt="" className="img-fluid  phones" />
                     </MDBCol>
                  </MDBRow>
               </MDBContainer>
            </section>

            <section className="service9 py-5 d-flex flex-column flex-md-row justify-content-center align-items-center">
               <div className="px-3 px-lg-5">
                  <div className="footer d-flex flex-column flex-md-row justify-content-between ">
                     <div className="row">
                        <div className="col-md-3 company">
                           <h3> COMPANY NAME</h3>

                           <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                              minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                              aliquip ex ea commodo consequat.
                           </p>
                        </div>
                        <div className="col-md-6 content">
                           <div className="d-flex flex-column flex-md-row justify-content-center w-100">
                              <div>
                                 <h3> About</h3>

                                 <ul>
                                    <li>Home</li>

                                    <li>Services</li>

                                    <li className="">About us</li>
                                 </ul>
                              </div>
                              <div className="mx-md-5 px-md-5">
                                 <h3> Products</h3>

                                 <ul>
                                    <li>Loans</li>

                                    <li>Purchase</li>

                                    <li>Sell</li>
                                 </ul>
                              </div>
                              <div>
                                 <h3> Legal</h3>

                                 <ul>
                                    <li>Privacy & Policy</li>

                                    <li>FAQs</li>

                                    <li>Terms & Conditions</li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-3 contact">
                           <h3> Contact</h3>

                           <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                              minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                              aliquip ex ea commodo consequat.
                           </p>
                        </div>
                     </div>
                  </div>

                  {/* <div className="social">
                     <MDBCol size="10" className="px-5">
                        <Dash className="m-3 img-fluid dash" />

                        <Link to="/">
                           <Facebook className="m-3 img-fluid face" />
                        </Link>
                        <Link to="/">
                           <Twitter className="m-3 img-fluid tweet" />
                        </Link>
                        <Link to="/">
                           <Instagram className="m-3 img-fluid insta" />
                        </Link>
                        <Link to="/">
                           <Linkedin className="m-3 img-fluid link" />
                        </Link>
                        <Dash className="m-3 img-fluid dash" />
                     </MDBCol>
                  </div>
                  
                  <div className="copyright">
                     <p>All rights reserved. © 2021 Buy and Go Limited</p>
                  </div> */}
               </div>
            </section>
         </div>
      </>
   );
}

export default LandingPage;
