import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// import PropTypes from 'prop-types';
import {
   MDBCard,
   MDBCardTitle,
   MDBCardText,
   MDBCardBody,
   MDBCardImage,
   MDBRow,
   MDBCol,
} from 'mdb-react-ui-kit';
import {Bar} from 'react-chartjs-2';
import {Link} from 'react-router-dom';
import Modal from 'react-modal';
import FormControl from '@material-ui/core/FormControl';
import WalletIcon from '../../../_assets/images/wallet.svg';
import Formsy from 'formsy-react';
import TextInput from '../../../_components/TextInput';
import ArrowIcon from '../../../_assets/images/Arrowdown.svg';
import {ReactComponent as Vector} from '../../../_assets/icon/Vector.svg';
import Warning from '../../../_assets/images/warning.svg';
import Transaction from '../../../_assets/images/transaction.png';
import Chat from '../../../_assets/images/chat.png';
import Google from '../../../_assets/images/google2.png';
import Apple from '../../../_assets/images/apple2.png';
// import './index.scss';
import classNames from 'classnames';

const selectOptions = [
   {label: 'Select Year', value: 0},
   {label: 'Jan - Jun 2021', value: 1},
   {label: 'Jul - Dec 2021', value: 2},
];

function Home() {
   const merchant = useSelector((s) => s.merchant.merchant);

   const [canSubmit, setCanSubmit] = useState(false);
   const [modalIsOpen, setModalIsOpen] = useState(
      merchant?.merchant_details === null ? true : false,
   );
   const [secModalIsOpen, setSecModalIsOpen] = useState(false);
   const [showTextOpen, setShowTextOpen] = useState(false);
   const [yearState, setYearState] = useState(0);
   const [selectOpen, setSelectOpen] = useState(false);
   const customStyles = {
      content: {
         // top: '40%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginTop: '18%',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         backgroundColor: '#ffffff',
         borderRadius: '20px',
         padding: '40px 40px',
         width: '38%',
         overflow: 'hidden',
         // height: '50%',
      },
   };

   const handleSubmit = (data) => {
      // console.log(data);
   };

   useEffect(() => {
      // launch modal in here
      setModalIsOpen(true);
      setShowIsOpenToTrue(false);
   }, []);

   const setShowIsOpenToTrue = () => {
      setShowTextOpen(false);
   };
   const setSecModalIsOpenToTrue = () => {
      setSecModalIsOpen(true);
      setModalIsOpen(false);
   };
   const setSecModalIsOpenToFalse = () => {
      setSecModalIsOpen(false);
      setShowIsOpenToTrue(true);
   };

   const data = {
      labels:
         yearState !== 2
            ? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
            : ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      datasets: [
         {
            label: 'Amount Per Month',
            data: yearState !== 2 ? [10, 10, 10, 10, 10, 10] : [10, 10, 10, 10, 10, 10],
            backgroundColor: '#3377ef',
            borderRadius: '4',
         },
      ],
   };
   const options = {
      plugins: {
         legend: {
            display: false,
            // position: 'bottom',
         },
         tooltips: {
            callbacks: {
               label: function (tooltipItem) {
                  return tooltipItem.yLabel;
               },
            },
         },
      },

      scales: {
         x: {
            display: true,
            responsive: true,
            stacked: true,
            ticks: {
               color: '#000',
               font: function (context) {
                  if (context.tick) {
                     return {
                        weight: 'bolder',
                     };
                  }
               },
            },
            grid: {
               display: false,
               drawBorder: false,
            },
         },
         y: {
            min: 0,
            max: 80,
            responsive: true,
            stacked: true,
            ticks: {
               stepSize: 20,
               callback: function (label, index, labels) {
                  return label.toFixed(0) + 'k';
               },
               color: '#000',
               font: function (context) {
                  if (context.tick) {
                     return {
                        weight: 'bolder',
                     };
                  }
               },
            },
            display: true,

            grid: {
               display: false,
               drawBorder: false,
            },
         },
      },
   };
   return (
      <section className="homes">
         {/* <div isOpen={setShowIsOpenToTrue} className="green">
            <p>Your Details has been Updated Successfully</p>
         </div> */}
         <div className="first">
            <div className="balance">
               <div className="content">
                  {' '}
                  <p>Balance</p>
                  <img
                     src={WalletIcon}
                     alt=""
                     style={{width: '50px'}}
                     className="img-fluid image"
                  />
               </div>
               <div className="text">
                  <h3> ₦ 0,000</h3>
               </div>
               <MDBCard className="cards" style={{height: '70px', width: '140px'}}>
                  <MDBRow className="g-0">
                     <MDBCol md="4" className="leftside">
                        <img
                           src={ArrowIcon}
                           alt=""
                           style={{width: '17px'}}
                           className="img-fluid arrow"
                        />
                     </MDBCol>
                     <MDBCol md="8" className="rightside">
                        <MDBCardBody>
                           <p>Recieved Today</p>
                           <h5>00,000</h5>
                           <MDBCardText>
                              <small className="text-muted"></small>
                           </MDBCardText>
                        </MDBCardBody>
                     </MDBCol>
                  </MDBRow>
               </MDBCard>
               <MDBCard className="cardss" style={{height: '70px', width: '140px'}}>
                  <MDBRow className="g-0">
                     <MDBCol md="4" className="leftside">
                        <img
                           src={ArrowIcon}
                           alt=""
                           style={{width: '17px'}}
                           className="img-fluid arrow"
                        />
                     </MDBCol>
                     <MDBCol md="8" className="rightside">
                        <MDBCardBody>
                           <p>Withdrawn Today</p>
                           <h5>00,000</h5>
                           <MDBCardText>
                              <small className="text-muted"></small>
                           </MDBCardText>
                        </MDBCardBody>
                     </MDBCol>
                  </MDBRow>
               </MDBCard>
            </div>
            <div className="year p-3">
               <div
                  className="custom-select d-flex align-items-center justify-content-between"
                  value={yearState}
                  onClick={(e) => {
                     setSelectOpen((prev) => !prev);
                  }}>
                  <span>{selectOptions[yearState]?.label}</span>
                  <Vector className="" style={{marginLeft: '20px'}} />
                  {selectOpen && (
                     <ul className="position-absolute custom-selected">
                        {selectOptions
                           .filter((item) => item.value !== 0)
                           .map((item) => (
                              <li onClick={() => setYearState(item.value)}>{item.label}</li>
                           ))}
                     </ul>
                  )}
               </div>
               <div className="bar-chart" /* style={{height: '300px'}} */>
                  <Bar className="mt-5" data={data} height={140} options={options} />
                  {/* }> Steak
                  </option>
                  <option value="Bar">
                  <Bar
                     className="mt-4"
                     data={data}
                     height={180}
                     options={options}
                     />
                  </option>
                     </div>
                     {yearState} */}
               </div>
            </div>
         </div>
         <div className="second">
            <div className="balance">
               <img src={Transaction} alt="" style={{width: '35%'}} className="img-fluid rafiki" />
               <p>Ooops!... No recent transaction</p>
            </div>
            <div className="year">
               <img src={Chat} alt="" style={{width: '30%'}} className="img-fluid chat" />
               <p>
                  Our mobile app will be <br />
                  available soon
               </p>
               <div className="store">
                  <img
                     src={Google}
                     alt=""
                     // style={{width: '15%'}}
                     className="img-fluid google"
                  />
                  <img
                     src={Apple}
                     alt=""
                     // style={{width: '20%'}}
                     className="img-fluid apple"
                  />
               </div>
            </div>
         </div>
         <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            // style={{overlay: {zIndex: 1000}}
            // onRequestClose={() => setModalIsOpen(false)}>
         >
            <img src={Warning} alt="" style={{width: '15%'}} className="img-fluid warning" />
            <h3>Additional Information Required</h3>
            <p>kindly fill the necessary information </p>
            <Formsy className="__form d-flex flex-column">
               <button>
                  <div onClick={setSecModalIsOpenToTrue} className="button">
                     Proceed
                  </div>
               </button>
            </Formsy>
         </Modal>

         <Modal
            isOpen={secModalIsOpen}
            // className="modal2"
            style={customStyles}
            // style={{overlay: {zIndex: 1000}}
            // onRequestClose={() => setSecModalIsOpen(false)}>
         >
            <Formsy
               onValidSubmit={handleSubmit}
               onValid={() => setCanSubmit(true)}
               onInvalid={() => setCanSubmit(false)}>
               <div className="forms col-md-10">
                  <div id="textExample1" className="form-text" style={{color: 'black'}}>
                     Company's Name
                  </div>
                  <FormControl margin="normal" required fullWidth>
                     <TextInput
                        name="fullname"
                        wrapperClassName="input-wrapper"
                        mainWrapperClassName="w-100 mb-3 mb-s-4"
                        placeholder="Kalu John"
                        useFocusedError
                        className="input border-0 w-100"
                        validations="isWords"
                        required
                        validationErrors={{
                           isWords: 'Invalid value, must contain only letters!',
                           required: 'Required!',
                        }}
                     />
                  </FormControl>
               </div>
               <div className="forms col-md-10">
                  <div id="textExample1" className="form-text" style={{color: 'black'}}>
                     Company's Email
                  </div>
                  <FormControl margin="normal" required fullWidth>
                     <TextInput
                        name="email"
                        wrapperClassName="input-wrapper"
                        mainWrapperClassName="w-100 mb-3 mb-s-4"
                        placeholder="kalu@gmail"
                        useFocusedError
                        className="input border-0 w-100"
                        validations="isEmail"
                        required
                        validationErrors={{
                           isEmail: 'This is not a valid email',
                           required: 'Required!',
                        }}
                     />
                  </FormControl>
               </div>
               <div className="forms col-md-10">
                  <div id="textExample1" className="form-text" style={{color: 'black'}}>
                     Bank Verification Number
                  </div>
                  <FormControl margin="normal" required fullWidth>
                     <TextInput
                        name="fullname"
                        wrapperClassName="input-wrapper"
                        mainWrapperClassName="w-100 mb-3 mb-s-4"
                        placeholder=""
                        useFocusedError
                        required
                        className="input border-0 w-100"
                        validations="isInt"
                        validationErrors={{
                           isInt: 'Invalid value, must contain only numbers!',
                           required: 'Required!',
                        }}
                     />
                  </FormControl>
               </div>
               {/* <Link to="/homeempty"> */}
                  <button
                     // to="/verify"
                     disabled={!canSubmit}
                     type="submit"
                     className={classNames('signup', {disabled: !canSubmit})}
                     onClick={setSecModalIsOpenToFalse}>
                     Submit
                  </button>
               {/* </Link> */}
            </Formsy>
         </Modal>
      </section>
   );
}

export default Home;
