import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MDBInputGroup, MDBInputGroupElement, MDBInputGroupText} from 'mdb-react-ui-kit';
import classNames from 'classnames';
import {ReactComponent as Contact} from '../../_assets/icon/contact.svg';
// import {ReactComponent as Dots} from '../../_assets/icon/buygo-dots.svg';
import {ReactComponent as SearchIcon} from '../../_assets/icon/1ssearch.svg';
import {ReactComponent as Close} from '../../_assets/icon/close.svg';
// import {NavLink} from 'react-router-dom';
import Select from 'react-dropdown-select';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from 'react-modal';
// import {ReactComponent as NotificationIcon} from '../../_assets/icon/bell.svg';
// import {ReactComponent as AdminIcon} from '../../_assets/icon/adrian.svg';
// import {ReactComponent as WavingIcon} from '../../_assets/icon/wavinghand.svg';
// import {ReactComponent as Multiple} from '../../_assets/icon/multiple-users.svg';
// import {useLocationCode} from '../../_navigation';
// import {useLocation} from 'react-router-dom';
import './index.scss';
import {Avatar, FormControl} from '@material-ui/core';
import {capitalizeFirstLetter} from '../../_helpers';
import {countryConstants} from '../../_constants';

import {merchantActions, stateActions} from '../../_actions';
import TextInput from '../../_components/TextInput';
import Formsy from 'formsy-react';

function CompanyTab({modalOpen, setModalOpen, location, history, companyInfo, data}) {
   const dispatch = useDispatch();

   const userMerchants = useSelector((s) => s.merchant.userMerchants);
   // const data = useSelector((s) => s.merchant.data);
   const IsCreatingMerchantCompany = useSelector((s) => s.merchant.IsCreatingMerchantCompany);
   const merchantCompanyCreated = useSelector((s) => s.merchant.merchantCompanyCreated);
   const merchantCompanyId = useSelector((s) => s.merchant.merchantCompanyId);

   const errorMessage = useSelector((s) => s.merchant.errorMessage);

   // const dashboardSuccess = useSelector((s) => s.merchant.dashboardSuccess);

   // const [modalOpen, setModalOpen] = useState(false);
   const handleMerchant = (row) => {
      // console.log('row', row);

      // if (row.is_child === false) {
      dispatch(merchantActions.getDashBoard(row.merchant_id._id));
      dispatch(merchantActions.getMerchantCompanyInformation(row.merchant_id._id));
      // } else {
      //    dispatch(merchantActions.getMerchantCompanyInformation(row.merchant_id._id));
      // }
      setModalOpen();
      // history.push('/loader');
   };

   const [canSubmit, setCanSubmit] = useState(false);
   const statesList = useSelector((s) => s.state.statesList);
   const citiesList = useSelector((s) => s.state.citiesList);
   // const addedStates = [{name: 'Select a state', code: '0'}, ...statesList];
   // const addedCities = [{name: 'Select a City', code: '0'}, ...citiesList];
   // const newArr = addedStates.map((v) => ({...v, label: v.name, value: v._id}));
   const newArr = statesList.map((v) => ({...v, label: v.name, value: v._id}));
   const newCitiesArr = citiesList.map((v) => ({...v, label: v.name, value: v._id}));
   const [state, setState] = useState('0');
   const [city, setCity] = useState('0');
   const [passwordShown, setPasswordShown] = useState(false);

   const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
   };

   const handleSetState = (value) => {
      setState(value);
      dispatch(stateActions.getCities(value));
   };

   // const handleSubmit = (data) => {};
   const transformToFormData = (model) => {
      const fData = new FormData();
      fData.append('password', model.password);
      fData.append('permission', 1);
      fData.append('city', city);
      fData.append('state', state);
      fData.append('country', countryConstants.NIGERIA_COUNTRY_ID);
      fData.append('merchant_address', model.merchant_address);
      fData.append('merchant_email', model.merchant_email);
      fData.append('merchant_phone', model.merchant_phone.replace(/^0/, '234').trim());
      fData.append('merchant_name', model.merchant_name);
      // fData.append('id', merchantData?._id);

      return fData;
   };

   console.log('statesList', statesList);
   console.log('newArr', newArr);
   console.log('DATAUSER', data);

   const handleSubmit = (model) => {
      // console.log(model);
      const fData = transformToFormData(model);

      dispatch(merchantActions.createMerchantCompany(fData));
   };

   useEffect(() => {
      if (merchantCompanyCreated) {
         dispatch(merchantActions.getDashBoard(merchantCompanyId));
         dispatch(merchantActions.getMerchantCompanyInformation(merchantCompanyId));
         setSecondModalOpen(false);
         history.push('/merchant/home');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [merchantCompanyCreated]);

   // if (dashboardSuccess === 'success') {
   //    if (location?.state !== undefined) {
   //       console.log('location.state', location.state);
   //       data?.is_child === true
   //          ? history.push('/history')
   //          : history.push(location.state.from.pathname);
   //    } else {
   //       data?.is_child === true ? history.push('/history') : history.push('/home');
   //    }
   // }
   const [secondModalOpen, setSecondModalOpen] = useState(false);
   const setSecondModalOpenToTrue = () => {
      dispatch(stateActions.getStates());
      setSecondModalOpen(true);
      setModalOpen(false);
   };
   const setSecondModalOpenToFalse = () => {
      setSecondModalOpen(false);
      // setModalOpen(false);
   };
   const customStyles = {
      overlay: {
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundColor: 'rgba(255, 255, 255, 0.75)',
      },
      content: {
         // top: '40%',
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: '1000px',
         maxWidth: '90%',
         height: '70vh',
         maxHeight: '90%',
         backgroundColor: '#F7F8FC',
         borderTopLeftRadius: '5px',
         borderBottomLeftRadius: '5px',
         borderTopRightRadius: '5px',
         borderBottomRightRadius: '5px',
         padding: '40px 40px',
         overflowX: 'hidden',
         overflow: 'scroll',
         WebkitOverflowScrolling: 'touch',
         zIndex: '-100',
      },
   };
   const secondCustomStyles = {
      overlay: {
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundColor: 'rgba(255, 255, 255, 0.75)',
      },
      content: {
         // top: '40%',
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: '700px',
         maxWidth: '95%',
         height: '70vh',
         maxHeight: '90%',
         backgroundColor: '#fff',
         border: '0px',
         borderTopLeftRadius: '5px',
         borderBottomLeftRadius: '5px',
         borderTopRightRadius: '5px',
         borderBottomRightRadius: '5px',
         padding: '40px 40px',
         overflowX: 'hidden',
         overflow: 'scroll',
         WebkitOverflowScrolling: 'touch',
         zIndex: '-100',
      },
   };

   return (
      <>
         <Modal isOpen={modalOpen} style={customStyles} onRequestClose={() => setModalOpen(false)}>
            <div className="ms-0 tab">
               <div className="d-flex flex-column flex-md-row m-0">
                  <div className="mt-4 col-12 ms-md-0 col-md-3">
                     <p className="col-12  text-truncate company-name" style={{color: '#000'}}>
                        {/* {capitalizeFirstLetter(data?.firstname)} Companies */}
                     </p>
                  </div>
                  <div className="mt-md-4 mt-5 col-10 col-md-4">
                     <MDBInputGroup className=" ">
                        <input
                           type="text"
                           placeholder="search"
                           aria-label="Search"
                           style={{border: '0px'}}
                        />

                        <span style={{backgroundColor: '#3377ef'}}>
                           <SearchIcon
                              style={{width: '.85rem', color: '#fff', cursor: 'pointer'}}
                           />
                        </span>
                     </MDBInputGroup>
                  </div>
                  <div className="mt-3 mt-md-0 col-12 mx-auto mb-2 col-md-4 ps-3 ps-md-5">
                     <button
                        type="button"
                        onClick={setSecondModalOpenToTrue}
                        style={{
                           backgroundColor: '#3377EF',
                           color: '#fff',
                           textTransform: 'none',
                           borderRadius: '10px',
                           fontSize: '14px',
                           padding: '8px 10px',
                        }}
                        className="btn">
                        Create New Company
                     </button>
                  </div>
               </div>
               <div className="row mt-3 ms-4">
                  {userMerchants?.length > 0 &&
                     userMerchants.map((row) => {
                        return (
                           <div
                              onClick={() => handleMerchant(row)}
                              style={{cursor: 'pointer'}}
                              className="justify-content-center col-11 col-sm-5 col-xl-2 mt-2 me-0 me-md-3 tab p-4">
                              <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                                 <Contact />
                                 <p
                                    className="col-12 text-wrap text-truncate text-center ms-1 mt-4"
                                    style={{}}>
                                    {capitalizeFirstLetter(row.merchant_id.merchant_name)}
                                 </p>
                                 <br />
                                 {/* <p className="ms-1" style={{color: '#c4c4c4', fontWeight: '300'}}>
                                 Team A
                              </p> */}
                              </div>
                           </div>
                        );
                     })}
                  {/* <div
                  // onClick={() => handleMerchant(row)}
                  style={{cursor: 'pointer'}}
                  className="col-11 col-sm-5 col-xl-2 mt-2 me-3 tab p-3">
                  <div className="row mt-n2 float-end">
                     <Dots className="" />
                  </div>
                  <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                     <Contact />
                     <p className="mt-4 ms-1">Ifako Branch</p>
                     <br />
                     <p className="ms-1" style={{color: '#c4c4c4', fontWeight: '300'}}>
                        Team B
                     </p>
                  </div>
               </div>
               <div
                  // onClick={() => handleMerchant(row)}
                  style={{cursor: 'pointer'}}
                  className="col-11 col-sm-5 col-xl-2 mt-2 me-3 tab p-4">
                  <div className="row mt-n2 float-end">
                     <Dots className="" />
                  </div>
                  <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                     <Contact />
                     <p className="mt-4 ms-1">Ifako Branch</p>
                     <br />
                     <p className="ms-1" style={{color: '#c4c4c4', fontWeight: '300'}}>
                        Team c
                     </p>
                  </div>
               </div> */}
               </div>
            </div>
         </Modal>

         <Modal isOpen={secondModalOpen} style={secondCustomStyles}>
            <div className="d-flex justify-content-center smallbox">
               <div className="col-10 col-md-8 ms-5 mb-5 ms-md-2 mt-4">
                  <Close className="close-button" onClick={setSecondModalOpenToFalse} />
                  <div className="ms-0 ms-md-5 ps-2 mb-4 title">Create New Company</div>
                  <Formsy
                     onValidSubmit={handleSubmit}
                     onValid={() => setCanSubmit(true)}
                     onInvalid={() => setCanSubmit(false)}>
                     <div className="new-form col-md-10">
                        <div id="textExample1" className="form-text" style={{color: 'black'}}>
                           Company's Name
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="merchant_name"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="company's name"
                              usefocusedError
                              className="input border-0 w-100"
                              required
                           />
                        </FormControl>
                     </div>

                     <div className="new-form col-md-10">
                        <div id="textExample1" className="form-text" style={{color: 'black'}}>
                           Company's Address
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="merchant_address"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="company's address"
                              usefocusedError
                              className="input border-0 w-100"
                              required
                           />
                        </FormControl>
                     </div>
                     <div className="new-form col-md-10">
                        <div id="textExample1" className="form-text" style={{color: 'black'}}>
                           Company's Email
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="merchant_email"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="company's email"
                              usefocusedError
                              className="input border-0 w-100"
                              validations="isEmail"
                              validationErrors={{
                                 isEmail: 'This is not a valid email',
                                 required: 'Required!',
                              }}
                              required
                           />
                        </FormControl>
                        <h5 style={{color: 'red', fontSize: '11px'}}>
                           {errorMessage?.merchant_email}
                        </h5>
                     </div>
                     <div className="new-form col-md-10">
                        <div id="textExample1" className="form-text" style={{color: 'black'}}>
                           Company's Phone
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="merchant_phone"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="company's phone"
                              usefocusedError
                              className="input border-0 w-100"
                              required
                              validations={{isNumeric: true, minLength: 9, maxLength: 11}}
                              validationErrors={{
                                 isNumeric: 'Invalid phone number',
                                 minLength: 'Phone number incomplete',
                                 maxLength: 'Just 11 characters',
                              }}
                           />
                        </FormControl>
                        <h5 style={{color: 'red', fontSize: '11px'}}>
                           {errorMessage?.merchant_phone}
                        </h5>
                     </div>
                     <div className="new-form col-md-10 mb-4">
                        <div id="textExample1" className="form-text mb-1" style={{color: 'black'}}>
                           State
                        </div>
                        <Select
                           // value={state}
                           options={newArr}
                           onChange={(values) => handleSetState(values[0]._id)}
                           // options={newArr}
                           backspaceDelete
                           // onChange={(values) => handleSetState(values[0]._id)}
                        />
                        <h5 style={{color: 'red', fontSize: '11px'}}>{errorMessage?.state}</h5>
                     </div>
                     <div className="new-form col-md-10 mb-4">
                        <div id="textExample1" className="form-text mb-1" style={{color: 'black'}}>
                           City
                        </div>
                        <Select
                           // value={city}
                           options={newCitiesArr}
                           backspaceDelete
                           onChange={(values) => setCity(values[0]._id)}
                        />
                        <h5 style={{color: 'red', fontSize: '11px'}}>{errorMessage?.city}</h5>
                     </div>
                     <div className="new-form col-md-10">
                        <div id="textExample1" className="form-text" style={{color: 'black'}}>
                           Your Current Password
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="password"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="current password"
                              usefocusedError
                              type={passwordShown ? 'text' : 'password'}
                              className="input border-0 w-100"
                              // validations="isWords"
                              // required
                              // validationErrors={{
                              //    isWords: 'Invalid value, must contain only letters!',
                              //    required: 'Required!',
                              // }}
                           />
                           <i onClick={togglePasswordVisiblity}>
                              {passwordShown ? <Visibility /> : <VisibilityOff />}
                           </i>
                        </FormControl>
                     </div>

                     {/* <div className="col-xl-11 ms-md-1 mb-4" marginLeft: '21.5rem',> */}
                     <button
                        disabled={!canSubmit}
                        type="submit"
                        className={classNames(
                           'col-12 d-none d-md-none d-lg-none d-xl-flex justify-content-center signbtn',
                           {
                              disabled: !canSubmit,
                           },
                        )}
                        style={{marginLeft: '21.5rem', padding: '.5rem 9.2rem'}}>
                        {IsCreatingMerchantCompany ? (
                           <CircularProgress color="inherit" thickness={1} />
                        ) : (
                           'Add'
                        )}
                     </button>
                     <button
                        disabled={!canSubmit}
                        type="submit"
                        className={classNames(
                           'col-12 d-none d-md-none d-lg-flex d-xl-none justify-content-center signbtn',
                           {
                              disabled: !canSubmit,
                           },
                        )}
                        style={{marginLeft: '3.5rem', padding: '.5rem 9.2rem'}}>
                        {IsCreatingMerchantCompany ? (
                           <CircularProgress color="inherit" thickness={1} />
                        ) : (
                           'Add'
                        )}
                     </button>
                     <button
                        disabled={!canSubmit}
                        type="submit"
                        className={classNames(
                           'col-10 d-none d-md-flex d-lg-none d-xl-none justify-content-center signbtn',
                           {
                              disabled: !canSubmit,
                           },
                        )}
                        style={{marginLeft: '3.4rem', padding: '.5rem 9.2rem'}}>
                        {IsCreatingMerchantCompany ? (
                           <CircularProgress color="inherit" thickness={1} />
                        ) : (
                           'Add'
                        )}
                     </button>
                     <button
                        disabled={!canSubmit}
                        type="submit"
                        className={classNames(
                           'col-10 d-none d-sm-flex d-md-none d-lg-none justify-content-center signbtn',
                           {
                              disabled: !canSubmit,
                           },
                        )}
                        style={{marginLeft: '8rem', padding: '.5rem 5.2rem'}}>
                        {IsCreatingMerchantCompany ? (
                           <CircularProgress color="inherit" thickness={1} />
                        ) : (
                           'Add'
                        )}
                     </button>
                     <button
                        disabled={!canSubmit}
                        type="submit"
                        className={classNames(
                           'col-10 d-flex d-sm-none d-md-none d-lg-none justify-content-center signbtn',
                           {
                              disabled: !canSubmit,
                           },
                        )}
                        style={{marginLeft: '2rem', padding: '.5rem 5.9rem'}}>
                        {IsCreatingMerchantCompany ? (
                           <CircularProgress color="inherit" thickness={1} />
                        ) : (
                           'Add'
                        )}
                     </button>
                     {/* </div> */}
                  </Formsy>
               </div>
            </div>
         </Modal>
      </>
   );
}

export default CompanyTab;
