import {call, put, takeLatest, all} from 'redux-saga/effects';
import {transactionConstants, merchantConstants} from '../../_constants';
import {
   createRequestWithToken,
   checkStatus,
   getObjectFromStorage,
   clearObjectFromStorage,
} from '../../_helpers';

function* getAllTransactions({data}) {
   yield put({type: transactionConstants.REQUEST_ALL_TRANSACTIONS});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      const merchantCompany = yield call(
         getObjectFromStorage,
         merchantConstants.MERCHANT_COMPANY_KEY,
      );
      const merchantSubUser = yield call(getObjectFromStorage, merchantConstants.MERCHANT_SUB_USER_KEY);
      let allTransactionsUri = `${transactionConstants.ALL_TRANSACTIONS_URI}${merchantCompany?.details?._id || data?.merchantId}?type=${data.type}`;

      if (data?.page) {
         allTransactionsUri = `${allTransactionsUri}&page=${data.page + 1}`;
      }

      const req = createRequestWithToken(allTransactionsUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(merchant?.token || merchantSubUser?.token);

      const response = yield call(fetch, req);

      if (response?.status === 401) {
         yield call(clearObjectFromStorage, merchantConstants.MERCHANT_KEY);

         yield put({type: merchantConstants.TOKEN_HAS_EXPIRED});
         return;
      }
      yield call(checkStatus, response);
      if (response.status === 204) {
         yield put({
            type: transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS_WITHOUT_DATA,
         });

         return;
      }
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS,
         allTransactions: jsonResponse,
      });
   } catch (error) {
      yield put({
         type: transactionConstants.REQUEST_ALL_TRANSACTIONS_ERROR,
      });
   }
}

function* getAllTransactionsAsASubUser({data}) {
   yield put({type: transactionConstants.REQUEST_ALL_TRANSACTIONS_AS_A_SUB_USER});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_SUB_USER_KEY);
      console.log('merchant', merchant);
      let allTransactionsUri = `${transactionConstants.ALL_TRANSACTIONS_AS_A_SUB_USER_URI}${merchant?.user?.merchant_id}`;

      if (data?.page) {
         allTransactionsUri = `${allTransactionsUri}&page=${data.page + 1}`;
      }

      const req = createRequestWithToken(allTransactionsUri, {
         method: 'GET',
      })(merchant?.token);

      const response = yield call(fetch, req);

      if (response?.status === 401) {
         yield call(clearObjectFromStorage, merchantConstants.MERCHANT_KEY);

         yield put({type: merchantConstants.TOKEN_HAS_EXPIRED});
         return;
      }
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: transactionConstants.REQUEST_ALL_TRANSACTIONS_AS_A_SUB_USER_SUCCESS,
         allTransactionsAsASubUser: jsonResponse,
      });
   } catch (error) {
      yield put({
         type: transactionConstants.REQUEST_ALL_TRANSACTIONS_AS_A_SUB_USER_ERROR,
      });
   }
}

function* getAllWithdrawals({data}) {
   yield put({type: transactionConstants.REQUEST_ALL_WITHDRAWALS});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      const merchantCompany = yield call(
         getObjectFromStorage,
         merchantConstants.MERCHANT_COMPANY_KEY,
      );

      let allWithdrawalsUri = `${transactionConstants.ALL_TRANSACTIONS_URI}${merchantCompany?.details?._id}?type=${data.type}`;

      if (data?.page) {
         allWithdrawalsUri = `${allWithdrawalsUri}&page=${data.page + 1}`;
      }

      const req = createRequestWithToken(allWithdrawalsUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(merchant?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: transactionConstants.REQUEST_ALL_WITHDRAWALS_SUCCESS_WITHOUT_DATA,
         });

         return;
      }
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: transactionConstants.REQUEST_ALL_WITHDRAWALS_SUCCESS,
         allWithdrawals: jsonResponse,
      });
   } catch (error) {
      yield put({
         type: transactionConstants.REQUEST_ALL_WITHDRAWALS_ERROR,
      });
   }
}

function* filterTransactions({data}) {
   yield put({type: transactionConstants.REQUEST_FILTER_TRANSACTIONS});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);

      let searchCustomerUri = `${transactionConstants.GET_CUSTOMER_URI}/advanced_search`;

      const req = createRequestWithToken(searchCustomerUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(merchant?.token);
      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: transactionConstants.FILTER_TRANSACTIONS_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: transactionConstants.FILTER_TRANSACTIONS_SUCCESS,
         tranaction: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: transactionConstants.FILTER_TRANSACTIONS_ERROR,
         error: errorMessage,
      });
   }
}

function* filterWithdrawals({data}) {
   yield put({type: transactionConstants.REQUEST_FILTER_WITHDRAWALS});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);

      let searchCustomerUri = `${transactionConstants.GET_CUSTOMER_URI}/advanced_search`;

      const req = createRequestWithToken(searchCustomerUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(merchant?.token);
      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: transactionConstants.FILTER_WITHDRAWALS_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: transactionConstants.FILTER_WITHDRAWALS_SUCCESS,
         tranaction: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: transactionConstants.FILTER_WITHDRAWALS_ERROR,
         error: errorMessage,
      });
   }
}

function* getAllTransactionsSagaWatcher() {
   yield takeLatest(transactionConstants.GET_ALL_TRANSACTIONS, getAllTransactions);
}

function* getAllTransactionsAsASubUserSagaWatcher() {
   yield takeLatest(
      transactionConstants.GET_ALL_TRANSACTIONS_AS_A_SUB_USER,
      getAllTransactionsAsASubUser,
   );
}

function* getAllWithdrawalsSagaWatcher() {
   yield takeLatest(transactionConstants.GET_ALL_WITHDRAWALS, getAllWithdrawals);
}

function* filterTransactionsSagaWatcher() {
   yield takeLatest(transactionConstants.FILTER_TRANSACTIONS, filterTransactions);
}

function* filterWithdrawalsSagaWatcher() {
   yield takeLatest(transactionConstants.FILTER_WITHDRAWALS, filterWithdrawals);
}

export default function* rootSaga() {
   yield all([
      getAllTransactionsSagaWatcher(),
      getAllTransactionsAsASubUserSagaWatcher(),
      getAllWithdrawalsSagaWatcher(),
      filterTransactionsSagaWatcher(),
      filterWithdrawalsSagaWatcher(),
   ]);
}
