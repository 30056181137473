import {range} from 'lodash/fp';
import localForage from 'localforage';
export * from './reducerUtility';

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const getObjectFromStorage = async (key) => {
   try {
      const object = await localForage.getItem(key);

      if (!object) {
         return null;
      }

      return object;
   } catch (error) {
      throw error;
   }
};

export const getObjectFromStorageWithExpiry = async (key) => {
   try {
      const object = await localForage.getItem(key);

      if (!object) {
         return null;
      }

      const item = JSON.parse(object);
      const now = new Date();
      //return object
      if (now.getTime() > item.expiry) {
         // If the item is expired, delete the item from storage
         // and return null
         localStorage.removeItem(key);
         return null;
      }
      return item.value;
   } catch (error) {
      throw error;
   }
};

export const clearObjectFromStorage = async (key) => {
   try {
      await localForage.removeItem(key);
      return true;
   } catch (error) {
      throw error;
   }
};

export const setObjectInStorage = async (key, object) => {
   try {
      await localForage.setItem(key, object);
      return true;
   } catch (error) {
      throw error;
   }
};

export const setObjectInStorageWithExpiry = async (key, object, ttl) => {
   try {
      const now = new Date();
      const item = {
         value: object,
         expiry: now.getTime() + ttl,
      };
      await localForage.setItem(key, JSON.stringify(item));
      return true;
   } catch (error) {
      throw error;
   }
};

export const checkStatus = (response) => {
   if (response.status >= 200 && response.status < 300) {
      return response;
   } else {
      const error = new Error(`HTTP Error ${response.statusText}`);
      error.status = response.statusText;
      error.response = response;

      throw error;
   }
};

export const checkContentType = (contentType) => (response) => {
   const responseContentType = response.headers.get('content-type');
   if (responseContentType && responseContentType.includes(contentType)) {
      return response;
   }
   return null;
};

export const parseJSON = (response) => response.json();

export const createRequestWithToken =
   (url = '', config) =>
   (token) => {
      const validMethods = ['GET', 'POST', 'HEAD', 'PUT', 'DELETE', 'PATCH'];
      const defaultconfig = {
         mode: 'cors',
         cache: 'default',
         credentials: 'same-origin',
      };
      const defaultHeaders = new Headers();

      defaultHeaders.set('Content-Type', 'application/json');
      defaultHeaders.set('Authorization', `Bearer ${token}`);
      defaultHeaders.set('Accept', 'application/json');

      if (typeof config.method !== 'string') {
         throw new TypeError('config method property must be a string.');
      }
      if (validMethods.indexOf(config.method.toUpperCase()) === -1) {
         throw Error(
            "config method property value most be one of ['GET','POST','HEAD','PUT','DELETE']",
         );
      }

      config.headers = config.headers || defaultHeaders;

      if (config.headers && !config.headers instanceof Headers) {
         throw new TypeError('config headers property must be of type Headers.');
      }

      const requestConfig = {
         ...defaultconfig,
         ...config,
      };
      return new Request(url, requestConfig);
   };

export const createMultiPartRequestWithToken =
   (url = '', config) =>
   (token) => {
      const validMethods = ['GET', 'POST', 'HEAD', 'PUT', 'DELETE', 'PATCH'];
      const defaultconfig = {
         mode: 'cors',
         cache: 'default',
         credentials: 'same-origin',
      };
      const defaultHeaders = new Headers();
      defaultHeaders.set('Authorization', `Bearer ${token}`);
      defaultHeaders.set('Accept', `application/json`);

      if (typeof config.method !== 'string') {
         throw new TypeError('config method property must be a string.');
      }
      if (validMethods.indexOf(config.method.toUpperCase()) === -1) {
         throw Error(
            "config method property value most be one of ['GET','POST','HEAD','PUT','DELETE']",
         );
      }

      config.headers = config.headers || defaultHeaders;

      if (config.headers && !config.headers instanceof Headers) {
         throw new TypeError('config headers property must be of type Headers.');
      }

      const requestConfig = {
         ...defaultconfig,
         ...config,
      };
      return new Request(url, requestConfig);
   };

export const createRequest = (url = '', config, token = '') => {
   const validMethods = ['GET', 'POST', 'HEAD', 'PUT', 'DELETE', 'PATCH'];
   const defaultconfig = {
      mode: 'cors',
      cache: 'default',
      credentials: 'same-origin',
   };
   const defaultHeaders = new Headers();
   defaultHeaders.set('Content-Type', 'application/json');
   defaultHeaders.set('Authorization', `Bearer ${token}`);
   defaultHeaders.set('Accept', `application/json`);

   if (typeof config.method !== 'string') {
      throw new TypeError('config method property must be a string.');
   }
   if (validMethods.indexOf(config.method.toUpperCase()) === -1) {
      throw Error(
         "config method property value most be one of ['GET','POST','HEAD','PUT','DELETE']",
      );
   }

   config.headers = config.headers || defaultHeaders;

   if (config.headers && !config.headers instanceof Headers) {
      throw new TypeError('config headers property must be of type Headers.');
   }

   const requestConfig = {
      ...defaultconfig,
      ...config,
   };
   return new Request(url, requestConfig);
};

export const is_Array = (value) =>
   value && typeof value === 'object' && value.constructor === Array;

export const createPager = ({totalCount: totalItems, pageSize}, currentPage) => {
   if (!totalItems) {
      return false;
   }
   const totalPages = Math.ceil(totalItems / pageSize);
   let startPage, endPage;
   if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
   } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
         startPage = 1;
         endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
         startPage = totalPages - 9;
         endPage = totalPages;
      } else {
         startPage = currentPage - 5;
         endPage = currentPage + 4;
      }
   }
   // calculate start and end item indexes
   const startIndex = (currentPage - 1) * pageSize;
   const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

   // create an array of pages to ng-repeat in the pager control
   const pages = range(startPage, endPage + 1);

   // return object with all pager properties required by the view
   return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
   };
};

export const removeTrailingSlash = (str) => {
   return str.split('')[str.length - 1] === '/'
      ? str
           .split('')
           .slice(0, str.length - 1)
           .join('')
      : str;
};

export const validateSubDomain = (sub, domain) => {
   // console.log('sub', sub);
   // console.log('domain', domain);

   const sub1 = sub.toLowerCase();
   const domain1 = domain.toLowerCase();
   if (sub1 === 'www' || sub === domain1 || sub1 === 'jobboard') {
      return null;
   }
   return sub;
};

export const capitalizeFirstLetter = (stringx) => {
   let string = stringx.toLowerCase();
   string = string.split(' ');

   for (var i = 0; i < string.length; i++) {
      string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
   }

   return string.join(' ');
};

export const getKey = (obj, value) => {
   return Object.keys(obj).find((key) => obj[key] === value);
};

export const getValue = (obj, value) => {
   return Object.values(obj).find((key) => obj[key] === value);
};

export const isANumber = (str) => {
   return !/\D/.test(str);
};

export const transformToFormData = (data) => {
   var fData = new FormData();
   // console.log('mydata', data);
   fData.append('cv_filename', data.cv_file);
   fData.append('other_file_filename', data.other_file);
   fData.append('firstname', data.firstname);
   fData.append('lastname', data.lastname);
   fData.append('email', data.email);
   fData.append('phone_number', data.phone_number);
   fData.append('linkedInProfile', data.linkedInProfile);
   fData.append('job_advert_uuid', data.job_advert_uuid);

   if (fData != null) {
      for (var value of fData) {
         // console.log(value);
      }
   }

   return fData;
};

export const readableDateString = (dateString) => {
   if (isDate(dateString)) {
      var date = new Date(dateString);

      return date.toDateString();
   }

   return 'no date';
};

export const GetTime = (date) => {
   var currentTime = new Date(date);
   var hours = currentTime.getHours();
   //Note: before converting into 12 hour format
   var suffix = '';
   if (hours > 11) {
      suffix += 'PM';
   } else {
      suffix += 'AM';
   }
   var minutes = currentTime.getMinutes();
   if (minutes < 10) {
      minutes = '0' + minutes;
   }
   if (hours > 12) {
      hours -= 12;
   } else if (hours === 0) {
      hours = 12;
   }
   var time = hours + ':' + minutes + ' ' + suffix;

   return time;
};

export const isDate = (sDate) => {
   if (sDate.toString() === parseInt(sDate).toString()) return false;
   var tryDate = new Date(sDate);
   return tryDate && tryDate.toString() !== 'NaN' && tryDate !== 'Invalid Date';
};

export const dateDifference = (fromDate) => {
   var date2 = new Date();
   var date1 = new Date(fromDate);
   var timeDiff = Math.abs(date2.getTime() - date1.getTime());

   return Math.ceil(timeDiff / (1000 * 3600 * 24));
};

export const generateString = (length) => {
   var result = '';
   var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;

   for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }

   return result;
};

// Slugify a string
export const slugify = (str) => {
   str = str.replace(/^\s+|\s+$/g, '');

   // Make the string lowercase
   str = str.toLowerCase();

   // Remove accents, swap ñ for n, etc
   var from =
      'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;';
   var to =
      'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------';
   for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
   }

   // Remove invalid chars
   str = str
      .replace(/[^a-z0-9 -]/g, '')
      // Collapse whitespace and replace by -
      .replace(/\s+/g, '-')
      // Collapse dashes
      .replace(/-+/g, '-');

   return str;
};

export function numberWithCommas(x) {
   if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
   }
   return 0;
}

export const isEmptyObject = (obj) => {
   for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
   }
   return true;
};
