import {call, put, takeLatest, all} from 'redux-saga/effects';
import {forgotPasswordConstants} from '../../_constants';
import {appActions} from '../../_actions';
import {createRequest, createRequestWithToken, checkStatus, parseJSON} from '../../_helpers';

function* getResetPasswordLinkSaga({data}) {
   yield put({type: forgotPasswordConstants.REQUEST_SEND_RESET_PASSWORD_LINK});

   try {
      const curriedReq = yield call(
         createRequest,
         forgotPasswordConstants.RESET_PASSWORD_LINK_URI,
         {
            method: 'POST',
            body: JSON.stringify(data),
         },
      );

      const response = yield call(fetch, curriedReq);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: forgotPasswordConstants.SEND_RESET_PASSWORD_LINK_SUCCESS,
         resetPasswordLink: jsonResponse,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);
         console.log('verify email error', res);
         if (res.errors) {
            let errors = {};
            console.log('res', res);
            for (let i = 0; i < res.errors.length; i++) {
               errors[res.errors[i].param] = res.errors[i].msg;
            }
            yield put({
               type: forgotPasswordConstants.SEND_RESET_PASSWORD_LINK_ERROR,
               error: errors,
            });

            return;
         }

         yield put({
            type: forgotPasswordConstants.SEND_RESET_PASSWORD_LINK_ERROR,
            error: res.message,
         });

         return;
      }

      console.log('verify email error', error);
      yield put({
         type: forgotPasswordConstants.SEND_RESET_PASSWORD_LINK_ERROR,
         error: error.message,
      });
   }
}

function* resetPasswordSaga({data}) {
   yield put({type: forgotPasswordConstants.REQUEST_RESET_PASSWORD});

   try {
      let customerPasswordResetUri = `${forgotPasswordConstants.RESET_PASSWORD_URI}/merchant/reset_password`;

      const customerPasswordResetReq = createRequestWithToken(customerPasswordResetUri, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(data.reset_token);

      const response = yield call(fetch, customerPasswordResetReq);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: forgotPasswordConstants.RESET_PASSWORD_SUCCESS,
         passwordReset: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      console.log('reset password error', errorMessage);

      // if (error.response) {
      //    const res = yield call(parseJSON, error.response);
      //    if (res.error) {
      //       let errors = {};
      //       for (let i = 0; i < res.error.length; i++) {
      //          errors[res.error[i].param] = res.error[i].msg;
      //       }
      //       yield put({
      //          type: forgotPasswordConstants.RESET_PASSWORD_ERROR,
      //          error: errors,
      //       });

      //       return;
      //    }
      //    yield put({
      //       type: forgotPasswordConstants.RESET_PASSWORD_ERROR,
      //       error: res.message,
      //    });

      //    return;
      // }

      console.log('reset password error', error);
      yield put({
         type: forgotPasswordConstants.RESET_PASSWORD_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage?.message ? errorMessage.message : 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* getResetPasswordLinkSagaWatcher() {
   yield takeLatest(forgotPasswordConstants.SEND_RESET_PASSWORD_LINK, getResetPasswordLinkSaga);
}

function* resetPasswordSagaWatcher() {
   yield takeLatest(forgotPasswordConstants.RESET_PASSWORD, resetPasswordSaga);
}

export default function* rootSaga() {
   yield all([getResetPasswordLinkSagaWatcher(), resetPasswordSagaWatcher()]);
}
