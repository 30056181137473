import React from 'react';
import {ReactComponent as HomeIcon} from '../_assets/icon/Home.svg';
import {ReactComponent as PaymentIcon} from '../_assets/icon/payment.svg';
import {ReactComponent as HistoryIcon} from '../_assets/icon/history.svg';
import {ReactComponent as AccountIcon} from '../_assets/icon/profile.svg';
// import {ReactComponent as ExitIcon} from '../_assets/icon/exit.svg';

export const dashboardData = [
   {
      title: 'Home',
      icon: <HomeIcon />,
      link: '/merchant/home',
   },
   {
      title: 'Payment',
      icon: <PaymentIcon />,
      link: '/merchant/payment',
   },
   {
      title: 'History',
      icon: <HistoryIcon />,
      link: '/merchant/history',
   },
   {
      title: 'Account',
      icon: <AccountIcon />,
      link: '/merchant/account',
   },
];
