import React, {useState, useEffect, useRef, useCallback} from 'react';
import {MDBContainer, MDBCol} from 'mdb-react-ui-kit';
// import {merchantConstants} from '../../../_constants';
import {merchantActions} from '../../../_actions';
import {useDispatch, useSelector} from 'react-redux';
import Formsy from 'formsy-react';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import BlueYellow from '..//..//../_assets/images/blueandyellow.svg';
import {Link, Redirect} from 'react-router-dom';
import './index.scss';

function OTP() {
   const dispatch = useDispatch();
   const {
      phoneToVerify,
      IsVerifyingPhone,
      verifyPhoneSuccess,
      phoneVerificationPinId,
      unverifiedUserId,
      errorMessage,
      IsResendingOtp,
      resendOtpSuccess,
   } = useSelector((s) => s.auth);
   const errorMessages = useSelector((s) => s.merchant.errorMessage);
   const [codeError, setCodeError] = useState('');
   const [canSubmit, setCanSubmit] = useState(false);
   const [remainingTime, setRemainingTime] = useState(10 * 60); // 10 minutes in seconds

   useEffect(() => {
      const timerId = setInterval(() => {
         if (remainingTime > 0) {
            setRemainingTime((prevTime) => prevTime - 1);
         } else {
            clearInterval(timerId);
         }
      }, 1000); // Update every second

      return () => clearInterval(timerId); // Cleanup function to clear timer on unmount
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []); // Empty dependency array ensures the effect runs only once on component mount

   const minutes = Math.floor(remainingTime / 60);
   const seconds = remainingTime % 60;

   const handleSubmit = (data) => {
      data['_id'] = unverifiedUserId;
      data['phone_number'] = `234${phoneToVerify}`;
      data['pinId'] = phoneVerificationPinId;
      data['otp'] = code;

      dispatch(merchantActions.verifyPhone(data));
   };

   useEffect(() => {
      if (typeof errorMessage === 'string') {
         setCodeError(errorMessage);
      }

      // dispatch(merchantActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [errorMessage]);

   const [one, setOne] = useState('');
   const [two, setTwo] = useState('');
   const [three, setThree] = useState('');
   const [four, setFour] = useState('');
   const [five, setFive] = useState('');
   const [six, setSix] = useState('');

   const [oneSet, setOneSet] = useState(false);
   const [twoSet, setTwoSet] = useState(false);
   const [threeSet, setThreeSet] = useState(false);
   const [fourSet, setFourSet] = useState(false);
   const [fiveSet, setFiveSet] = useState(false);
   const [sixSet, setSixSet] = useState(false);

   const code = `${one}${two}${three}${four}${five}${six}`;

   const oneRef = useRef();
   const twoRef = useRef();
   const threeRef = useRef();
   const fourRef = useRef();
   const fiveRef = useRef();
   const sixRef = useRef();

   const clearError = () => {
      setCodeError('');
   };

   const setError = (itm) => {
      setCodeError(itm);
   };

   const validateCode = (val, setter) => {
      console.log('code val', val);
      if (val.length === 0) {
         clearError();
         setter(false);
         return true;
      }

      if (!/^[0-9]$/.test(val)) {
         setError('Code can only contain numbers!');
         setter(false);
         return false;
      }

      clearError();
      setter(true);
      return true;
   };

   const handleOneChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setOneSet)) {
         setOne(itemValue);
         if (itemValue.length) {
            twoRef?.current?.focus();
         }
      }
   };

   const handleTwoChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setTwoSet)) {
         setTwo(itemValue);
         if (itemValue.length) {
            threeRef?.current?.focus();
         }
      }
   };

   const handleThreeChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setThreeSet)) {
         setThree(itemValue);
         if (itemValue.length) {
            fourRef?.current?.focus();
         }
      }
   };

   const handleFourChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setFourSet)) {
         setFour(itemValue);
         if (itemValue.length) {
            fiveRef?.current?.focus();
         }
      }
   };

   const handleFiveChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setFiveSet)) {
         setFive(itemValue);
         if (itemValue.length) {
            sixRef?.current?.focus();
         }
      }
   };

   const handleSixChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setSixSet)) {
         setSix(itemValue);
         if (itemValue.length) {
            sixRef?.current?.blur();
         }
      }
   };

   const handleWrongNumber = () => {
      dispatch(merchantActions.wrongNumber());
   };

   // console.log('code', code);

   const handleResendOtp = () => {
      const data = {
         phone_number: `234${phoneToVerify}`,
         _id: unverifiedUserId,
         pinId: phoneVerificationPinId,
         // country_code: '234',
      };
      console.log('data', data);
      dispatch(merchantActions.reSendOtp(data));
   };

   const onkeypress = useCallback(
      (e, value, prevRef, setState) => {
         if (e.keyCode === 8) {
            if (!value.length) {
               if (prevRef) {
                  prevRef.focus();
                  setState('');
               }
            }
         }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
   );

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   if (verifyPhoneSuccess) {
      return <Redirect to="/pin" />;
   }
   console.log(errorMessage);

   return (
      <>
         <section className="otp">
            <img src={BlueYellow} alt="" className="img-fluid image" />
            <MDBContainer className="align-items-center">
               <div className="bigboxes">
                  <MDBCol className="text">
                     <br />
                     <h3>
                        Enter the <br />
                        Code Sent to
                        <span style={{color: '#c4c4c4'}}> 0{phoneToVerify} </span>
                     </h3>
                     <span>
                        <Link className="ms-md-5" onClick={handleWrongNumber} to="/phone">
                           Wrong number?
                        </Link>
                     </span>
                  </MDBCol>
                  <MDBCol className="smallbox">
                     <MDBCol className="smallbox2">
                        <Formsy
                           onValidSubmit={handleSubmit}
                           onValid={() => setCanSubmit(true)}
                           onInvalid={() => setCanSubmit(false)}>
                           <div className="forms flex col-md-10">
                              {/* <h5>{errorMessage}</h5> */}
                              <h5>
                                 OTP expires in: {minutes}:{seconds.toString().padStart(2, '0')}
                              </h5>
                              {codeError && <h5 className="error">{codeError}</h5>}
                              <FormControl margin="normal" style={{marginRight: '14px'}}>
                                 <input
                                    name="one"
                                    value={one}
                                    ref={oneRef}
                                    onChange={handleOneChange}
                                    className="input"
                                 />
                              </FormControl>
                              <FormControl margin="normal" style={{marginRight: '14px'}}>
                                 <input
                                    name="two"
                                    value={two}
                                    ref={twoRef}
                                    onKeyDown={(e) => onkeypress(e, two, oneRef.current, setOne)}
                                    onChange={handleTwoChange}
                                    className="input"
                                 />
                              </FormControl>
                              <FormControl margin="normal" style={{marginRight: '14px'}}>
                                 <input
                                    name="three"
                                    value={three}
                                    ref={threeRef}
                                    onKeyDown={(e) => onkeypress(e, three, twoRef.current, setTwo)}
                                    onChange={handleThreeChange}
                                    className="input"
                                 />
                              </FormControl>
                              <FormControl margin="normal" style={{marginRight: '14px'}}>
                                 <input
                                    name="four"
                                    value={four}
                                    ref={fourRef}
                                    onKeyDown={(e) =>
                                       onkeypress(e, four, threeRef.current, setThree)
                                    }
                                    onChange={handleFourChange}
                                    className="input"
                                 />
                              </FormControl>
                              <FormControl margin="normal" style={{marginRight: '14px'}}>
                                 <input
                                    name="five"
                                    value={five}
                                    ref={fiveRef}
                                    onKeyDown={(e) => onkeypress(e, five, fourRef.current, setFour)}
                                    onChange={handleFiveChange}
                                    className="input"
                                 />
                              </FormControl>
                              <FormControl margin="normal">
                                 <input
                                    name="six"
                                    value={six}
                                    ref={sixRef}
                                    onKeyDown={(e) => onkeypress(e, six, fiveRef.current, setFive)}
                                    onChange={handleSixChange}
                                    className="input"
                                 />
                              </FormControl>
                           </div>
                           {/* {!!codeError && (
                              <span className="d-block mx-5 my-0 py-3" style={{color: 'red'}}>
                                 {codeError}
                              </span>
                           )} */}
                           <button
                              // to="/verify"
                              disabled={
                                 !oneSet || !twoSet || !threeSet || !fourSet || !fiveSet || !sixSet
                              }
                              type="submit"
                              className={classNames('signupbtn', {
                                 disabled: !canSubmit,
                              })}>
                              {IsVerifyingPhone ? (
                                 <CircularProgress color="inherit" thickness={1} />
                              ) : (
                                 'Submit'
                              )}
                           </button>
                        </Formsy>
                        <div className="otps d-flex mt-3">
                           <div
                              style={{
                                 color: '#c4c4c4',
                                 fontSize: '12px',
                                 fontWeight: 'lighter',
                                 marginLeft: '57px',
                              }}>
                              Didn't get OTP?
                           </div>
                           <div
                              onClick={handleResendOtp}
                              style={{
                                 color: '#3377ef',
                                 marginLeft: '5px',
                                 marginTop: '0px',
                                 fontSize: '12px',
                                 cursor: 'pointer',
                              }}>
                              {IsResendingOtp ? 'Resending...' : 'Resend'}
                           </div>
                        </div>
                     </MDBCol>
                  </MDBCol>
               </div>
            </MDBContainer>
         </section>
      </>
   );
}

export default OTP;
