import React, {useEffect, useState} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
// import PrivateRoute from './_components/PrivateRoute';
import {PrivateRoute, SubUserPrivateRoute} from './_components/PrivateRoute';
import Landingpage from './Pages/LandingPage';
import Customerpage from './Pages/Customer';
import Merchantpage from './Pages/Merchant/Merchantsignup';
import NewUserSignUp from './Pages/Merchant/Merchantsignup/newUser';
import Snackbar from '@material-ui/core/Snackbar';
import Snackbar2 from './_components/Snackbar';
import SignUp from './Pages/SignUp';
import Phonepage from './Pages/Merchant/Phone';
import Phonepageagain from './Pages/Merchant/PhoneAgain';
import Otp from './Pages/Merchant/OTP';
import Pin from './Pages/Merchant/Pin';
import Loginpage from './Pages/Merchant/Login';
import SubmerchantLoginpage from './Pages/Merchant/SubmerchantLogin';
import Createnewpassword from './Pages/Merchant/Createnewpassword';
import Resetpassword from './Pages/Merchant/Resetpassword';
import Passwordcreated from './Pages/Merchant/Passwordcreated';
import Loadingpage from './Pages/Merchant/Loading';
import Loader from './Pages/Merchant/Loading/loader';
import Creationpage from './Pages/Merchant/Creation';
import Emailverifypage from './Pages/Merchant/Emailverify';
import VerifyCustomer from './Pages/Merchant/Emailverify/verifyCustomer';
import Emailverifyfailedpage from './Pages/Merchant/Emailverifyfailed';
import Homepage from './Pages/Merchant/Home';
import MerchantPage from './Pages/Merchant';
// import Existhomepage from './Pages/Merchant/Existinghome';
// import Paymentpage from './Pages/Merchant/Payment';
// import Historypage from './Pages/Merchant/History';
import NewCompanypage from './Pages/Merchant/NewCompany';
// import CompanyPage from './Pages/Merchant/Company';
// import Accountpage from './Pages/Merchant/Account';
import {MuiThemeProvider} from '@material-ui/core/styles';
import ThemeDefault from './_components/DefaultTheme';
import Verifypage from './Pages/Verification';
import NotFound from './404';
import {useLocationCode} from './_navigation';
import classNames from 'classnames';
import Sidebar from './_navigation/Sidebar';
import Header from './_navigation/Header';
import {merchantActions, appActions} from './_actions';
//import {MuiThemeProvider} from '@material-ui/core/styles';

// import './App.css';
import './App.scss';
// import ModalController from './_components/Modal/ModalController';
// import MerchantPage from './Pages/Merchant/index.';

function App(props) {
   const [sideBarOpen, setSideBarOpen] = useState(false);
   const openSideBar = () => {
      setSideBarOpen(true);
   };
   const closeSideBar = () => {
      setSideBarOpen(false);
   };

   const code = useLocationCode();
   const dispatch = useDispatch();
   // const merchant = useSelector((s) => s.merchant);
   const snackbar = useSelector((s) => s.snackbar);
   // const companyData = useSelector((s) => s.merchant.companyData);
   const data = useSelector((s) => s.merchant.data);
   // const newUserMerchant = useSelector((s) => s.merchant.newUserMerchant);

   const logout = () => {
      const cashier = data?.role === 3 ? true : false;
      dispatch(merchantActions.logout(cashier));
   };

   const clearSnackBar = () => {
      dispatch(appActions.clearSnackBar());
   };

   const [timeoutId, setTimeoutId] = useState(null);
   // const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming initial login state

   const handleUserActivity = () => {
      clearTimeout(timeoutId); // Clear any existing timeout
      setTimeoutId(
         setTimeout(() => {
            // setIsLoggedIn(false); // Logout after 5 minutes
            logout(); // Logout after 5 minutes
         }, 5 * 60 * 1000),
      ); // 5 minutes in milliseconds
   };

   useEffect(() => {
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('click', handleUserActivity);

      // Cleanup function to remove event listeners
      return () => {
         window.removeEventListener('mousemove', handleUserActivity);
         window.removeEventListener('keydown', handleUserActivity);
         window.removeEventListener('click', handleUserActivity);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <MuiThemeProvider theme={ThemeDefault}>
         <div className="wrap row m-0">
            <div className="container-fluid p-0 h-100 col">
               <div
                  className={classNames('body', {
                     full: code === 6,
                     content: code !== 6,
                  })}>
                  <Switch>
                     <Route exact path="/landingpage" component={Landingpage} />
                     <Route exact path="/signup" component={SignUp} />
                     <Route exact path="/app/merchant/sub-user/:token" component={NewUserSignUp} />
                     <Route exact path="/customer" component={Customerpage} />
                     <Route exact path="/merchantsignup" component={Merchantpage} />
                     <Route exact path="/verify" component={Verifypage} />
                     <Route exact path="/phone" component={Phonepage} />
                     <Route exact path="/phoneagain" component={Phonepageagain} />
                     <Route exact path="/otp" component={Otp} />
                     <Route exact path="/loading" component={Loadingpage} />
                     <Route exact path="/loader" component={Loader} />
                     <Route exact path="/new-company" component={NewCompanypage} />
                     <Route path="/merchant/*" render={(props) => <MerchantPage {...props} />} />
                     {/* <PrivateRoute
                        exact
                        path="/companies"
                        merchant={merchant?.data}
                        component={CompanyPage}
                     /> */}
                     <Route exact path="/login" component={Loginpage} />
                     <Route exact path="/submerchantlogin" component={SubmerchantLoginpage} />
                     <Route exact path="/company" component={Loginpage} />
                     <Route exact path="/resetpassword" component={Resetpassword} />
                     <Route
                        exact
                        path="/user/reset_password/:token"
                        component={Createnewpassword}
                     />
                     <Route
                        exact
                        path="/app/merchant/reset_password/:token"
                        component={Createnewpassword}
                     />
                     {/* <Route
                        exact
                        path="/createnewpassword"
                        component={Createnewpassword}
                     /> */}
                     <Route exact path="/passwordcreated" component={Passwordcreated} />
                     <Route
                        exact
                        path="/app/merchant/verify_email/:token/:email"
                        component={Emailverifypage}
                     />
                     <Route
                        exact
                        path="/app/customer/verify_email/:token/:email"
                        component={VerifyCustomer}
                     />
                     {/* <Route
                        exact
                        path="/emailverify" path="/user/verify_email/:token/:email"
                        component={Emailverifypage}
                     /> */}

                     <Route exact path="/emailverifyfailed" component={Emailverifyfailedpage} />
                     <Route exact path="/creation" component={Creationpage} />
                     <Route exact path="/homeempty" component={Homepage} />
                     {/* <Route exact path="/home" component={Existhomepage} /> */}
                     {/* <PrivateRoute
                        exact
                        path="/home"
                        merchant={merchant?.data}
                        companyInfo={merchant?.companyInfo}
                        component={Existhomepage}
                     /> */}
                     {/* <PrivateRoute
                        exact
                        path="/payment"
                        merchant={merchant?.data}
                        companyInfo={merchant?.companyInfo}
                        component={Paymentpage}
                     />
                     <PrivateRoute
                        exact
                        path="/history"
                        merchant={merchant?.data}
                        companyInfo={merchant?.companyInfo}
                        component={Historypage}
                     />
                     <SubUserPrivateRoute
                        exact
                        path="/sub-user-history"
                        merchant={merchant?.data}
                        companyInfo={merchant?.companyInfo}
                        component={Historypage}
                     />
                     <PrivateRoute
                        exact
                        path="/account"
                        merchant={merchant?.data}
                        companyInfo={merchant?.companyInfo}
                        component={Accountpage}
                     /> */}
                     <Route exact path="/pin" component={Pin} />
                     <Route exact path="/" component={Landingpage} />

                     <Route render={(props) => <NotFound {...props} />} />
                  </Switch>
               </div>
            </div>
         </div>
         {snackbar?.message && (
            <Snackbar
               message={snackbar?.message.text}
               autoHideDuration={3000}
               clearSnackBar={clearSnackBar}
            />
         )}
         {snackbar?.message && (
            <Snackbar2
               onClose={clearSnackBar}
               variant={snackbar.message.variant}
               message={<span id="message-id">{snackbar?.message.text}</span>}
            />
         )}
         {/* <NavsPage user={user.user} logout={logout} />
            {props.location.pathname !== '/' && (
               <img
                  //src={isLogin || isSignup || isForgot ? headset : background}
                  className="bg-image"
               />
            )} */}
      </MuiThemeProvider>
   );
}

export default withRouter(App);
