import {call, put, takeLatest, all} from 'redux-saga/effects';
import {stateConstants, merchantConstants} from '../../_constants';
import {
   createRequest,
   createRequestWithToken,
   checkStatus,
   getObjectFromStorage,
} from '../../_helpers';

function* getStateSaga() {
   yield put({type: stateConstants.REQUEST_STATES});

   try {
      // const user = yield call(getObjectFromStorage, userConstants.USER_KEY);
      let statesUri = `${stateConstants.STATE_URL}`;

      // const permissionsReq = createRequestWithToken(permissionsUri, {
      //    method: 'GET',
      // })(user?.token);
      const curriedReq = yield call(createRequest, statesUri, {
         method: 'GET',
      });

      const response = yield call(fetch, curriedReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: stateConstants.REQUEST_STATES_SUCCESS,
         states: jsonResponse,
      });
   } catch (error) {
      yield put({type: stateConstants.REQUEST_STATES_ERROR});
   }
}

function* getCitiesSaga({data}) {
   console.log('data', data);
   yield put({type: stateConstants.REQUEST_CITIES});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      let citiesUri = `${stateConstants.CITY_URL}/${data}`;

      const curriedReq = createRequestWithToken(citiesUri, {
         method: 'GET',
      })(merchant?.token);
      // const curriedReq = yield call(createRequest, citiesUri, {
      //    method: 'GET',
      // });

      const response = yield call(fetch, curriedReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: stateConstants.REQUEST_CITIES_SUCCESS,
         cities: jsonResponse,
      });
   } catch (error) {
      yield put({type: stateConstants.REQUEST_CITIES_ERROR});
   }
}

function* getStateSagaWatcher() {
   yield takeLatest(stateConstants.GET_STATES, getStateSaga);
}

function* getCitiesSagaWatcher() {
   yield takeLatest(stateConstants.GET_CITIES, getCitiesSaga);
}

export default function* rootSaga() {
   yield all([getStateSagaWatcher(), getCitiesSagaWatcher()]);
}
