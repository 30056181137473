import {combineReducers} from 'redux';
import {countryConstants} from '../../_constants';

const IsRequestingCountries = (state = false, action) => {
   switch (action.type) {
      case countryConstants.REQUEST_COUNTRIES:
         return true;
      case countryConstants.REQUEST_COUNTRIES_ERROR:
         return false;
      case countryConstants.REQUEST_COUNTRIES_SUCCESS:
         return false;
      default:
         return state;
   }
};

const CountriesList = (state = [], action) => {
   switch (action.type) {
      case countryConstants.REQUEST_COUNTRIES_SUCCESS: {
         return action.countries.data;
      }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   CountriesList,
   IsRequestingCountries,
});

export default rootReducer;
