import {bankConstants} from '../_constants';

const getBanks = () => ({
   type: bankConstants.GET_BANKS,
});

const getAccountName = (model) => ({
   type: bankConstants.GET_ACCOUNT_NAME,
   data: model,
});

const saveBankDetails = (model) => ({
   type: bankConstants.SAVE_BANK_DETAILS,
   data: model,
});

const saveBvn = (model) => ({
   type: bankConstants.SAVE_BVN,
   data: model,
});

const deleteBankDetails = (_id) => ({
   type: bankConstants.DELETE_BANK_DETAILS,
   _id,
});

const getMerchantBankDetails = () => ({
   type: bankConstants.GET_MERCHANT_BANK_DETAILS,
});

export const bankActions = {
   getBanks,
   getAccountName,
   saveBankDetails,
   saveBvn,
   deleteBankDetails,
   getMerchantBankDetails,
};
