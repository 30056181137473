import React from 'react';
// import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import welcome from '../../_assets/images/newwelcome.jpg';
import Customer from '../../_assets/images/customer-jpg.jpg';
import Merchant from '../../_assets/images/merchant-jpg.jpg';
import {Link} from 'react-router-dom';
import './index.scss';

function SignUp() {
   return (
      <>
         <section className="signup row m-0">
            <div
               className="col-md-6 background-image d-flex align-items-center"
               style={{
                  backgroundImage: `url(${welcome})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  height: '100vh',
               }}>
               <div className="bigboxes">
                  <div className="image bg-image">
                     <div>
                        <div className="over">
                           <div className="word d-flex flex-column justify-content-center align-items-center">
                              <h3>WELCOME TO BUY AND GO</h3>
                              <br />
                              <p>Get Your Loans with Ease Based on Your Budget</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
               <div className="col-12 d-flex flex-column justify-content-center align-items-center smallbox">
                  <p className="small-text">
                     Already have an account? <Link to="/login">sign in</Link>
                  </p>
                  <div className="d-flex flex-column align-items-center justify-content-center smallbox2">
                     <p className="">Select account type</p>
                     <div className="d-flex justify-content-center images">
                        <Link to="/customer">
                           <img src={Customer} className="custom" alt=".." />

                           <p className="customer">Customer</p>
                        </Link>
                        <Link to="/merchantsignup">
                           <img src={Merchant} className="merchant" alt=".." />
                           <p className="merchant-text">Merchant</p>
                        </Link>
                     </div>
                     {/* <Link to="/signup" type="button" className="button">
                           Continue
                        </Link> */}
                  </div>
               </div>
            </div>
         </section>
      </>
   );
}

export default SignUp;
