import React, {useState, useEffect, useRef, useCallback} from 'react';
import {MDBContainer, MDBCol} from 'mdb-react-ui-kit';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
// import TextInput from '../../../_components/TextInput';
import BlueYellow from '../../../_assets/images/blueandyellow.svg';
import {merchantActions} from '../../../_actions';
import {useDispatch, useSelector} from 'react-redux';
// import classNames from 'classnames';
// import Formsy from 'formsy-react';
import CircularProgress from '@material-ui/core/CircularProgress';
// import {makeStyles} from '@material-ui/core/styles';
// import {Link, Redirect} from 'react-router-dom';
import './index.scss';
// import {FormatColorTextSharp} from '@material-ui/icons';

// const useStyles = makeStyles((theme) => ({
//    margin: {
//       margin: theme.spacing(1),
//    },
//    textField: {
//       width: '45ch',
//       height: '36px',
//    },
//    root: {
//       width: '100%',
//       marginRight: '230p',
//       height: '36px',
//    },
//    // padding: {
//    //    padding: '10px 2px',
//    //    height: '15px',
//    //    width: '100%',
//    //    marginLeft: '-10px',
//    //    borderColor: 'red',
//    // },
//    input: {
//       padding: '10px 2px',
//       height: '15px',
//       width: '100%',
//       border: '1px solid white',
//       '&$focused $hover $notchedOutline': {
//          borderColor: 'white',
//          borderWidth: 1,
//       },
//    },
// }));

function PinPage({history}) {
   const dispatch = useDispatch();
   const {unverifiedUserId, createPinSuccess, IsCreatingPin} = useSelector((s) => s.auth);
   const IsChild = useSelector((s) => s.merchant.IsChild);
   const companyId = useSelector((s) => s.merchant.companyId);
   const [codeError, setCodeError] = useState('');

   const [one, setOne] = useState('');
   const [two, setTwo] = useState('');
   const [three, setThree] = useState('');
   const [four, setFour] = useState('');
   const [oneConfirm, setOneConfirm] = useState('');
   const [twoConfirm, setTwoConfirm] = useState('');
   const [threeConfirm, setThreeConfirm] = useState('');
   const [fourConfirm, setFourConfirm] = useState('');

   const [oneSet, setOneSet] = useState(false);
   const [twoSet, setTwoSet] = useState(false);
   const [threeSet, setThreeSet] = useState(false);
   const [fourSet, setFourSet] = useState(false);
   const [oneConfirmSet, setOneConfirmSet] = useState(false);
   const [twoConfirmSet, setTwoConfirmSet] = useState(false);
   const [threeConfirmSet, setThreeConfirmSet] = useState(false);
   const [fourConfirmSet, setFourConfirmSet] = useState(false);

   const code = `${one}${two}${three}${four}`;
   const codeConfirm = `${oneConfirm}${twoConfirm}${threeConfirm}${fourConfirm}`;

   const confirmCode = useCallback(() => {
      if (code !== codeConfirm) {
         setCodeError("Pins don't match!");
         return false;
      }

      setCodeError('');
      return true;
   }, [code, codeConfirm]);

   const handleSubmit = useCallback(() => {
      if (confirmCode()) {
         dispatch(
            merchantActions.createPin({
               _id: unverifiedUserId,
               transaction_pin: code.trim(),
               role: 'merchant',
            }),
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [code, confirmCode, unverifiedUserId]);

   const oneRef = useRef();
   const twoRef = useRef();
   const threeRef = useRef();
   const fourRef = useRef();
   const oneConfirmRef = useRef();
   const twoConfirmRef = useRef();
   const threeConfirmRef = useRef();
   const fourConfirmRef = useRef();

   const validateCode = (val, setter) => {
      if (val.length === 0) {
         setCodeError('');
         setter(false);
         return true;
      }

      if (!/^[0-9]$/.test(val)) {
         setCodeError('Code can only contain numbers!');
         setter(false);
         return false;
      }

      setCodeError('');
      setter(true);
      return true;
   };

   const handleOneChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setOneSet)) {
         setOne(itemValue);
         if (itemValue.length) {
            twoRef?.current?.focus();
         }
      }
   };

   const handleOneConfirmChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setOneConfirmSet)) {
         setOneConfirm(itemValue);
         if (itemValue.length) {
            twoConfirmRef?.current?.focus();
         }
      }
   };

   const handleTwoChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setTwoSet)) {
         setTwo(itemValue);
         if (itemValue.length) {
            threeRef?.current?.focus();
         }
      }
   };

   const handleTwoConfirmChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setTwoConfirmSet)) {
         setTwoConfirm(itemValue);
         if (itemValue.length) {
            threeConfirmRef?.current?.focus();
         }
      }
   };

   const handleThreeChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setThreeSet)) {
         setThree(itemValue);
         if (itemValue.length) {
            fourRef?.current?.focus();
         }
      }
   };
   const handleThreeConfirmChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setThreeConfirmSet)) {
         setThreeConfirm(itemValue);
         if (itemValue.length) {
            fourConfirmRef?.current?.focus();
         }
      }
   };

   const handleFourChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setFourSet)) {
         setFour(itemValue);
         if (itemValue.length) {
            oneConfirmRef?.current?.focus();
         }
      }
   };
   const handleFourConfirmChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setFourConfirmSet)) {
         setFourConfirm(itemValue);
         if (itemValue.length) {
            fourConfirmRef?.current?.focus();
         }
      }
   };

   const onkeypress = useCallback(
      (e, value, prevRef, setState) => {
         if (e.keyCode === 8) {
            if (!value.length) {
               if (prevRef) {
                  prevRef.focus();
                  setState('');
               }
            }
         }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
   );
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   // const handleSubmit = (data) => {
   //    console.log(data);
   // };
   // if (createPinSuccess) {
   //    return <Redirect to="/home" />;
   // }
   if (createPinSuccess) {
      history.push('/merchant/home');
      // if (IsChild) {
      //    dispatch(merchantActions.getMerchantCompanyInformation(companyId));
      //    history.push('/history');
      // } else {
      // }
   }

   return (
      <>
         <section className="pin ">
            <img src={BlueYellow} alt="" className="img-fluid image" />
            <MDBContainer className="align-items-center">
               <div className="bigboxes">
                  <MDBCol className="text">
                     <h3>
                        Please
                        <br /> Create Transaction Pin
                     </h3>
                     <p>
                        Already have an account? &nbsp; <br />
                        <a style={{fontWeight: 'bolder'}} href="/login">
                           Log in
                        </a>
                     </p>
                  </MDBCol>
                  <MDBCol className="smallbox">
                     <MDBCol className="smallbox2">
                        <div>
                           <div className="forms col-md-12">
                              <div id="textExample1" className="form-text" style={{color: 'black'}}>
                                 Enter Pin
                              </div>
                              <FormControl margin="normal" style={{marginRight: '15px'}}>
                                 <input
                                    name="one"
                                    value={one}
                                    required
                                    usefocusedError
                                    placeholder=""
                                    ref={oneRef}
                                    onChange={handleOneChange}
                                    className="input"
                                    type="password"
                                 />
                              </FormControl>
                              <FormControl margin="normal" style={{marginRight: '15px'}}>
                                 <input
                                    name="two"
                                    value={two}
                                    required
                                    usefocusedError
                                    placeholder=""
                                    ref={twoRef}
                                    onKeyDown={(e) => onkeypress(e, two, oneRef.current, setOne)}
                                    onChange={handleTwoChange}
                                    className="input"
                                    type="password"
                                 />
                              </FormControl>
                              <FormControl margin="normal" style={{marginRight: '15px'}}>
                                 <input
                                    name="three"
                                    value={three}
                                    required
                                    usefocusedError
                                    placeholder=""
                                    ref={threeRef}
                                    onKeyDown={(e) => onkeypress(e, three, twoRef.current, setTwo)}
                                    onChange={handleThreeChange}
                                    className="input"
                                    type="password"
                                 />
                              </FormControl>
                              <FormControl margin="normal" style={{marginRight: '15px'}}>
                                 <input
                                    name="four"
                                    value={four}
                                    ref={fourRef}
                                    required
                                    usefocusedError
                                    placeholder=""
                                    onKeyDown={(e) =>
                                       onkeypress(e, four, threeRef.current, setThree)
                                    }
                                    onChange={handleFourChange}
                                    className="input"
                                    type="password"
                                 />
                              </FormControl>
                           </div>
                           <div className="forms col-md-10">
                              <div id="textExample1" className="form-text" style={{color: 'black'}}>
                                 Confirm Pin
                              </div>
                              <FormControl margin="normal" style={{marginRight: '15px'}}>
                                 <input
                                    name="one"
                                    value={oneConfirm}
                                    required
                                    usefocusedError
                                    placeholder=""
                                    ref={oneConfirmRef}
                                    onChange={handleOneConfirmChange}
                                    className="input"
                                    type="password"
                                 />
                              </FormControl>
                              <FormControl margin="normal" style={{marginRight: '15px'}}>
                                 <input
                                    name="two"
                                    value={twoConfirm}
                                    required
                                    usefocusedError
                                    placeholder=""
                                    ref={twoConfirmRef}
                                    onKeyDown={(e) =>
                                       onkeypress(
                                          e,
                                          twoConfirm,
                                          oneConfirmRef.current,
                                          setOneConfirm,
                                       )
                                    }
                                    onChange={handleTwoConfirmChange}
                                    className="input"
                                    type="password"
                                 />
                              </FormControl>
                              <FormControl margin="normal" style={{marginRight: '15px'}}>
                                 <input
                                    name="three"
                                    value={threeConfirm}
                                    required
                                    usefocusedError
                                    placeholder=""
                                    ref={threeConfirmRef}
                                    onKeyDown={(e) =>
                                       onkeypress(
                                          e,
                                          threeConfirm,
                                          twoConfirmRef.current,
                                          setTwoConfirm,
                                       )
                                    }
                                    onChange={handleThreeConfirmChange}
                                    className="input"
                                    type="password"
                                 />
                              </FormControl>
                              <FormControl margin="normal" style={{marginRight: '15px'}}>
                                 <input
                                    name="four"
                                    value={fourConfirm}
                                    ref={fourConfirmRef}
                                    required
                                    usefocusedError
                                    placeholder=""
                                    onKeyDown={(e) =>
                                       onkeypress(
                                          e,
                                          fourConfirm,
                                          threeConfirmRef.current,
                                          setThreeConfirm,
                                       )
                                    }
                                    onChange={handleFourConfirmChange}
                                    className="input"
                                    type="password"
                                 />
                              </FormControl>
                           </div>
                           {!!codeError && (
                              <span
                                 className="d-block  my-0 py-2"
                                 style={{color: 'red', marginLeft: '70px'}}>
                                 {codeError}
                              </span>
                           )}
                           <button
                              // to="/verify"
                              disabled={
                                 !oneSet ||
                                 !twoSet ||
                                 !threeSet ||
                                 !fourSet ||
                                 !oneConfirmSet ||
                                 !twoConfirmSet ||
                                 !threeConfirmSet ||
                                 !fourConfirmSet
                              }
                              onClick={handleSubmit}
                              className="signupbtn">
                              {IsCreatingPin ? (
                                 <CircularProgress color="inherit" size="1rem" thickness={2} />
                              ) : (
                                 'Submit'
                              )}
                           </button>
                        </div>
                        {/* <div className="forms col-md-10">
                              <div id="textExample1" className="form-text" style={{color: 'black'}}>
                                 Confirm Pin
                              </div>
                              <FormControl margin="normal" required fullWidth>
                                 <TextInput
                                    name="confirmpassword"
                                    wrapperClassName="input-wrapper"
                                    mainWrapperClassName="w-100 mb-3 mb-s-4"
                                    placeholder=""
                                    useFocusedError
                                    type={passwordShow ? 'text' : 'password'}
                                    className="input border-0 w-100"
                                    validations="equalsField:password"
                                    validationError="Passwords don't match!"
                                    required
                                 />
                                 <i onClick={togglePasswordVisible}>
                                    {passwordShow ? <Visibility /> : <VisibilityOff />}
                                 </i>
                              </FormControl>
                           </div> */}
                        {/* <Link to="/verify">
                              <button
                                 // to="/verify"
                                 disabled={!canSubmit}
                                 type="submit"
                                 className="signupbtn">
                                 Submit
                              </button>
                           </Link>
                        </Formsy> */}
                     </MDBCol>
                  </MDBCol>
               </div>
            </MDBContainer>
         </section>
      </>
   );
}

export default PinPage;
