import React from 'react';
import {MDBContainer, MDBCol} from 'mdb-react-ui-kit';
import {Link} from 'react-router-dom';
import BlueYellow from '..//..//../_assets/images/blueandyellow.svg';
import Cart from '..//..//../_assets/images/cart-image.png';
import Google from '..//..//..//_assets/images/google2.png';
import Apple from '..//..//..//_assets/images/apple2.png';
import {ReactComponent as Bluetick} from '..//..//..//_assets/icon/bluetick.svg';
import './index.scss';

function VerifyCustomer({match}) {
   return (
      <section className="customer_verify_email ">
         <img src={BlueYellow} alt="" className="img-fluid image" />
         <MDBContainer className="d-flex justify-content-center align-items-center">
            <MDBCol className="col-11 col-md-7 col-lg-4 mt-5 mt-md-0  smallbox2">
               <div>
                  <h2>Get the App Now!!</h2>
                  <p className="pt-2">
                     Download the Buy and Go app for loans, purchase and payments
                  </p>
                  <img className="mt-3 img-fluid" src={Cart} alt="..." style={{width: '40%'}} />
                  <div className="d-flex mt-5 justify-content-center">
                     <img
                        src={Google}
                        className="mt-2"
                        alt="..."
                        style={{width: '35%', height: '45%'}}
                     />
                     <img src={Apple} className="" alt="..." style={{width: '40%'}} />
                  </div>
               </div>
            </MDBCol>
         </MDBContainer>
      </section>
   );
}
export default VerifyCustomer;
