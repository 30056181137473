import React from 'react';

function NotFound(props) {
   const path = window.location.hostname;
   return (
      <div>
         <h3>
            Error! No matches for <code>{path + props.location.pathname}</code>
         </h3>
      </div>
   );
}

export default NotFound;
