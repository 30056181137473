import {combineReducers} from 'redux';
import {stateConstants} from '../../_constants';

const IsRequestingStates = (state = false, action) => {
   switch (action.type) {
      case stateConstants.REQUEST_STATES:
         return true;
      case stateConstants.REQUEST_STATES_ERROR:
         return false;
      case stateConstants.REQUEST_STATES_SUCCESS:
         return false;
      default:
         return state;
   }
};

const statesList = (state = [], action) => {
   switch (action.type) {
      case stateConstants.REQUEST_STATES_SUCCESS: {
         return action.states.data;
      }
      default:
         return state;
   }
};

const citiesList = (state = [], action) => {
   switch (action.type) {
      case stateConstants.REQUEST_CITIES_SUCCESS: {
         return action.cities.data;
      }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   statesList,
   citiesList,
   IsRequestingStates,
});

export default rootReducer;
