import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
   MDBContainer,
   MDBCol,
   MDBInputGroup,
   MDBInputGroupElement,
   MDBInputGroupText,
} from 'mdb-react-ui-kit';
import Companies from './companyTab';
import classNames from 'classnames';
import Brand from '../../../_assets/images/BuyGo.png';
import {ReactComponent as SearchIcon} from '../../../_assets/icon/1ssearch.svg';
import {Link} from 'react-router-dom';
import './index.scss';

function Company({location, history}) {
   const companyData = useSelector((s) => s.merchant.companyData);

   useEffect(() => {
      // dispatch(appActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // if (companyData) {
   //    // if (location.state !== undefined) {
   //    //    console.log('location.state', location.state);
   //    //    companyData?.is_child === true
   //    //       ? history.push('/history')
   //    //       : history.push(location.state.from.pathname);
   //    // } else {
   //    // }
   //    companyData?.is_child === true ? history.push('/history') : history.push('/home');

   // }

   return (
      <>
         <section className="company">
            <div className="h-100">
               <div className="d-flex flex-column flex-md-row  justify-content-between h-100">
                  <div className="col-12 col-sm-6 col-lg-4">
                     <img
                        src={Brand}
                        alt=""
                        className="px-0 px-md-3 ms-0 ms-md-4 mt-2"
                        style={{width: '50%'}}
                     />
                  </div>

                  <div>
                     <MDBInputGroup className="col-9 col-sm-6 col-md-12 col-lg-12 ms-1 ms-md-0 mb-sm-0 mt-4 pe-5">
                        <input
                           type="text"
                           placeholder="search"
                           aria-label="Search"
                           style={{border: '0px'}}
                        />

                        <span style={{backgroundColor: '#3377ef'}}>
                           <SearchIcon
                              style={{width: '.85rem', color: '#fff', cursor: 'pointer'}}
                           />
                        </span>
                     </MDBInputGroup>
                  </div>
               </div>
            </div>
            <Companies location={location} history={history} />
         </section>
      </>
   );
}

export default Company;
