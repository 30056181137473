import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CircularProgress, FormControl} from '@material-ui/core';
import {MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Formsy from 'formsy-react';
import Modal from 'react-modal';
// import FormControl from '@material-ui/core/FormControl';
import TextInput from '../../../_components/TextInput';
import classNames from 'classnames';
import LetteredAvatar from 'lettered-avatar';
import Select from 'react-select';
// import Lara from '../../../_assets/images/lara.png';
// import Emeka from '../../../_assets/images/emeka.png';
// import Kafilat from '../../../_assets/images/kafilat.png';
// import Yetunde from '../../../_assets/images/yetunde.png';
import Loader from '../../../_helpers/loader';
// import {ReactComponent as Vector} from '../../../_assets/icon/downarr.svg';
// import {ReactComponent as Lock} from '../../../_assets/icon/lock.svg';
import {ReactComponent as Delete} from '../../../_assets/icon/delete.svg';
import {ReactComponent as Close} from '../../../_assets/icon/close.svg';
import {ReactComponent as Edit} from '../../../_assets/icon/edit.svg';
// import {ReactComponent as Arrdown} from '../../../_assets/icon/1arrow-down.svg';
// import {ReactComponent as Pen} from '../../../_assets/icon/pen.svg';
import {Link} from 'react-router-dom';
import './index.scss';
import {merchantRole} from '../../../_constants';
import {merchantActions} from '../../../_actions';

function Users() {
   const dispatch = useDispatch();
   const IsRequestingMerchantUsers = useSelector((s) => s.merchant.IsRequestingMerchantUsers);
   const merchantUsersList = useSelector((s) => s.merchant.merchantUsersList);
   const IsCreatingMerchantUser = useSelector((s) => s.merchant.IsCreatingMerchantUser);
   const merchantUserCreated = useSelector((s) => s.merchant.merchantUserCreated);
   const companyInfo = useSelector((s) => s.merchant.companyInfo);
   const errorMessage = useSelector((s) => s.merchant.errorMessage);
   const role = useSelector((s) => s.merchant.role);

   const [canSubmit, setCanSubmit] = useState(false);
   const [selectedOption, setSelectedOption] = useState(null);
   useEffect(() => {
      dispatch(merchantActions.getMerchantUsers());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (merchantUserCreated === true) {
         setModalOpen(false);
      }
   }, [merchantUserCreated]);

   const [passwordShown, setPasswordShown] = useState(false);
   const [modalIsOpen, setModalIsOpen] = useState(false);
   const [modalOpen, setModalOpen] = useState(false);
   const [open, setOpen] = useState(false);
   const [userId, setUserId] = useState('');
   // const [deleteUser, setDeleteUser] = useState(false);
   // const [userData, setUserData] = useState({});

   const setModalIsOpenToTrue = (id) => {
      setUserId(id);
      setModalIsOpen(true);
   };
   const setModalOpenToTrue = () => {
      setModalOpen(true);
   };

   // const toggleOpen = (id) => {
   //    setUserId(id);
   //    setOpen((open) => !open);
   // };

   // const toggleUpdate = () => {
   //    setOpen(false);
   //    setUserId('');
   // };

   // const toggleDelete = (row) => {
   //    console.log('UPDATE', row);
   //    setUserData(row);
   //    setDeleteUser(!deleteUser);
   // };

   const handleDelete = () => {
      if (userId) {
         dispatch(merchantActions.deleteMerchantUser(userId));
      }
      setModalIsOpen(false);
   };

   const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
   };

   const handleSubmit = (data) => {
      data['role'] = selectedOption?.value || 3;
      // setModalOpen(true);
      if (selectedOption?.value === 2) {
         console.log('data', data);

         dispatch(merchantActions.createMerchantUser(data));
      } else {
         data['merchant_id'] = companyInfo?._id;
         data['phone_number'] = data['phone_number'].replace(/^0/, '234').trim();

         console.log('data', data);

         dispatch(merchantActions.createMerchantSubUser(data));
      }
   };

   console.log('role', role);

   const customStyles = {
      content: {
         // top: '40%',
         position: 'fixed',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: '450px',
         maxWidth: '90%',
         height: '400px',
         maxHeight: '90%',
         backgroundColor: '#ffffff',
         borderRadius: '5px',
         padding: '40px 40px',
         overflowX: 'hidden',
         zIndex: '100',
      },
   };
   // 20 Users
   const usernameError = errorMessage?.errors?.filter((error) => error.param === 'username');
   const phoneError = errorMessage?.errors?.filter((error) => error.param === 'phone_number');
   console.log('usernameError', usernameError);
   console.log('phone_number2349088776655', phoneError);

   return (
      <>
         <div className="user mt-3">
            <div className="mb-5 history w-100 d-flex">
               <div className="" style={{marginTop: '-12px'}}>
                  <div className="d-flex align-items-center ms-auto">
                     <span className="number"></span>
                     <div className="newuse ms-auto">
                        <Link className="new" onClick={setModalOpenToTrue}>
                           {' '}
                           Add New User{' '}
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
            {IsRequestingMerchantUsers ? (
               <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                  <div />
               </Loader>
            ) : (
               <div className="customer-list mt-3">
                  <MDBTable className="profile mb-5" responsive borderless hover align="middle">
                     <MDBTableHead>
                        <tr>
                           <th
                              style={{
                                 paddingLeft: '120px',
                                 fontSize: '13px',
                                 fontWeight: '700',
                              }}
                              className="name"
                              scope="col">
                              User Name
                           </th>
                           <th style={{fontSize: '13px', fontWeight: '700'}} scope="col">
                              Email
                           </th>
                           <th style={{fontSize: '13px', fontWeight: '700'}} scope="col">
                              Phone Number
                           </th>
                           <th style={{fontSize: '13px', fontWeight: '700'}} scope="col">
                              Active
                           </th>
                           <th style={{fontSize: '13px', fontWeight: '700'}} scope="col"></th>
                        </tr>
                     </MDBTableHead>
                     <MDBTableBody>
                        {merchantUsersList?.length > 0 ? (
                           merchantUsersList.map((row) => {
                              return (
                                 <div
                                    className="d-table-row"
                                    style={{
                                       background: '#fbfbfc',
                                       verticalAlign: 'middle',
                                    }}>
                                    <th scope="row" className="d-flex mx-5 px-5 align-items-center">
                                       {/* <img
                                          src={Lara}
                                          alt="profile-img"
                                          className="rounded-circle img-fluid"
                                          style={{width: '3rem', height: '3rem'}}
                                       /> */}
                                       <LetteredAvatar
                                          name={
                                             row.username ||
                                             row.user_id?.firstname + ' ' + row.user_id?.lastname
                                          }
                                          options={{
                                             size: 50,
                                             twoLetter: true,
                                             //shape: 'square',
                                             bgColor: '',
                                             imgClass: 'image-responsive user-image',
                                             imgWidth: '3rem',
                                             imgHeight: '3rem',
                                          }}
                                       />
                                       <span className="mx-1">
                                          {row.username ||
                                             row.user_id?.firstname + ' ' + row.user_id?.lastname}
                                       </span>
                                    </th>
                                    <td>{row.user_id?.email || '-'}</td>
                                    <td>
                                       <div className="ms-4">
                                          {row.user_id?.phone_number?.replace(/^.{3}/g, '0') ||
                                             row.phone_number?.replace(/^.{3}/g, '0')}
                                       </div>
                                    </td>
                                    <td>
                                       {' '}
                                       <div className="ms-4">
                                          {row.role === 3 || row.user_id?.isActive
                                             ? 'Active'
                                             : 'Not Active'}
                                       </div>
                                    </td>
                                    {/* <td>Thursday, May 2021</td>role?.role !== 2 &&companyInfo?.is_child === false && row.user_id === null */}
                                    {row.role !== 1 && role?.role !== row.role && (
                                       <td>
                                          <Link
                                             onClick={() => setModalIsOpenToTrue(row._id)}
                                             className="more position-relative p-2">
                                             Delete <Close className="d-none" />
                                          </Link>
                                          {open && userId === row._id && (
                                             <div className="dropdownmenu position-absolute">
                                                {/* <li>
                                                <div className="w-100 d-block text-center" to="/">
                                                   <Edit style={{marginRight: '8px'}} />
                                                   Edit
                                                </div>
                                             </li> */}
                                                <li>
                                                   <div
                                                      onClick={setModalIsOpenToTrue}
                                                      className="w-100 d-block text-center"
                                                      // style={{marginLeft:'-5px'}}
                                                   >
                                                      <Close style={{marginRight: '8px'}} />
                                                      Remove
                                                   </div>
                                                </li>
                                             </div>
                                          )}
                                       </td>
                                    )}
                                 </div>
                              );
                           })
                        ) : (
                           <td align="center" colSpan={5}>
                              This merchant has not added any user on this platform
                           </td>
                        )}
                     </MDBTableBody>
                  </MDBTable>
               </div>
            )}
         </div>
         <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            onRequestClose={() => setModalIsOpen(false)}
            id="mode">
            <Formsy className="__form d-flex flex-column align-items-center">
               <Delete className="mb-5 delete" />
               <p className="mx-2"> Are you sure you want to remove user</p>
               <div className="mt-5 mr-5 pt-3 d-flex">
                  <Link onClick={() => setModalIsOpen(false)}>
                     <div className="mx-5 white">Cancel</div>
                  </Link>
                  <Link onClick={() => handleDelete()}>
                     <div className="blue">Delete</div>
                  </Link>
               </div>
            </Formsy>
         </Modal>
         <Modal
            isOpen={modalOpen}
            style={customStyles}
            onRequestClose={() => setModalOpen(false)}>
            <Formsy
               onValidSubmit={handleSubmit}
               onValid={() => setCanSubmit(true)}
               onInvalid={() => setCanSubmit(false)}>
               <div id="textExample1" className="form col-md-11">
                  Add New User
               </div>
               <h6 style={{color: 'red', textAlign: 'center'}}>{errorMessage?.message}</h6>
               {role?.role !== 2 && (
                  <div className="form col-md-7">
                     <Select
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={merchantRole}
                        placeholder="User Type"
                     />
                  </div>
               )}
               {selectedOption?.value === 2 ? (
                  <div className="form col-md-7">
                     <div id="textExample1" className="mb-n3 form-text" style={{color: 'black'}}>
                        Email
                     </div>
                     <FormControl margin="normal" required fullWidth>
                        <TextInput
                           name="email"
                           // wrapperClassName="input-wrapper"
                           // mainWrapperClassName="mb-3 mb-s-4"
                           placeholder="email@example.com"
                           useFocusedError
                           required
                           style={{textAlign: 'start'}}
                           className="input w-100 border-0"
                           autoComplete="on"
                           validations="isEmail"
                           validationError="This is not a valid email!"
                        />
                     </FormControl>
                  </div>
               ) : (
                  <>
                     <div className="form pt-2 col-md-11">
                        <div id="textExample1" className="mb-n4 form-text" style={{color: 'black'}}>
                           User Name
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="username"
                              autoComplete="on"
                              valError={usernameError?.[0]?.msg}
                              placeholder="user"
                              useFocusedError
                              required
                              className="input w-100 border-0"
                           />
                        </FormControl>
                     </div>
                     <div className="form col-md-11">
                        <div id="textExample1" className="mb-n4 form-text" style={{color: 'black'}}>
                           Phone Number
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="phone_number"
                              autoComplete="on"
                              // wrapperClassName="input-wrapper"
                              // mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="Phone Number"
                              useFocusedError
                              className="input border-0 w-100"
                              valError={phoneError?.[0]?.msg}
                              validations={{isNumeric: true, minLength: 9, maxLength: 11}}
                              validationErrors={{
                                 isNumeric: 'Invalid phone number',
                                 minLength: 'Phone number incomplete',
                                 maxLength: 'Just 11 characters',
                              }}
                              required
                           />
                        </FormControl>
                     </div>
                     <div className="form col-md-11">
                        <div id="textExample1" className="mb-n4 form-text" style={{color: 'black'}}>
                           User's Password
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="password"
                              autoComplete="off"
                              type={passwordShown ? 'text' : 'password'}
                              // wrapperClassName="input-wrapper"
                              // mainWrapperClassName="mb-3 mb-s-4"
                              placeholder="*********"
                              useFocusedError
                              required
                              className="input w-100 border-0"
                           />
                           <i className="mt-4 mx-n2" onClick={togglePasswordVisiblity}>
                              {passwordShown ? <Visibility /> : <VisibilityOff />}
                           </i>
                        </FormControl>
                     </div>
                  </>
               )}

               {/* <Link> */}
               <button
                  type="submit"
                  style={{marginLeft: ' 100px'}}
                  className={classNames('py-2 px-3 userbtn', {
                     userdisabled: !canSubmit,
                  })}>
                  {IsCreatingMerchantUser ? (
                     <CircularProgress
                        variant="indeterminate"
                        disableShrink="true"
                        color="inherit"
                        size="1.2rem"
                        thickness={2}
                        style={{
                           radius: '.1px',
                        }}
                     />
                  ) : (
                     'Submit'
                  )}
               </button>
               {/* </Link> */}
            </Formsy>
         </Modal>
      </>
   );
}

export default Users;
