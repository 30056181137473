import {call, put, takeLatest, all} from 'redux-saga/effects';
import {merchantConstants} from '../../_constants';
import {appActions} from '../../_actions';
import {
   createRequest,
   createRequestWithToken,
   clearObjectFromStorage,
   checkStatus,
   getObjectFromStorage,
   setObjectInStorage,
   parseJSON,
   createMultiPartRequestWithToken,
} from '../../_helpers';

function* getMerchantSaga() {
   try {
      yield put({type: merchantConstants.GET_REQUEST});

      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      console.log('getMerchantSaga', merchant);
      if (merchant) {
         yield put({
            type: merchantConstants.GET_SUCCESS,
            user: merchant.user,
            is_child: merchant.is_child,
            userMerchants: merchant.userMerchants,
         });
         return;
      }
      yield call(clearObjectFromStorage, merchantConstants.MERCHANT_KEY);

      yield put({type: merchantConstants.GET_FAILURE});
   } catch (error) {
      yield put({type: merchantConstants.GET_FAILURE});
   }
}

function* getMerchantCompanySaga() {
   try {
      yield put({type: merchantConstants.GET_MERCHANT_COMPANY_REQUEST});

      const merchantCompany = yield call(
         getObjectFromStorage,
         merchantConstants.MERCHANT_COMPANY_KEY,
      );
      if (merchantCompany) {
         yield put({
            type: merchantConstants.GET_MERCHANT_COMPANY_SUCCESS,
            user: merchantCompany.user,
            is_child: merchantCompany.is_child,
            details: merchantCompany.details,
         });
         return;
      }
      yield call(clearObjectFromStorage, merchantConstants.MERCHANT_COMPANY_KEY);

      yield put({type: merchantConstants.GET_MERCHANT_COMPANY_FAILURE});
   } catch (error) {
      yield put({type: merchantConstants.GET_MERCHANT_COMPANY_FAILURE});
   }
}

function* getMerchantSubUserSaga() {
   try {
      yield put({type: merchantConstants.GET_MERCHANT_SUB_USER_REQUEST});

      const cashier = yield call(getObjectFromStorage, merchantConstants.MERCHANT_SUB_USER_KEY);
      console.log('cashier', cashier);
      if (cashier) {
         yield put({
            type: merchantConstants.GET_MERCHANT_SUB_USER_SUCCESS,
            user: cashier.user,
            is_child: cashier.is_child,
            details: cashier.details,
         });
         return;
      }
      yield call(clearObjectFromStorage, merchantConstants.MERCHANT_SUB_USER_KEY);

      yield put({type: merchantConstants.GET_MERCHANT_SUB_USER_FAILURE});
   } catch (error) {
      yield put({type: merchantConstants.GET_MERCHANT_SUB_USER_FAILURE});
   }
}

function* updatePasswordSaga({data}) {
   yield put({type: merchantConstants.CHANGING_PASSWORD});
   try {
      const savedUser = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      const req = createRequestWithToken(
         merchantConstants.CHANGE_PASSWORD_URI(savedUser?.user._id),
         {
            method: 'PATCH',
            body: JSON.stringify(data),
         },
      )(savedUser?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      yield put({
         type: merchantConstants.CHANGE_PASSWORD_SUCCESS,
      });
      yield put(
         appActions.setSnackBar({
            message: 'password changed successfully',
            variant: 'success',
         }),
      );

      console.log('change password success');
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);
         console.log('change password error', res);
         if (res.errors) {
            let errors = {};
            for (let i = 0; i < res.errors.length; i++) {
               errors[res.errors[i].param] = res.errors[i].msg;
            }

            yield put({
               type: merchantConstants.CHANGE_PASSWORD_FAILURE,
               error: errors,
            });
            yield put(
               appActions.setSnackBar({
                  message: 'Pin change unsuccessful',
                  variant: 'error',
               }),
            );
            return;
         }

         yield put({
            type: merchantConstants.CHANGE_PASSWORD_FAILURE,
            error: res.message,
         });
         yield put(
            appActions.setSnackBar({
               message: 'Pin change unsuccessful',
               variant: 'error',
            }),
         );

         return;
      }

      console.log('change password error', error.message);
      yield put({
         type: merchantConstants.CHANGE_PASSWORD_FAILURE,
         error: error.message,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Pin change unsuccessful',
            variant: 'error',
         }),
      );
   }
}

function* updatePinSaga({data}) {
   console.log('data', data);
   yield put({type: merchantConstants.CHANGING_PIN});
   try {
      const savedUser = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      // const req = yield call(
      //    createRequestWithToken,
      //    merchantConstants.CHANGE_PIN_URI(savedUser?.user._id),
      //    {
      //       method: 'PATCH',
      //       body: JSON.stringify(data),
      //    },
      //    savedUser?.token,
      // );
      const req = createRequestWithToken(merchantConstants.CHANGE_PIN_URI(savedUser?.user._id), {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(savedUser?.token);
      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      yield put({
         type: merchantConstants.CHANGE_PIN_SUCCESS,
      });
      yield put(
         appActions.setSnackBar({
            message: 'pin changed successfully',
            variant: 'success',
         }),
      );

      console.log('change pin success');
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);
         console.log('change pin error', res);
         if (res.errors) {
            let errors = {};
            for (let i = 0; i < res.errors.length; i++) {
               errors[res.errors[i].param] = res.errors[i].msg;
            }

            yield put({
               type: merchantConstants.CHANGE_PIN_FAILURE,
               error: errors,
            });
            yield put(
               appActions.setSnackBar({
                  message: 'Pin change unsuccessful',
                  variant: 'error',
               }),
            );
            return;
         }

         yield put({
            type: merchantConstants.CHANGE_PIN_FAILURE,
            error: res.message,
         });
         yield put(
            appActions.setSnackBar({
               message: 'Pin change unsuccessful',
               variant: 'error',
            }),
         );

         return;
      }

      console.log('change pin error', error.message);
      yield put({
         type: merchantConstants.CHANGE_PIN_FAILURE,
         error: error.message,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Pin change unsuccessful',
            variant: 'error',
         }),
      );
   }
}

function* resetPinSaga({data}) {
   console.log('data', data);
   yield put({type: merchantConstants.RESETTING_PIN});
   try {
      const savedUser = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);

      const req = createRequestWithToken(merchantConstants.CHANGE_PIN_URI(savedUser?.user._id), {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(savedUser?.token);
      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      yield put({
         type: merchantConstants.RESET_PIN_SUCCESS,
      });
      yield put(
         appActions.setSnackBar({
            message: 'pin reset successfully',
            variant: 'success',
         }),
      );
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);
         if (res.errors) {
            let errors = {};
            for (let i = 0; i < res.errors.length; i++) {
               errors[res.errors[i].param] = res.errors[i].msg;
            }

            yield put({
               type: merchantConstants.RESET_PIN_FAILURE,
               error: errors,
            });
            yield put(
               appActions.setSnackBar({
                  message: 'Pin reset unsuccessful',
                  variant: 'error',
               }),
            );
            return;
         }

         yield put({
            type: merchantConstants.RESET_PIN_FAILURE,
            error: res.message,
         });
         yield put(
            appActions.setSnackBar({
               message: 'Pin reset unsuccessful',
               variant: 'error',
            }),
         );

         return;
      }

      console.log('Reset pin error', error.message);
      yield put({
         type: merchantConstants.RESET_PIN_FAILURE,
         error: error.message,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Pin reset unsuccessful',
            variant: 'error',
         }),
      );
   }
}

function* registerMerchant({data}) {
   yield put({type: merchantConstants.REQUEST_REGISTER_MERCHANT});

   try {
      data['role'] = merchantConstants.MERCHANT_ROLE_ID;
      const url = `${merchantConstants.REGISTER_MERCHANT_URI}`;
      const curriedReq = yield call(createRequest, url, {
         method: 'POST',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, curriedReq);
      console.log({
         response,
      });
      yield call(checkStatus, response);

      const jsonResponse = yield call(parseJSON, response);

      yield put({
         type: merchantConstants.REGISTER_MERCHANT_SUCCESS,
         id: jsonResponse.user_id,
      });
   } catch (error) {
      console.log('register error', {
         error,
      });
      if (error.response) {
         const res = yield call(parseJSON, error.response);

         if (res.errors) {
            let errors = {};
            for (let i = 0; i < res.errors.length; i++) {
               errors[res.errors[i].param] = res.errors[i].msg;
            }

            yield put({
               type: merchantConstants.REGISTER_MERCHANT_ERROR,
               error: errors,
            });
            return;
         }

         yield put({
            type: merchantConstants.REGISTER_MERCHANT_ERROR,
            error: res.message,
         });

         return;
      }

      yield put({
         type: merchantConstants.REGISTER_MERCHANT_ERROR,
         error: error.message,
      });
   }
}

function* createMerchantCompany({data}) {
   yield put({type: merchantConstants.IS_CREATING_MERCHANT_COMPANY});

   try {
      // data['role'] = merchantConstants.MERCHANT_ROLE_ID;
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      const url = `${merchantConstants.MERCHANT_URI}`;
      // const curriedReq = yield call(createRequest, url, {
      //    method: 'POST',
      //    body: JSON.stringify(data),
      // });
      const curriedReq = yield call(createMultiPartRequestWithToken, url, {
         method: 'POST',
         body: data,
      });
      const req = yield call(curriedReq, merchant?.token);
      const response = yield call(fetch, req);
      yield call(checkStatus, response);
      // const response = yield call(fetch, curriedReq);
      // console.log({
      //    response,
      // });
      // yield call(checkStatus, response);

      const jsonResponse = yield call(parseJSON, response);

      yield put({
         type: merchantConstants.CREATE_MERCHANT_COMPANY_SUCCESS,
         id: jsonResponse.merchant._id,
         merchantCompany: jsonResponse.newMerchant,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Company created successfully',
            variant: 'success',
         }),
      );
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);

         if (res.errors) {
            let errors = {};
            for (let i = 0; i < res.errors.length; i++) {
               errors[res.errors[i].param] = res.errors[i].msg;
            }

            yield put({
               type: merchantConstants.CREATE_MERCHANT_COMPANY_FAILURE,
               error: errors,
            });
            return;
         }

         yield put({
            type: merchantConstants.CREATE_MERCHANT_COMPANY_FAILURE,
            error: res.message,
         });

         return;
      }

      yield put({
         type: merchantConstants.CREATE_MERCHANT_COMPANY_FAILURE,
         error: error.errors,
      });
   }
}

function* registerMerchantSubUser({data}) {
   yield put({type: merchantConstants.REQUEST_REGISTER_MERCHANT_SUB_USER});

   try {
      // data['role'] = merchantConstants.MERCHANT_ROLE_ID;
      const url = `${merchantConstants.REGISTER_MERCHANT_SUB_USER_URI(data.token)}`;
      const curriedReq = yield call(createRequest, url, {
         method: 'POST',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, curriedReq);
      console.log({
         response,
      });
      yield call(checkStatus, response);

      const jsonResponse = yield call(parseJSON, response);

      yield put({
         type: merchantConstants.REGISTER_MERCHANT_SUB_USER_SUCCESS,
         id: jsonResponse.merchant_id,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);

         if (res.errors) {
            let errors = {};
            for (let i = 0; i < res.errors.length; i++) {
               errors[res.errors[i].param] = res.errors[i].msg;
            }

            yield put({
               type: merchantConstants.REGISTER_MERCHANT_SUB_USER_ERROR,
               error: errors,
            });
            return;
         }

         yield put({
            type: merchantConstants.REGISTER_MERCHANT_SUB_USER_ERROR,
            error: res.message,
         });

         return;
      }

      yield put({
         type: merchantConstants.REGISTER_MERCHANT_SUB_USER_ERROR,
         error: error.message,
      });
   }
}

function* addSubUserToMerchant({data}) {
   yield put({type: merchantConstants.REQUEST_ADD_SUB_USER_TO_MERCHANT});

   try {
      // data['role'] = merchantConstants.MERCHANT_ROLE_ID;
      const url = `${merchantConstants.ADD_SUB_USER_TO_MERCHANT_URI(data)}`;
      const curriedReq = yield call(createRequest, url, {
         method: 'POST',
         // body: JSON.stringify(data),
      });

      const response = yield call(fetch, curriedReq);
      console.log({
         response,
      });
      yield call(checkStatus, response);

      const jsonResponse = yield call(parseJSON, response);

      yield put({
         type: merchantConstants.ADD_SUB_USER_TO_MERCHANT_SUCCESS,
         id: jsonResponse,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);

         if (res.errors) {
            let errors = {};
            for (let i = 0; i < res.errors.length; i++) {
               errors[res.errors[i].param] = res.errors[i].msg;
            }

            yield put({
               type: merchantConstants.ADD_SUB_USER_TO_MERCHANT_ERROR,
               error: errors,
            });
            return;
         }

         yield put({
            type: merchantConstants.ADD_SUB_USER_TO_MERCHANT_ERROR,
            error: res.message,
         });

         return;
      }

      yield put({
         type: merchantConstants.ADD_SUB_USER_TO_MERCHANT_ERROR,
         error: error.message,
      });
   }
}

function* updateMerchantInfo({data}) {
   yield put({type: merchantConstants.UPDATING_MERCHANT_INFO});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      const merchantCompany = yield call(
         getObjectFromStorage,
         merchantConstants.MERCHANT_COMPANY_KEY,
      );
      const url = `${merchantConstants.UPDATE_MERCHANT_INFO_URI(merchantCompany?.details?._id)}`;
      const curriedReq = yield call(createMultiPartRequestWithToken, url, {
         method: 'PATCH',
         body: data,
      });
      const req = yield call(curriedReq, merchant?.token);
      // const req = createRequestWithToken(url, {
      //    method: 'PATCH',
      //    body: JSON.stringify(data),
      // })(merchant?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(parseJSON, response);

      const updatedUser = {
         ...merchantCompany,
         user: {...merchant.user, profile_updated: true},
         details: jsonResponse.data,
      };
      console.log('updatedUser', updatedUser);
      yield call(setObjectInStorage, merchantConstants.MERCHANT_COMPANY_KEY, updatedUser);

      yield put({
         type: merchantConstants.UPDATE_MERCHANT_INFO_SUCCESS,
         // user: updatedUser.user,
         details: jsonResponse.data,
      });
      yield put(
         appActions.setSnackBar({
            message: 'merchant successfully updated',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: merchantConstants.UPDATE_MERCHANT_INFO_FAILURE,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: errorMessage.message ? errorMessage.message : 'Something went wrong',
            variant: 'error',
         }),
      );

      // if (error.response) {
      //    const res = yield call(parseJSON, error.response);

      //    if (res.errors) {
      //       let errors = {};
      //       for (let i = 0; i < res.errors.length; i++) {
      //          errors[res.errors[i].param] = res.errors[i].msg;
      //       }

      //       yield put({
      //          type: merchantConstants.UPDATE_MERCHANT_INFO_FAILURE,
      //          error: errors,
      //       });
      //       yield put(
      //          appActions.setSnackBar({
      //             message: 'error in updating merchant',
      //             variant: 'error',
      //          }),
      //       );
      //       return;
      //    }

      //    yield put({
      //       type: merchantConstants.UPDATE_MERCHANT_INFO_FAILURE,
      //       error: res.message,
      //    });
      //    yield put(
      //       appActions.setSnackBar({
      //          message: 'error in updating merchant',
      //          variant: 'error',
      //       }),
      //    );

      //    return;
      // }

      // yield put({
      //    type: merchantConstants.UPDATE_MERCHANT_INFO_FAILURE,
      //    error: error.message,
      // });
      // yield put(
      //    appActions.setSnackBar({
      //       message: 'error in updating merchant',
      //       variant: 'error',
      //    }),
      // );
   }
}

function* updateBasicSaga({data}) {
   yield put({type: merchantConstants.CREATING_DETAILS});
   try {
      const savedmerchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      const body = new FormData();
      Object.keys(data).forEach((item) => body.append(item, data[item]));
      const req = yield call(
         createRequest,
         `${merchantConstants.MERCHANT_URI}/create`,
         {
            method: 'PATCH',
            body,
         },
         savedmerchant.token,
      );
      const response = yield call(fetch, req);
      yield call(checkStatus, response);
      const jsonResponse = yield call(parseJSON, response);

      yield put({
         type: merchantConstants.CREATE_DETAILS_SUCCESS,
         merchant: jsonResponse.merchant,
      });
      console.log('create details success');
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);
         console.log('create details error', res);
         if (res.errors) {
            let errors = {};
            for (let i = 0; i < res.errors.length; i++) {
               errors[res.errors[i].param] = res.errors[i].msg;
            }

            yield put({
               type: merchantConstants.CREATE_DETAILS_FAILURE,
               error: errors,
            });
            return;
         }

         yield put({
            type: merchantConstants.CREATE_DETAILS_FAILURE,
            error: res.message,
         });

         return;
      }

      console.log('create details error end', error.message);
      yield put({
         type: merchantConstants.CREATE_DETAILS_FAILURE,
         error: error.message,
      });
   }
}

function* createNextKinSaga({data}) {
   yield put({type: merchantConstants.ADDING_NEXT_OF_KIN});
   try {
      const savedmerchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      const req = yield call(
         createRequest,
         `${merchantConstants.ADD_NEXT_OF_KIN_URI(savedmerchant.user._id)}`,
         {
            method: 'PATCH',
            body: JSON.stringify(data),
         },
         savedmerchant.token,
      );
      console.log('savedmerchant.user._id', savedmerchant.user._id);
      const response = yield call(fetch, req);
      yield call(checkStatus, response);
      const jsonResponse = yield call(parseJSON, response);
      const updatedmerchant = {
         ...savedmerchant,
         merchant: {
            ...savedmerchant,
            user: {...savedmerchant.user, next_of_kin: jsonResponse.data},
         },
      };
      call(setObjectInStorage, merchantConstants.MERCHANT_KEY, updatedmerchant);

      yield put({
         type: merchantConstants.ADD_NEXT_OF_KIN_SUCCESS,
         merchant: updatedmerchant,
      });

      console.log('update next of kin success', updatedmerchant);
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);
         console.log('update next of kin error', res);
         if (res.errors) {
            let errors = {};
            for (let i = 0; i < res.errors.length; i++) {
               errors[res.errors[i].param] = res.errors[i].msg;
            }

            yield put({
               type: merchantConstants.ADD_NEXT_OF_KIN_FAILURE,
               error: errors,
            });
            console.log('update next of kin errors', errors);
            return;
         }

         yield put({
            type: merchantConstants.ADD_NEXT_OF_KIN_FAILURE,
            error: res.message,
         });

         return;
      }

      console.log(
         'update next of kin error',
         error.message,
         merchantConstants.ADD_NEXT_OF_KIN_URI(':id'),
      );
      yield put({
         type: merchantConstants.ADD_NEXT_OF_KIN_FAILURE,
         error: error.message,
      });
   }
}

function* getDashBoardSaga({data}) {
   yield put({type: merchantConstants.GETTING_DASHBOARD});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);

      const req = createRequestWithToken(merchantConstants.GET_MERCHANT_DASHBOARD_URI(data), {
         method: 'GET',
      })(merchant?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);
      const jsonResponse = yield call(parseJSON, response);

      yield put({
         type: merchantConstants.GET_DASHBOARD_SUCCESS,
         wallet_balance: jsonResponse.wallet_balance,
         received_today: jsonResponse.received_today,
         settled_today: jsonResponse.settled_today,
         recent_transaction: jsonResponse.recent_transaction,
         message: jsonResponse.message,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);
         console.log('get dashboard error', res.error);
         yield put({
            type: merchantConstants.GET_DASHBOARD_FAILURE,
            error: res.error,
         });

         return;
      }

      console.log('get dashboard error', error);
      yield put({
         type: merchantConstants.GET_DASHBOARD_FAILURE,
         error: error.message,
      });
   }
}

function* getMerchantUsers() {
   yield put({type: merchantConstants.REQUEST_MERCHANT_USERS});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      const merchantCompany = yield call(
         getObjectFromStorage,
         merchantConstants.MERCHANT_COMPANY_KEY,
      );
      let merchantUsersUri = `${merchantConstants.GET_MERCHANT_USERS_URI(
         merchantCompany?.details?._id,
      )}`;
      console.log('merchant', merchant);
      const merchantUsersReq = createRequestWithToken(merchantUsersUri, {
         method: 'GET',
      })(merchant?.token);

      const response = yield call(fetch, merchantUsersReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: merchantConstants.REQUEST_MERCHANT_USERS_SUCCESS,
         merchantUsers: jsonResponse,
      });
   } catch (error) {
      yield put({type: merchantConstants.REQUEST_MERCHANT_USERS_FAILURE});
   }
}

function* saveMerchantUser({data}) {
   yield put({type: merchantConstants.IS_CREATING_MERCHANT_USER});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      const merchantCompany = yield call(
         getObjectFromStorage,
         merchantConstants.MERCHANT_COMPANY_KEY,
      );
      let saveMerchantUserUri = `${merchantConstants.GET_MERCHANT_USERS_URI(
         merchantCompany?.details?._id,
      )}`;

      const req = createRequestWithToken(saveMerchantUserUri, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(merchant?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: merchantConstants.CREATE_MERCHANT_USER_SUCCESS,
         merchantUser: jsonResponse,
      });
      yield put(
         appActions.setSnackBar({
            message: jsonResponse ? jsonResponse?.message : 'User successfully created',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: merchantConstants.CREATE_MERCHANT_USER_FAILURE,
         error: errorMessage,
      });
   }
}

function* saveMerchantSubUser({data}) {
   yield put({type: merchantConstants.IS_CREATING_MERCHANT_SUB_USER});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      // const merchantCompany = yield call(
      //    getObjectFromStorage,
      //    merchantConstants.MERCHANT_COMPANY_KEY,
      // );
      let saveMerchantSubUserUri = `${merchantConstants.ADD_MERCHANT_SUB_USER_URI}`;

      const req = createRequestWithToken(saveMerchantSubUserUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(merchant?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));
      console.log('jsonResponse', jsonResponse);

      yield put({
         type: merchantConstants.CREATE_MERCHANT_SUB_USER_SUCCESS,
         merchantSubUser: jsonResponse,
      });
      yield put(
         appActions.setSnackBar({
            message: jsonResponse ? jsonResponse?.message : 'User successfully created',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: merchantConstants.CREATE_MERCHANT_SUB_USER_FAILURE,
         error: errorMessage,
      });
   }
}

function* deleteMerchantUser({_id}) {
   try {
      yield put({type: merchantConstants.IS_DELETING_MERCHANT_USER});
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);

      const url = `${merchantConstants.DELETE_MERCHANT_USER_URI(_id)}`;

      const req = createRequestWithToken(url, {
         method: 'DELETE',
         body: JSON.stringify({_id: _id}),
      })(merchant?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      yield put({type: merchantConstants.DELETE_MERCHANT_USER_SUCCESS, _id});

      yield put(
         appActions.setSnackBar({
            message: ' user successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: merchantConstants.DELETE_MERCHANT_USER_FAILURE,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* getMerchantSagaWatcher() {
   yield takeLatest(merchantConstants.GET_MERCHANT, getMerchantSaga);
}

function* getMerchantCompanySagaWatcher() {
   yield takeLatest(merchantConstants.GET_MERCHANT_COMPANY, getMerchantCompanySaga);
}

function* getMerchantSubUserSagaWatcher() {
   yield takeLatest(merchantConstants.GET_MERCHANT_SUB_USER, getMerchantSubUserSaga);
}

function* createNextSagaWatcher() {
   yield takeLatest(merchantConstants.ADD_NEXT_OF_KIN, createNextKinSaga);
}

function* registerMerchantSagaWatcher() {
   yield takeLatest(merchantConstants.REGISTER_MERCHANT, registerMerchant);
}

function* createMerchantCompanySagaWatcher() {
   yield takeLatest(merchantConstants.CREATE_MERCHANT_COMPANY, createMerchantCompany);
}

function* registerMerchantSubUserSagaWatcher() {
   yield takeLatest(merchantConstants.REGISTER_MERCHANT_SUB_USER, registerMerchantSubUser);
}

function* addSubUserToMerchantSagaWatcher() {
   yield takeLatest(merchantConstants.ADD_SUB_USER_TO_MERCHANT, addSubUserToMerchant);
}

function* updateMerchantInfoSagaWatcher() {
   yield takeLatest(merchantConstants.UPDATE_MERCHANT_INFO, updateMerchantInfo);
}

function* updatePasswordSagaWatcher() {
   yield takeLatest(merchantConstants.CHANGE_PASSWORD, updatePasswordSaga);
}

function* updatePinSagaWatcher() {
   yield takeLatest(merchantConstants.CHANGE_PIN, updatePinSaga);
}

function* resetPinSagaWatcher() {
   yield takeLatest(merchantConstants.RESET_PIN, resetPinSaga);
}

function* updateBasicSagaWatcher() {
   yield takeLatest(merchantConstants.CREATE_DETAILS, updateBasicSaga);
}

function* getDashBoardSagaWatcher() {
   yield takeLatest(merchantConstants.GET_DASHBOARD, getDashBoardSaga);
}

function* getMerchantUsersWatcher() {
   yield takeLatest(merchantConstants.GET_MERCHANT_USERS, getMerchantUsers);
}

function* saveMerchantUserWatcher() {
   yield takeLatest(merchantConstants.CREATE_MERCHANT_USER, saveMerchantUser);
}

function* saveMerchantSubUserWatcher() {
   yield takeLatest(merchantConstants.CREATE_MERCHANT_SUB_USER, saveMerchantSubUser);
}

function* deleteMerchantUserWatcher() {
   yield takeLatest(merchantConstants.DELETE_MERCHANT_USER, deleteMerchantUser);
}

export default function* rootSaga() {
   yield all([
      getMerchantSagaWatcher(),
      getMerchantCompanySagaWatcher(),
      getMerchantSubUserSagaWatcher(),
      registerMerchantSagaWatcher(),
      registerMerchantSubUserSagaWatcher(),
      createMerchantCompanySagaWatcher(),
      addSubUserToMerchantSagaWatcher(),
      updateBasicSagaWatcher(),
      updateMerchantInfoSagaWatcher(),
      createNextSagaWatcher(),
      updatePasswordSagaWatcher(),
      updatePinSagaWatcher(),
      resetPinSagaWatcher(),
      getDashBoardSagaWatcher(),
      getMerchantUsersWatcher(),
      saveMerchantUserWatcher(),
      saveMerchantSubUserWatcher(),
      deleteMerchantUserWatcher(),
   ]);
}
