import {settlementConstants} from '../_constants';

const makeFullSettlement = (model) => ({
   type: settlementConstants.MAKE_FULL_SETTLEMENT,
   data: model,
});

const makePartialSettlement = (model) => ({
   type: settlementConstants.MAKE_PARTIAL_SETTLEMENT,
   data: model,
});

export const settlementActions = {
   makeFullSettlement,
   makePartialSettlement,
};
