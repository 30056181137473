import React, {useState, useEffect} from 'react';
import {MDBContainer, MDBCol} from 'mdb-react-ui-kit';
import classNames from 'classnames';
import Brand from '../../../_assets/images/BuyGo.png';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextInput from '../../../_components/TextInput';
import Formsy from 'formsy-react';
import {Link} from 'react-router-dom';
import './index.scss';

function NewCompany({location, history}) {
   const [canSubmit, setCanSubmit] = useState(false);

   const handleSubmit = (data) => {};

   useEffect(() => {
      // dispatch(appActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleRoute = () => {
      history.push('/company');
   };

   return (
      <>
         <section className="new-company">
            <div className="h-100">
               <div className="bigboxes h-100">
                  <div className="col-10 col-sm-6 col-lg-4">
                     <img
                        src={Brand}
                        alt=""
                        className="px-0 px-md-3 ms-4 ms-md-4 mt-2"
                        style={{width: '50%'}}
                     />
                  </div>
                  <div className="d-flex justify-content-center smallbox">
                     <div className="col-10 col-md-8 col-lg-5 ms-5 mb-5 ms-md-2 mt-5 smallbox2">
                        <div className="ms-0 ms-md-5 ps-2 mb-4 title">Create New Company</div>
                        <Formsy
                           onValidSubmit={handleSubmit}
                           onValid={() => setCanSubmit(true)}
                           onInvalid={() => setCanSubmit(false)}>
                           <div className="forms ms-md-5 col-md-10">
                              <div
                                 id="textExample1"
                                 className="form-text"
                                 style={{color: 'black', fontWeight: '500', fontSize: '13px'}}>
                                 Company Name
                              </div>
                              <FormControl margin="normal" required fullWidth>
                                 <TextInput
                                    name="email_phone"
                                    autoComplete="on"
                                    wrapperClassName="input-wrapper"
                                    mainWrapperClassName="w-100 mb-3 mb-s-4"
                                    placeholder="company name"
                                    useFocusedError
                                    className="input border-0 w-100"
                                    required
                                 />
                              </FormControl>
                           </div>
                           <div className="forms ms-md-5 col-md-10">
                              <div
                                 id="textExample1"
                                 className="form-text"
                                 style={{color: 'black', fontWeight: '500'}}>
                                 Location
                              </div>
                              <FormControl margin="normal" required fullWidth>
                                 <TextInput
                                    name="password"
                                    wrapperClassName="input-wrapper"
                                    mainWrapperClassName="w-100 mb-3 mb-s-4"
                                    placeholder="select location"
                                    useFocusedError
                                    className="input border-0 w-100"
                                    validationError="Please provide your password!"
                                    required
                                 />
                              </FormControl>
                           </div>
                           <div className="new-form col-xl-12 ms-xl-0">
                              <button
                                 disabled={!canSubmit}
                                 type="submit"
                                 onClick={handleRoute}
                                 className={classNames(
                                    'd-flex col-12 col-md-10 p-2 ms-0 ms-md-5 justify-content-center signbtn',
                                    {
                                       disabled: !canSubmit,
                                    },
                                 )}>
                                 Add
                              </button>
                           </div>
                           {/* </Link> */}
                        </Formsy>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
}

export default NewCompany;
