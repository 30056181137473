import React, {useState} from 'react';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import {Link} from 'react-router-dom';
import BlueYellow from '..//../..//_assets/images/blueandyellow.svg';
// import {ReactComponent as Bluetick} from '..//..//..//_assets/icon/bluetick.svg';
import {ReactComponent as Load} from '..//..//..//_assets/icon/verify.svg';
import './index.scss';

function Loading() {
   return (
      <section className="load">
         <img src={BlueYellow} alt="" className="img-fluid image" />
         <MDBContainer className="align-items-center">
            <MDBCol className="smallbox2">
               <Load className="img-fluid tick" />
               <br />
               <br />
               <br />
               <p>
               Please wait
                  <br />
                  While we verify your email
               </p>
               <br />
            </MDBCol>
         </MDBContainer>
      </section>
   );
}
export default Loading;
