import React, {useState, useEffect} from 'react';
import {MDBContainer, MDBCol} from 'mdb-react-ui-kit';
import {useDispatch, useSelector} from 'react-redux';
// import {merchantConstants} from '../../../_constants';
import {appActions, merchantActions} from '../../../_actions';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextInput from '..//..//..//_components/TextInput';
// import classNames from 'classnames';
import BlueYellow from '..//..//../_assets/images/blueandyellow.svg';
// import Customerone from '../../_assets/images/customerone.png';
import Formsy from 'formsy-react';
// import Customertwo from '../../_assets/images/customertwo.png';
// import Customerthree from '../../_assets/images/customerthree.png';
// import {makeStyles} from '@material-ui/core/styles';
import {Redirect} from 'react-router-dom';
import './index.scss';

// const useStyles = makeStyles((theme) => ({
//    margin: {
//       margin: theme.spacing(1),
//    },
//    textField: {
//       width: '45ch',
//       height: '36px',
//    },
//    root: {
//       width: '100%',
//       marginRight: '230p',
//       height: '36px',
//    },
//    // padding: {
//    //    padding: '10px 2px',
//    //    height: '15px',
//    //    width: '100%',
//    //    marginLeft: '-10px',
//    //    borderColor: 'red',
//    // },
//    input: {
//       padding: '10px 2px',
//       height: '15px',
//       width: '100%',
//       border: '1px solid white',
//       '&$focused $hover $notchedOutline': {
//          borderColor: 'white',
//          borderWidth: 1,
//       },
//    },
// }));

function PhonePage() {
   const dispatch = useDispatch();
   const {unverifiedUserId, errorMessage, sendingOtp, sendOtpSuccess} = useSelector((s) => s.auth);
   // const errorMessage = useSelector((s) => s.merchant.errorMessage);
   // const classes = useStyles();
   const [codeError, setCodeError] = useState('');
   const [phoneError, setPhoneError] = useState('');
   const [canSubmit, setCanSubmit] = useState(false);

   useEffect(() => {
      window.scrollTo(0, 0);
      dispatch(appActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleSubmit = (data) => {
      data['_id'] = unverifiedUserId;
      data['phone_number'] = data['phone_number'].replace(/^0/, '').trim();
      data['country_code'] = '234';

      dispatch(merchantActions.sendOtp(data));
   };

   useEffect(() => {
      console.log('errorMessage', errorMessage);

      if (typeof errorMessage === 'string') {
         setCodeError(errorMessage);
      }

      if (errorMessage?.[0]?.msg) {
         setPhoneError(errorMessage?.[0]?.msg);
      }

      // dispatch(merchantActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [errorMessage]);

   if (sendOtpSuccess) {
      return <Redirect to="/otp" />;
   }

   return (
      <>
         <section className="phone">
            <img src={BlueYellow} alt="" className="img-fluid image" />
            <MDBContainer className="align-items-center">
               <div className="bigboxes">
                  <MDBCol className="text">
                     <h3>
                        Please
                        <br /> Enter Phone Number
                     </h3>
                     {/* <p> */}
                     <div className="ms-4 ps-1 ps-md-0 ms-md-5">
                        Already have an account? <br />
                        <a style={{fontWeight: 'bolder'}} href="/login">
                           Log in
                        </a>
                     </div>
                     {/* </p> */}
                  </MDBCol>
                  <MDBCol className="smallbox">
                     <MDBCol className="smallbox2">
                        <Formsy
                           onValidSubmit={handleSubmit}
                           onValid={() => setCanSubmit(true)}
                           onInvalid={() => setCanSubmit(false)}>
                           <div className="forms col-md-10">
                              {codeError && <h5 className="error">{codeError}</h5>}
                              <div id="textExample1" className="form-text" style={{color: 'black'}}>
                                 Phone Number
                              </div>
                              <FormControl margin="normal" required fullWidth>
                                 <TextInput
                                    name="phone_number"
                                    wrapperClassName="input-wrapper"
                                    mainWrapperClassName="w-100 mb-3 mb-s-4"
                                    placeholder="phone number"
                                    useFocusedError
                                    required
                                    className="input border-0 w-100"
                                    valError={phoneError}
                                    validations={{isNumeric: true, minLength: 9, maxLength: 11}}
                                    validationErrors={{
                                       isNumeric: 'Invalid phone number',
                                       minLength: 'Phone number incomplete',
                                       maxLength: 'Just 11 characters',
                                    }}
                                    // validations="isInt"
                                    // validationErrors={{
                                    //    isInt: 'Invalid value, must contain only numbers!',
                                    //    required: 'Required!',
                                    // }}
                                 />
                              </FormControl>
                           </div>
                           {/* 
                           <Link to="/otp"> */}
                           <button disabled={!canSubmit} type="submit" className="signupbtn">
                              {sendingOtp ? (
                                 <CircularProgress color="inherit" size="1rem" thickness={2} />
                              ) : (
                                 'Submit'
                              )}
                           </button>
                           {/* </Link> */}
                        </Formsy>
                     </MDBCol>
                  </MDBCol>
               </div>
            </MDBContainer>
         </section>
      </>
   );
}

export default PhonePage;
