import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import {MDBContainer, MDBCol, MDBRow} from 'mdb-react-ui-kit';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextInput from '../../../_components/TextInput';
import classNames from 'classnames';
import BlueYellow from '..//../../_assets/images/blueandyellow.svg';
import {ReactComponent as Error} from '..//..//..//_assets/icon/error.svg';
import Formsy from 'formsy-react';
import Verification from '../../Verification';
// import Customertwo from '../../_assets/images/customertwo.png';
// import Customerthree from '../../_assets/images/customerthree.png';
// import {makeStyles} from '@material-ui/core/styles';
// import {Link} from 'react-router-dom';
import './index.scss';
import {appActions, merchantActions} from '../../../_actions';
// import NewUserSignUp from '../../SignUp/newUser';
// import {merchantConstants} from '../../../_constants';

// const useStyles = makeStyles((theme) => ({
//    margin: {
//       margin: theme.spacing(1),
//    },
//    textField: {
//       width: '45ch',
//       height: '36px',
//    },
//    root: {
//       width: '100%',
//       marginRight: '230p',
//       height: '36px',
//    },
//    // padding: {
//    //    padding: '10px 2px',
//    //    height: '15px',
//    //    width: '100%',
//    //    marginLeft: '-10px',
//    //    borderColor: 'red',
//    // },
//    input: {
//       padding: '10px 2px',
//       height: '15px',
//       width: '100%',
//       border: '1px solid white',
//       '&$focused $hover $notchedOutline': {
//          borderColor: 'white',
//          borderWidth: 1,
//       },
//    },
// }));

function NewUserSignUp({match, location, history}) {
   let params = queryString.parse(location.search);
   const {token} = match.params;

   const dispatch = useDispatch();
   const IsRegisteringMerchantSubUser = useSelector((s) => s.merchant.IsRegisteringMerchantSubUser);
   const merchantSubUserCreated = useSelector((s) => s.merchant.merchantSubUserCreated);
   const subUserAddedToMerchantSuccessfully = useSelector(
      (s) => s.merchant.subUserAddedToMerchantSuccessfully,
   );
   // const merchant = useSelector((s) => s.merchant.merchant);
   const errorMessage = useSelector((s) => s.merchant.errorMessage);
   // const classes = useStyles();
   const [canSubmit, setCanSubmit] = useState(false);
   const [passwordShown, setPasswordShown] = useState(false);
   const [passwordShow, setPasswordShow] = useState(false);
   const [firstNameError, setFirstNameError] = useState('');
   const [lastNameError, setLastNameError] = useState('');
   // const [emailError, setEmailError] = useState('');
   const [passwordError, setPasswordError] = useState('');
   const [codeError, setCodeError] = useState('');
   const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
   };
   const togglePasswordVisible = () => {
      setPasswordShow(passwordShow ? false : true);
   };

   useEffect(() => {
      dispatch(appActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (params?.new_user === 'false') {
         const data = token;
         dispatch(merchantActions.addSubUserToMerchant(data));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [params?.new_user === 'false']);

   useEffect(() => {
      if (subUserAddedToMerchantSuccessfully) {
         history.push('/login');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [subUserAddedToMerchantSuccessfully]);

   const handleSubmit = (data) => {
      setCodeError('');
      // setEmailError('');
      setFirstNameError('');
      setLastNameError('');
      setPasswordError('');
      data['token'] = token;
      // merchantConstants.MERCHANT_ROLE_ID;
      console.log(data);
      dispatch(merchantActions.registerMerchantSubUser(data));
   };
   console.log({
      firstNameError,
      lastNameError,
      // emailError,
      passwordError,
   });

   // useEffect(() => {
   //    if (typeof errorMessage === 'string') {
   //       setCodeError(errorMessage);
   //    }

   //    if (errorMessage?.firstname) {
   //       setFirstNameError(errorMessage?.firstname);
   //    }

   //    if (errorMessage?.lastname) {
   //       setLastNameError(errorMessage?.lastname);
   //    }

   //    // if (errorMessage?.email) {
   //    //    setEmailError(errorMessage?.email);
   //    // }

   //    if (errorMessage?.password) {
   //       setPasswordError(errorMessage?.password);
   //    }

   //    dispatch(appActions.clearMessages());
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [errorMessage]);
   // if (!params?.new_user) {
   //    return null;
   // }

   if (errorMessage) {
      return (
         <section className="emailverifyfailed">
            <img src={BlueYellow} alt="" className="img-fluid image" />
            <MDBContainer className="align-items-center">
               <MDBCol className="smallbox2">
                  <Error className="img-fluid tick" />
                  <br />
                  <br />
                  <br />
                  <br />
                  {/* <h3>Oops!...<br/>
               link expired
                  
                  
                  
                  </h3><br /> */}
                  <h3>{errorMessage}</h3>
                  <Link to="/login">
                     <button
                        // to="/verify"
                        // disabled={!canSubmit}
                        type="submit"
                        className="signupbtn">
                        Proceed to Login
                     </button>
                  </Link>
               </MDBCol>
            </MDBContainer>
         </section>
      );
   }

   if (params?.new_user === 'true') {
      return (
         <>
            <section className="merchant">
               {!merchantSubUserCreated && (
                  <>
                     <img src={BlueYellow} alt="" className="img-fluid styled-image" />
                     <MDBContainer className="h-100">
                        <MDBRow className="bigboxes h-100">
                           <MDBCol className="text">
                              <h3>Sign Up to Enjoy Unlimited Benefits</h3>
                              <p>
                                 Already have an account? &nbsp;
                                 <a style={{fontWeight: 'bolder'}} href="/login">
                                    Login
                                 </a>
                              </p>
                           </MDBCol>
                           <MDBCol className="smallbox">
                              <MDBCol className="smallbox2">
                                 <Formsy
                                    onValidSubmit={handleSubmit}
                                    onValid={() => setCanSubmit(true)}
                                    onInvalid={() => setCanSubmit(false)}>
                                    <div className="forms col-md-10">
                                       {errorMessage && <h5 className="error">{errorMessage}</h5>}
                                       <div
                                          id="textExample1"
                                          className="form-text"
                                          style={{color: 'black'}}>
                                          Promoter's FirstName
                                       </div>
                                       <FormControl margin="normal" required fullWidth>
                                          <TextInput
                                             name="firstname"
                                             wrapperClassName="input-wrapper"
                                             mainWrapperClassName="w-100 mb-3 mb-s-4"
                                             placeholder="firstname"
                                             useFocusedError
                                             className="input border-0 w-100"
                                             valError={errorMessage?.firstname}
                                             // validations="isWords"
                                             required
                                             // validationErrors={{
                                             //    isWords: 'Invalid value, must contain only letters!',
                                             //    required: 'Required!',
                                             // }}
                                          />
                                       </FormControl>
                                    </div>
                                    <div className="forms col-md-10">
                                       {errorMessage && (
                                          <h5 className="error">{errorMessage?.message}</h5>
                                       )}
                                       <div
                                          id="textExample1"
                                          className="form-text"
                                          style={{color: 'black'}}>
                                          Promoter's LastName
                                       </div>
                                       <FormControl margin="normal" required fullWidth>
                                          <TextInput
                                             name="lastname"
                                             wrapperClassName="input-wrapper"
                                             mainWrapperClassName="w-100 mb-3 mb-s-4"
                                             placeholder="lastname"
                                             useFocusedError
                                             valError={errorMessage?.lastname}
                                             className="input border-0 w-100"
                                             validations="isWords"
                                             required
                                          />
                                       </FormControl>
                                    </div>
                                    {/* <div className="forms col-md-10">
                                    <div
                                       id="textExample1"
                                       className="form-text"
                                       style={{color: 'black'}}>
                                       Email Address
                                    </div>
                                    <FormControl margin="normal" required fullWidth>
                                       <TextInput
                                          name="email"
                                          wrapperClassName="input-wrapper"
                                          mainWrapperClassName="w-100 mb-3 mb-s-4"
                                          placeholder="Rose@gmail"
                                          useFocusedError
                                          autoComplete="on"
                                          className="input border-0 w-100"
                                          validations="isEmail"
                                          required
                                          // valError={emailError}
                                          validationErrors={{
                                             isEmail: 'This is not a valid email',
                                             required: 'Required!',
                                          }}
                                       />
                                    </FormControl>
                                 </div> */}
                                    <div className="forms col-md-10">
                                       <div
                                          id="textExample1"
                                          className="form-text"
                                          style={{color: 'black'}}>
                                          Password
                                       </div>
                                       <FormControl margin="normal" required fullWidth>
                                          <TextInput
                                             name="password"
                                             wrapperClassName="input-wrapper"
                                             mainWrapperClassName="w-100 mb-3 mb-s-4"
                                             placeholder="********"
                                             useFocusedError
                                             type={passwordShown ? 'text' : 'password'}
                                             className="input border-0 w-100"
                                             valError={errorMessage?.password}
                                             validationError="Please provide your password!"
                                             required
                                          />
                                          <i onClick={togglePasswordVisiblity}>
                                             {passwordShown ? <Visibility /> : <VisibilityOff />}
                                          </i>
                                       </FormControl>
                                    </div>
                                    <div className="forms col-md-10">
                                       <div
                                          id="textExample1"
                                          className="form-text"
                                          style={{color: 'black'}}>
                                          Confirm Password
                                       </div>
                                       <FormControl margin="normal" required fullWidth>
                                          <TextInput
                                             name="confirmpassword"
                                             wrapperClassName="input-wrapper"
                                             mainWrapperClassName="w-100 mb-3 mb-s-4"
                                             placeholder="********"
                                             useFocusedError
                                             type={passwordShow ? 'text' : 'password'}
                                             className="input border-0 w-100"
                                             validations="equalsField:password"
                                             validationError="Passwords don't match!"
                                             required
                                          />
                                          <i onClick={togglePasswordVisible}>
                                             {passwordShow ? <Visibility /> : <VisibilityOff />}
                                          </i>
                                       </FormControl>
                                    </div>
                                    {/* <Link to="/verify"> */}
                                    <button
                                       // to="/verify"
                                       disabled={!canSubmit}
                                       type="submit"
                                       className={classNames('signupbtn', {
                                          disabled: !canSubmit,
                                       })}>
                                       {IsRegisteringMerchantSubUser ? (
                                          <CircularProgress color="inherit" thickness={2} />
                                       ) : (
                                          'Sign Up'
                                       )}
                                    </button>
                                    {/* </Link> */}
                                 </Formsy>
                              </MDBCol>
                           </MDBCol>
                        </MDBRow>
                     </MDBContainer>
                  </>
               )}
               {merchantSubUserCreated && <Verification />}
            </section>
         </>
      );
   } else {
      return null;
   }
}

export default NewUserSignUp;
