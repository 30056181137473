import {combineReducers} from 'redux';
import {settlementConstants, appConstants} from '../../_constants';

const IsMakingFullSettlement = (state = false, action) => {
   switch (action.type) {
      case settlementConstants.REQUEST_MAKE_FULL_SETTLEMENT:
         return true;
      case settlementConstants.REQUEST_MAKE_FULL_SETTLEMENT_ERROR:
         return false;
      case settlementConstants.REQUEST_MAKE_FULL_SETTLEMENT_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsMakingPartialSettlement = (state = false, action) => {
   switch (action.type) {
      case settlementConstants.REQUEST_MAKE_PARTIAL_SETTLEMENT:
         return true;
      case settlementConstants.REQUEST_MAKE_PARTIAL_SETTLEMENT_ERROR:
         return false;
      case settlementConstants.REQUEST_MAKE_PARTIAL_SETTLEMENT_SUCCESS:
         return false;
      default:
         return state;
   }
};

const fullSettlementMade = (state = false, action) => {
   switch (action.type) {
      case settlementConstants.REQUEST_MAKE_FULL_SETTLEMENT:
         return false;
      case settlementConstants.REQUEST_MAKE_FULL_SETTLEMENT_ERROR:
         return false;
      case settlementConstants.REQUEST_MAKE_FULL_SETTLEMENT_SUCCESS:
         return true;
      default:
         return state;
   }
};

const partialSettlementMade = (state = false, action) => {
   switch (action.type) {
      case settlementConstants.REQUEST_MAKE_PARTIAL_SETTLEMENT:
         return false;
      case settlementConstants.REQUEST_MAKE_PARTIAL_SETTLEMENT_ERROR:
         return false;
      case settlementConstants.REQUEST_MAKE_PARTIAL_SETTLEMENT_SUCCESS:
         return true;
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case settlementConstants.REQUEST_MAKE_FULL_SETTLEMENT_ERROR:
         if (error) return error;
         return state;
      case settlementConstants.REQUEST_MAKE_FULL_SETTLEMENT:
         return null;
      case settlementConstants.REQUEST_MAKE_PARTIAL_SETTLEMENT_ERROR:
         if (error) return error;
         return state;
      case settlementConstants.REQUEST_MAKE_PARTIAL_SETTLEMENT:
         return null;
      case appConstants.CLEAR_MESSAGES:
         return null;
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsMakingFullSettlement,
   IsMakingPartialSettlement,
   fullSettlementMade,
   partialSettlementMade,
   errorMessage,
});

export default rootReducer;
