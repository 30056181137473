import React, {useState, useEffect} from 'react';
import {
   // MDBContainer,
   MDBCarousel,
   MDBCarouselInner,
   MDBCarouselItem,
   MDBCarouselElement,
   MDBCarouselCaption,
   MDBCol,
} from 'mdb-react-ui-kit';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import TextInput from '../../_components/TextInput';
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import Brand from '../../_assets/images/buygo_white.png';
import Customerone from '../../_assets/images/customerone.png';
import Formsy from 'formsy-react';
import Customertwo from '../../_assets/images/customertwo.png';
import Customerthree from '../../_assets/images/customerthree.png';
// import {makeStyles} from '@material-ui/core/styles';
import {appActions, userActions} from '../../_actions';
import {capitalizeFirstLetter} from '../../_helpers';
// import {Link} from 'react-router-dom';
import './index.scss';
// import {FormatColorTextSharp} from '@material-ui/icons';
import Verification from '../Verification';

// const useStyles = makeStyles((theme) => ({
//    margin: {
//       margin: theme.spacing(1),
//    },
//    textField: {
//       width: '45ch',
//       height: '36px',
//    },
//    root: {
//       width: '100%',
//       marginRight: '230p',
//       height: '36px',
//    },
//    // padding: {
//    //    padding: '10px 2px',
//    //    height: '15px',
//    //    width: '100%',
//    //    marginLeft: '-10px',
//    //    borderColor: 'red',
//    // },
//    input: {
//       padding: '10px 2px',
//       height: '15px',
//       width: '100%',
//       border: '1px solid white',
//       '&$focused $hover $notchedOutline': {
//          borderColor: 'white',
//          borderWidth: 1,
//       },
//    },
// }));

function CustomerPage() {
   const dispatch = useDispatch();
   const IsRegisteringCustomer = useSelector((s) => s.customer.IsRegisteringCustomer);
   const customerCreated = useSelector((s) => s.customer.customerCreated);
   const errorMessage = useSelector((s) => s.customer.errorMessage);
   // const classes = useStyles();
   const [canSubmit, setCanSubmit] = useState(false);
   const [passwordShown, setPasswordShown] = useState(false);
   const [passwordShow, setPasswordShow] = useState(false);
   const [firstNameError, setFirstNameError] = useState('');
   const [lastNameError, setLastNameError] = useState('');
   const [emailError, setEmailError] = useState('');
   const [passwordError, setPasswordError] = useState('');
   const [codeError, setCodeError] = useState('');

   const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
   };
   const togglePasswordVisible = () => {
      setPasswordShow(passwordShow ? false : true);
   };
   useEffect(() => {
      dispatch(appActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleSubmit = (data) => {
      setCodeError('');
      setEmailError('');
      setFirstNameError('');
      setLastNameError('');
      setPasswordError('');
      // console.log(data);
      dispatch(userActions.createCustomer(data));
   };
   // console.log({
   //    firstNameError,
   //    lastNameError,
   //    emailError,
   //    passwordError,
   // });
   console.log('err', errorMessage);
   useEffect(() => {
      if (typeof errorMessage?.message === 'string') {
         setCodeError(errorMessage?.message);
      }

      if (errorMessage?.firstname) {
         setFirstNameError(errorMessage?.firstname);
      }

      if (errorMessage?.lastname) {
         setLastNameError(errorMessage?.lastname);
      }

      if (errorMessage?.email) {
         setEmailError(errorMessage?.email);
      }

      if (errorMessage?.password) {
         setPasswordError(errorMessage?.password);
      }

      dispatch(appActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [errorMessage]);

   return (
      <>
         <section className="customer pt-5">
            {!customerCreated && (
               <div className="align-items-center">
                  <div className="bigboxes">
                     <MDBCol>
                        <MDBCarousel showIndicators fade>
                           <img
                              src={Brand}
                              alt=""
                              className=" brand img-fluid"
                              style={{
                                 width: '35%',
                              }}
                           />
                           {/* <MDBCarouselInner> */}
                              <MDBCarouselItem itemId={1}>
                                 <img
                                    src={Customerone}
                                    className="customerone"
                                    alt="..."
                                    // style={{width: '100%'}}
                                 />

                                 <MDBCarouselCaption className="d-block text">
                                    <h5>Easy Access</h5>
                                    <br />
                                    <p>Access the app with so much ease and easy to use.</p>
                                 </MDBCarouselCaption>
                              </MDBCarouselItem>

                              <MDBCarouselItem itemId={2}>
                                 <img
                                    src={Customertwo}
                                    className="customertwo"
                                    alt="..."
                                 />
                                 <MDBCarouselCaption className="d-block text">
                                    <h5>Fast Purchase</h5>
                                    <br />
                                    <p>Purchase anything you want with the help of the app.</p>
                                 </MDBCarouselCaption>
                              </MDBCarouselItem>

                              <MDBCarouselItem itemId={3}>
                                 <img
                                    src={Customerthree}
                                    className="customerthree"
                                    alt="..."
                                 />
                                 <MDBCarouselCaption className="d-block text">
                                    <h5>Quick Payment</h5>
                                    <br />
                                    <p>Access the app with so much ease and easy to use.</p>
                                 </MDBCarouselCaption>
                              </MDBCarouselItem>
                           {/* </MDBCarouselInner> */}
                        </MDBCarousel>
                     </MDBCol>
                     <MDBCol className="smallbox">
                        <p className="another">Sign Up to Enjoy Unlimited Benefits</p>

                        <MDBCol className=" smallbox2">
                           
                           <Formsy
                              onValidSubmit={handleSubmit}
                              onValid={() => setCanSubmit(true)}
                              onInvalid={() => setCanSubmit(false)}>
                              <div className="forms col-md-10">
                                 {codeError && (
                                    <h5 className="error">{capitalizeFirstLetter(codeError)}</h5>
                                 )}
                                 <div
                                    id="textExample1"
                                    className="form-text"
                                    style={{color: 'black'}}>
                                    First Name
                                 </div>
                                 <FormControl margin="normal" required fullWidth>
                                    <TextInput
                                       name="firstname"
                                       wrapperClassName="input-wrapper"
                                       mainWrapperClassName="w-100 mb-3 mb-s-4"
                                       placeholder="first name"
                                       useFocusedError
                                       className="input border-0 w-100"
                                       valError={firstNameError}
                                       validations="isWords"
                                       validationErrors={{
                                          isWords: 'Invalid value, must contain only letters!',
                                          required: 'Required!',
                                       }}
                                       // valError={fullNameError}
                                    />
                                 </FormControl>
                              </div>
                              <div className="forms col-md-10">
                                 {codeError && <h5 className="error">{codeError?.message}</h5>}
                                 <div
                                    id="textExample1"
                                    className="form-text"
                                    style={{color: 'black'}}>
                                    Last Name
                                 </div>
                                 <FormControl margin="normal" required fullWidth>
                                    <TextInput
                                       name="lastname"
                                       wrapperClassName="input-wrapper"
                                       mainWrapperClassName="w-100 mb-3 mb-s-4"
                                       placeholder="last name"
                                       useFocusedError
                                       className="input border-0 w-100"
                                       validations="isWords"
                                       valError={lastNameError}
                                       validationErrors={{
                                          isWords: 'Invalid value, must contain only letters!',
                                          required: 'Required!',
                                       }}
                                       // valError={fullNameError}
                                    />
                                 </FormControl>
                              </div>
                              <div className="forms col-md-10">
                                 <div
                                    id="textExample1"
                                    className="form-text"
                                    style={{color: 'black'}}>
                                    Email Address
                                 </div>
                                 <FormControl margin="normal" required fullWidth>
                                    <TextInput
                                       name="email"
                                       wrapperClassName="input-wrapper"
                                       mainWrapperClassName="w-100 mb-3 mb-s-4"
                                       placeholder="email address"
                                       useFocusedError
                                       className="input border-0 w-100"
                                       validations="isEmail"
                                       autoComplete
                                       required
                                       valError={emailError}
                                       validationErrors={{
                                          isEmail: 'This is not a valid email',
                                          required: 'Required!',
                                       }}
                                    />
                                 </FormControl>
                              </div>
                              <div className="forms col-md-10">
                                 <div
                                    id="textExample1"
                                    className="form-text"
                                    style={{color: 'black'}}>
                                    Password
                                 </div>
                                 <FormControl margin="normal" required fullWidth>
                                    <TextInput
                                       name="password"
                                       wrapperClassName="input-wrapper"
                                       mainWrapperClassName="w-100 mb-3 mb-s-4"
                                       placeholder="********"
                                       useFocusedError
                                       type={passwordShown ? 'text' : 'password'}
                                       className="input border-0 w-100"
                                       valError={passwordError}
                                       validationError="Please provide your password!"
                                       required
                                    />
                                    <i onClick={togglePasswordVisiblity}>
                                       {passwordShown ? <Visibility /> : <VisibilityOff />}
                                    </i>
                                 </FormControl>
                              </div>
                              <div className="forms col-md-10">
                                 <div
                                    id="textExample1"
                                    className="form-text"
                                    style={{color: 'black'}}>
                                    Confirm Password
                                 </div>
                                 <FormControl margin="normal" required fullWidth>
                                    <TextInput
                                       name="confirmpassword"
                                       wrapperClassName="input-wrapper"
                                       mainWrapperClassName="w-100 mb-3 mb-s-4"
                                       placeholder="********"
                                       useFocusedError
                                       type={passwordShow ? 'text' : 'password'}
                                       className="input border-0 w-100"
                                       validations="equalsField:password"
                                       validationError="Passwords don't match!"
                                       required
                                    />
                                    <i onClick={togglePasswordVisible}>
                                       {passwordShow ? <Visibility /> : <VisibilityOff />}
                                    </i>
                                 </FormControl>
                              </div>

                              <button
                                 // to="/verify"
                                 disabled={!canSubmit}
                                 type="submit"
                                 className={classNames('sign col-md-10', {
                                    able: !canSubmit,
                                 })}>
                                 {IsRegisteringCustomer ? (
                                    <CircularProgress color="inheirit" thickness={2} />
                                 ) : (
                                    'Sign Up'
                                 )}
                              </button>
                           </Formsy>
                        </MDBCol>
                     </MDBCol>
                  </div>
               </div>
            )}
            {customerCreated && <Verification />}
         </section>
      </>
   );
}

export default CustomerPage;
