import React, {useState, useEffect} from 'react';
import {MDBContainer, MDBCol} from 'mdb-react-ui-kit';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextInput from '../../../_components/TextInput';
// import classNames from 'classnames';
import BlueYellow from '../../../_assets/images/blueandyellow.svg';
// import Customerone from '../../_assets/images/customerone.png';
import Formsy from 'formsy-react';
import {Link} from 'react-router-dom';
import './index.scss';
import {merchantActions} from '../../../_actions';

function SubmerchantLoginPage({location, history}) {
   const dispatch = useDispatch();
   // The first application below
   const {
      IsRequestingLogin,
      IsRequestingLogout,
      // IsVerifyingPhone,
      errorMessage,
      unverifiedEmail,
      unverifiedPhone,
      unsetTransactionPin,
   } = useSelector((s) => s.auth);

   const {data, IsRequestingMerchant} = useSelector((s) => s.merchant);

   const [canSubmit, setCanSubmit] = useState(false);
   const [passwordShown, setPasswordShown] = useState(false);
   const [codeError, setCodeError] = useState('');
   const [phoneError, setPhoneError] = useState('');
   const [passwordError, setPasswordError] = useState('');
   const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
   };

   const handleSubmit = (data) => {
      data['phone_number'] = data['phone_number'].replace(/^0/, '234').trim();

      setCodeError('');
      setPhoneError('');
      setPasswordError('');
      dispatch(merchantActions.merchantSubUserLogin(data));
   };

   useEffect(() => {
      if (typeof errorMessage === 'string') {
         setCodeError(errorMessage);
      }
      if (errorMessage?.password) {
         setPasswordError(
            'password must be 8 characters long and must contain minimum of one lowercase, uppercase, digit',
         );
      }
      if (errorMessage?.email_phone) {
         setPhoneError('Invalid phone number, N.B: number must begin with 234');
      }
   }, [errorMessage]);

   if (IsRequestingMerchant) {
      return null;
   }

   if (IsRequestingLogout) {
      return null;
   }

   if (data) {
      history.push('/merchant/sub-user-history');
   }

   return (
      <>
         <section className="submerchantlogin">
            <img src={BlueYellow} alt="" className="img-fluid image" />
            <MDBContainer className="h-100 align-items-center">
               <div className="bigboxes h-100">
                  <MDBCol className="text">
                     <h3>
                        Welcome
                        <br />
                        Lorem Ipsum Ipsum
                     </h3>
                     {/* <p>
                        New user? &nbsp; <br />
                        <a style={{fontWeight: 'bolder'}} href="/signup">
                           Sign up
                        </a>
                     </p> */}
                  </MDBCol>
                  <MDBCol className="mb-5 smallbox">
                     <MDBCol className="smallbox2">
                        <Formsy
                           onValidSubmit={handleSubmit}
                           onValid={() => setCanSubmit(true)}
                           onInvalid={() => setCanSubmit(false)}>
                           <div className="forms col-md-10">
                              {codeError && <h5 className="error">{codeError}</h5>}
                              <div id="textExample1" className="form-text" style={{color: 'black'}}>
                                 Phone Number
                              </div>
                              <FormControl margin="normal" required fullWidth>
                                 <TextInput
                                    name="phone_number"
                                    autoComplete="on"
                                    wrapperClassName="input-wrapper"
                                    mainWrapperClassName="w-100 mb-3 mb-s-4"
                                    placeholder="Phone Number"
                                    useFocusedError
                                    className="input border-0 w-100"
                                    valError={phoneError}
                                    validations={{isNumeric: true, minLength: 9, maxLength: 11}}
                                    validationErrors={{
                                       isNumeric: 'Invalid phone number',
                                       minLength: 'Phone number incomplete',
                                       maxLength: 'Just 11 characters',
                                    }}
                                    required
                                 />
                              </FormControl>
                           </div>
                           <div className="forms col-md-10">
                              <div id="textExample1" className="form-text" style={{color: 'black'}}>
                                 Password
                              </div>
                              <FormControl margin="normal" required fullWidth>
                                 <TextInput
                                    name="password"
                                    wrapperClassName="input-wrapper"
                                    mainWrapperClassName="w-100 mb-3 mb-s-4"
                                    placeholder="********"
                                    useFocusedError
                                    type={passwordShown ? 'text' : 'password'}
                                    className="input border-0 w-100"
                                    validationError="Please provide your password!"
                                    required
                                    valError={passwordError}
                                 />
                                 <i onClick={togglePasswordVisiblity}>
                                    {passwordShown ? <Visibility /> : <VisibilityOff />}
                                 </i>
                              </FormControl>
                           </div>

                           <button
                              disabled={!canSubmit}
                              type="submit"
                              className={classNames('signbtn', {
                                 disabled: !canSubmit,
                              })}>
                              {IsRequestingLogin ? (
                                 <CircularProgress color="inherit" thickness={1} />
                              ) : (
                                 'Log In'
                              )}
                           </button>
                           {/* </Link> */}
                        </Formsy>
                     </MDBCol>
                  </MDBCol>
               </div>
            </MDBContainer>
         </section>
      </>
   );
}

export default SubmerchantLoginPage;
