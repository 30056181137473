import {appConstants} from './app.constant';

const signup = 'signup';
const user = 'user';

export const customerConstants = {
   REQUEST_REGISTER_CUSTOMER: 'REQUEST_REGISTER_CUSTOMER',
   REGISTER_CUSTOMER_SUCCESS: 'REGISTER_CUSTOMER_SUCCESS',
   REGISTER_CUSTOMER_ERROR: 'REGISTER_CUSTOMER_ERROR',

   REGISTER_CUSTOMER: 'REGISTER_CUSTOMER',
   // CUSTOMER_ROLE_ID: '6390913d2493d92563f85418', //test server
   // CUSTOMER_ROLE_ID: '60d9a02aebe692002293dda1', //test server
   // CUSTOMER_ROLE_ID: '6135fb4af931706b4e131e3e', //prod server
   CUSTOMER_ROLE_ID: '66684e42d3bed7453c3db33a', //prod server

   REGISTER_CUSTOMER_URI: `${appConstants.BASE_URI}${user}/${signup}`,
};
