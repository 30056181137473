import {call, put, takeLatest, all} from 'redux-saga/effects';
import {customerConstants} from '../../_constants';
import {createRequest, parseJSON, checkStatus} from '../../_helpers';

function* registerCustomer({data}) {
   yield put({type: customerConstants.REQUEST_REGISTER_CUSTOMER});

   try {
      data['role'] = customerConstants.CUSTOMER_ROLE_ID;
      const url = `${customerConstants.REGISTER_CUSTOMER_URI}`;
      const curriedReq = yield call(createRequest, url, {
         method: 'POST',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, curriedReq);
      yield call(checkStatus, response);

      const jsonResponse = yield call(parseJSON, response);
      yield put({
         type: customerConstants.REGISTER_CUSTOMER_SUCCESS,
         id: jsonResponse.user_id,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);
         // console.log('res', res)

         if (res.errors) {
            let errors = {};
            for (let i = 0; i < res.errors.length; i++) {
               errors[res.errors[i].param] = res.errors[i].msg;
            }

            yield put({
               type: customerConstants.REGISTER_CUSTOMER_ERROR,
               error: errors,
            });
            return;
         }

         yield put({
            type: customerConstants.REGISTER_CUSTOMER_ERROR,
            error: res,
         });

         return;
      }

      yield put({
         type: customerConstants.REGISTER_CUSTOMER_ERROR,
         error: error.message,
      });
   }
}

function* registerCustomerSagaWatcher() {
   yield takeLatest(customerConstants.REGISTER_CUSTOMER, registerCustomer);
}

export default function* rootSaga() {
   yield all([registerCustomerSagaWatcher()]);
}
