import {combineReducers} from 'redux';
import customerRootReducer from './customer.reducer';
import authRootReducer from './auth.reducer';
import bankRootReducer from './bank.reducer';
import forgotPasswordRootReducer from './forgotPassword.reducer';
import merchantRootReducer from './merchant.reducer';
import transactionRootReducer from './transaction.reducer';
import stateRootReducer from './state.reducer';
import countryRootReducer from './country.reducer';
import snackbarRootReducer from './snackbar.reducer';
import settlementRootReducer from './settlement.reducer';

const rootReducer = combineReducers({
   customer: customerRootReducer,
   auth: authRootReducer,
   bank: bankRootReducer,
   forgotPassword: forgotPasswordRootReducer,
   merchant: merchantRootReducer,
   transaction: transactionRootReducer,
   state: stateRootReducer,
   country: countryRootReducer,
   snackbar: snackbarRootReducer,
   settlement: settlementRootReducer,
});

export default rootReducer;
