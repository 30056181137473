import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
   MDBTable,
   MDBTableBody,
   MDBTableHead,
   // MDBInputGroup,
   // MDBInputGroupText,
   // MDBInputGroupElement,
} from 'mdb-react-ui-kit';
// import {Link} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import './index.scss';
// import Lara from '../../../_assets/images/lara.png';
import TablePaginationActions from '../../../_components/TablePaginationAction';
import {numberWithCommas, GetTime, capitalizeFirstLetter} from '../../../_helpers';
// import LetteredAvatar from 'lettered-avatar';

import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../../_helpers/loader';
import {transactionActions} from '../../../_actions';

// import {ReactComponent as Vector} from '../../../_assets/icon/Vector.svg';
// import {ReactComponent as Prev} from '../../../_assets/icon/1arr-left.svg';
// import {ReactComponent as Next} from '../../../_assets/icon/1arr-right.svg';
// import {ReactComponent as SearchIcon} from '../../../_assets/icon/1ssearch.svg';
import {ReactComponent as Filter} from '../../../_assets/icon/1filter.svg';

export default function Withdrawals() {
   const dispatch = useDispatch();

   useEffect(() => {
      const model = {
         type: 'settlement',
      };
      dispatch(transactionActions.getAllWithdrawals(model));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
         type: 'settlement',
      };

      dispatch(transactionActions.getAllWithdrawals(model));
   };
   // const [searchInput, setSearchInput] = useState('');
   const [toDate, setToDate] = useState(false);
   const [startDate, setStartDate] = useState(new Date());
   const [endDate, setEndDate] = useState(new Date());
   const [isOpen, setIsOpen] = useState(false);

   const toggleList = () => {
      setIsOpen((wasOpen) => !wasOpen);
      setToDate(false);
   };

   useEffect(() => {
      if (!toDate) {
         const model = {
            type: 'settlement',
            // merchantId: companyInfo?._id,
         };
         dispatch(transactionActions.getAllWithdrawals(model));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [toDate]);

   useEffect(() => {
      if (toDate) {
         const model = {
            from: startDate,
            to: endDate,
            type: 'settlement',
         };
         dispatch(transactionActions.getAllWithdrawals(model));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [endDate]);

   // const handleFilter = () => {
   //    const model = {
   //       from: startDate,
   //       to: endDate,
   //       type: 'settlement',
   //    };
   //    dispatch(transactionActions.getAllWithdrawals(model));
   // };

   // const searchTransaction = () => {
   //    const model = {
   //       searchName: searchInput,
   //       type: 'transaction',
   //    };
   //    dispatch(transactionActions.searchTransaction(model));
   // };

   const transaction = useSelector((s) => s.transaction);
   const AllWithdrawalsList = useSelector((s) => s.transaction.AllWithdrawalsList);
   const {totalDocs, limit, page, totalPages} = transaction?.pagination;
   const total = totalDocs;
   const pages = page - 1;

   return (
      <div>
         <div className="search-filter d-flex flex-column flex-sm-row w-100 d-flex justify-content-between">
            <div className="mx-4 ps-3 mb-5 mb-sm-0 search filter">
               {
                  isOpen ? (
                     <div className="collaps d-flex flex-md-row mb-n5 mb-sm-0">
                        <div
                           // onClick={toggleDate}
                           className="col-5 col-md-4 collaps-item my-5 mx-4 mx-md-5 py-2 px-2 position-relative"
                           style={{width: '50%'}}>
                           From Date Range:
                           <DatePicker
                              selected={startDate}
                              onSelect={() => setToDate(true)}
                              onChange={(date) => setStartDate(date)}
                           />
                        </div>
                        {toDate && (
                           <div
                              // onClick={toggleDate}
                              className="col-5 col-md-4 collaps-item my-5 mx-1 py-2 px-2 position-relative"
                              style={{width: '50%'}}>
                              To Date Range:
                              <DatePicker
                                 selected={endDate}
                                 // onSelect={handleFilter}
                                 onChange={(date) => setEndDate(date)}
                              />
                           </div>
                        )}
                     </div>
                  ) : null
                  // <MDBInputGroup className="mb-n5 mb-sm-0">
                  //    <MDBInputGroupElement
                  //       type="text"
                  //       placeholder="Search"
                  //       aria-label="Search"
                  //       value={searchInput}
                  //       onChange={(e) => setSearchInput(e.target.value)}
                  //    />

                  //    <MDBInputGroupText
                  //       style={{backgroundColor: '#3377ef'}}
                  //       onClick={searchTransaction}>
                  //       <SearchIcon style={{width: '.85rem', color: '#fff', cursor: 'pointer'}} />
                  //    </MDBInputGroupText>
                  // </MDBInputGroup>
               }
            </div>
            <div onClick={toggleList} className="mt-5 mt-sm-0 mx-3 mx-sm-0 filter">
               {/* <div className="d-flex menu align-items-center">Sum Table</div> */}
               <div className="col-5 col-sm-12 col-md-12 menu menu2 align-items-center">
                  <Filter className="mr-3" style={{width: '1rem'}} />
                  Filter
               </div>
            </div>
         </div>
         <div className="customer-list mt-3">
            <MDBTable className="profile mb-5" responsive borderless hover align="middle">
               <MDBTableHead>
                  <tr>
                     {/* <th style={{paddingLeft: '120px'}} className="name" scope="col">
                        Name
                     </th> */}
                     <th scope="col">Product</th>
                     <th scope="col">Amount</th>
                     <th scope="col">Status</th>
                     <th scope="col">Time</th>
                  </tr>
               </MDBTableHead>
               {transaction.IsRequestingAllWithdrawals ? (
                  <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                     <div />
                  </Loader>
               ) : (
                  <MDBTableBody>
                     {AllWithdrawalsList?.length > 0 ? (
                        AllWithdrawalsList.map((row) => {
                           return (
                              <div className="d-table-row" style={{background: '#fbfbfc'}}>
                                 {/* <th
                                    scope="row"
                                    className="d-flex justify-content-center align-items-center">
                                    <LetteredAvatar
                                       name={row.sender_name}
                                       options={{
                                          size: 50,
                                          twoLetter: true,
                                          //shape: 'square',
                                          bgColor: '',
                                          imgClass: 'image-responsive user-image',
                                          imgWidth: '3rem',
                                          imgHeight: '3rem',
                                       }}
                                    />
                                    <span>{row.sender_name}</span>
                                 </th> */}
                                 <td>
                                    <div className="ms-2">
                                       {capitalizeFirstLetter(row.fund_type)}
                                    </div>
                                 </td>
                                 <td>
                                    <div className="ms-3">₦{numberWithCommas(row.amount)}</div>
                                 </td>
                                 <td>
                                    <div className="ms-2">{capitalizeFirstLetter(row.status)}</div>
                                 </td>
                                 <td>
                                    <div className="ms-2">{GetTime(row.created_at)}</div>
                                 </td>
                              </div>
                           );
                        })
                     ) : (
                        <th scope="row">
                           <td align="center" colSpan={5}>
                              No result to display
                           </td>
                        </th>
                     )}
                  </MDBTableBody>
               )}
            </MDBTable>
            {!transaction.IsRequestingAllWithdrawals && totalPages > 1 && (
               <TablePaginationActions
                  rowsPerPageOptions={[]}
                  colSpan={2}
                  count={total}
                  rowsPerPage={limit}
                  page={pages}
                  totalPages={totalPages}
                  onChangePage={handleChangePage}
               />
            )}
         </div>
      </div>
   );
}
