import {all} from 'redux-saga/effects';
import customerRootSaga from './customer.saga';
import authRootSaga from './auth.saga';
import bankRootSaga from './bank.saga';
import stateRootSaga from './state.saga';
import forgotPasswordRootSaga from './forgotPassword.saga';
import merchantRootSaga from './merchant.saga';
import transactionRootSaga from './transaction.saga';
import countryRootSaga from './country.saga';
import settlementRootSaga from './settlement.saga';

export default function* rootSaga() {
   yield all([
      customerRootSaga(),
      authRootSaga(),
      bankRootSaga(),
      forgotPasswordRootSaga(),
      merchantRootSaga(),
      transactionRootSaga(),
      stateRootSaga(),
      countryRootSaga(),
      settlementRootSaga(),
   ]);
}
