import {appConstants} from './app.constant';
const country = 'country/get_all';

export const countryConstants = {
   GET_COUNTRIES: 'GET_COUNTRIES',
   // GET_ACCOUNT_NAME: 'GET_ACCOUNT_NAME',
   COUNTRY: 'country',

   REQUEST_COUNTRIES: 'REQUEST_COUNTRIES',
   REQUEST_COUNTRIES_ERROR: 'REQUEST_COUNTRIES_ERROR',
   REQUEST_COUNTRIES_SUCCESS: 'REQUEST_COUNTRIES_SUCCESS',

   // NIGERIA_COUNTRY_ID: '6148de716a14d33e7a116850', // prod server
   NIGERIA_COUNTRY_ID: '632066f66265dd26277ea725', // test server

   COUNTRY_URL: `${appConstants.BASE_URI}${country}`,
};
