import React, {useState, useEffect} from 'react';
import {Avatar, CircularProgress, FormControl} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import TextInput from '../../../../_components/TextInput';
import Select from 'react-dropdown-select';
import Formsy from 'formsy-react';
import {MDBCol, MDBContainer, MDBRow} from 'mdb-react-ui-kit';
import {stateActions, merchantActions} from '../../../../_actions';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';
import {countryConstants} from '../../../../_constants';
import {STATE_CODES} from '../../../../_helpers/state';

const BasicForm = (props) => {
   const dispatch = useDispatch();
   let newCitiesArr;
   const [picture, setPicture] = useState(null);
   const [imgData, setImgData] = useState(null);
   const [canSubmit, setCanSubmit] = useState(false);
   const [passwordShown, setPasswordShown] = useState(false);
   const [logoError, setLogoError] = useState(false);
   const [showCity, setShowCity] = useState(false);
   const statesList = useSelector((s) => s.state.statesList);
   const citiesList = useSelector((s) => s.state.citiesList);
   const info = useSelector((s) => s.merchant.info);
   const companyInfo = useSelector((s) => s.merchant.companyInfo);
   // const addedCities = [{name: 'Select a city', code: '0'}, ...citiesList];
   const newArr = statesList.map((v) => ({label: v.name, value: v._id}));
   newCitiesArr = citiesList.map((v) => ({label: v.name, value: v._id}));
   // const selectedState = STATE_CODES.findIndex((x) => x.value === companyInfo?.state);
   // const selectedCity = addedCities.findIndex((x) => x.value === companyInfo?.city);

   const IsUpdatingMerchantInfo = useSelector((s) => s.merchant.IsUpdatingMerchantInfo);
   const merchantData = useSelector((s) => s.merchant.data);

   const [state, setState] = useState(companyInfo?.state || '');
   // const [city, setCity] = useState(
   //    newCitiesArr.find((el) => el.value === companyInfo?.city)?.value ?? '0',
   // );
   const [city, setCity] = useState(companyInfo?.city || '');

   // const [open, setOpen] = useState(false); newArr.find((el) => el.value === info?.state) ??
   const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
   };

   const onChangePicture = (e) => {
      setLogoError('');
      if (e.target.files[0]) {
         // console.log('picture: ', e.target.files);
         setPicture(e.target.files[0]);
         const reader = new FileReader();
         reader.addEventListener('load', () => {
            setImgData(reader.result);
         });
         reader.readAsDataURL(e.target.files[0]);
      }
   };

   useEffect(() => {
      dispatch(stateActions.getStates());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (citiesList.length > 0) {
         // eslint-disable-next-line react-hooks/exhaustive-deps
         newCitiesArr = citiesList.map((v) => ({label: v.name, value: v._id}));
         // console.log('value@@@@@@@@?.value', newCitiesArr?.[0]?.value);
         if (showCity === true) {
            setCity(newCitiesArr?.[0]?.value);
            // setShowCity(false);
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [citiesList]);

   useEffect(() => {
      dispatch(stateActions.getCities(companyInfo?.state));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [companyInfo?.state]);
   // console.log('valuenewCitiesArrnewCitiesArrnewCitiesArr', newCitiesArr);

   const handleSetState = (value) => {
      // console.log('value@@@@@@@@', value);
      setState(value);
      setShowCity(true);
      dispatch(stateActions.getCities(value?.value));
   };

   const handleSetCity = (value) => {
      // console.log('city@@@@@@@@', value);
      if (showCity === true) {
         setCity(value?.value);
         // setShowCity(false);
      }
      // setShowCity(false);
   };
   console.log('companyInfo', companyInfo);

   const transformToFormData = (data) => {
      const fData = new FormData();
      fData.append('password', data.password);
      fData.append('permission', 1);
      fData.append('city', city || companyInfo?.city);
      fData.append('state', state || companyInfo?.state);
      fData.append('country', countryConstants.NIGERIA_COUNTRY_ID);
      fData.append('merchant_address', data.merchant_address);
      fData.append('merchant_email', merchantData?.email);
      fData.append('merchant_name', data.merchant_name);

      return fData;
   };

   const handleSubmit = (data) => {
      const fData = transformToFormData(data);

      if (picture) {
         if (picture.type.match('image.*')) {
            if (picture.size > 1048576) {
               setLogoError('Logo size should not exceed 1MB');
            } else {
               fData.append('logo_url', picture);
               dispatch(merchantActions.updateMerchantInfo(fData));
            }
         } else {
            setLogoError('File type only accept images');
         }
      }
      if (!picture) {
         dispatch(merchantActions.updateMerchantInfo(fData));
      }
   };
   // console.log('newCitiesArr!!!', newCitiesArr);
   // console.log('statesList!!!', statesList);
   // console.log('companyInfo', companyInfo);

   return (
      <div className="basic-info mt-3">
         <Formsy
            onValidSubmit={handleSubmit}
            onValid={() => setCanSubmit(true)}
            onInvalid={() => setCanSubmit(false)}>
            <MDBContainer>
               <MDBRow>
                  <MDBCol className="smallbox2 col-12 col-md-6">
                     <span id="form" className="forms d-flex">
                        <div className="register_profile_image">
                           <Avatar
                              className="previewProfilePic"
                              src={imgData || info?.logo_url}
                              alt=""
                           />
                           {/* <label for="files" type="file"  onChange={onChangePicture} className="btn">Select Image</label> */}
                           <input
                              // id="profilePic"
                              // style={{background:'red'}}
                              type="file"
                              id="files"
                              name="logo_url"
                              className="hidden"
                              style={{display: 'none'}}
                              visibility="none"
                              placeholder="choose"
                              onChange={onChangePicture}
                           />
                           {/* <input type="file" id="files" className="hidden" /> */}
                           <label
                              style={{
                                 fontSize: '14px',
                                 color: '#000',
                                 cursor: 'pointer',
                                 fontWeight: '600',
                                 // marginLeft: '70px',
                                 // marginBottom: '-270px',
                              }}
                              for="files">
                              Tap to upload profile picture
                           </label>
                           <p className="error-text">{logoError}</p>
                        </div>
                     </span>
                     <div className="forms col-md-10">
                        <div id="textExample1" className="form-text" style={{color: 'black'}}>
                           Company Name
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="merchant_name"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="company's name"
                              useFocusedError
                              className="input border-0 w-100"
                              // validations="isWords"
                              value={companyInfo?.merchant_name}
                              // required
                              // validationErrors={{
                              //    isWords: 'Invalid value, must contain only letters!',
                              //    required: 'Required!',
                              // }}
                           />
                        </FormControl>
                     </div>
                     <div className="forms col-md-10">
                        <div id="textExample1" className="form-text" style={{color: 'black'}}>
                           Company Address
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="merchant_address"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="company's address"
                              useFocusedError
                              className="input border-0 w-100"
                              // validations="isWords"
                              value={companyInfo?.merchant_address}
                              // validationErrors={{
                              //    isWords: 'Invalid value, must contain only letters!',
                              //    required: 'Required!',
                              // }}
                           />
                        </FormControl>
                     </div>
                     <div className="forms col-md-10">
                        <div id="textExample1" className="form-text" style={{color: 'black'}}>
                           Your Current Password
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="password"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="current password"
                              usefocusedError
                              type={passwordShown ? 'text' : 'password'}
                              className="input border-0 w-100"
                              value={info?.password}
                              // validations="isWords"
                              required
                              // validationErrors={{
                              //    isWords: 'Invalid value, must contain only letters!',
                              //    required: 'Required!',
                              // }}
                           />
                           <i onClick={togglePasswordVisiblity}>
                              {passwordShown ? <Visibility /> : <VisibilityOff />}
                           </i>
                        </FormControl>
                     </div>
                  </MDBCol>
                  <MDBCol className="smallbox2 col-12 col-md-6">
                     {newArr && (
                        <div className="forms col-md-10 mb-4">
                           <div
                              id="textExample1"
                              className="form-text mb-1"
                              style={{color: 'black'}}>
                              State
                           </div>
                           <Select
                              options={STATE_CODES}
                              backspaceDelete
                              // defaultValue={companyInfo?.state}
                              values={[newArr.find((opt) => opt.value === String(state))]}
                              // defaultValue={STATE_CODES[selectedState]}
                              // onChange={(values) => handleSetState(values.value)}
                              onChange={(values) => handleSetState(values[0].value)}
                           />
                        </div>
                     )}
                     {newCitiesArr?.length > 0 && (
                        <div className="forms col-md-10 mb-4">
                           <div
                              id="textExample1"
                              className="form-text mb-1"
                              style={{color: 'black'}}>
                              City
                           </div>
                           <Select
                              options={newCitiesArr}
                              values={
                                 showCity === true
                                    ? []
                                    : [
                                         newCitiesArr?.find(
                                            (opt) => String(opt.value) === String(city),
                                         ),
                                      ]
                              }
                              // values={[newCitiesArr.find((opt) => opt.value === companyInfo?.city)]}
                              // defaultValue={newCitiesArr[selectedCity]}
                              // onChange={(values) => setCity(values.value)}
                              onChange={(values) => handleSetCity(values[0])}
                           />
                        </div>
                     )}
                     <button
                        // to="/verify"
                        disabled={
                           !canSubmit || IsUpdatingMerchantInfo || city === '0' || state === '0'
                        }
                        type="submit"
                        className={classNames('signupbtn', {
                           disabled: !canSubmit,
                        })}>
                        {IsUpdatingMerchantInfo ? (
                           <CircularProgress
                              variant="indeterminate"
                              disableShrink="true"
                              color="inherit"
                              size="1.2rem"
                              thickness={2}
                              style={{
                                 radius: '.1px',
                              }}
                           />
                        ) : (
                           'Update'
                        )}
                     </button>
                  </MDBCol>
               </MDBRow>
            </MDBContainer>
         </Formsy>
      </div>
   );
};

export default BasicForm;
