import React, {useState, useEffect} from 'react';
import {MDBContainer, MDBCol} from 'mdb-react-ui-kit';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextInput from '../../../_components/TextInput';
// import classNames from 'classnames';
import BlueYellow from '../../../_assets/images/blueandyellow.svg';
// import Customerone from '../../_assets/images/customerone.png';
import Formsy from 'formsy-react';
import {Link} from 'react-router-dom';
import './index.scss';
import {merchantActions} from '../../../_actions';

function LoginPage({location, history}) {
   const dispatch = useDispatch();
   // The first application below
   const {
      IsRequestingLogin,
      IsRequestingLogout,
      // IsVerifyingPhone,
      errorMessage,
      unverifiedEmail,
      unverifiedPhone,
      unsetTransactionPin,
   } = useSelector((s) => s.auth);

   const {userMerchants, IsRequestingMerchant} = useSelector((s) => s.merchant);

   const [canSubmit, setCanSubmit] = useState(false);
   const [passwordShown, setPasswordShown] = useState(false);
   const [codeError, setCodeError] = useState('');
   const [emailPhoneError, setEmailphoneError] = useState('');
   const [passwordError, setPasswordError] = useState('');
   const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
   };

   const handleSubmit = (data) => {
      setCodeError('');
      setEmailphoneError('');
      setPasswordError('');
      // data['role'] = merchantConstants.MERCHANT_ROLE_ID;
      dispatch(merchantActions.login(data));
   };

   useEffect(() => {
      if (typeof errorMessage === 'string') {
         setCodeError(errorMessage);
      }
      if (errorMessage?.password) {
         setPasswordError(
            'password must be 8 characters long and must contain minimum of one lowercase, uppercase, digit',
         );
      }
      if (errorMessage?.email_phone) {
         setEmailphoneError('Invalid email or phone number, N.B: number must begin with 234');
      }

      // dispatch(appActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [errorMessage]);

   // if (unverifiedEmail) {
   //    return <Redirect to="/verify" />;
   // }

   // if (unverifiedPhone) {
   //    return <Redirect to="/phone" />;
   // }

   // if (unsetTransactionPin) {
   //    return <Redirect to="/pin" />;
   // }

   // if (IsAuthenticated) {
   //    return <Redirect to="/home" />;
   // }

   if (unverifiedEmail) {
      history.push('/verify');
   }

   if (errorMessage && unverifiedPhone) {
      history.push('/phone');
   }

   if (unsetTransactionPin) {
      history.push('/pin');
   }

   // if (IsAuthenticated) {
   //    history.push('/home');
   // }

   if (IsRequestingMerchant) {
      return null;
   }

   if (IsRequestingLogout) {
      return null;
   }

   if (userMerchants?.length > 0) {
      // if (location.state !== undefined) {
      //    console.log('location.state', location.state);
      //    data?.is_child === true
      //       ? history.push('/history')
      //       : history.push(location.state.from.pathname);
      // } else {
      //    data?.is_child === true ?
      history.push('/merchant/companies');
      //     : history.push('/new-company')
      // }
   } else if (userMerchants?.length === 0) {
      history.push('/merchant/home');
   }

   return (
      <>
         <section className="login">
            <img src={BlueYellow} alt="" className="img-fluid image" />
            <MDBContainer className="h-100 py-5 align-items-center">
               <div className="row login-space">
                  <div className="col-md-6 text">
                     <h3>
                        Welcome
                        <br />
                        Lorem Ipsum Ipsum
                     </h3>
                     <p>
                        New user? &nbsp;
                        <a style={{fontWeight: 'bolder'}} href="/signup">
                           Sign up
                        </a>
                     </p>
                  </div>
                  <div className="col-md-6 login-form-space">
                     <div className="login-form-space2">
                        <Formsy
                           onValidSubmit={handleSubmit}
                           onValid={() => setCanSubmit(true)}
                           onInvalid={() => setCanSubmit(false)}>
                           <div className="forms col-md-12">
                              {codeError && <h5 className="error">{codeError}</h5>}
                              <div id="textExample1" className="form-text" style={{color: 'black'}}>
                                 Email or Phone Number
                              </div>
                              <FormControl margin="normal" required fullWidth>
                                 <TextInput
                                    name="email_phone"
                                    autoComplete="on"
                                    wrapperClassName="input-wrapper"
                                    mainWrapperClassName="w-100 mb-3 mb-s-4"
                                    placeholder="Email or Phone"
                                    useFocusedError
                                    className="input border-0 w-100"
                                    valError={emailPhoneError}
                                    required
                                 />
                              </FormControl>
                           </div>
                           <div className="forms col-md-12">
                              <div id="textExample1" className="form-text" style={{color: 'black'}}>
                                 Password
                              </div>
                              <FormControl margin="normal" required fullWidth>
                                 <TextInput
                                    name="password"
                                    wrapperClassName="input-wrapper"
                                    mainWrapperClassName="w-100 mb-3 mb-s-4"
                                    placeholder="********"
                                    useFocusedError
                                    type={passwordShown ? 'text' : 'password'}
                                    className="input border-0 w-100"
                                    validationError="Please provide your password!"
                                    required
                                    valError={passwordError}
                                 />
                                 <i onClick={togglePasswordVisiblity}>
                                    {passwordShown ? <Visibility /> : <VisibilityOff />}
                                 </i>
                              </FormControl>
                              <div className="d-flex flex-column">
                                 <span
                                    style={{
                                       fontSize: '13px',
                                       fontWeight: 'bolder',
                                       // marginLeft: '40px',
                                    }}
                                    className="forgot">
                                    <Link to="/resetpassword">Forgot Password?</Link>
                                 </span>

                                 <button
                                    disabled={!canSubmit}
                                    type="submit"
                                    className={classNames('signbtn', {
                                       disabled: !canSubmit,
                                    })}>
                                    {IsRequestingLogin ? (
                                       <CircularProgress color="inherit" thickness={1} />
                                    ) : (
                                       'Log In'
                                    )}
                                 </button>
                              </div>
                           </div>
                           {/* </Link> */}
                        </Formsy>
                     </div>
                  </div>
               </div>
            </MDBContainer>
         </section>
      </>
   );
}

export default LoginPage;
