import {createMuiTheme} from '@material-ui/core/styles';

const ThemeDefault = createMuiTheme({
   // overrides: {
   //    // Style sheet name ⚛️
   //    MuiOutlinedInput: {
   //       // Name of the rule
   //       root: {
   //          // Some CSS
   //          color: 'white',
   //          borderColor: '#FFFFFF',
   //          borderWidth: 1,
   //          backgroundColor: 'transparent',
   //       },
   //    },
   // },
   palette: {
      primary: {
         main: '#FFFFFF',
      },
      secondary: {
         main: '#FFFFFF',
      },
      tableHeader: {
         //fontWeight: 'bold'
      },
      background: {
         //default: '#f9fcf9'
      },
   },
   appBar: {
      height: 57,
      color: 'white',
   },
   drawer: {
      width: 230,
      //color: grey900
   },
   raisedButton: {
      //primaryColor: blue600,
   },
   typography: {
      lineHeight: '26px',
   },
});

export default ThemeDefault;
