import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Modal from 'react-modal';
import FormControl from '@material-ui/core/FormControl';
import Formsy from 'formsy-react';
import TextInput from '../TextInput';
import Select from 'react-dropdown-select';
import classNames from 'classnames';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import {merchantActions, stateActions} from '../../_actions';
import {ReactComponent as Warning} from '../../_assets/images/warning.svg';
// import {ReactComponent as Vector} from '../../_assets/icon/Vector.svg';
import {countryConstants} from '../../_constants';

function ModalController({history}) {
   const dispatch = useDispatch();

   const {
      IsCreatingMerchantCompany,
      merchantCompanyCreated,
      info,
      data,
      errorMessage,
      // merchantInfoUpdated,
   } = useSelector((s) => s.merchant);
   const statesList = useSelector((s) => s.state.statesList);
   const citiesList = useSelector((s) => s.state.citiesList);
   // const merchantData = useSelector((s) => s.merchant.data);
   // const companyInfo = useSelector((s) => s.merchant.companyInfo);
   const companyData = useSelector((s) => s.merchant.companyData);
   // const addedStates = [{label: 'Select a state', value: '0'}, ...statesList];
   const addedCities = [{label: 'Select a City', value: '0'}, ...citiesList];
   const newArr = statesList.map((v) => ({...v, label: v.name, value: v._id}));
   const newCitiesArr = citiesList.map((v) => ({...v, label: v.name, value: v._id}));
   // const addedCountries = [{name: 'Select a country', code: '0'}, ...countriesList];
   const [canSubmit, setCanSubmit] = useState(false);
   // console.log('statesList', statesList);
   // console.log('newarr', newArr);

   const [modalIsOpen, setModalIsOpen] = useState(false);
   const [secModalIsOpen, setSecModalIsOpen] = useState(false);
   // const [loader, setLoader] = useState(false);
   // const [passwordShown, setPasswordShown] = useState(false);
   // const [selectOpen, setSelectOpen] = useState(false);
   const [state, setState] = useState(newArr.find((el) => el.name === info?.state)?.code ?? '0');
   // const [state, setState] = useState([]);
   const [city, setCity] = useState(addedCities.find((el) => el.name === info?.city)?.code ?? '0');
   // const [open, setOpen] = useState(false);
   // const togglePasswordVisiblity = () => {
   //    setPasswordShown(passwordShown ? false : true);
   // };

   // console.log('ModalControllercompanyInfo', companyInfo);
   // const setValue = (data) => {
   //    setState(data);
   //    console.log('data', data);
   // };

   const customStyles = {
      content: {
         // top: '40%',
         position: 'relative',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: '600px',
         maxWidth: '90%',
         height: '400px',
         maxHeight: '90%',
         backgroundColor: '#ffffff',
         borderTopLeftRadius: '5px',
         borderBottomLeftRadius: '5px',
         borderTopRightRadius: '5px',
         borderBottomRightRadius: '5px',
         padding: '40px 40px',
         // overflowX: 'none',
         overflowY: 'scroll',
         zIndex: '-100',
      },
   };

   useEffect(() => {
      if (data?.hasCompany === false) {
         setModalIsOpen(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data]);

   // useEffect(() => {
   //    if (companyInfo && !companyData?.is_child && !companyInfo?.registrationComplete) {
   //       setModalIsOpen(true);
   //    }
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [companyInfo?.registrationComplete, companyInfo]);

   const transformToFormData = (model) => {
      const fData = new FormData();
      // fData.append('password', model.password);
      fData.append('permission', 1);
      fData.append('city', city);
      fData.append('state', state);
      fData.append('country', countryConstants.NIGERIA_COUNTRY_ID);
      fData.append('merchant_address', model.merchant_address);
      // fData.append('merchant_email', merchantData?.email);
      fData.append('merchant_name', model.merchant_name);
      // fData.append('merchant_address', model.merchant_address);
      fData.append('merchant_email', model.merchant_email);
      fData.append('merchant_phone', model.merchant_phone.replace(/^0/, '234').trim());
      // fData.append('merchant_name', model.merchant_name);
      // fData.append('id', merchantData?._id);

      return fData;
   };
   useEffect(() => {
      if (companyData) {
         companyData?.is_child === true
            ? history.push('/merchant/history')
            : history.push('/merchant/home');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [companyData]);

   // if (companyData) {
   //    console.log('companyData', companyData)
   //    console.log('history', history)
   //    // if (location.state !== undefined) {
   //    //    console.log('location.state', location.state);
   //    //    companyData?.is_child === true
   //    //       ? history.push('/history')
   //    //       : history.push(location.state.from.pathname);
   //    // } else {
   //    // }
   //    companyData?.is_child === true ? history.push('/history') : history.push('/home');

   // }

   const handleSubmit = (model) => {
      const fData = transformToFormData(model);

      dispatch(merchantActions.createMerchantCompany(fData));
   };

   useEffect(() => {
      if (merchantCompanyCreated) {
         setSecModalIsOpen(false);
      }
   }, [merchantCompanyCreated]);
   const setSecModalIsOpenToTrue = () => {
      setSecModalIsOpen(true);
      setModalIsOpen(false);
   };
   const handleSetState = (value) => {
      setState(value);
      dispatch(stateActions.getCities(value));
   };

   // console.log('loader', loader);

   // const merchantPhoneError = errorMessage?.filter((error) => error.param === 'merchant_phone');

   return (
      <section className="modal">
         {modalIsOpen && (
            <Modal isOpen={true} style={customStyles}>
               {/*  */}
               <div className="d-flex flex-column justify-content-center align-items-center">
                  <Warning style={{width: '15%'}} />
                  
                  <h3>Additional Information Required</h3>
                  <p>kindly fill the necessary information </p>
                  <Formsy className="__form">
                     <button>
                        <div onClick={setSecModalIsOpenToTrue} className="button">
                           Proceed
                        </div>
                     </button>
                  </Formsy>
               </div>
            </Modal>
         )}
         {secModalIsOpen && (
            <Modal
               isOpen={true}
               // className="modal2"
               style={customStyles}
               // style={{overlay: {zIndex: 1000}}
               onRequestClose={() => setSecModalIsOpen(false)}>
               <div>
                  <Formsy
                     onValidSubmit={handleSubmit}
                     onValid={() => setCanSubmit(true)}
                     onInvalid={() => setCanSubmit(false)}>
                     <div className="new-form col-md-10">
                        {/* {errorMessage && <h5 style={{color: 'red'}}>{errorMessage}</h5>} */}

                        <div id="textExample1" className="form-text" style={{color: 'black'}}>
                           Company's Name
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="merchant_name"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="company's name"
                              usefocusedError
                              className="input border-0 w-100"
                              value={info?.merchant_name}
                              required
                           />
                        </FormControl>
                        <h5 style={{color: 'red', fontSize: '11px'}}>
                           {errorMessage?.merchant_name}
                        </h5>
                     </div>

                     <div className="new-form col-md-10">
                        <div id="textExample1" className="form-text" style={{color: 'black'}}>
                           Company's Address
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="merchant_address"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="company's address"
                              usefocusedError
                              className="input border-0 w-100"
                              value={info?.merchant_address}
                              required
                           />
                        </FormControl>
                     </div>
                     <div className="new-form col-md-10">
                        <div id="textExample1" className="form-text" style={{color: 'black'}}>
                           Company's Email
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="merchant_email"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="company's email"
                              usefocusedError
                              className="input border-0 w-100"
                              validations="isEmail"
                              validationErrors={{
                                 isEmail: 'This is not a valid email',
                                 required: 'Required!',
                              }}
                              required
                           />
                        </FormControl>
                     </div>
                     <div className="new-form col-md-10">
                        <div id="textExample1" className="form-text" style={{color: 'black'}}>
                           Company's Phone
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="merchant_phone"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="company's phone"
                              usefocusedError
                              className="input border-0 w-100"
                              required
                              validations={{isNumeric: true, minLength: 9, maxLength: 11}}
                              validationErrors={{
                                 isNumeric: 'Invalid phone number',
                                 minLength: 'Phone number incomplete',
                                 maxLength: 'Just 11 characters',
                              }}
                           />
                        </FormControl>
                        <h5 style={{color: 'red', fontSize: '11px'}}>
                           {errorMessage?.merchant_phone}
                        </h5>
                     </div>
                     <div className="new-form col-md-10 mb-4">
                        <div id="textExample1" className="form-text mb-1" style={{color: 'black'}}>
                           State
                        </div>
                        <Select
                           // values={state}
                           options={newArr}
                           backspaceDelete
                           onChange={(values) => handleSetState(values[0]._id)}
                        />
                        <h5 style={{color: 'red', fontSize: '11px'}}>{errorMessage?.state}</h5>
                     </div>
                     <div className="new-form col-md-10 mb-4">
                        <div id="textExample1" className="form-text mb-1" style={{color: 'black'}}>
                           City
                        </div>
                        <Select
                           // values={city}
                           backspaceDelete
                           options={newCitiesArr}
                           onChange={(values) => setCity(values[0]._id)}
                        />
                        <h5 style={{color: 'red', fontSize: '11px'}}>{errorMessage?.city}</h5>
                     </div>
                     {/* <div className="new-form col-md-10">
                        <div id="textExample1" className="form-text" style={{color: 'black'}}>
                           Your Current Password
                        </div>
                        <FormControl margin="normal" required fullWidth>
                           <TextInput
                              name="password"
                              wrapperClassName="input-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="current password"
                              usefocusedError
                              value={info?.password}
                              type={passwordShown ? 'text' : 'password'}
                              className="input border-0 w-100"
                           />
                           <i onClick={togglePasswordVisiblity}>
                              {passwordShown ? <Visibility /> : <VisibilityOff />}
                           </i>
                        </FormControl>
                     </div> */}
                     <button
                        disabled={!canSubmit}
                        type="submit"
                        className={classNames('col-9 full-width w-100 p-3 home-signup', {
                           homedisabled: !canSubmit,
                        })}>
                        {IsCreatingMerchantCompany ? (
                           <CircularProgress color="inherit" thickness={1} />
                        ) : (
                           'Submit'
                        )}
                     </button>
                  </Formsy>
               </div>
            </Modal>
         )}
      </section>
   );
}

export default ModalController;
