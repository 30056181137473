import React, {useEffect} from 'react';
import './index.scss';

export default function Snackbar({message, onClose, variant}) {
   useEffect(() => {
      const timer = setTimeout(() => {
         onClose();
      }, 5000);
      return () => clearTimeout(timer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [message]);

   return (
      <div className={'notice d-flex align-items-center justify-content-center w-100 ' + variant}>
         <p style={{textAlign: 'center'}}>
            {message}
            {/* Your Details has been Updated Successfully */}
         </p>
      </div>
   );
}
