import {appConstants} from './app.constant';

const signup = 'signup';
const signin = 'merchant/signin';
const subLogin = 'sub-user/login';
const user = 'user';
const merchant = 'merchant';
const merchantUser = 'merchant_user';
const subUser = 'sub-user';
const acceptInvitation = 'merchant/accept_invitation';

export const merchantConstants = {
   REQUEST_REGISTER_MERCHANT: 'REQUEST_REGISTER_MERCHANT',
   REGISTER_MERCHANT_SUCCESS: 'REGISTER_MERCHANT_SUCCESS',
   REGISTER_MERCHANT_ERROR: 'REGISTER_MERCHANT_ERROR',

   REQUEST_REGISTER_MERCHANT_SUB_USER: 'REQUEST_REGISTER_MERCHANT_SUB_USER',
   REGISTER_MERCHANT_SUB_USER_SUCCESS: 'REGISTER_MERCHANT_SUB_USER_SUCCESS',
   REGISTER_MERCHANT_SUB_USER_ERROR: 'REGISTER_MERCHANT_SUB_USER_ERROR',

   REQUEST_ADD_SUB_USER_TO_MERCHANT: 'REQUEST_ADD_SUB_USER_TO_MERCHANT',
   ADD_SUB_USER_TO_MERCHANT_SUCCESS: 'ADD_SUB_USER_TO_MERCHANT_SUCCESS',
   ADD_SUB_USER_TO_MERCHANT_ERROR: 'ADD_SUB_USER_TO_MERCHANT_ERROR',

   LOGIN_REQUEST: 'MERCHANT_LOGIN_REQUEST',
   LOGIN_SUCCESS: 'MERCHANT_LOGIN_SUCCESS',
   LOGIN_FAILURE: 'MERCHANT_LOGIN_FAILURE',

   MERCHANT_SUB_USER_LOGIN_REQUEST: 'MERCHANT_SUB_USER_LOGIN_REQUEST',
   MERCHANT_SUB_USER_LOGIN_SUCCESS: 'MERCHANT_SUB_USER_LOGIN_SUCCESS',
   MERCHANT_SUB_USER_LOGIN_FAILURE: 'MERCHANT_SUB_USER_LOGIN_FAILURE',

   LOGOUT_REQUEST: 'MERCHANT_LOGOUT_REQUEST',
   LOGOUT_SUCCESS: 'MERCHANT_LOGOUT_SUCCESS',
   LOGOUT_FAILURE: 'MERCHANT_LOGOUT_FAILURE',

   GET_REQUEST: 'MERCHANT_GET_REQUEST',
   GET_SUCCESS: 'MERCHANT_GET_SUCCESS',
   GET_FAILURE: 'MERCHANT_GET_FAILURE',

   GET_MERCHANT_COMPANY_REQUEST: 'MERCHANT_COMPANY_GET_REQUEST',
   GET_MERCHANT_COMPANY_SUCCESS: 'MERCHANT_COMPANY_GET_SUCCESS',
   GET_MERCHANT_COMPANY_FAILURE: 'MERCHANT_COMPANY_GET_FAILURE',

   GET_MERCHANT_SUB_USER_REQUEST: 'MERCHANT_SUB_USER_GET_REQUEST',
   GET_MERCHANT_SUB_USER_SUCCESS: 'MERCHANT_SUB_USER_GET_SUCCESS',
   GET_MERCHANT_SUB_USER_FAILURE: 'MERCHANT_SUB_USER_GET_FAILURE',

   SEND_VERIFICATION_EMAIL: 'SEND_VERIFICATION_EMAIL',
   SENDING_VERIFICATION_EMAIL: 'SENDING_VERIFICATION_EMAIL',
   SEND_VERIFICATION_EMAIL_SUCCESS: 'SEND_VERIFICATION_EMAIL_SUCCESS',
   SEND_VERIFICATION_EMAIL_FAILURE: 'SEND_VERIFICATION_EMAIL_FAILURE',

   CREATE_PIN: 'CREATE_PIN',
   CREATING_PIN: 'CREATING_PIN',
   CREATE_PIN_SUCCESS: 'CREATE_PIN_SUCCESS',
   CREATE_PIN_FAILURE: 'CREATE_PIN_FAILURE',

   CREATE_DETAILS: 'CREATE_DETAILS',
   CREATING_DETAILS: 'CREATING_DETAILS',
   CREATE_DETAILS_SUCCESS: 'CREATE_DETAILS_SUCCESS',
   CREATE_DETAILS_FAILURE: 'CREATE_DETAILS_FAILURE',

   ADD_NEXT_OF_KIN: 'ADD_NEXT_OF_KIN',
   ADDING_NEXT_OF_KIN: 'ADDING_NEXT_OF_KIN',
   ADD_NEXT_OF_KIN_SUCCESS: 'ADD_NEXT_OF_KIN_SUCCESS',
   ADD_NEXT_OF_KIN_FAILURE: 'ADD_NEXT_OF_KIN_FAILURE',

   CHANGE_PASSWORD: 'CHANGE_PASSWORD',
   CHANGING_PASSWORD: 'CHANGING_PASSWORD',
   CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
   CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

   CHANGE_PIN: 'CHANGE_PIN',
   CHANGING_PIN: 'CHANGING_PIN',
   CHANGE_PIN_SUCCESS: 'CHANGE_PIN_SUCCESS',
   CHANGE_PIN_FAILURE: 'CHANGE_PIN_FAILURE',

   RESET_PIN: 'RESET_PIN',
   RESETTING_PIN: 'RESETTING_PIN',
   RESET_PIN_SUCCESS: 'RESET_PIN_SUCCESS',
   RESET_PIN_FAILURE: 'RESET_PIN_FAILURE',

   SEND_OTP: 'SEND_OTP',
   WRONG_NUMBER: 'WRONG_NUMBER',
   SENDING_OTP: 'SENDING_OTP',
   SEND_OTP_SUCCESS: 'SEND_OTP_SUCCESS',
   SEND_OTP_FAILURE: 'SEND_OTP_FAILURE',

   RESEND_OTP: 'RESEND_OTP',
   RESENDING_OTP: 'RESENDING_OTP',
   RESEND_OTP_SUCCESS: 'RESEND_OTP_SUCCESS',
   RESEND_OTP_FAILURE: 'RESEND_OTP_FAILURE',

   VERIFY_EMAIL: 'VERIFY_EMAIL',
   VERIFYING_EMAIL: 'VERIFYING_EMAIL',
   VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
   VERIFY_EMAIL_FAILURE: 'VERIFY_EMAIL_FAILURE',

   RESEND_EMAIL_LINK: 'RESEND_EMAIL_LINK',
   RESENDING_EMAIL_LINK: 'RESENDING_EMAIL_LINK',
   RESEND_EMAIL_LINK_SUCCESS: 'RESEND_EMAIL_LINK_SUCCESS',
   RESEND_EMAIL_LINK_FAILURE: 'RESEND_EMAIL_LINK_FAILURE',

   VERIFY_PHONE: 'VERIFY_PHONE',
   VERIFYING_PHONE: 'VERIFYING_PHONE',
   VERIFY_PHONE_SUCCESS: 'VERIFY_PHONE_SUCCESS',
   VERIFY_PHONE_FAILURE: 'VERIFY_PHONE_FAILURE',

   UPDATE_MERCHANT_INFO: 'UPDATE_MERCHANT_INFO',
   UPDATING_MERCHANT_INFO: 'UPDATING_MERCHANT_INFO',
   UPDATE_MERCHANT_INFO_SUCCESS: 'UPDATE_MERCHANT_INFO_SUCCESS',
   UPDATE_MERCHANT_INFO_FAILURE: 'UPDATE_MERCHANT_INFO_FAILURE',

   GET_MERCHANT_COMPANY_INFORMATION: 'GET_MERCHANT_COMPANY_INFORMATION',
   REQUEST_MERCHANT_COMPANY_INFORMATION: 'REQUEST_MERCHANT_COMPANY_INFORMATION',
   REQUEST_MERCHANT_COMPANY_INFORMATION_SUCCESS: 'REQUEST_MERCHANT_COMPANY_INFORMATION_SUCCESS',
   REQUEST_MERCHANT_COMPANY_INFORMATION_FAILURE: 'REQUEST_MERCHANT_COMPANY_INFORMATION_FAILURE',

   CREATE_MERCHANT_COMPANY: 'CREATE_MERCHANT_COMPANY',
   IS_CREATING_MERCHANT_COMPANY: 'IS_CREATING_MERCHANT_COMPANY',
   CREATE_MERCHANT_COMPANY_SUCCESS: 'CREATE_MERCHANT_COMPANY_SUCCESS',
   CREATE_MERCHANT_COMPANY_FAILURE: 'CREATE_MERCHANT_COMPANY_FAILURE',

   GET_MERCHANT_USERS: 'GET_MERCHANT_USERS',
   REQUEST_MERCHANT_USERS: 'REQUEST_MERCHANT_USERS',
   REQUEST_MERCHANT_USERS_SUCCESS: 'REQUEST_MERCHANT_USERS_SUCCESS',
   REQUEST_MERCHANT_USERS_FAILURE: 'REQUEST_MERCHANT_USERS_FAILURE',

   CREATE_MERCHANT_USER: 'CREATE_MERCHANT_USER',
   IS_CREATING_MERCHANT_USER: 'IS_CREATING_MERCHANT_USER',
   CREATE_MERCHANT_USER_SUCCESS: 'CREATE_MERCHANT_USER_SUCCESS',
   CREATE_MERCHANT_USER_FAILURE: 'CREATE_MERCHANT_USER_FAILURE',

   CREATE_MERCHANT_SUB_USER: 'CREATE_MERCHANT_SUB_USER',
   IS_CREATING_MERCHANT_SUB_USER: 'IS_CREATING_MERCHANT_SUB_USER',
   CREATE_MERCHANT_SUB_USER_SUCCESS: 'CREATE_MERCHANT_SUB_USER_SUCCESS',
   CREATE_MERCHANT_SUB_USER_FAILURE: 'CREATE_MERCHANT_SUB_USER_FAILURE',

   DELETE_MERCHANT_USER: 'DELETE_MERCHANT_USER',
   IS_DELETING_MERCHANT_USER: 'IS_DELETING_MERCHANT_USER',
   DELETE_MERCHANT_USER_SUCCESS: 'DELETE_MERCHANT_USER_SUCCESS',
   DELETE_MERCHANT_USER_FAILURE: 'DELETE_MERCHANT_USER_FAILURE',

   GET_DASHBOARD: 'GET_DASHBOARD',
   GETTING_DASHBOARD: 'GETTING_DASHBOARD',
   GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
   GET_DASHBOARD_FAILURE: 'GET_DASHBOARD_FAILURE',

   REGISTER_MERCHANT: 'REGISTER_MERCHANT',
   REGISTER_MERCHANT_SUB_USER: 'REGISTER_MERCHANT_SUB_USER',
   ADD_SUB_USER_TO_MERCHANT: 'ADD_SUB_USER_TO_MERCHANT',

   GET_MERCHANT: 'GET_MERCHANT',
   GET_MERCHANT_COMPANY: 'GET_MERCHANT_COMPANY',
   GET_MERCHANT_SUB_USER: 'GET_MERCHANT_SUB_USER',

   LOGIN: 'LOGIN',
   MERCHANT_SUB_USER_LOGIN: 'MERCHANT_SUB_USER_LOGIN',
   TOKEN_HAS_EXPIRED: 'TOKEN_HAS_EXPIRED',
   LOGOUT: 'LOGOUT',
   mer: 'mer',
   // MERCHANT_ROLE_ID: '6390913d2493d92563f85419', //test server
   // MERCHANT_ROLE_ID: '60d9a086ebe692002293dda1', //test server
   // MERCHANT_ROLE_ID: '60d9a086ebe692002293dda2', //test server
   // MERCHANT_ROLE_ID: '6135fb4af931706b4e131e3f', //prod server
   MERCHANT_ROLE_ID: '66684e42d3bed7453c3db33b', //prod server
   MERCHANT_KEY: 'MERCHANT_KEY',
   MERCHANT_COMPANY_KEY: 'merchant_company',
   MERCHANT_USER_KEY: 'merchant',
   MERCHANT_SUB_USER_KEY: 'merchant_sub_user',

   REGISTER_MERCHANT_URI: `${appConstants.BASE_URI}${user}/${signup}`,
   REGISTER_MERCHANT_SUB_USER_URI: (token) =>
      `${appConstants.BASE_URI}${user}/${merchantUser}/${token}`,
   ADD_MERCHANT_SUB_USER_URI: `${appConstants.BASE_URI}${subUser}`,
   ADD_SUB_USER_TO_MERCHANT_URI: (token) => `${appConstants.BASE_URI}${acceptInvitation}/${token}`,
   LOGIN_MERCHANT_URI: `${appConstants.BASE_URI}${user}/${signin}`,
   MERCHANT_SUB_USER_LOGIN_URI: `${appConstants.BASE_URI}${subLogin}`,
   GET_MERCHANT_DASHBOARD_URI: (id) => `${appConstants.BASE_URI}${merchant}/${id}/dashboard`,
   GET_MERCHANT_COMPANY_INFORMATION_URI: (id) => `${appConstants.BASE_URI}${merchant}/${id}/info`,
   VERIFICATION_EMAIL_URI: (id) => `${appConstants.BASE_URI}${user}/resend_mail/${id}`,
   VERIFY_PHONE_URI: (id) => `${appConstants.BASE_URI}${user}/verify_mobile/${id}`,
   MERCHANT_URI: `${appConstants.BASE_URI}merchant`,
   VERIFY_EMAIL_URI: (data) =>
      `${appConstants.BASE_URI}${user}/verify_email/${data.token}/${data.email}`,
   RESEND_EMAIL_LINK_URI: (data) => `${appConstants.BASE_URI}${user}/resend_mail/${data.id}`,
   SEND_OTP_URI: (data) => `${appConstants.BASE_URI}${user}/send_otp/${data._id}`,
   RESEND_OTP_URI: (data) => `${appConstants.BASE_URI}${user}/resend_otp/${data._id}`,
   CREATE_PIN_URI: (data) => `${appConstants.BASE_URI}${user}/create_transaction_pin/${data._id}`,
   UPDATE_MERCHANT_INFO_URI: (id) => `${appConstants.BASE_URI}${merchant}/${id}`,
   ADD_NEXT_OF_KIN_URI: (id) => `${appConstants.BASE_URI}${user}/add_next_of_kin​/${id}`,
   CHANGE_PASSWORD_URI: (id) => `${appConstants.BASE_URI}${user}/update_password/${id}`,
   CHANGE_PIN_URI: (id) => `${appConstants.BASE_URI}${user}/update_transaction_pin/${id}`,
   GET_DASHBOARD_URI: `${appConstants.BASE_URI}merchant/dashboard`,
   GET_MERCHANT_USERS_URI: (id) => `${appConstants.BASE_URI}merchant/${id}/children`,
   DELETE_MERCHANT_USER_URI: (id) => `${appConstants.BASE_URI}merchant/children/${id}`,

   // CREATE_DETAILS_URI: (id) => `${appConstants.BASE_URI}${user}/update_password/${id}`,
};

export const merchantRole = [
   {label: '-Select Type-', value: '0'},
   {label: 'Admin User', value: 2},
   {label: 'Cashier', value: 3},
];
