export const STATE_CODES = [
   {label: 'Abia', value: '6148dfcfd201bd457ec1407f'},
   {label: 'Adamawa', value: '6148dfcfd201bd457ec14080'},
   {label: 'Akwa Ibom', value: '6148dfcfd201bd457ec14081'},
   {label: 'Anambra', value: '6148dfcfd201bd457ec14082'},
   {label: 'Bauchi', value: '6148dfcfd201bd457ec14083'},
   {label: 'Bayelsa', value: '6148dfcfd201bd457ec14084'},
   {label: 'Benue', value: '6148dfcfd201bd457ec14085'},
   {label: 'Borno', value: '6148dfcfd201bd457ec14086'},
   {label: 'Cross River', value: '6148dfcfd201bd457ec14087'},
   {label: 'Delta', value: '6148dfcfd201bd457ec14088'},
   {label: 'Ebonyi', value: '6148dfcfd201bd457ec14089'},
   {label: 'Edo', value: '6148dfcfd201bd457ec1408a'},
   {label: 'Ekiti', value: '6148dfcfd201bd457ec1408b'},
   {label: 'Enugu', value: '6148dfcfd201bd457ec1408c'},
   {label: 'Federal Capital Territory', value: '6148dfcfd201bd457ec1408d'},
   {label: 'Gombe', value: '6148dfcfd201bd457ec1408e'},
   {label: 'Imo', value: '6148dfcfd201bd457ec1408f'},
   {label: 'Jigawa', value: '6148dfcfd201bd457ec14090'},
   {label: 'Kaduna', value: '6148dfcfd201bd457ec14091'},
   {label: 'Kano', value: '6148dfcfd201bd457ec14092'},
   {label: 'Katsina', value: '6148dfcfd201bd457ec14093'},
   {label: 'Kebbi', value: '6148dfcfd201bd457ec14094'},
   {label: 'Kogi', value: '6148dfcfd201bd457ec14095'},
   {label: 'Kwara', value: '6148dfcfd201bd457ec14096'},
   {label: 'Lagos', value: '6148dfcfd201bd457ec14097'},
   {label: 'Nasarawa', value: '6148dfcfd201bd457ec14098'},
   {label: 'Niger', value: '6148dfcfd201bd457ec14099'},
   {label: 'Ogun', value: '6148dfcfd201bd457ec1409a'},
   {label: 'Ondo', value: '6148dfcfd201bd457ec1409b'},
   {label: 'Osun', value: '6148dfcfd201bd457ec1409c'},
   {label: 'Oyo', value: '6148dfcfd201bd457ec1409d'},
   {label: 'Plateau', value: '6148dfcfd201bd457ec1409e'},
   {label: 'Rivers', value: '615c35abab7b507e5d6934ab'},
   {label: 'Sokoto', value: '6148dfcfd201bd457ec1409f'},
   {label: 'Taraba State', value: '6148dfcfd201bd457ec140a0'},
   {label: 'Yobe', value: '6148dfcfd201bd457ec140a1'},
   {label: 'Zamfara', value: '6148dfcfd201bd457ec140a2'},
];
