import {transactionConstants} from '../_constants';

const getAllTransactions = (model) => ({
   type: transactionConstants.GET_ALL_TRANSACTIONS,
   data: model,
});

const getAllTransactionsAsASubUser = (model) => ({
   type: transactionConstants.GET_ALL_TRANSACTIONS_AS_A_SUB_USER,
   data: model,
});

const getAllWithdrawals = (model) => ({
   type: transactionConstants.GET_ALL_WITHDRAWALS,
   data: model,
});

const filterTransactions = (model) => ({
   type: transactionConstants.FILTER_TRANSACTIONS,
   data: model,
});

const filterWithdrawals = (model) => ({
   type: transactionConstants.FILTER_WITHDRAWALS,
   data: model,
});

export const transactionActions = {
   getAllTransactions,
   getAllTransactionsAsASubUser,
   getAllWithdrawals,
   filterTransactions,
   filterWithdrawals,
};
