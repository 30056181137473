import React, {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {MDBContainer, MDBCol} from 'mdb-react-ui-kit';
import classNames from 'classnames';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import TextInput from '../../../_components/TextInput';
import {appActions, forgotPasswordActions} from '../../../_actions';
import CircularProgress from '@material-ui/core/CircularProgress';
// import classNames from 'classnames';
import BlueYellow from '..//..//../_assets/images/blueandyellow.svg';
// import Customerone from '../../_assets/images/customerone.png';
import Formsy from 'formsy-react';
// import Customertwo from '../../_assets/images/customertwo.png';
// import Customerthree from '../../_assets/images/customerthree.png';
import {makeStyles} from '@material-ui/core/styles';
import {Link, useParams} from 'react-router-dom';
import './index.scss';
import {capitalizeFirstLetter} from '../../../_helpers';
// import {FormatColorTextSharp} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
   margin: {
      margin: theme.spacing(1),
   },
   textField: {
      width: '45ch',
      height: '36px',
   },
   root: {
      width: '100%',
      marginRight: '230p',
      height: '36px',
   },
   // padding: {
   //    padding: '10px 2px',
   //    height: '15px',
   //    width: '100%',
   //    marginLeft: '-10px',
   //    borderColor: 'red',
   // },
   input: {
      padding: '10px 2px',
      height: '15px',
      width: '100%',
      border: '1px solid white',
      '&$focused $hover $notchedOutline': {
         borderColor: 'white',
         borderWidth: 1,
      },
   },
}));

function LoginPage({history}) {
   const dispatch = useDispatch();
   const {successMessageIsSent, resetPasswordSuccessMessage, errorMessage, isResettingPassword} =
      useSelector((s) => s.forgotPassword);
   console.log({errorMessage});
   const [canSubmit, setCanSubmit] = useState(false);
   const [passwordShown, setPasswordShown] = useState(false);
   const [passwordShow, setPasswordShow] = useState(false);
   const [codeError, setCodeError] = useState('');
   const [passwordError, setPasswordError] = useState('');
   const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
   };
   const togglePasswordVisible = () => {
      setPasswordShow(passwordShow ? false : true);
   };

   const {token} = useParams();

   useEffect(() => {
      dispatch(appActions.clearMessages());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (typeof errorMessage === 'string') {
         setCodeError(errorMessage);
      }
      if (errorMessage?.new_password) {
         setPasswordError('password is Invalid');
         console.log({errorMessage});
      }
   }, [errorMessage]);
   useEffect(() => {
      if (
         typeof resetPasswordSuccessMessage === 'string' &&
         resetPasswordSuccessMessage.length > 1
      ) {
         const timeoutId = setTimeout(() => {
            history.push('/login');
         }, 2000);

         return () => clearTimeout(timeoutId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [resetPasswordSuccessMessage]);

   const handleSubmit = useCallback(
      (data) => {
         setCodeError('');
         setPasswordError('');
         data.reset_token = token;
         data.role = 'merchant';
         console.log('Reset Data', data);
         dispatch(forgotPasswordActions.resetPassword(data));
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [token],
   );

   const passError =
      errorMessage?.errors?.[0].param === 'new_password' && errorMessage?.errors[0].msg;

   return (
      <>
         <section className="new">
            <img src={BlueYellow} alt="" className="img-fluid image" />
            <MDBContainer className="align-items-center">
               <div className="bigboxes">
                  <MDBCol className="text">
                     <h3>
                        Create
                        <br />
                        New Password
                     </h3>
                     <p>
                        New user?&nbsp;
                        <br />
                        <a style={{fontWeight: 'bolder'}} href="/merchantsignup">
                           Signup
                        </a>
                     </p>
                  </MDBCol>
                  <MDBCol className="smallbox">
                     <MDBCol className="smallbox2">
                        <Formsy
                           onValidSubmit={handleSubmit}
                           onValid={() => setCanSubmit(true)}
                           onInvalid={() => setCanSubmit(false)}>
                           <div className="forms col-md-10">
                              {codeError && <h5 className="error">{codeError}</h5>}
                              {!!resetPasswordSuccessMessage && (
                                 <h5 className="success">
                                    {capitalizeFirstLetter(resetPasswordSuccessMessage)}
                                 </h5>
                              )}
                              <div id="textExample1" className="form-text" style={{color: 'black'}}>
                                 Password
                              </div>
                              <FormControl margin="normal" required fullWidth>
                                 <TextInput
                                    name="new_password"
                                    wrapperClassName="input-wrapper"
                                    mainWrapperClassName="w-100 mb-3 mb-s-4"
                                    placeholder="********"
                                    useFocusedError
                                    type={passwordShown ? 'text' : 'password'}
                                    className="input border-0 w-100"
                                    validationError="Please provide your password!"
                                    required
                                    valError={passwordError || passError}
                                 />
                                 <i onClick={togglePasswordVisiblity}>
                                    {passwordShown ? <Visibility /> : <VisibilityOff />}
                                 </i>
                                 {errorMessage && (
                                    <h5 claassName="error">{errorMessage?.message}</h5>
                                 )}
                              </FormControl>
                           </div>
                           <div className="forms col-md-10">
                              <div id="textExample1" className="form-text" style={{color: 'black'}}>
                                 Confirm Password
                              </div>
                              <FormControl margin="normal" required fullWidth>
                                 <TextInput
                                    name="confirmpassword"
                                    wrapperClassName="input-wrapper"
                                    mainWrapperClassName="w-100 mb-3 mb-s-4"
                                    placeholder="********"
                                    useFocusedError
                                    type={passwordShow ? 'text' : 'password'}
                                    className="input border-0 w-100"
                                    validations="equalsField:new_password"
                                    validationError="Passwords don't match!"
                                    required
                                 />
                                 <i onClick={togglePasswordVisible}>
                                    {passwordShow ? <Visibility /> : <VisibilityOff />}
                                 </i>
                              </FormControl>
                           </div>

                           <button
                              // to="/verify"
                              disabled={!canSubmit}
                              type="submit"
                              className={classNames('signupbtn', {
                                 disabled: !canSubmit,
                              })}>
                              {isResettingPassword ? (
                                 <CircularProgress color="inherit" thickness={2} />
                              ) : (
                                 'Confirm'
                              )}
                           </button>
                        </Formsy>
                     </MDBCol>
                  </MDBCol>
               </div>
            </MDBContainer>
         </section>
      </>
   );
}

export default LoginPage;
