import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {merchantConstants} from '../../../_constants';
import {appActions, merchantActions} from '../../../_actions';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import Verification from '../../Verification';
import {ReactComponent as Error} from '..//..//..//_assets/icon/error.svg';
import BlueYellow from '..//..//../_assets/images/blueandyellow.svg';
import {ReactComponent as Bluetick} from '..//..//..//_assets/icon/bluetick.svg';
import './index.scss';

function Emailverify({match}) {
   const dispatch = useDispatch();
   const {verifyEmailSuccess, verifyEmailFailure, IsResendingEmailLink, resendEmailLinkSuccess} =
      useSelector((s) => s.auth);
   const errorMessage = useSelector((s) => s.merchant.errorMessage);

   useEffect(() => {
      window.scrollTo(0, 0);
      const {token, email} = match.params;
      const data = {
         token: token,
         email: email,
      };
      dispatch(merchantActions.verifyEmail(data));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const resendLink = () => {
      const {user_id} = errorMessage;
      const data = {
         id: user_id,
      };
      dispatch(merchantActions.resendEmailLink(data));
   };
   if (verifyEmailSuccess) {
      return (
         <section className="emailverify">
            <img src={BlueYellow} alt="" className="img-fluid image" />
            <MDBContainer className="align-items-center">
               <MDBCol className="smallbox2">
                  <Bluetick className="img-fluid tick" />
                  <br />
                  <br />
                  <br />
                  <br />
                  <h3>Email Verification Successful</h3>
                  <br />
                  <Link to="/phone">
                     <button
                        // to="/verify"
                        // disabled={!canSubmit}
                        type="submit"
                        className="signupbtn">
                        Next
                     </button>
                  </Link>
               </MDBCol>
            </MDBContainer>
         </section>
      );
   } else if (verifyEmailFailure) {
      return (
         <section className="emailverify">
            <img src={BlueYellow} alt="" className="img-fluid image" />
            <MDBContainer className="align-items-center">
               <MDBCol className="smallbox2">
                  <Error className="img-fluid tick" />
                  <br />
                  <br />
                  <br />
                  <br />
                  {/* <h3>
                     Oops!...
                     <br />
                     link expired
                  </h3> */}
                  <h3>{errorMessage?.message}</h3>
                  <br />
                  {errorMessage?.message === 'user email has already been verified' ? (
                     <Link to="/login">
                        <button
                           // to="/verify"
                           // disabled={!canSubmit}
                           type="submit"
                           className="signupbtn">
                           Login
                        </button>
                     </Link>
                  ) : (
                     <Link>
                        <button
                           onClick={resendLink}
                           // to="/verify"
                           // disabled={!canSubmit}
                           type="submit"
                           className="signupbtn">
                           {IsResendingEmailLink ? (
                              <CircularProgress color="inherit" thickness={1} />
                           ) : (
                              'Resend'
                           )}
                        </button>
                     </Link>
                  )}
               </MDBCol>
            </MDBContainer>
            {resendEmailLinkSuccess && <Verification />}
         </section>
      );
   } else {
      return null;
   }
}
export default Emailverify;
