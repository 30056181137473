import {FormControl} from '@material-ui/core';
import React, {useState, useEffect, useRef, useCallback} from 'react';
import Formsy from 'formsy-react';
import classNames from 'classnames';
import Modal from 'react-modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useDispatch, useSelector} from 'react-redux';
import {merchantActions} from '../../../../_actions';
import {MDBCol, MDBContainer, MDBRow} from 'mdb-react-ui-kit';
import Password from './updatePassword';
import {ReactComponent as Lock} from '../../../../_assets/icon/lock.svg';
// import TextInput from '../../../../_components/TextInput';
// import {ReactComponent as Vector} from '../../../../_assets/icon/downarr.svg';
import {Link} from '@material-ui/core';
// import {ReactComponent as Pen} from '../../../../_assets/icon/pen.svg';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';

const Security = (props) => {
   const dispatch = useDispatch();
   const {data, changedPassword, changingPin, changedPin} = useSelector((s) => s.merchant);
   const [canSubmit, setCanSubmit] = useState(false);
   // const [showInput, setShowInput] = useState(false);
   const [viewInput, setViewInput] = useState(false);
   // const [modalIsOpen, setModalIsOpen] = useState(false);
   const [secModalIsOpen, setSecModalIsOpen] = useState(false);
   const [codeError, setCodeError] = useState('');

   const [oneOld, setOneOld] = useState('');
   const [twoOld, setTwoOld] = useState('');
   const [threeOld, setThreeOld] = useState('');
   const [fourOld, setFourOld] = useState('');
   const [one, setOne] = useState('');
   const [two, setTwo] = useState('');
   const [three, setThree] = useState('');
   const [four, setFour] = useState('');
   const [oneConfirm, setOneConfirm] = useState('');
   const [twoConfirm, setTwoConfirm] = useState('');
   const [threeConfirm, setThreeConfirm] = useState('');
   const [fourConfirm, setFourConfirm] = useState('');

   const [oneOldSet, setOneOldSet] = useState(false);
   const [twoOldSet, setTwoOldSet] = useState(false);
   const [threeOldSet, setThreeOldSet] = useState(false);
   const [fourOldSet, setFourOldSet] = useState(false);
   const [oneSet, setOneSet] = useState(false);
   const [twoSet, setTwoSet] = useState(false);
   const [threeSet, setThreeSet] = useState(false);
   const [fourSet, setFourSet] = useState(false);
   const [oneConfirmSet, setOneConfirmSet] = useState(false);
   const [twoConfirmSet, setTwoConfirmSet] = useState(false);
   const [threeConfirmSet, setThreeConfirmSet] = useState(false);
   const [fourConfirmSet, setFourConfirmSet] = useState(false);

   const codeOld = `${oneOld}${twoOld}${threeOld}${fourOld}`;
   const code = `${one}${two}${three}${four}`;
   const codeConfirm = `${oneConfirm}${twoConfirm}${threeConfirm}${fourConfirm}`;

   const confirmCode = useCallback(() => {
      if (code !== codeConfirm) {
         setCodeError("Pins don't match!");
         return false;
      }

      setCodeError('');
      return true;
   }, [code, codeConfirm]);

   const handleSubmit = useCallback(() => {
      if (confirmCode()) {
         const model = {
            old_transaction_pin: codeOld.trim(),
            new_transaction_pin: code.trim(),
         };

         dispatch(merchantActions.changePin(model));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [code, codeOld, confirmCode]);

   useEffect(() => {
      if (changedPin) {
         setSecModalIsOpen(false);
         setOneOld('');
         setTwoOld('');
         setThreeOld('');
         setFourOld('');
         setOneConfirm('');
         setTwoConfirm('');
         setThreeConfirm('');
         setFourConfirm('');
         setOne('');
         setTwo('');
         setThree('');
         setFour('');
      }
   }, [changedPin]);

   const oneRef = useRef();
   const twoRef = useRef();
   const threeRef = useRef();
   const fourRef = useRef();
   const oneConfirmRef = useRef();
   const twoConfirmRef = useRef();
   const threeConfirmRef = useRef();
   const fourConfirmRef = useRef();
   const oneOldRef = useRef();
   const twoOldRef = useRef();
   const threeOldRef = useRef();
   const fourOldRef = useRef();

   const clearError = () => {
      setCodeError('');
   };

   const setError = (itm) => {
      setCodeError(itm);
   };

   const validateCode = (val, setter) => {
      console.log('code val', val);
      if (val.length === 0) {
         clearError();
         setter(false);
         return true;
      }

      if (!/^[0-9]$/.test(val)) {
         setError('Code can only contain numbers!');
         setter(false);
         return false;
      }

      clearError();
      setter(true);
      return true;
   };

   const handleOneChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setOneSet)) {
         setOne(itemValue);
         if (itemValue.length) {
            twoRef?.current?.focus();
         }
      }
   };
   const handleOneConfirmChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setOneConfirmSet)) {
         setOneConfirm(itemValue);
         if (itemValue.length) {
            twoConfirmRef?.current?.focus();
         }
      }
   };
   const handleOneOldChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setOneOldSet)) {
         setOneOld(itemValue);
         if (itemValue.length) {
            twoOldRef?.current?.focus();
         }
      }
   };

   const handleTwoChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setTwoSet)) {
         setTwo(itemValue);
         if (itemValue.length) {
            threeRef?.current?.focus();
         }
      }
   };
   const handleTwoOldChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setTwoOldSet)) {
         setTwoOld(itemValue);
         if (itemValue.length) {
            threeOldRef?.current?.focus();
         }
      }
   };
   const handleTwoConfirmChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setTwoConfirmSet)) {
         setTwoConfirm(itemValue);
         if (itemValue.length) {
            threeConfirmRef?.current?.focus();
         }
      }
   };

   const handleThreeChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setThreeSet)) {
         setThree(itemValue);
         if (itemValue.length) {
            fourRef?.current?.focus();
         }
      }
   };
   const handleThreeConfirmChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setThreeConfirmSet)) {
         setThreeConfirm(itemValue);
         if (itemValue.length) {
            fourConfirmRef?.current?.focus();
         }
      }
   };
   const handleThreeOldChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setThreeOldSet)) {
         setThreeOld(itemValue);
         if (itemValue.length) {
            fourOldRef?.current?.focus();
         }
      }
   };

   const handleFourChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setFourSet)) {
         setFour(itemValue);
         if (itemValue.length) {
            oneConfirmRef?.current?.focus();
         }
      }
   };
   const handleFourConfirmChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setFourConfirmSet)) {
         setFourConfirm(itemValue);
         if (itemValue.length) {
            fourConfirmRef?.current?.focus();
         }
      }
   };
   const handleFourOldChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setFourOldSet)) {
         setFourOld(itemValue);
         if (itemValue.length) {
            oneRef?.current?.focus();
         }
      }
   };

   const onkeypress = useCallback(
      (e, value, prevRef, setState) => {
         if (e.keyCode === 8) {
            if (!value.length) {
               if (prevRef) {
                  prevRef.focus();
                  setState('');
               }
            }
         }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
   );

   // const onClick = () => {
   //    setShowInput(true);
   // };

   // const onClick2 = () => {
   //    setShowInput(false);
   // };

   const onClick3 = () => {
      setViewInput(true);
   };

   useEffect(() => {
      if (changedPassword) {
         setViewInput(false);
      }
   }, [changedPassword]);

   const setSecModalIsOpenToTrue = () => {
      setSecModalIsOpen(true);
      // setModalIsOpen(false);
   };

   const customStyles = {
      content: {
         // top: '40%',
         position: 'fixed',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: '400px',
         maxWidth: '90%',
         height: '400px',
         maxHeight: '90%',
         backgroundColor: '#ffffff',
         borderRadius: '20px',
         padding: '40px 40px',
         overflowX: 'hidden',
         zIndex: '100',
      },
   };

   return (
      <>
         <div className="security mt-3">
            <MDBContainer id="basic">
               <MDBRow>
                  <MDBCol className="smallbox2">
                     <Formsy
                        // onValidSubmit={handleSubmit}
                        onValid={() => setCanSubmit(true)}
                        onInvalid={() => setCanSubmit(false)}>
                        <div style={{marginBottom: '80px'}} className="forms col-md-10">
                           <div
                              id="textExample1"
                              className="form-text mb-2"
                              style={{color: 'black'}}>
                              Email Address
                           </div>
                           <div className="smalltext">
                              <h5>{data?.email}</h5>
                           </div>
                        </div>
                        <div className="forms col-md-10">
                           <div
                              id="textExample1"
                              className="form-text mb-2"
                              style={{color: 'black'}}>
                              Phone Number
                           </div>
                           <div className="smalltext d-flex">
                              <h5>{data?.phone_number?.replace(/^234/, '0')}</h5>
                              {/* {!showInput && (
                                 <>
                                    <h5>{phoneToVerify}</h5>
                                    <Pen onClick={onClick} className="pen" />
                                 </>
                              )}
                              {showInput ? <Input onClick={onClick2} /> : null} */}
                           </div>
                        </div>
                        {/* <div className="forms col-md-10">
                           <div
                              id="textExample1"
                              className="form-text mb-2"
                              style={{color: 'black'}}>
                              Bank Verification Number(BVN)
                           </div>
                           <div className="smalltext d-flex">
                              <h5 style={{color: '#34CF4D'}}>verified</h5>
                           </div>
                        </div> */}
                     </Formsy>
                  </MDBCol>
                  <MDBCol className="smallbox2">
                     <div>
                        <div style={{marginBottom: '65px'}} className="forms col-md-10">
                           <div
                              id="textExample1"
                              className="form-text mb-2"
                              style={{color: 'black'}}>
                              Update Pasword
                           </div>
                           <div className="smalltext d-flex">
                              {!viewInput && (
                                 <h5
                                    style={{
                                       color: '#3377ef',
                                       cursor: 'pointer',
                                    }}
                                    onClick={onClick3}>
                                    Change Password
                                 </h5>
                              )}
                              {viewInput ? <Password /> : null}
                           </div>
                        </div>
                        <div className="forms col-md-10">
                           <div
                              id="textExample1"
                              className="form-text mb-2"
                              style={{color: 'black'}}>
                              Update Pin
                           </div>
                           <div className="smalltext d-flex">
                              <Link>
                                 <h5
                                    style={{
                                       color: '#3377ef',
                                       cursor: 'pointer',
                                    }}
                                    onClick={setSecModalIsOpenToTrue}>
                                    Change Pin
                                 </h5>
                              </Link>
                           </div>
                        </div>

                        {/* <Link to="/verify">
                              <button
                                 // to="/verify"
                                 disabled={!canSubmit}
                                 type="submit"
                                 className="signupbtn">
                                 Submit
                              </button>
                           </Link> */}
                     </div>
                  </MDBCol>
               </MDBRow>
            </MDBContainer>
         </div>
         <Modal
            isOpen={secModalIsOpen}
            style={customStyles}
            onRequestClose={() => setSecModalIsOpen(false)}>
            <Lock className="pin-lock" />
            <p style={{textAlign: 'center'}}>Enter your Buy and Go Pin</p>
            <Formsy
            // onValidSubmit={handleSubmit}
            // onValid={() => setCanSubmit(true)}
            // onInvalid={() => setCanSubmit(false)}
            >
               <div className="formed col-md-12 d-flex justify-content-center">
                  <div id="textExample1" className="formtext" style={{color: 'black'}}>
                     Old Transaction Pin
                  </div>

                  <FormControl margin="normal" style={{marginRight: '15px'}}>
                     <input
                        name="one"
                        value={oneOld}
                        required
                        useFocusedError
                        placeholder="*"
                        ref={oneOldRef}
                        onChange={handleOneOldChange}
                        className="input"
                        type="password"
                     />
                  </FormControl>
                  <FormControl margin="normal" style={{marginRight: '15px'}}>
                     <input
                        name="two"
                        value={twoOld}
                        reduired
                        useFocusedError
                        placeholder="*"
                        ref={twoOldRef}
                        onKeyDown={(e) => onkeypress(e, twoOld, oneOldRef.current, setOneOld)}
                        onChange={handleTwoOldChange}
                        className="input"
                        type="password"
                     />
                  </FormControl>
                  <FormControl margin="normal" style={{marginRight: '15px'}}>
                     <input
                        name="three"
                        value={threeOld}
                        required
                        useFocusedError
                        placeholder="*"
                        ref={threeOldRef}
                        onKeyDown={(e) => onkeypress(e, threeOld, twoOldRef.current, setTwoOld)}
                        onChange={handleThreeOldChange}
                        className="input"
                        type="password"
                     />
                  </FormControl>
                  <FormControl margin="normal" style={{marginRight: '15px'}}>
                     <input
                        name="four"
                        value={fourOld}
                        ref={fourOldRef}
                        required
                        useFocusedError
                        placeholder="*"
                        onKeyDown={(e) => onkeypress(e, fourOld, threeOldRef.current, setThreeOld)}
                        onChange={handleFourOldChange}
                        className="input"
                        type="password"
                     />
                  </FormControl>
               </div>
               <div className="formed col-md-12 mt-5 d-flex justify-content-center">
                  <div id="textExample1" className="formtext" style={{color: 'black'}}>
                     New Transaction Pin
                  </div>

                  <FormControl margin="normal" style={{marginRight: '15px'}}>
                     <input
                        name="one"
                        value={one}
                        required
                        useFocusedError
                        placeholder="*"
                        ref={oneRef}
                        onChange={handleOneChange}
                        className="input"
                        type="password"
                     />
                  </FormControl>
                  <FormControl margin="normal" style={{marginRight: '15px'}}>
                     <input
                        name="two"
                        value={two}
                        reduired
                        useFocusedError
                        placeholder="*"
                        ref={twoRef}
                        onKeyDown={(e) => onkeypress(e, two, oneRef.current, setOne)}
                        onChange={handleTwoChange}
                        className="input"
                        type="password"
                     />
                  </FormControl>
                  <FormControl margin="normal" style={{marginRight: '15px'}}>
                     <input
                        name="three"
                        value={three}
                        required
                        useFocusedError
                        placeholder="*"
                        ref={threeRef}
                        onKeyDown={(e) => onkeypress(e, three, twoRef.current, setTwo)}
                        onChange={handleThreeChange}
                        className="input"
                        type="password"
                     />
                  </FormControl>
                  <FormControl margin="normal" style={{marginRight: '15px'}}>
                     <input
                        name="four"
                        value={four}
                        ref={fourRef}
                        required
                        useFocusedError
                        placeholder="*"
                        onKeyDown={(e) => onkeypress(e, four, threeRef.current, setThree)}
                        onChange={handleFourChange}
                        className="input"
                        type="password"
                     />
                  </FormControl>
               </div>
               <div className="formed col-md-12 mt-5 d-flex justify-content-center">
                  <div id="textExample1" className="formtext" style={{color: 'black'}}>
                     Check Transaction Pin
                  </div>

                  <FormControl margin="normal" style={{marginRight: '15px'}}>
                     <input
                        name="one"
                        value={oneConfirm}
                        required
                        useFocusedError
                        placeholder="*"
                        ref={oneConfirmRef}
                        onChange={handleOneConfirmChange}
                        className="input"
                        type="password"
                     />
                  </FormControl>
                  <FormControl margin="normal" style={{marginRight: '15px'}}>
                     <input
                        name="two"
                        value={twoConfirm}
                        reduired
                        useFocusedError
                        placeholder="*"
                        ref={twoConfirmRef}
                        onKeyDown={(e) =>
                           onkeypress(e, twoConfirm, oneConfirmRef.current, setOneConfirm)
                        }
                        onChange={handleTwoConfirmChange}
                        className="input"
                        type="password"
                     />
                  </FormControl>
                  <FormControl margin="normal" style={{marginRight: '15px'}}>
                     <input
                        name="three"
                        value={threeConfirm}
                        required
                        useFocusedError
                        placeholder="*"
                        ref={threeConfirmRef}
                        onKeyDown={(e) =>
                           onkeypress(e, threeConfirm, twoConfirmRef.current, setTwoConfirm)
                        }
                        onChange={handleThreeConfirmChange}
                        className="input"
                        type="password"
                     />
                  </FormControl>
                  <FormControl margin="normal" style={{marginRight: '15px'}}>
                     <input
                        name="four"
                        value={fourConfirm}
                        ref={fourConfirmRef}
                        required
                        useFocusedError
                        placeholder="*"
                        onKeyDown={(e) =>
                           onkeypress(e, fourConfirm, threeConfirmRef.current, setThreeConfirm)
                        }
                        onChange={handleFourConfirmChange}
                        className="input"
                        type="password"
                     />
                  </FormControl>
               </div>
               {!!codeError && (
                  <span className="d-block  my-0 py-2" style={{color: 'red', marginLeft: '70px'}}>
                     {codeError}
                  </span>
               )}

               <button
                  // to="/verify"
                  disabled={
                     !oneSet ||
                     !twoSet ||
                     !threeSet ||
                     !fourSet ||
                     !oneConfirmSet ||
                     !twoConfirmSet ||
                     !threeConfirmSet ||
                     !fourConfirmSet ||
                     !oneOldSet ||
                     !twoOldSet ||
                     !threeOldSet ||
                     !fourOldSet
                  }
                  onClick={handleSubmit}
                  type="submit"
                  // onClick={setSeventhModalOpenToTrue}
                  className={classNames('mt-5 py-2 px-4 pinbtn', {})}>
                  {changingPin ? (
                     <CircularProgress color="inherit" size="1rem" thickness={2} />
                  ) : (
                     'Submit'
                  )}
               </button>
            </Formsy>
         </Modal>
      </>
   );
};

export default Security;

// const Input = (onClick) => {
//    const dispatch = useDispatch();
//    const {phoneToVerify} = useSelector((s) => s.auth);
//    const handleClick = (data) => {
//       data['phone_number'] = `234${phoneToVerify}`;

//       dispatch(merchantActions.verifyPhone(data));
//    }
//    return(
//    <div style={{marginLeft: '-30px', marginTop: '-20px'}} className="form col-md-8">
//       <FormControl margin="normal" required fullWidth>
//          <TextInput
//             name="phone_number"
//             wrapperClassName="input-wrapper"
//             mainWrapperClassName="w-100 mb-3 mb-s-4"
//             placeholder="07036334745"
//             useFocusedError
//             className="input border-0 w-100"
//             // validations="isInt"
//             // validationErrors={{
//             //    isInt: 'Invalid value, must contain only numbers!',
//             //    required: 'Required!',
//             // }}
//          />
//       </FormControl>
//       {/* <Link to="/verify"> */}
//       <button
//          // to="/verify"
//          // disabled={!canSubmit}
//          onClick={onClick}
//          type="submit"
//          className="signbutton">
//          Change Phone Number
//       </button>
//       {/* </Link> */}
//    </div>
//    );
//          };
