import {appConstants} from './app.constant';

const settlement = 'merchant/settlement';

export const settlementConstants = {
   MAKE_FULL_SETTLEMENT: 'MAKE_FULL_SETTLEMENT',
   MAKE_PARTIAL_SETTLEMENT: 'MAKE_PARTIAL_SETTLEMENT',

   SETTLEMENT: 'settlement',

   REQUEST_MAKE_FULL_SETTLEMENT: 'REQUEST_MAKE_FULL_SETTLEMENT',
   REQUEST_MAKE_FULL_SETTLEMENT_ERROR: 'REQUEST_MAKE_FULL_SETTLEMENT_ERROR',
   REQUEST_MAKE_FULL_SETTLEMENT_SUCCESS: 'REQUEST_MAKE_FULL_SETTLEMENT_SUCCESS',

   REQUEST_MAKE_PARTIAL_SETTLEMENT: 'REQUEST_MAKE_PARTIAL_SETTLEMENT',
   REQUEST_MAKE_PARTIAL_SETTLEMENT_ERROR: 'REQUEST_MAKE_PARTIAL_SETTLEMENT_ERROR',
   REQUEST_MAKE_PARTIAL_SETTLEMENT_SUCCESS: 'REQUEST_MAKE_PARTIAL_SETTLEMENT_SUCCESS',

   SETTLEMENT_KEY: 'settlement',
   SETTLEMENT_URI: `${appConstants.BASE_URI}${settlement}`,
};
