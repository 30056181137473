import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
   MDBTable,
   MDBTableBody,
   MDBTableHead,
   // MDBInputGroup,
   // MDBInputGroupText,
   // MDBInputGroupElement,
} from 'mdb-react-ui-kit';
// import {Link} from 'react-router-dom';
// import classNames from 'classnames';
import './index.scss';
import LetteredAvatar from 'lettered-avatar';
// import Lara from '../../../_assets/images/lara.png';
import TablePaginationActions from '../../../_components/TablePaginationAction';
import DatePicker from 'react-datepicker';
// import {FaTimes} from 'react-icons/fa';

import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../../_helpers/loader';
import {transactionActions} from '../../../_actions';
import {numberWithCommas, GetTime, capitalizeFirstLetter} from '../../../_helpers';
// import {ReactComponent as XIcon} from '../../../_assets/icon/1x-icon.svg';
// import {ReactComponent as SearchIcon} from '../../../_assets/icon/1ssearch.svg';
import {ReactComponent as Filter} from '../../../_assets/icon/1filter.svg';

export default function Transactions({companyData}) {
   const dispatch = useDispatch();
   const companyInfo = useSelector((s) => s.merchant.companyInfo);
   console.log('companyInfo', companyInfo);

   useEffect(() => {
      const model = {
         type: 'transaction',
         merchantId: companyInfo?._id,
      };

      dispatch(
         // companyData?.is_child === false
         //    ? transactionActions.getAllTransactions(model)
         //    :
         transactionActions.getAllTransactions(model),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   // const [searchInput, setSearchInput] = useState('');
   const [toDate, setToDate] = useState(false);
   const [startDate, setStartDate] = useState(new Date());
   const [endDate, setEndDate] = useState(new Date());

   const handleChangePage = (event, pages) => {
      const model = {
         page: pages,
         type: 'payment',
         merchantId: companyInfo?._id,
      };

      dispatch(
         // companyData?.is_child === false
         transactionActions.getAllTransactions(model),
         // : transactionActions.getAllTransactionsAsASubUser(model),
      );
      // dispatch(transactionActions.getAllTransactions(model));
   };

   useEffect(() => {
      if (!toDate) {
         const model = {
            type: 'transaction',
            merchantId: companyInfo?._id,
         };
         dispatch(transactionActions.getAllTransactions(model));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [toDate]);

   useEffect(() => {
      if (toDate) {
         const model = {
            from: startDate,
            to: endDate,
            type: 'transaction',
            merchantId: companyInfo?._id,
         };
         dispatch(transactionActions.getAllTransactions(model));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [endDate]);

   // const handleFilter = () => {
   //    const model = {
   //       from: startDate,
   //       to: endDate,
   //       type: 'transaction',
   //       merchantId: companyInfo?._id,
   //    };
   //    dispatch(transactionActions.getAllTransactions(model));
   // };

   // const searchTransaction = () => {
   //    const model = {
   //       searchName: searchInput,
   //       type: 'transaction',
   //    };
   //    dispatch(transactionActions.searchTransaction(model));
   // };

   // useEffect(() => {
   //    if (searchInput === '') {
   //       dispatch(transactionActions.getAllTransactions());
   //    }
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [searchInput]);

   const [isOpen, setIsOpen] = useState(false);
   const toggleList = () => {
      setIsOpen((wasOpen) => !wasOpen);
      setToDate(false);
   };

   const data = useSelector((s) => s.merchant.data);
   const transaction = useSelector((s) => s.transaction);
   const AllTransactionsList = useSelector((s) => s.transaction.AllTransactionsList);
   const {totalDocs, limit, page, totalPages} = transaction?.pagination;
   const total = totalDocs;
   const pages = page - 1;

   return (
      <div>
         {data?.role !== 3 && (
            <div className="search-filter d-flex flex-column flex-sm-row w-100 d-flex justify-content-between">
               <div className="mx-0 mx-sm-4 ps-1 ps-sm-3 mb-5 mb-sm-0 search filter">
                  {
                     isOpen ? (
                        <div className="collaps d-flex flex-md-row">
                           <div
                              // onClick={toggleDate}
                              className="col-5 col-md-4 collaps-item my-5 mx-4 mx-md-5 py-2 px-2 position-relative"
                              style={{width: '50%'}}>
                              From Date Range:
                              <DatePicker
                                 selected={startDate}
                                 onSelect={() => setToDate(true)}
                                 onChange={(date) => setStartDate(date)}
                              />
                           </div>
                           {toDate && (
                              <div
                                 // onClick={toggleDate}
                                 className="col-5 col-md-4 collaps-item my-5 mx-1 py-2 px-2 position-relative"
                                 style={{width: '50%'}}>
                                 To Date Range:
                                 <DatePicker
                                    selected={endDate}
                                    // onSelect={handleFilter}
                                    onChange={(date) => setEndDate(date)}
                                 />
                              </div>
                           )}
                        </div>
                     ) : null
                     // <MDBInputGroup className="mb-n5 mb-sm-0">
                     //    <MDBInputGroupElement
                     //       type="text"
                     //       placeholder="Search"
                     //       aria-label="Search"
                     //       value={searchInput}
                     //       onChange={(e) => setSearchInput(e.target.value)}
                     //    />

                     //    <MDBInputGroupText
                     //       style={{backgroundColor: '#3377ef'}}
                     //       onClick={searchTransaction}>
                     //       <SearchIcon
                     //          style={{width: '.85rem', color: '#fff', cursor: 'pointer'}}
                     //       />
                     //    </MDBInputGroupText>
                     // </MDBInputGroup>
                  }
               </div>
               <div onClick={toggleList} className="mt-5 mt-sm-0 mx-3 mx-sm-0 filter">
                  {/* <div className="d-flex menu align-items-center">Sum Table</div> */}
                  <div className="col-5 col-sm-12 col-md-12 menu menu2 align-items-center">
                     <Filter className="mr-3" style={{width: '1rem'}} />
                     Filter
                  </div>
               </div>
            </div>
         )}
         <div className="customer-list mx-3 mx-sm-5 mt-5">
            <MDBTable className="profile mb-5" responsive borderless hover align="middle">
               <MDBTableHead>
                  <tr>
                     <th style={{paddingLeft: '120px'}} className="name" scope="col">
                        Name
                     </th>
                     <th scope="col">Product</th>
                     <th scope="col">Amount</th>
                     <th scope="col">Status</th>
                     <th scope="col">Time</th>
                  </tr>
               </MDBTableHead>
               {transaction.IsRequestingAllTransactions ? (
                  <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                     <div />
                  </Loader>
               ) : (
                  <MDBTableBody>
                     {AllTransactionsList?.length > 0 ? (
                        AllTransactionsList.map((row) => {
                           return (
                              <div className="d-table-row" style={{background: '#fbfbfc'}}>
                                 <th
                                    scope="row"
                                    className="d-flex justify-content-center align-items-center">
                                    {/* <img
                                       src={Lara}
                                       alt="profile-img"
                                       className="rounded-circle img-fluid"
                                       style={{width: '3rem', height: '3rem'}}
                                    /> */}
                                    <LetteredAvatar
                                       name={row.sender_name}
                                       options={{
                                          size: 50,
                                          twoLetter: true,
                                          //shape: 'square',
                                          bgColor: '',
                                          imgClass: 'image-responsive user-image',
                                          imgWidth: '3rem',
                                          imgHeight: '3rem',
                                       }}
                                    />
                                    <span>{row.sender_name}</span>
                                 </th>
                                 <td>{capitalizeFirstLetter(row.narration)}</td>
                                 <td>₦{numberWithCommas(row.amount)}</td>
                                 <td>{row.status === 'success' ? 'Successful' : 'Unsuccessful'}</td>
                                 <td>{GetTime(row.created_at)}</td>
                              </div>
                              // .split('T')[1]).split('.')[0]
                           );
                        })
                     ) : (
                        <th scope="row">
                           <td align="center" colSpan={5}>
                              No result to display
                           </td>
                        </th>
                     )}
                  </MDBTableBody>
               )}
            </MDBTable>
            {!transaction.IsRequestingAllTransactions && totalPages > 1 && (
               <TablePaginationActions
                  rowsPerPageOptions={[]}
                  colSpan={2}
                  count={total}
                  rowsPerPage={limit}
                  page={pages}
                  totalPages={totalPages}
                  onChangePage={handleChangePage}
               />
            )}
         </div>
      </div>
   );
}

// <div className="search-filter w-100 d-flex justify-content-between">
//             <div className="search filter">
//                {isOpen ? (
//                   <div className="collaps">
//                      <div
//                         // onClick={toggleDate}
//                         className="collaps-item mr-5 position-relation">
//                         From Date Range:
//                         <DatePicker
//                            selected={startDate}
//                            onSelect={() => setToDate(true)}
//                            onChange={(date) => setStartDate(date)}
//                         />
//                      </div>
//                      {toDate && (
//                         <div
//                            // onClick={toggleDate}
//                            className="collaps-item mr-5 position-relation">
//                            To Date Range:
//                            <DatePicker
//                               selected={endDate}
//                               onSelect={handleFilter}
//                               onChange={(date) => setEndDate(date)}
//                            />
//                         </div>
//                      )}
//                   </div>
//                ) : (
//                   <div className="input-group d-flex align-items-center">
//                      <input
//                         type="text"
//                         className="for"
//                         placeholder="Search"
//                         aria-label="Search"
//                         value={searchInput}
//                         onChange={(e) => setSearchInput(e.target.value)}
//                      />
//                      <XIcon className="icons i-x" style={{width: '1rem'}} />
//                      <span className="i-search" onClick={searchTransaction}>
//                         <SearchIcon style={{width: '1rem'}} />
//                      </span>
//                   </div>
//                )}
//             </div>
//             <div className="filter d-flex">
//                {/* <div className="d-flex menu align-items-center">Sum Table</div> */}
//                <div
//                   onClick={toggleList}
//                   className="d-flex menu menu2 align-items-center">
//                   <Filter className="mr-2" style={{width: '1rem'}} />
//                   Filter
//                </div>
//             </div>
//          </div>
