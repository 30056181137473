// import React from 'react';
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import TextInput from '../../../_components/TextInput';
import Formsy from 'formsy-react';
import Modal from 'react-modal';
import Loader from '../../../_helpers/loader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from 'react-dropdown-select';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import Character from '../../../_assets/images/girlcharacter.png';
import {ReactComponent as Lock} from '../../../_assets/icon/lock.svg';
// import {ReactComponent as Vector} from '../../../_assets/icon/downarr.svg';
import {ReactComponent as Verify} from '../../../_assets/icon/verify.svg';
import Card from '..//..//..//_assets/images/emptycard.png';
import Card2 from '..//..//..//_assets/images/EmptyCard2.png';
import {ReactComponent as Delete} from '../../../_assets/icon/delete.svg';
import {ReactComponent as Home} from '../../../_assets/icon/blackhome.svg';
import './index.scss';
import {appActions, bankActions, merchantActions, settlementActions} from '../../../_actions';

// const options = [
//    {label: 'Select Bank', value: 0},
//    {label: 'GTBank', value: 1},
//    {label: 'Union Bank', value: 2},
//    {label: 'UBA', value: 3},
//    {label: 'Wema Bank', value: 4},
//    {label: 'Eco Bank', value: 5},
//    {label: 'Access Bank', value: 6},
// ];   0690000031

function Payment({merchant}) {
   const dispatch = useDispatch();
   const {changingPin, changedPin, IsResettingPin, resettedPin} = useSelector((s) => s.merchant);
   const banksList = useSelector((s) => s.bank.banksList);
   const IsRequestingAccountName = useSelector((s) => s.bank.IsRequestingAccountName);
   const accountName = useSelector((s) => s.bank.accountName);
   const role = useSelector((s) => s.merchant.role);
   const IsRequestingMerchantBankDetails = useSelector(
      (s) => s.bank.IsRequestingMerchantBankDetails,
   );
   const IsSavingBankDetails = useSelector((s) => s.bank.IsSavingBankDetails);
   const IsSavingBvn = useSelector((s) => s.bank.IsSavingBvn);
   const bankDetailsSaved = useSelector((s) => s.bank.bankDetailsSaved);
   const bvnSaved = useSelector((s) => s.bank.bvnSaved);
   const userBvn = useSelector((s) => s.bank.userBvn);

   const settlementErrorMessage = useSelector((s) => s.settlement.errorMessage);
   const IsMakingPartialSettlement = useSelector((s) => s.settlement.IsMakingPartialSettlement);
   const IsMakingFullSettlement = useSelector((s) => s.settlement.IsMakingFullSettlement);
   const partialSettlementMade = useSelector((s) => s.settlement.partialSettlementMade);
   const fullSettlementMade = useSelector((s) => s.settlement.fullSettlementMade);

   const merchantBankDetailsList = useSelector((s) => s.bank.merchantBankDetailsList);
   const errorMessage = useSelector((s) => s.bank.errorMessage);
   const newArr = banksList.map((v) => ({...v, label: v.name, value: v.code}));
   const newMerchantBankDetailsArr = merchantBankDetailsList.map((v) => ({
      ...v,
      label: v.account_name,
      value: v.account_number,
   }));

   useEffect(() => {
      dispatch(appActions.clearMessages());
      dispatch(bankActions.getBanks());
      dispatch(bankActions.getMerchantBankDetails());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (partialSettlementMade || fullSettlementMade) {
         setFourthModalOpen(false);
         setThirdModalOpen(false);
         setEightModalOpen(false);
         setModalOpen(false);
         setOneOld('');
         setTwoOld('');
         setThreeOld('');
         setFourOld('');
         setOneConfirm('');
         setTwoConfirm('');
         setThreeConfirm('');
         setFourConfirm('');
         setOne('');
         setTwo('');
         setThree('');
         setFour('');
      }
   }, [partialSettlementMade, fullSettlementMade]);

   // const oneRef = useRef();
   // const twoRef = useRef();
   // const threeRef = useRef();
   // const fourRef = useRef();
   const oneConfirmRef = useRef();
   const twoConfirmRef = useRef();
   const threeConfirmRef = useRef();
   const fourConfirmRef = useRef();
   const oneOldRef = useRef();
   const twoOldRef = useRef();
   const threeOldRef = useRef();
   const fourOldRef = useRef();

   const [shown, setShown] = useState(1);
   const [bankCode, setBankCode] = useState('');
   const [accountCode, setAccountCode] = useState('');
   const [accountNumber, setAccountNumber] = useState('');
   const [amount, setAmount] = useState('');
   const [accountNumberError, setAccountNumberError] = useState('');
   const [canSubmit, setCanSubmit] = useState(false);
   const [modalOpen, setModalOpen] = useState(false);
   const [secModalOpen, setSecModalOpen] = useState(false);
   const [thirdModalOpen, setThirdModalOpen] = useState(false);
   const [eightModalOpen, setEightModalOpen] = useState(false);
   const [fourthModalOpen, setFourthModalOpen] = useState(false);
   const [fifthModalOpen, setFifthModalOpen] = useState(false);
   const [bvnModalOpen, setBvnModalOpen] = useState(false);
   const [seventhModalOpen, setSeventhModalOpen] = useState(false);
   const [codeError, setCodeError] = useState('');
   const [bankDetailsId, setBankDetailsId] = useState('');
   const [secModalIsOpen, setSecModalIsOpen] = useState(false);
   const [password, setPassword] = useState(false);
   const [passwordValue, setPasswordValue] = useState('');
   const [passwordError, setPasswordError] = useState('');

   const [one, setOne] = useState('');
   const [two, setTwo] = useState('');
   const [three, setThree] = useState('');
   const [four, setFour] = useState('');
   const [five, setFive] = useState('');
   const [six, setSix] = useState('');

   const [oneSet, setOneSet] = useState(false);
   const [twoSet, setTwoSet] = useState(false);
   const [threeSet, setThreeSet] = useState(false);
   const [fourSet, setFourSet] = useState(false);
   const [fiveSet, setFiveSet] = useState(false);
   const [sixSet, setSixSet] = useState(false);

   const [oneOld, setOneOld] = useState('');
   const [twoOld, setTwoOld] = useState('');
   const [threeOld, setThreeOld] = useState('');
   const [fourOld, setFourOld] = useState('');
   const [oneConfirm, setOneConfirm] = useState('');
   const [twoConfirm, setTwoConfirm] = useState('');
   const [threeConfirm, setThreeConfirm] = useState('');
   const [fourConfirm, setFourConfirm] = useState('');

   const [oneOldSet, setOneOldSet] = useState(false);
   const [twoOldSet, setTwoOldSet] = useState(false);
   const [threeOldSet, setThreeOldSet] = useState(false);
   const [fourOldSet, setFourOldSet] = useState(false);
   const [oneConfirmSet, setOneConfirmSet] = useState(false);
   const [twoConfirmSet, setTwoConfirmSet] = useState(false);
   const [threeConfirmSet, setThreeConfirmSet] = useState(false);
   const [fourConfirmSet, setFourConfirmSet] = useState(false);

   // const code = `${one}${two}${three}${four}${five}${six}`;
   const code = `${one}${two}${three}${four}`;
   const codeOld = `${oneOld}${twoOld}${threeOld}${fourOld}`;
   const codeConfirm = `${oneConfirm}${twoConfirm}${threeConfirm}${fourConfirm}`;

   const oneRef = useRef();
   const twoRef = useRef();
   const threeRef = useRef();
   const fourRef = useRef();
   const fiveRef = useRef();
   const sixRef = useRef();

   const clearError = () => {
      setCodeError('');
   };

   const setError = (itm) => {
      setCodeError(itm);
   };

   const validateCode = (val, setter) => {
      if (val.length === 0) {
         clearError();
         setter(false);
         return true;
      }

      if (!/^[0-9]$/.test(val)) {
         setError('Code can only contain numbers!');
         setter(false);
         return false;
      }

      clearError();
      setter(true);
      return true;
   };

   const validatePassword = useCallback((val, type) => {
      if (val.length === 0) {
         setPasswordError('');
         // setPasswordSet(false);
         return;
      }

      if (!/^[a-zA-Z0-9]+$/.test(val)) {
         setPasswordError('Contains invalid character(s)!');
         return;
      }

      if (!/[A-Z]/.test(val)) {
         setPasswordError('Should contain a capital letter!');
         return;
      }

      if (!/[a-z]/.test(val)) {
         setPasswordError('Should contain a small letter!');
         return;
      }

      if (!/[0-9]/.test(val)) {
         setPasswordError('Should contain a number!');
         return;
      }

      if (val.length < 8) {
         setPasswordError('Minimum of 8 characters required!');
         return;
      }

      setPasswordError('');
      // setPasswordSet(true);
   }, []);

   const confirmCode = useCallback(() => {
      if (code !== codeConfirm) {
         setCodeError("Pins don't match!");
         return false;
      }

      setCodeError('');
      return true;
   }, [code, codeConfirm]);

   const handleSubmit = useCallback(() => {
      if (confirmCode()) {
         const model = {
            password: passwordValue,
            // old_transaction_pin: codeOld.trim(),
            new_transaction_pin: code.trim(),
         };

         dispatch(merchantActions.resetPin(model));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [code, codeOld, confirmCode]);

   useEffect(() => {
      if (changedPin) {
         setSecModalIsOpen(false);
         setOneOld('');
         setTwoOld('');
         setThreeOld('');
         setFourOld('');
         setOneConfirm('');
         setTwoConfirm('');
         setThreeConfirm('');
         setFourConfirm('');
         setOne('');
         setTwo('');
         setThree('');
         setFour('');
      }
   }, [changedPin]);

   useEffect(() => {
      if (resettedPin) {
         setSecModalIsOpen(false);
         setModalOpen(false);
         setSecModalOpen(false);
         setOneOld('');
         setTwoOld('');
         setThreeOld('');
         setFourOld('');
         setOneConfirm('');
         setTwoConfirm('');
         setThreeConfirm('');
         setFourConfirm('');
         setOne('');
         setTwo('');
         setThree('');
         setFour('');
      }
   }, [resettedPin]);

   const handleOneOldChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setOneOldSet)) {
         setOneOld(itemValue);
         if (itemValue.length) {
            twoOldRef?.current?.focus();
         }
      }
   };

   const handleOneConfirmChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setOneConfirmSet)) {
         setOneConfirm(itemValue);
         if (itemValue.length) {
            twoConfirmRef?.current?.focus();
         }
      }
   };

   // const handleTwoChange = (e) => {
   //    const itemValue = e.target.value;
   //    if (validateCode(itemValue, setTwoSet)) {
   //       setTwo(itemValue);
   //       if (itemValue.length) {
   //          threeRef?.current?.focus();
   //       }
   //    }
   // };
   const handleTwoOldChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setTwoOldSet)) {
         setTwoOld(itemValue);
         if (itemValue.length) {
            threeOldRef?.current?.focus();
         }
      }
   };
   const handleTwoConfirmChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setTwoConfirmSet)) {
         setTwoConfirm(itemValue);
         if (itemValue.length) {
            threeConfirmRef?.current?.focus();
         }
      }
   };

   // const handleThreeChange = (e) => {
   //    const itemValue = e.target.value;
   //    if (validateCode(itemValue, setThreeSet)) {
   //       setThree(itemValue);
   //       if (itemValue.length) {
   //          fourRef?.current?.focus();
   //       }
   //    }
   // };
   const handleThreeConfirmChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setThreeConfirmSet)) {
         setThreeConfirm(itemValue);
         if (itemValue.length) {
            fourConfirmRef?.current?.focus();
         }
      }
   };
   const handleThreeOldChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setThreeOldSet)) {
         setThreeOld(itemValue);
         if (itemValue.length) {
            fourOldRef?.current?.focus();
         }
      }
   };

   // const handleFourChange = (e) => {
   //    const itemValue = e.target.value;
   //    if (validateCode(itemValue, setFourSet)) {
   //       setFour(itemValue);
   //       if (itemValue.length) {
   //          oneConfirmRef?.current?.focus();
   //       }
   //    }
   // };
   const handleFourConfirmChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setFourConfirmSet)) {
         setFourConfirm(itemValue);
         if (itemValue.length) {
            fourConfirmRef?.current?.focus();
         }
      }
   };
   const handleFourOldChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setFourOldSet)) {
         setFourOld(itemValue);
         if (itemValue.length) {
            oneRef?.current?.focus();
         }
      }
   };

   const handleOneChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setOneSet)) {
         setOne(itemValue);
         if (itemValue.length) {
            twoRef?.current?.focus();
         }
      }
   };

   const handleTwoChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setTwoSet)) {
         setTwo(itemValue);
         if (itemValue.length) {
            threeRef?.current?.focus();
         }
      }
   };

   const handleThreeChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setThreeSet)) {
         setThree(itemValue);
         if (itemValue.length) {
            fourRef?.current?.focus();
         }
      }
   };

   const handleFourChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setFourSet)) {
         setFour(itemValue);
         if (itemValue.length) {
            fiveRef?.current?.focus();
         }
      }
   };

   const handleFiveChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setFiveSet)) {
         setFive(itemValue);
         if (itemValue.length) {
            sixRef?.current?.focus();
         }
      }
   };

   const handleSixChange = (e) => {
      const itemValue = e.target.value;
      if (validateCode(itemValue, setSixSet)) {
         setSix(itemValue);
         if (itemValue.length) {
            sixRef?.current?.blur();
         }
      }
   };

   const handleSaveBankDetails = () => {
      const data = {
         bank_code: bankCode || '044',
         bank_name: 'Access Bank' || banksList.filter((item) => item.code === bankCode)[0].name,
         account_number: accountNumber,
         account_name: accountName?.account_name,
         transaction_pin: code,
      };

      dispatch(bankActions.saveBankDetails(data));
   };

   const handleSettlement = () => {
      const data = {
         bank_id: accountCode,
         // bank_name: 'Access Bank' || banksList.filter((item) => item.code === accountCode)[0].name,
         amount: amount,
         // account_name: accountName?.account_name,
         transaction_pin: code,
      };

      const model = {
         bank_id: accountCode,
         // bank_name: 'Access Bank' || banksList.filter((item) => item.code === accountCode)[0].name,
         // account_name: accountName?.account_name,
         transaction_pin: code,
      };
      amount
         ? dispatch(settlementActions.makePartialSettlement(data))
         : dispatch(settlementActions.makeFullSettlement(model));
   };

   useEffect(() => {
      if (bankDetailsSaved) {
         setFourthModalOpen(false);
         setThirdModalOpen(false);
         setModalOpen(false);
      }
   }, [bankDetailsSaved]);

   useEffect(() => {
      if (bvnSaved) {
         setThirdModalOpen(false);
         setFourthModalOpen(true);
         setBvnModalOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [bvnSaved]);

   const onkeypress = useCallback(
      (e, value, prevRef, setState) => {
         if (e.keyCode === 8) {
            if (!value.length) {
               if (prevRef) {
                  prevRef.focus();
                  setState('');
               }
            }
         }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
   );

   const setModalOpenToTrue = () => {
      setModalOpen(true);
   };
   const setThirdModalOpenToTrue = () => {
      // setSecModalOpen(false);
      setThirdModalOpen(true);
   };
   const setFourthModalOpenToTrue = () => {
      console.log('userBvn', userBvn);
      console.log('setFourthModalOpenToTrue', fourthModalOpen);
      if (userBvn?.bvn) {
         setThirdModalOpen(false);
         setFourthModalOpen(true);
      } else {
         setThirdModalOpen(false);
         setBvnModalOpen(true);
      }
   };

   const handleBvnSubmit = (data) => {
      dispatch(bankActions.saveBvn(data));
   };

   const setFifthModalOpenToTrue = (id) => {
      setBankDetailsId(id);
      setFifthModalOpen(true);
   };

   const setEightModalOpenToTrue = () => {
      setEightModalOpen(true);
   };

   const handlePasswordChange = useCallback((itemValue) => {
      console.log('GOTHERE!!!', itemValue);
      // validatePassword(itemValue, 'old');
      setPasswordValue(itemValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
   console.log('passwordValue!!!', passwordValue);

   const handleDelete = () => {
      if (bankDetailsId) {
         dispatch(bankActions.deleteBankDetails(bankDetailsId));
      }
      setFifthModalOpen(false);
   };

   const getAccountName = () => {
      if (accountNumber === '') {
         setAccountNumberError('Account number is required');
      } else if (isNaN(accountNumber)) {
         setAccountNumberError('Invalid account number');
      } else {
         const data = {
            account_number: accountNumber,
            bank_code: bankCode || '044',
         };
         dispatch(bankActions.getAccountName(data));
      }
   };

   const customStyles = {
      overlay: {
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundColor: 'rgba(255, 255, 255, 0.75)',
      },
      content: {
         // top: '40%',
         position: 'absolute',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         // display: 'flex',
         // alignItems: 'center',
         // justifyContent: 'center',
         width: '500px',
         maxWidth: '90%',
         // height: '60vh',
         maxHeight: '90%',
         backgroundColor: '#ffffff',
         borderTopLeftRadius: '5px',
         borderBottomLeftRadius: '5px',
         borderTopRightRadius: '5px',
         borderBottomRightRadius: '5px',
         padding: '40px 40px',
         overflowX: 'hidden',
         overflow: 'scroll',
         WebkitOverflowScrolling: 'touch',
         zIndex: '-100',
      },
   };

   const bvnError = errorMessage?.errors?.filter((error) => error.param === 'bvn');
   return (
      <>
         <section className="payment">
            <div className="pay w-100 d-flex justify-content-between">
               <ul className="bank_link d-flex">
                  <li>
                     <div
                        onClick={() => setShown(1)}
                        className={classNames('w-100 d-block', {
                           active: shown === 1,
                        })}>
                        Banks
                     </div>
                  </li>
               </ul>
               {role?.role !== 2 && (
                  <div className="d-block d-sm-flex" style={{marginTop: '-12px'}}>
                     <Link
                        onClick={setThirdModalOpenToTrue}
                        className="d-block menu2 align-items-center">
                        Full Settlement
                     </Link>
                     <Link
                        onClick={setEightModalOpenToTrue}
                        className="d-block menu align-items-center">
                        Part Settlement
                     </Link>
                  </div>
               )}
            </div>
            {IsRequestingMerchantBankDetails ? (
               <Loader className="ball-clip-rotate flex justify-center pt5 text-center">
                  <div />
               </Loader>
            ) : (
               <>
                  {/* {merchantBankDetailsList?.length > 0
                     ? merchantBankDetailsList.map((row) => {
                          return (
                             <div className="bankcard" onClick={setFifthModalOpenToTrue}>
                                <img alt="" className="card_bank" src={Card} />
                                <p className="accountname mb-3">{row.account_name}</p> <br />
                                <br />
                                <p className="accountno">{row.account_number}</p>
                                <p className="bankname">{row.bank_name}</p>
                             </div>
                          );
                       })
                     : null} */}
                  {merchantBankDetailsList?.length > 0
                     ? merchantBankDetailsList.map((row) => {
                          return (
                             <div
                                className="bankcard"
                                onClick={
                                   role?.role !== 2 ? () => setFifthModalOpenToTrue(row._id) : null
                                }>
                                <img alt="" className="card_bank" src={Card} />
                                <p className="text-center accountname mb-3">
                                   {row.account_name}
                                </p>{' '}
                                <br />
                                <br />
                                <p className="accountno">{row.account_number}</p>
                                <p className="bankname">{row.bank_name}</p>
                             </div>
                          );
                       })
                     : role?.role === 2 && (
                          <div className="bankcard">
                             <img alt="" className="card_bank" src={Card} />
                             <p className="bankname">
                                No bank account has been added on this platform
                             </p>{' '}
                          </div>
                       )}
                  {role?.role !== 2 && (
                     <div onClick={setModalOpenToTrue} className="bankcard">
                        <img
                           alt=""
                           className="card_bank2"
                           src={Card2}
                           style={{
                              transform: 'rotate(270deg)',
                              marginLeft: '100px',
                              marginTop: '-110px',
                              width: '73%',
                           }}
                        />
                        <Home className="home2" />
                        <p className="addbank">Add New Bank</p>
                     </div>
                  )}
               </>
            )}
            {/* ADDING BANK ACCOUNT MODAL */}
            <Modal
               isOpen={modalOpen}
               // className="modal2"
               style={customStyles}
               // style={{overlay: {zIndex: 1000}}
               onRequestClose={() => setModalOpen(false)}>
               <Formsy
                  // onValidSubmit={handleSubmit}
                  onValid={() => setCanSubmit(true)}
                  onInvalid={() => setCanSubmit(false)}
                  className="d-flex flex-column align-items-center justify-content-center">
                  <div className="form col-md-7">
                     <h4>Add Bank Account</h4>
                     <h5>Withdrawals are paid into your bank account</h5>
                     <h5 style={{color: 'red'}}>{errorMessage?.message}</h5>
                     <div id="textExample1" className="form-text mb-2" style={{color: 'black'}}>
                        Bank
                     </div>
                     <Select
                        value={bankCode}
                        backspaceDelete
                        options={newArr}
                        onChange={(values) => setBankCode(values[0]?.code)}
                     />
                  </div>

                  <div className="form col-md-7">
                     <div
                        id="textExample1"
                        className="mb-n2 mb-sm-n4 form-text"
                        style={{color: 'black'}}>
                        Account Number
                     </div>
                     <FormControl margin="normal" required fullWidth>
                        <TextInput
                           name="fullname"
                           value={accountNumber}
                           onChange={(event) => setAccountNumber(event.target.value)}
                           useFocusedError
                           required
                           className="input w-100 border-0"
                           onBlur={getAccountName}
                        />
                     </FormControl>
                  </div>
                  <div className="form col-md-7">
                     <div id="textExample1" className="form-text" style={{color: 'black'}}>
                        Account Name
                     </div>
                     {IsRequestingAccountName ? (
                        <CircularProgress color="inherit" thickness={1} />
                     ) : (
                        <FormControl margin="normal" fullWidth>
                           <TextInput
                              name="account_name"
                              wrapperClassName="input1-wrapper"
                              mainWrapperClassName="w-100 mb-3 mb-s-4"
                              placeholder="Adrian Will Lake"
                              value={accountName?.account_name}
                              useFocusedError
                              className="input1 w-100"
                              validations="isAlpha"
                              type="text"
                              disabled
                              style={{color: '#000'}}
                              validationErrors={{
                                 isNumeric: 'invalid value, must contain only alphabets',
                                 required: 'Required!',
                              }}
                           />
                        </FormControl>
                     )}
                  </div>
                  <Link>
                     <button
                        // onClick={setFourthModalOpen}
                        onClick={setFourthModalOpenToTrue}
                        disabled={!accountName}
                        type="submit"
                        // style={{marginLeft: ' 100px'}}
                        className={classNames('newbutton px-3 py-2', {
                           newdisabled: !canSubmit,
                        })}
                        // onClick={setSecModalIsOpenToFalse}>
                     >
                        Submit
                     </button>
                  </Link>
               </Formsy>
            </Modal>
            <Modal
               isOpen={secModalOpen}
               // className="modal2"
               style={customStyles}
               // style={{overlay: {zIndex: 1000}}
               onRequestClose={() => setSecModalOpen(false)}>
               <Verify className="verified" />
               <p className="mb-5">
                  Please wait <br /> While we add card to your account
               </p>
            </Modal>

            <Modal
               isOpen={thirdModalOpen}
               style={customStyles}
               onRequestClose={() => setThirdModalOpen(false)}>
               <Formsy
                  // onValidSubmit={handleSubmit}
                  onValid={() => setCanSubmit(true)}
                  onInvalid={() => setCanSubmit(false)}
                  className="d-flex flex-column align-items-center justify-content-center">
                  <div className="form col-md-7">
                     <h4>Withdraw to Account</h4>
                     <h5>Withdrawals are paid into your bank account</h5>
                     <div id="textExample1" className="form-text mb-2" style={{color: 'black'}}>
                        Account
                     </div>
                     <Select
                        value={accountCode}
                        backspaceDelete
                        options={newMerchantBankDetailsArr}
                        onChange={(values) => setAccountCode(values[0]?._id)}
                     />
                  </div>
                  <Link>
                     <button
                        // to="/verify"
                        disabled={!canSubmit}
                        onClick={setFourthModalOpenToTrue}
                        type="submit"
                        className={classNames('newbutton px-3 py-2 my-3', {
                           newdisabled: !canSubmit,
                        })}
                        // onClick={setSecModalIsOpenToFalse}>
                     >
                        Submit
                     </button>
                  </Link>
               </Formsy>
            </Modal>
            <Modal
               isOpen={eightModalOpen}
               style={customStyles}
               onRequestClose={() => setEightModalOpen(false)}>
               <Formsy
                  // onValidSubmit={handleSubmit}
                  onValid={() => setCanSubmit(true)}
                  onInvalid={() => setCanSubmit(false)}
                  className="d-flex flex-column align-items-center justify-content-center">
                  <div className="form col-md-7">
                     <h4>Withdraw to Account</h4>
                     <h5>Withdrawals are paid into your bank account</h5>
                     <div id="textExample1" className="form-text mb-2" style={{color: 'black'}}>
                        Account
                     </div>
                     <Select
                        value={accountCode}
                        backspaceDelete
                        options={newMerchantBankDetailsArr}
                        onChange={(values) => setAccountCode(values[0]?._id)}
                     />
                  </div>
                  <div className="form col-md-7">
                     <div id="textExample1" className="form-text" style={{color: 'black'}}>
                        Amount
                     </div>
                     <FormControl margin="normal" required fullWidth>
                        <TextInput
                           name="fullname"
                           // wrapperClassName="input-wrapper"
                           // mainWrapperClassName="mb-3 mb-s-4"
                           placeholder="Amount to Withdraw"
                           useFocusedError
                           required
                           value={amount}
                           onChange={(event) => setAmount(event.target.value)}
                           className="input w-100 border-0"
                           validations="isInt"
                           validationErrors={{
                              isInt: 'Invalid value, must contain only numbers!',
                              required: 'Required!',
                           }}
                        />
                     </FormControl>
                  </div>

                  <Link>
                     <button
                        // to="/verify"
                        disabled={!canSubmit}
                        onClick={setFourthModalOpenToTrue}
                        type="submit"
                        className={classNames('newbutton px-3 py-2', {
                           newdisabled: !canSubmit,
                        })}
                        // onClick={setSecModalIsOpenToFalse}>
                     >
                        Submit
                     </button>
                  </Link>
               </Formsy>
            </Modal>
            <Modal
               isOpen={bvnModalOpen}
               style={customStyles}
               onRequestClose={() => setBvnModalOpen(false)}>
               <Formsy
                  onValidSubmit={handleBvnSubmit}
                  onValid={() => setCanSubmit(true)}
                  onInvalid={() => setCanSubmit(false)}>
                  <div className="form col-md-7">
                     <h4>Enter Your BVN</h4>
                  </div>
                  <h6 className="form col-md-7" style={{color: 'red'}}>
                     {bvnError?.[0]?.msg}
                  </h6>
                  <div className="form col-md-7">
                     {/* <div id="textExample1" className="form-text" style={{color: 'black'}}>
                        BVN
                     </div> */}
                     <FormControl margin="normal" required fullWidth>
                        <TextInput
                           name="bvn"
                           // wrapperClassName="input-wrapper"
                           // mainWrapperClassName="mb-3 mb-s-4"
                           placeholder="BVN"
                           useFocusedError
                           required
                           className="input w-100 border-0"
                           validations="isInt"
                           validationErrors={{
                              isInt: 'Invalid value, must contain only numbers!',
                              required: 'Required!',
                           }}
                        />
                     </FormControl>
                  </div>

                  {/* <Link> */}
                  <button
                     // to="/verify"
                     disabled={!canSubmit}
                     onClick={handleBvnSubmit}
                     type="submit"
                     className={classNames('newbtn', {
                        newdisabled: !canSubmit,
                     })}
                     // onClick={setSecModalIsOpenToFalse}>
                  >
                     {IsSavingBvn ? <CircularProgress color="inherit" thickness={1} /> : 'Submit'}
                     {}
                  </button>
                  {/* </Link> */}
               </Formsy>
            </Modal>
            {/* PIN MODAL */}
            <Modal
               isOpen={fourthModalOpen}
               style={customStyles}
               onRequestClose={() => setFourthModalOpen(false)}>
               
               {/* <p></p> */}
               <h6 className="form col-md-7" style={{color: 'red'}}>
                  {settlementErrorMessage?.message}
               </h6>
               <h5 className="form col-md-7" style={{color: 'red'}}>
                  {errorMessage?.message}
               </h5>
               <div className="d-flex flex-column justify-content-center align-items-center">
                     <Lock className="reset-lock" />
                     <p className="mt-5 mb-3">Enter your Buy and Go Pin</p>
               </div>
               <Formsy
                  // onValidSubmit={handleSubmit}
                  onValid={() => setCanSubmit(true)}
                  onInvalid={() => setCanSubmit(false)}
                  className="d-flex flex-column align-items-center justify-content-center">
                  <div className="form-payment d-flex justify-content-center col-12">
                     <FormControl margin="normal" style={{marginRight: '15px'}}>
                        <input
                           name="one"
                           value={one}
                           required
                           useFocusedError
                           placeholder="*"
                           ref={oneRef}
                           onChange={handleOneChange}
                           className="input"
                        />
                     </FormControl>
                     <FormControl margin="normal" style={{marginRight: '15px'}}>
                        <input
                           name="two"
                           value={two}
                           reduired
                           useFocusedError
                           placeholder="*"
                           ref={twoRef}
                           onKeyDown={(e) => onkeypress(e, two, oneRef.current, setOne)}
                           onChange={handleTwoChange}
                           className="input"
                        />
                     </FormControl>
                     <FormControl margin="normal" style={{marginRight: '15px'}}>
                        <input
                           name="three"
                           value={three}
                           required
                           useFocusedError
                           placeholder="*"
                           ref={threeRef}
                           onKeyDown={(e) => onkeypress(e, three, twoRef.current, setTwo)}
                           onChange={handleThreeChange}
                           className="input"
                        />
                     </FormControl>
                     <FormControl margin="normal" style={{marginRight: '15px'}}>
                        <input
                           name="four"
                           value={four}
                           ref={fourRef}
                           required
                           useFocusedError
                           placeholder="*"
                           onKeyDown={(e) => onkeypress(e, four, threeRef.current, setThree)}
                           onChange={handleFourChange}
                           className="input"
                        />
                     </FormControl>
                  </div>
                  {!!codeError && (
                     <span
                        className="d-block  my-0 py-2"
                        style={{color: 'red', marginLeft: '70px'}}>
                        {codeError}
                     </span>
                  )}
                  <Link>
                     <button
                        // to="/verify"
                        disabled={!oneSet || !twoSet || !threeSet || !fourSet}
                        type="submit"
                        onClick={bankCode ? handleSaveBankDetails : handleSettlement}
                        // onClick={setSeventhModalOpenToTrue}
                        className={classNames('newbutton px-3 py-2', {
                           newdisabled: !canSubmit,
                        })}>
                        {' '}
                        {IsSavingBankDetails ||
                        IsMakingFullSettlement ||
                        IsMakingPartialSettlement ? (
                           <CircularProgress color="inherit" thickness={1} />
                        ) : (
                           'Submit'
                        )}
                     </button>
                  </Link>{' '}
                  <p style={{color: '#c4c4c4'}} className="my-3">
                     Can't remember?{' '}
                     <span
                        style={{
                           color: '#3377ef',
                           cursor: 'pointer',
                        }}
                        onClick={() => setSecModalIsOpen(true)}>
                        Reset Pin
                     </span>
                  </p>
               </Formsy>
            </Modal>
            {/* OTP MODAL */}
            <Modal
               isOpen={secModalIsOpen}
               style={customStyles}
               onRequestClose={() => setSecModalIsOpen(false)}>
                  <div className="d-flex flex-column justify-content-center align-items-center">

                     <Lock className="reset-lock" />
                     <p className="mt-3">Enter your Buy and Go Pin</p>
                  </div>
               <Formsy className="d-flex flex-column align-items-center justify-content-center"
               >
                    <div className="reset-form col-md-7 mt-5">
                     <div
                        id="textExample1"
                        className="form-text mb-n4"
                        style={{color: 'black'}}>
                        Password
                     </div>
                     <FormControl margin="normal" required fullWidth>
                        <TextInput
                           name="password"
                           useFocusedError
                           placeholder="********"
                           // value={passwordValue}
                           type={password ? 'text' : 'password'}
                           required
                           className="input w-100 border-0"
                           validationError="Please provide your password!"
                           onChange={(event) => setPasswordValue(event.target.value)}
                           // validationError="Please provide your password!"
                           // onChange={handlePasswordChange}
                           rightIcon={    
                              <i onClick={() => setPassword(!password)}>
                                 {password ? (
                                    <Visibility style={{width: '1rem'}} />
                                 ) : (
                                    <VisibilityOff style={{width: '1rem'}} />
                                 )}
                              </i>
                           }
                        />
                        
                     </FormControl>
                  </div>

                  
                  <div className="payment-formed col-md-12 mt-5 d-flex flex-column justify-content-center align-items-start align-items-md-center">
                        <div id="textExample1" className="formtext ms-3 ms-md-0" style={{color: 'black'}}>
                           New Transaction Pin
                        </div>
                        <div className="d-flex mx-4">

                           <FormControl margin="normal" style={{marginRight: '15px'}}>
                              <input
                                 name="one"
                                 value={one}
                                 required
                                 useFocusedError
                                 placeholder="*"
                                 ref={oneRef}
                                 onChange={handleOneChange}
                                 className="input"
                                 type="password"
                              />
                           </FormControl>
                           <FormControl margin="normal" style={{marginRight: '15px'}}>
                              <input
                                 name="two"
                                 value={two}
                                 reduired
                                 useFocusedError
                                 placeholder="*"
                                 ref={twoRef}
                                 onKeyDown={(e) => onkeypress(e, two, oneRef.current, setOne)}
                                 onChange={handleTwoChange}
                                 className="input"
                                 type="password"
                              />
                           </FormControl>
                           <FormControl margin="normal" style={{marginRight: '15px'}}>
                              <input
                                 name="three"
                                 value={three}
                                 required
                                 useFocusedError
                                 placeholder="*"
                                 ref={threeRef}
                                 onKeyDown={(e) => onkeypress(e, three, twoRef.current, setTwo)}
                                 onChange={handleThreeChange}
                                 className="input"
                                 type="password"
                              />
                           </FormControl>
                           <FormControl margin="normal" style={{marginRight: '15px'}}>
                              <input
                                 name="four"
                                 value={four}
                                 ref={fourRef}
                                 required
                                 useFocusedError
                                 placeholder="*"
                                 onKeyDown={(e) => onkeypress(e, four, threeRef.current, setThree)}
                                 onChange={handleFourChange}
                                 className="input"
                                 type="password"
                              />
                           </FormControl>
                        </div>
                  </div>
                  <div className="payment-formed col-md-12 mt-5 d-flex flex-column justify-content-center align-items-start align-items-md-center">
                     <div id="textExample1" className="formtext ms-3 ms-md-0" style={{color: 'black'}}>
                        Check Transaction Pin
                     </div>
                           <div className="d-flex mx-4">

                     <FormControl margin="normal" style={{marginRight: '15px'}}>
                        <input
                           name="one"
                           value={oneConfirm}
                           required
                           useFocusedError
                           placeholder="*"
                           ref={oneConfirmRef}
                           onChange={handleOneConfirmChange}
                           className="input"
                           type="password"
                        />
                     </FormControl>
                     <FormControl margin="normal" style={{marginRight: '15px'}}>
                        <input
                           name="two"
                           value={twoConfirm}
                           reduired
                           useFocusedError
                           placeholder="*"
                           ref={twoConfirmRef}
                           onKeyDown={(e) =>
                              onkeypress(e, twoConfirm, oneConfirmRef.current, setOneConfirm)
                           }
                           onChange={handleTwoConfirmChange}
                           className="input"
                           type="password"
                        />
                     </FormControl>
                     <FormControl margin="normal" style={{marginRight: '15px'}}>
                        <input
                           name="three"
                           value={threeConfirm}
                           required
                           useFocusedError
                           placeholder="*"
                           ref={threeConfirmRef}
                           onKeyDown={(e) =>
                              onkeypress(e, threeConfirm, twoConfirmRef.current, setTwoConfirm)
                           }
                           onChange={handleThreeConfirmChange}
                           className="input"
                           type="password"
                        />
                     </FormControl>
                     <FormControl margin="normal" style={{marginRight: '15px'}}>
                        <input
                           name="four"
                           value={fourConfirm}
                           ref={fourConfirmRef}
                           required
                           useFocusedError
                           placeholder="*"
                           onKeyDown={(e) =>
                              onkeypress(e, fourConfirm, threeConfirmRef.current, setThreeConfirm)
                           }
                           onChange={handleFourConfirmChange}
                           className="input"
                           type="password"
                        />
                     </FormControl>
                           </div>
                  </div>
                  {!!codeError && (
                     <span
                        className="d-block  my-0 py-2"
                        style={{color: 'red', marginLeft: '70px'}}>
                        {codeError}
                     </span>
                  )}
                  <button
                     // to="/verify"
                     disabled={
                        !oneSet ||
                        !twoSet ||
                        !threeSet ||
                        !fourSet ||
                        !oneConfirmSet ||
                        !twoConfirmSet ||
                        !threeConfirmSet ||
                        !fourConfirmSet
                        // ||
                        // !oneOldSet ||
                        // !twoOldSet ||
                        // !threeOldSet ||
                        // !fourOldSet
                     }
                     onClick={handleSubmit}
                     type="submit"
                     // onClick={setSeventhModalOpenToTrue}
                     className={classNames('my-3 py-2 px-4 resetbtn d-flex justify-content-center', {})}>
                     {IsResettingPin ? (
                        <CircularProgress color="inherit" size="1rem" thickness={2} />
                     ) : (
                        'Submit'
                     )}
                  </button>
               </Formsy>
            </Modal>
            <Modal
               isOpen={seventhModalOpen}
               style={customStyles}
               onRequestClose={() => setSeventhModalOpen(false)}>
               <Lock className="lock" />
               <p style={{marginLeft: '-10px'}}>Enter OTP sent to your phone</p>
               <Formsy
                  // onValidSubmit={handleSubmit}
                  onValid={() => setCanSubmit(true)}
                  onInvalid={() => setCanSubmit(false)}>
                  <div style={{marginRight: '-50px'}} className="forms d-flex">
                     <FormControl margin="normal" style={{marginRight: '15px'}}>
                        <input
                           name="one"
                           value={one}
                           required
                           useFocusedError
                           placeholder="*"
                           ref={oneRef}
                           onChange={handleOneChange}
                           className="value"
                        />
                     </FormControl>
                     <FormControl margin="normal" style={{marginRight: '15px'}}>
                        <input
                           name="two"
                           value={two}
                           reduired
                           useFocusedError
                           placeholder="*"
                           ref={twoRef}
                           onKeyDown={(e) => onkeypress(e, two, oneRef.current, setOne)}
                           onChange={handleTwoChange}
                           className="value"
                        />
                     </FormControl>
                     <FormControl margin="normal" style={{marginRight: '15px'}}>
                        <input
                           name="three"
                           value={three}
                           required
                           useFocusedError
                           placeholder="*"
                           ref={threeRef}
                           onKeyDown={(e) => onkeypress(e, three, twoRef.current, setTwo)}
                           onChange={handleThreeChange}
                           className="value"
                        />
                     </FormControl>
                     <FormControl margin="normal" style={{marginRight: '15px'}}>
                        <input
                           name="four"
                           value={four}
                           ref={fourRef}
                           required
                           useFocusedError
                           placeholder="*"
                           onKeyDown={(e) => onkeypress(e, four, threeRef.current, setThree)}
                           onChange={handleFourChange}
                           className="value"
                        />
                     </FormControl>
                     <FormControl margin="normal" style={{marginRight: '15px'}}>
                        <input
                           name="five"
                           value={five}
                           required
                           useFocusedError
                           placeholder="*"
                           ref={fiveRef}
                           onKeyDown={(e) => onkeypress(e, five, fourRef.current, setFour)}
                           onChange={handleFiveChange}
                           className="value"
                        />
                     </FormControl>
                     <FormControl margin="normal">
                        <input
                           name="six"
                           value={six}
                           required
                           useFocusedError
                           placeholder="*"
                           ref={sixRef}
                           onKeyDown={(e) => onkeypress(e, six, fiveRef.current, setFive)}
                           onChange={handleSixChange}
                           className="value"
                        />
                     </FormControl>
                  </div>
                  {!!codeError && (
                     <span
                        className="d-block  my-0 py-2"
                        style={{color: 'red', marginLeft: '70px'}}>
                        {codeError}
                     </span>
                  )}
                  <Link to="">
                     <button
                        // to="/verify"
                        disabled={
                           !oneSet || !twoSet || !threeSet || !fourSet || !fiveSet || !sixSet
                        }
                        type="submit"
                        // onClick={setSeventhModalOpenToTrue}
                        className={classNames('newbtn', {
                           newdisabled: !canSubmit,
                        })}>
                        {' '}
                        Submit
                     </button>
                  </Link>{' '}
                  <p style={{color: '#c4c4c4', marginBottom: '10px'}}>
                     Can't remember? <a href=" ">Reset Pin</a>
                  </p>
               </Formsy>
            </Modal>
            <Modal
               isOpen={fifthModalOpen}
               style={customStyles}
               onRequestClose={() => setFifthModalOpen(false)}
               id="mode">
               <Formsy className="__form d-flex flex-column align-items-center">
                  <Delete className="mb-5 delete" />
                  <p className="mx-2"> Are you sure you want to delete card</p>
                  <div className="mt-5 mr-5 pt-3 d-flex">
                     <Link onClick={() => setFifthModalOpen(false)}>
                        <div className="mx-5 white">Cancel</div>
                     </Link>
                     <Link onClick={() => handleDelete()}>
                        <div className="blue">Delete</div>
                     </Link>
                  </div>
               </Formsy>
            </Modal>
         </section>
      </>
   );
}

export default Payment;
