import React, {useState} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
// import PrivateRoute from './_components/PrivateRoute';
import {PrivateRoute, SubUserPrivateRoute} from '../../_components/PrivateRoute';
import Snackbar from '@material-ui/core/Snackbar';
import Snackbar2 from '../../_components/Snackbar';
import Existhomepage from './Existinghome';
import Paymentpage from './Payment';
import Historypage from './History';
import Accountpage from './Account';
import CompanyPage from './Company';
import NotFound from '../../404';
import {useLocationCode} from '../../_navigation';
import classNames from 'classnames';
import Sidebar from '../../_navigation/Sidebar';
import Header from '../../_navigation/Header';
import {merchantActions, appActions} from '../../_actions';
//import {MuiThemeProvider} from '@material-ui/core/styles';

// import './App.css';
import './index.scss';
import ModalController from '../../_components/Modal/ModalController';
// import MerchantPage from './Pages/Merchant/index.';

function MerchantPage(props) {
   const [sideBarOpen, setSideBarOpen] = useState(false);
   const openSideBar = () => {
      setSideBarOpen(true);
   };
   const closeSideBar = () => {
      setSideBarOpen(false);
   };

   const code = useLocationCode();
   const dispatch = useDispatch();
   const merchant = useSelector((s) => s.merchant);
   const snackbar = useSelector((s) => s.snackbar);
   const companyData = useSelector((s) => s.merchant.companyData);
   const data = useSelector((s) => s.merchant.data);
   const newUserMerchant = useSelector((s) => s.merchant.newUserMerchant);

   const logout = () => {
      const cashier = data?.role === 3 ? true : false;
      dispatch(merchantActions.logout(cashier));
   };

   const clearSnackBar = () => {
      dispatch(appActions.clearSnackBar());
   };

   return (
      <>
         <ModalController history={props.history} />
         <div className="wrap row m-0">
            {data?.role !== 3 && (
               <Sidebar
                  sideBarOpen={sideBarOpen}
                  closeSideBar={closeSideBar}
                  logoutMerchant={logout}
               />
            )}
            <div className="container-fluid p-0 h-100 col">
               <Header
                  sideBarOpen={sideBarOpen}
                  openSideBar={openSideBar}
                  logoutMerchant={logout}
                  history={props.history}
                  // role={newUserMerchantDetails[0]?.role}
                  isChild={companyData?.is_child || newUserMerchant?.is_child}
               />
               <div
                  className={classNames('switch', {
                     full: code === 6,
                     content: code !== 6,
                  })}>
                  <Switch>
                     <PrivateRoute
                        exact
                        path="/merchant/companies"
                        merchant={merchant?.data}
                        component={CompanyPage}
                     />
                     <PrivateRoute
                        exact
                        path="/merchant/home"
                        merchant={merchant?.data}
                        companyInfo={merchant?.companyInfo}
                        component={Existhomepage}
                     />
                     <PrivateRoute
                        exact
                        path="/merchant/payment"
                        merchant={merchant?.data}
                        companyInfo={merchant?.companyInfo}
                        component={Paymentpage}
                     />
                     <PrivateRoute
                        exact
                        path="/merchant/history"
                        merchant={merchant?.data}
                        companyInfo={merchant?.companyInfo}
                        component={Historypage}
                     />
                     <SubUserPrivateRoute
                        exact
                        path="/merchant/sub-user-history"
                        merchant={merchant?.data}
                        companyInfo={merchant?.companyInfo}
                        component={Historypage}
                     />
                     <PrivateRoute
                        exact
                        path="/merchant/account"
                        merchant={merchant?.data}
                        companyInfo={merchant?.companyInfo}
                        component={Accountpage}
                     />
                     <Route render={(props) => <NotFound {...props} />} />
                  </Switch>
               </div>
            </div>
         </div>
         {snackbar?.message && (
            <Snackbar
               message={snackbar?.message.text}
               autoHideDuration={3000}
               clearSnackBar={clearSnackBar}
            />
         )}
         {snackbar?.message && (
            <Snackbar2
               onClose={clearSnackBar}
               variant={snackbar.message.variant}
               message={<span id="message-id">{snackbar?.message.text}</span>}
            />
         )}
      </>
   );
}

export default withRouter(MerchantPage);
