import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import {Link} from 'react-router-dom';
import BlueYellow from '..//..//../_assets/images/blueandyellow.svg';
import {ReactComponent as Error} from '..//..//..//_assets/icon/error.svg';
import './index.scss';

function Emailverifyfailed() {
   const errorMessage = useSelector((s) => s.merchant.errorMessage);
   // console.log('errorMessage', errorMessage)

   return (
      <section className="emailverifyfailed">
         <img src={BlueYellow} alt="" className="img-fluid image" />
         <MDBContainer className="align-items-center">
            <MDBCol className="smallbox2">
               <Error className="img-fluid tick" />
               <br />
               <br />
               <br />
               <br />
               {/* <h3>Oops!...<br/>
               link expired
                  
                  
                  
                  </h3><br /> */}
               <h3>{errorMessage?.message}</h3>
               <Link to="/phone">
                  <button
                     // to="/verify"
                     // disabled={!canSubmit}
                     type="submit"
                     className="signupbtn">
                    Resend
                  </button>
               </Link>
            </MDBCol>
         </MDBContainer>
      </section>
   );
}
export default Emailverifyfailed;