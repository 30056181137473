import {createStore, applyMiddleware} from 'redux';
import rootReducer from '../_redux/reducers';
import createSagaMiddleware from 'redux-saga';
import {composeWithDevTools} from 'redux-devtools-extension';
import rootSaga from '../_redux/sagas';
import {merchantConstants} from '../_constants';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

store.dispatch({type: merchantConstants.GET_MERCHANT});
store.dispatch({type: merchantConstants.GET_MERCHANT_COMPANY});
store.dispatch({type: merchantConstants.GET_MERCHANT_SUB_USER});

export default store;
