import {appConstants} from './app.constant';

const transactions = 'account/transaction_history/merchant';
const subUserTransactions = 'sub-user/transactions';
// const settlements = 'account/settlements/all';

export const transactionConstants = {
   GET_ALL_TRANSACTIONS: 'GET_ALL_TRANSACTIONS',
   GET_ALL_TRANSACTIONS_AS_A_SUB_USER: 'GET_ALL_TRANSACTIONS_AS_A_SUB_USER',
   FILTER_TRANSACTIONS: 'FILTER_TRANSACTIONS',
   GET_ALL_WITHDRAWALS: 'GET_ALL_WITHDRAWALS',
   FILTER_WITHDRAWALS: 'FILTER_WITHDRAWALS',

   TRANSACTIONS: 'transactions',

   REQUEST_ALL_TRANSACTIONS: 'REQUEST_ALL_TRANSACTIONS',
   REQUEST_ALL_TRANSACTIONS_ERROR: 'REQUEST_ALL_TRANSACTIONS_ERROR',
   REQUEST_ALL_TRANSACTIONS_SUCCESS: 'REQUEST_ALL_TRANSACTIONS_SUCCESS',
   REQUEST_ALL_TRANSACTIONS_SUCCESS_WITHOUT_DATA: 'REQUEST_ALL_TRANSACTIONS_SUCCESS_WITHOUT_DATA',

   REQUEST_ALL_TRANSACTIONS_AS_A_SUB_USER: 'REQUEST_ALL_TRANSACTIONS_AS_A_SUB_USER',
   REQUEST_ALL_TRANSACTIONS_AS_A_SUB_USER_ERROR: 'REQUEST_ALL_TRANSACTIONS_AS_A_SUB_USER_ERROR',
   REQUEST_ALL_TRANSACTIONS_AS_A_SUB_USER_SUCCESS: 'REQUEST_ALL_TRANSACTIONS_AS_A_SUB_USER_SUCCESS',

   REQUEST_ALL_WITHDRAWALS: 'REQUEST_ALL_WITHDRAWALS',
   REQUEST_ALL_WITHDRAWALS_ERROR: 'REQUEST_ALL_WITHDRAWALS_ERROR',
   REQUEST_ALL_WITHDRAWALS_SUCCESS: 'REQUEST_ALL_WITHDRAWALS_SUCCESS',
   REQUEST_ALL_WITHDRAWALS_SUCCESS_WITHOUT_DATA: 'REQUEST_ALL_WITHDRAWALS_SUCCESS_WITHOUT_DATA',

   REQUEST_FILTER_TRANSACTIONS: 'REQUEST_FILTER_TRANSACTIONS',
   FILTER_TRANSACTIONS_SUCCESS: 'FILTER_TRANSACTIONS_SUCCESS',
   FILTER_TRANSACTIONS_ERROR: 'FILTER_TRANSACTIONS_ERROR',
   FILTER_TRANSACTIONS_SUCCESS_WITHOUT_DATA: 'FILTER_TRANSACTIONS_SUCCESS_WITHOUT_DATA',

   REQUEST_FILTER_WITHDRAWALS: 'REQUEST_FILTER_WITHDRAWALS',
   FILTER_WITHDRAWALS_SUCCESS: 'FILTER_WITHDRAWALS_SUCCESS',
   FILTER_WITHDRAWALS_ERROR: 'FILTER_WITHDRAWALS_ERROR',
   FILTER_WITHDRAWALS_SUCCESS_WITHOUT_DATA: 'FILTER_WITHDRAWALS_SUCCESS_WITHOUT_DATA',

   TRANSACTION_KEY: 'transaction',
   ALL_TRANSACTIONS_URI: `${appConstants.BASE_URI}${transactions}/`,
   ALL_TRANSACTIONS_AS_A_SUB_USER_URI: `${appConstants.BASE_URI}${subUserTransactions}/`,
};
