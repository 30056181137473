import React, {useState, useEffect} from 'react';
import {MDBContainer, MDBCol} from 'mdb-react-ui-kit';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import TextInput from '..//..//..//_components/TextInput';
// import classNames from 'classnames';
import BlueYellow from '..//..//../_assets/images/blueandyellow.svg';
// import Customerone from '../../_assets/images/customerone.png';
import Formsy from 'formsy-react';
// import Customertwo from '../../_assets/images/customertwo.png';
// import Customerthree from '../../_assets/images/customerthree.png';
// import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import './index.scss';
// import {FormatColorTextSharp} from '@material-ui/icons';

// const useStyles = makeStyles((theme) => ({
//    margin: {
//       margin: theme.spacing(1),
//    },
//    textField: {
//       width: '45ch',
//       height: '36px',
//    },
//    root: {
//       width: '100%',
//       marginRight: '230p',
//       height: '36px',
//    },
//    // padding: {
//    //    padding: '10px 2px',
//    //    height: '15px',
//    //    width: '100%',
//    //    marginLeft: '-10px',
//    //    borderColor: 'red',
//    // },
//    input: {
//       padding: '10px 2px',
//       height: '15px',
//       width: '100%',
//       border: '1px solid white',
//       '&$focused $hover $notchedOutline': {
//          borderColor: 'white',
//          borderWidth: 1,
//       },
//    },
// }));

function PhonePageAgain() {
   // const classes = useStyles();
   const [canSubmit, setCanSubmit] = useState(false);
   // const [passwordShown, setPasswordShown] = useState(false);
   // const [passwordShow, setPasswordShow] = useState(false);
   // const togglePasswordVisiblity = () => {
   //    setPasswordShown(passwordShown ? false : true);
   // };
   // const togglePasswordVisible = () => {
   //    setPasswordShow(passwordShow ? false : true);
   // };
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   const handleSubmit = (data) => {
      console.log(data);
   };

   return (
      <>
         <section className="phoneagain">
            <img src={BlueYellow} alt="" className="img-fluid image" />
            <MDBContainer className="align-items-center">
               <div className="bigboxes">
                  <MDBCol size="5" className="text">
                     <h3>
                        Please
                        <br /> Re-Enter Phone Number
                     </h3>
                     <p>
                        Already have an account?<Link to="/">Login</Link>
                     </p>
                  </MDBCol>
                  <MDBCol className="smallbox">
                     <MDBCol className="smallbox2">
                        <Formsy
                           onValidSubmit={handleSubmit}
                           onValid={() => setCanSubmit(true)}
                           onInvalid={() => setCanSubmit(false)}>
                           <div className="forms col-md-10">
                              <div id="textExample1" className="form-text" style={{color: 'black'}}>
                                 Phone Number
                              </div>
                              <FormControl margin="normal" required fullWidth>
                                 <TextInput
                                    name="fullname"
                                    wrapperClassName="input-wrapper"
                                    mainWrapperClassName="w-100 mb-3 mb-s-4"
                                    placeholder="07036334745"
                                    useFocusedError
                                    className="input border-0 w-100"
                                    validations="isInt"
                                    validationErrors={{
                                       isInt: 'Invalid value, must contain only numbers!',
                                       required: 'Required!',
                                    }}
                                 />
                              </FormControl>
                           </div>

                           <Link to="/">
                              <button
                                 // to="/verify"
                                 disabled={!canSubmit}
                                 type="submit"
                                 className="signupbtn">
                                 Submit
                              </button>
                           </Link>
                        </Formsy>
                     </MDBCol>
                  </MDBCol>
               </div>
            </MDBContainer>
         </section>
      </>
   );
}

export default PhonePageAgain;
