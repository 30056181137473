import React, {useCallback, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import useOnClickOutside from './clickOutside';
// import {MDBInputGroup, MDBInputGroupElement, MDBInputGroupText} from 'mdb-react-ui-kit';

// import {ReactComponent as Contact} from '../../_assets/icon/contact.svg';
// import {ReactComponent as Dots} from '../../_assets/icon/buygo-dots.svg';
// import {ReactComponent as SearchIcon} from '../../_assets/icon/1ssearch.svg';
// import {NavLink} from 'react-router-dom';
// import Modal from 'react-modal';
// import {ReactComponent as NotificationIcon} from '../../_assets/icon/bell.svg';
// import {ReactComponent as AdminIcon} from '../../_assets/icon/adrian.svg';
import {ReactComponent as WavingIcon} from '../../_assets/icon/wavinghand.svg';
import {ReactComponent as Multiple} from '../../_assets/icon/multiple-users.svg';
import {useLocationCode} from '../../_navigation';
import {useLocation} from 'react-router-dom';
import {capitalizeFirstLetter} from '../../_helpers';
// import useOnClickOutside from '../OnClickOutside';
import CompanyTab from './companyTab';
import './index.scss';
import {Avatar} from '@material-ui/core';

function Header({sideBarOpen, isChild, openSideBar, history, logoutMerchant, location}) {
   const code = useLocationCode();
   const {pathname} = useLocation();
   const ref = useRef();
   const {info} = useSelector((s) => s.merchant);
   const companyInfo = useSelector((s) => s.merchant.companyInfo);
   const data = useSelector((s) => s.merchant.data);

   const [modalOpen, setModalOpen] = useState(false);
   console.log('?.merchant_name', companyInfo);
   console.log('?.data', data);

   const [open, setOpen] = useState(false);
   const toggleOpen = () => setOpen((p) => !p);

   const setModalOpenToTrue = () => {
      setModalOpen(true);
   };

   const logout = () => {
      console.log('GOTHEREEEE');
      setOpen((p) => !p);
      history.push(data?.role === 3 ? '/submerchantlogin' : '/login');
      logoutMerchant();
   };

   const goToSettings = () => {
      setOpen((p) => !p);
      history.push('/merchant/account');
   };

   const pageName = useCallback(() => {
      switch (code) {
         case 1:
            return 'Welcome,';
         case 2:
            return 'Payment';
         case 3:
            return 'History';
         case 4:
            return 'Account';
         case 6:
            return 'History';
         case 5:
            return 'Welcome,';
         default:
            return null;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [code, pathname])();

   const numb = useCallback(() => {
      switch (code) {
         case 1:
            return `Welcome, ${companyInfo?.merchant_name || data?.firstname}`;
         case 5:
            return `Welcome, ${companyInfo?.merchant_name || data?.firstname}`;
         default:
            return null;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [code])();

   useOnClickOutside(ref, () => setOpen(false));
   return code !== 7 ? (
      <>
         <header ref={ref} className="d-flex my-2 my-md-0 w-100 justify-content-between">
            <div className="nav_icon" onClick={() => openSideBar()}>
               <i className="mt-3 pt-md-2 ms-2 fa fa-bars"></i>
            </div>
            <div className="d-flex w-100">
               <div className="d-none d-sm-flex ms-0 ms-md-4 ps-1 ps-md-4 mt-3 pt-1 mb-4 mb-md-0 h-100 text-truncate companyname">
                  {history?.location.pathname &&
                     capitalizeFirstLetter(
                        `Welcome, ${companyInfo?.merchant_name || data?.firstname}`,
                     )}
                  {history?.location.pathname && (
                     <WavingIcon
                        className="p-1 p-md-0 mt-n2 mt-md-0 d-none d-sm-flex svg"
                        id="notice"
                     />
                  )}
               </div>
               <div className="d-flex align-items-center mt-n4 ms-auto div-header me-3">
                  {data?.role !== 3 && (
                     <div
                        onClick={setModalOpenToTrue}
                        className="d-flex align-items-center mt-4 px-2 multiple">
                        <Multiple className="p-2 p-md-2" onClick={setModalOpenToTrue} />
                        <span className="me-1 small">Companies</span>
                     </div>
                  )}
                  <div onClick={toggleOpen} className="mt-4 profile">
                     {info?.logo_url ? (
                        <Avatar className="v-svg" src={info?.logo_url} alt="" />
                     ) : (
                        <Avatar className="v-svg" src={info?.logo_url} alt="" />
                     )}
                  </div>
               </div>
            </div>
            {open && (
               <div className="profile-settings position-absolute">
                  <ul>
                     {data?.role !== 3 && (
                        <li className="d-block" onClick={goToSettings}>
                           Account
                        </li>
                     )}
                     <li className="d-block" onClick={logout}>
                        Logout
                     </li>
                  </ul>
               </div>
            )}
         </header>
         {data?.role !== 3 && (
            <CompanyTab
               modalOpen={modalOpen}
               setModalOpen={() => setModalOpen(false)}
               location={location}
               history={history}
               companyInfo={companyInfo}
               data={data}
            />
         )}
      </>
   ) : null;
}

export default Header;
