import {appConstants, customerConstants, merchantConstants} from '../../_constants';
import {combineReducers} from 'redux';

const IsRequestingLogin = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.LOGIN_REQUEST:
         return true;
      case merchantConstants.LOGIN_FAILURE:
         return false;
      case merchantConstants.LOGIN_SUCCESS:
         return false;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_REQUEST:
         return true;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_FAILURE:
         return false;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_SUCCESS:
         return false;

      default:
         return state;
   }
};

const IsRequestingLogout = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.LOGOUT_REQUEST:
         return true;
      case merchantConstants.LOGOUT_FAILURE:
         return false;
      case merchantConstants.LOGOUT_SUCCESS:
         return false;
      default:
         return state;
   }
};

const error = (state = false, {type}) => {
   switch (type) {
      case merchantConstants.LOGIN_REQUEST:
         return false;
      case merchantConstants.LOGIN_FAILURE:
         return true;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_REQUEST:
         return false;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_FAILURE:
         return true;
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case merchantConstants.LOGIN_FAILURE:
         if (error) return error;
         return state;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_FAILURE:
         if (error) return error;
         return state;
      case merchantConstants.LOGIN_REQUEST:
         return null;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_REQUEST:
         return null;
      case merchantConstants.VERIFY_EMAIL_FAILURE:
         if (error) return error;
         return state;
      case merchantConstants.VERIFYING_EMAIL:
         return null;
      case merchantConstants.SENDING_OTP:
         return null;
      case merchantConstants.SEND_OTP_FAILURE:
         if (error) return error;
         return state;
      case merchantConstants.VERIFY_PHONE_FAILURE:
         if (error) return error;
         return state;
      case appConstants.CLEAR_MESSAGES:
         return null;
      default:
         return state;
   }
};

const unverifiedEmail = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.LOGIN_REQUEST:
         return false;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_REQUEST:
         return false;
      case merchantConstants.LOGIN_FAILURE:
         if (action.unverifiedEmail) return true;
         return state;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_FAILURE:
         if (action.unverifiedEmail) return true;
         return state;
      case merchantConstants.LOGIN_SUCCESS:
         return false;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_SUCCESS:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};
const verifyEmailSuccess = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.VERIFYING_EMAIL:
         return false;
      case merchantConstants.VERIFY_EMAIL_FAILURE:
         return false;
      case merchantConstants.VERIFY_EMAIL_SUCCESS:
         return true;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const IsResendingEmailLink = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.RESENDING_EMAIL_LINK:
         return true;
      case merchantConstants.RESEND_EMAIL_LINK_FAILURE:
         return false;
      case merchantConstants.RESEND_EMAIL_LINK_SUCCESS:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const resendEmailLinkSuccess = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.RESENDING_EMAIL_LINK:
         return false;
      case merchantConstants.RESEND_EMAIL_LINK_FAILURE:
         return false;
      case merchantConstants.RESEND_EMAIL_LINK_SUCCESS:
         return true;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const sendOtpSuccess = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.WRONG_NUMBER:
         return false;
      case merchantConstants.SENDING_OTP:
         return false;
      case merchantConstants.SEND_OTP_FAILURE:
         return false;
      case merchantConstants.SEND_OTP_SUCCESS:
         return true;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const sendingOtp = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.SENDING_OTP:
         return true;
      case merchantConstants.SEND_OTP_FAILURE:
         return false;
      case merchantConstants.SEND_OTP_SUCCESS:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const IsResendingOtp = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.RESENDING_OTP:
         return true;
      case merchantConstants.RESEND_OTP_FAILURE:
         return false;
      case merchantConstants.RESEND_OTP_SUCCESS:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const resendOtpSuccess = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.RESENDING_OTP:
         return false;
      case merchantConstants.RESEND_OTP_FAILURE:
         return false;
      case merchantConstants.RESEND_OTP_SUCCESS:
         return true;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const verifyPhoneSuccess = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.VERIFYING_PHONE:
         return false;
      case merchantConstants.VERIFY_PHONE_FAILURE:
         return false;
      case merchantConstants.VERIFY_PHONE_SUCCESS:
         return true;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const verifyEmailFailure = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.VERIFYING_EMAIL:
         return false;
      case merchantConstants.VERIFY_EMAIL_FAILURE:
         return true;
      case merchantConstants.VERIFY_EMAIL_SUCCESS:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const unverifiedPhone = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.LOGIN_REQUEST:
         return false;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_REQUEST:
         return false;
      case merchantConstants.LOGIN_FAILURE:
         if (action.unverifiedPhone) return true;
         return state;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_FAILURE:
         if (action.unverifiedPhone) return true;
         return state;
      case merchantConstants.LOGIN_SUCCESS:
         return false;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_SUCCESS:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const unsetTransactionPin = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.LOGIN_REQUEST:
         return false;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_REQUEST:
         return false;
      case merchantConstants.LOGIN_FAILURE:
         if (action.unsetTransactionPin) return true;
         return state;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_FAILURE:
         if (action.unsetTransactionPin) return true;
         return state;
      case merchantConstants.LOGIN_SUCCESS:
         return false;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_SUCCESS:
         return false;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const createPinSuccess = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.CREATING_PIN:
         return false;
      case merchantConstants.CREATE_PIN_FAILURE:
         return false;
      case merchantConstants.CREATE_PIN_SUCCESS:
         return true;
      case appConstants.CLEAR_MESSAGES:
         return false;
      default:
         return state;
   }
};

const IsCreatingPin = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.CREATING_PIN:
         return true;
      case merchantConstants.CREATE_PIN_FAILURE:
         return false;
      case merchantConstants.CREATE_PIN_SUCCESS:
         return false;
      default:
         return state;
   }
};

const unverifiedUserId = (state = null, action) => {
   switch (action.type) {
      case merchantConstants.LOGIN_SUCCESS:
         return null;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_SUCCESS:
         return null;
      case merchantConstants.REGISTER_MERCHANT_SUCCESS:
         return action.id;
      case customerConstants.REGISTER_CUSTOMER_SUCCESS:
         return action.id;
      case merchantConstants.VERIFY_EMAIL_SUCCESS:
         return action.id;
      case merchantConstants.VERIFY_EMAIL_FAILURE:
         return null;
      case merchantConstants.REGISTER_MERCHANT_ERROR:
         return null;
      case merchantConstants.CREATE_PIN_SUCCESS:
         return null;
      case merchantConstants.LOGIN_FAILURE:
         if (action.unverifiedPhone || action.unverifiedEmail || action.unsetTransactionPin)
            return action.id;
      // eslint-disable-next-line no-fallthrough
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_FAILURE:
         if (action.unverifiedPhone || action.unverifiedEmail || action.unsetTransactionPin)
            return action.id;
      // eslint-disable-next-line no-fallthrough
      default:
         return state;
   }
};

const phoneToVerify = (state = null, action) => {
   switch (action.type) {
      case merchantConstants.SEND_OTP:
         return action.data.phone_number;
      case merchantConstants.VERIFY_PHONE_SUCCESS:
         return null;
      default:
         return state;
   }
};
const emailToVerify = (state = null, action) => {
   switch (action.type) {
      case merchantConstants.VERIFY_EMAIL:
         return action.data.email;
      case merchantConstants.VERIFY_EMAIL_SUCCESS:
         return null;
      default:
         return state;
   }
};

const IsVerifyingPhone = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.VERIFYING_PHONE:
         return true;
      case merchantConstants.VERIFY_PHONE_FAILURE:
         return false;
      case merchantConstants.VERIFY_PHONE_SUCCESS:
         return false;
      default:
         return state;
   }
};

const phoneVerificationPinId = (state = null, action) => {
   switch (action.type) {
      case merchantConstants.SEND_OTP_SUCCESS:
         return action.id;
      case merchantConstants.SENDING_OTP:
         return null;
      case merchantConstants.SEND_OTP_FAILURE:
         return null;
      default:
         return state;
   }
};

const IsSendingVerificationEmail = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.SENDING_VERIFICATION_EMAIL:
         return true;
      case merchantConstants.SEND_VERIFICATION_EMAIL_FAILURE:
         return false;
      case merchantConstants.SEND_VERIFICATION_EMAIL_SUCCESS:
         return false;
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingLogin,
   IsRequestingLogout,
   IsSendingVerificationEmail,
   error,
   unverifiedUserId,
   phoneToVerify,
   errorMessage,
   unsetTransactionPin,
   unverifiedPhone,
   createPinSuccess,
   IsCreatingPin,
   phoneVerificationPinId,
   sendingOtp,
   IsResendingOtp,
   resendOtpSuccess,
   unverifiedEmail,
   verifyPhoneSuccess,
   sendOtpSuccess,
   verifyEmailSuccess,
   IsResendingEmailLink,
   resendEmailLinkSuccess,
   verifyEmailFailure,
   IsVerifyingPhone,
   emailToVerify,
});
export default rootReducer;
