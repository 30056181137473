import {merchantConstants} from '../_constants';

const login = (data) => ({
   type: merchantConstants.LOGIN,
   data,
});

const merchantSubUserLogin = (data) => ({
   type: merchantConstants.MERCHANT_SUB_USER_LOGIN,
   data,
});

const logout = (data) => ({
   type: merchantConstants.LOGOUT,
   data,
});

const sendVerificationEmail = (data) => ({
   type: merchantConstants.SEND_VERIFICATION_EMAIL,
   data,
});

const verifyEmail = (data) => ({
   type: merchantConstants.VERIFY_EMAIL,
   data,
});

const resendEmailLink = (data) => ({
   type: merchantConstants.RESEND_EMAIL_LINK,
   data,
});

const sendOtp = (data) => ({
   type: merchantConstants.SEND_OTP,
   data,
});

const reSendOtp = (data) => ({
   type: merchantConstants.RESEND_OTP,
   data,
});

const verifyPhone = (data) => ({
   type: merchantConstants.VERIFY_PHONE,
   data,
});

const wrongNumber = () => ({
   type: merchantConstants.WRONG_NUMBER,
});

const createPin = (data) => ({
   type: merchantConstants.CREATE_PIN,
   data,
});

const createMerchant = (data) => ({
   type: merchantConstants.REGISTER_MERCHANT,
   data,
});

const createMerchantCompany = (data) => ({
   type: merchantConstants.CREATE_MERCHANT_COMPANY,
   data,
});

const registerMerchantSubUser = (data) => ({
   type: merchantConstants.REGISTER_MERCHANT_SUB_USER,
   data,
});

const addSubUserToMerchant = (data) => ({
   type: merchantConstants.ADD_SUB_USER_TO_MERCHANT,
   data,
});

const addNOK = (data) => ({
   type: merchantConstants.ADD_NEXT_OF_KIN,
   data,
});

const changePassword = (data) => ({
   type: merchantConstants.CHANGE_PASSWORD,
   data,
});

const changePin = (data) => ({
   type: merchantConstants.CHANGE_PIN,
   data,
});

const resetPin = (data) => ({
   type: merchantConstants.RESET_PIN,
   data,
});

const getDashBoard = (data) => ({
   type: merchantConstants.GET_DASHBOARD,
   data,
});

const updateMerchantInfo = (data) => ({
   type: merchantConstants.UPDATE_MERCHANT_INFO,
   data,
});

const getMerchantCompanyInformation = (data) => ({
   type: merchantConstants.GET_MERCHANT_COMPANY_INFORMATION,
   data,
});

const getMerchantUsers = (data) => ({
   type: merchantConstants.GET_MERCHANT_USERS,
   data,
});

const createMerchantUser = (data) => ({
   type: merchantConstants.CREATE_MERCHANT_USER,
   data,
});

const createMerchantSubUser = (data) => ({
   type: merchantConstants.CREATE_MERCHANT_SUB_USER,
   data,
});

const deleteMerchantUser = (_id) => ({
   type: merchantConstants.DELETE_MERCHANT_USER,
   _id,
});

export const merchantActions = {
   login,
   merchantSubUserLogin,
   logout,
   createMerchant,
   createMerchantCompany,
   registerMerchantSubUser,
   addSubUserToMerchant,
   updateMerchantInfo,
   sendVerificationEmail,
   verifyPhone,
   verifyEmail,
   wrongNumber,
   changePassword,
   createPin,
   sendOtp,
   reSendOtp,
   resendEmailLink,
   addNOK,
   changePin,
   resetPin,
   getDashBoard,
   getMerchantCompanyInformation,
   getMerchantUsers,
   createMerchantUser,
   createMerchantSubUser,
   deleteMerchantUser,
};
