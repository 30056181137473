import {call, put, takeLatest, all} from 'redux-saga/effects';
import {bankConstants, appConstants, merchantConstants} from '../../_constants';
import {appActions} from '../../_actions';
import {
   createRequest,
   checkStatus,
   getObjectFromStorage,
   setObjectInStorage,
   createRequestWithToken,
} from '../../_helpers';

function* getBanks() {
   yield put({type: bankConstants.REQUEST_BANKS});

   try {
      // const user = yield call(getObjectFromStorage, userConstants.USER_KEY);
      let banksUri = `${appConstants.BANK_URL}`;

      const curriedReq = yield call(createRequest, banksUri, {
         method: 'GET',
      });

      const response = yield call(fetch, curriedReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: bankConstants.REQUEST_BANKS_SUCCESS,
         banks: jsonResponse,
      });
   } catch (error) {
      yield put({type: bankConstants.REQUEST_BANKS_ERROR});
   }
}

function* getAccountName({data}) {
   yield put({type: bankConstants.REQUEST_ACCOUNT_NAME});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);

      let getAccountNameUri = `${bankConstants.GET_ACCOUNT_NAME_URI}`;

      const req = createRequestWithToken(getAccountNameUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(merchant?.token);
      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      if (response.status === 204) {
         yield put({
            type: bankConstants.REQUEST_ACCOUNT_NAME_SUCCESS_WITHOUT_DATA,
         });

         return;
      }

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: bankConstants.REQUEST_ACCOUNT_NAME_SUCCESS,
         accountName: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: bankConstants.REQUEST_ACCOUNT_NAME_ERROR,
         error: errorMessage,
      });
   }
}

function* saveBankDetails({data}) {
   yield put({type: bankConstants.REQUEST_SAVE_BANK_DETAILS});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      const merchantCompany = yield call(
         getObjectFromStorage,
         merchantConstants.MERCHANT_COMPANY_KEY,
      );
      let saveBankDetailsUri = `${bankConstants.SAVE_BANK_DETAILS_URI(
         merchantCompany?.details?._id,
      )}`;

      const req = createRequestWithToken(saveBankDetailsUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(merchant?.token);
      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: bankConstants.REQUEST_SAVE_BANK_DETAILS_SUCCESS,
         bankDetails: jsonResponse,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Bank details saved successfully',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: bankConstants.REQUEST_SAVE_BANK_DETAILS_ERROR,
         error: errorMessage,
      });
   }
}

function* saveBvn({data}) {
   yield put({type: bankConstants.REQUEST_SAVE_BVN});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      // const merchantCompany = yield call(
      //    getObjectFromStorage,
      //    merchantConstants.MERCHANT_COMPANY_KEY,
      // );
      let saveBvnUri = `${bankConstants.SAVE_BVN_URI(merchant?.user?._id)}`;

      const req = createRequestWithToken(saveBvnUri, {
         method: 'PATCH',
         body: JSON.stringify(data),
      })(merchant?.token);
      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      const jsonResponse = yield call(response.json.bind(response));
      const user = {
         ...merchant.user,
         bvn: jsonResponse,
      };
      yield call(setObjectInStorage, merchantConstants.MERCHANT_KEY, {
         token: merchant.token,
         user: user,
         is_child: merchant.is_child,
         userMerchants: merchant.userMerchants,
      });
      yield put({
         type: bankConstants.REQUEST_SAVE_BVN_SUCCESS,
         bvn: jsonResponse,
      });
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));
      yield put({
         type: bankConstants.REQUEST_SAVE_BVN_ERROR,
         error: errorMessage,
      });
   }
}

// function* deleteBankDetails({_id}) {
//    yield put({type: bankConstants.REQUEST_DELETE_BANK_DETAILS});

//    try {
//       const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);

//       const url = `${merchantConstants.GET_MERCHANT_USERS_URI(_id)}`;

//       const req = createRequestWithToken(url, {
//          method: 'DELETE',
//          body: JSON.stringify({_id: _id}),
//       })(merchant?.token);

//       const response = yield call(fetch, req);
//       yield call(checkStatus, response);

//       yield put({type: bankConstants.REQUEST_DELETE_BANK_DETAILS_SUCCESS, _id});

//       yield put(
//          appActions.setSnackBar({
//             message: ' user successfully deleted',
//             variant: 'success',
//          }),
//       );
//    } catch (error) {
//       const errorMessage = yield call(error.response.json.bind(error.response));

//       yield put({
//          type: bankConstants.REQUEST_DELETE_BANK_DETAILS_ERROR,
//          error: errorMessage,
//       });
//       yield put(
//          appActions.setSnackBar({
//             message: 'Something went wrong',
//             variant: 'error',
//          }),
//       );
//    }
// }

function* getMerchantBankDetails() {
   yield put({type: bankConstants.REQUEST_MERCHANT_BANK_DETAILS});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      const merchantCompany = yield call(
         getObjectFromStorage,
         merchantConstants.MERCHANT_COMPANY_KEY,
      );
      let merchantBankDetailsUri = `${bankConstants.GET_MERCHANT_BANK_DETAILS_URI(
         merchantCompany?.details?._id,
      )}`;

      const merchantBankDetailsReq = createRequestWithToken(merchantBankDetailsUri, {
         method: 'GET',
      })(merchant?.token);

      const response = yield call(fetch, merchantBankDetailsReq);
      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      yield put({
         type: bankConstants.REQUEST_MERCHANT_BANK_DETAILS_SUCCESS,
         merchantBankDetails: jsonResponse,
      });
   } catch (error) {
      yield put({type: bankConstants.REQUEST_MERCHANT_BANK_DETAILS_ERROR});
   }
}

function* deleteMerchantBankDetails({_id}) {
   try {
      yield put({type: bankConstants.REQUEST_DELETE_BANK_DETAILS});
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);

      const url = `${bankConstants.DELETE_BANK_DETAILS_URI(_id)}`;

      const req = createRequestWithToken(url, {
         method: 'DELETE',
         body: JSON.stringify({_id: _id}),
      })(merchant?.token);

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      yield put({type: bankConstants.REQUEST_DELETE_BANK_DETAILS_SUCCESS, _id});

      yield put(
         appActions.setSnackBar({
            message: ' bank details successfully deleted',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: bankConstants.REQUEST_DELETE_BANK_DETAILS_ERROR,
         error: errorMessage,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Something went wrong',
            variant: 'error',
         }),
      );
   }
}

function* getBanksSagaWatcher() {
   yield takeLatest(bankConstants.GET_BANKS, getBanks);
}

function* getAccountNameSagaWatcher() {
   yield takeLatest(bankConstants.GET_ACCOUNT_NAME, getAccountName);
}

function* saveBankDetailsSagaWatcher() {
   yield takeLatest(bankConstants.SAVE_BANK_DETAILS, saveBankDetails);
}

function* saveBvnSagaWatcher() {
   yield takeLatest(bankConstants.SAVE_BVN, saveBvn);
}

// function* deleteBankDetailsSagaWatcher() {
//    yield takeLatest(bankConstants.DELETE_BANK_DETAILS, deleteBankDetails);
// }

function* getMerchantBankDetailsSagaWatcher() {
   yield takeLatest(bankConstants.GET_MERCHANT_BANK_DETAILS, getMerchantBankDetails);
}

function* deleteMerchantBankDetailsSagaWatcher() {
   yield takeLatest(bankConstants.DELETE_BANK_DETAILS, deleteMerchantBankDetails);
}
export default function* rootSaga() {
   yield all([
      getBanksSagaWatcher(),
      getAccountNameSagaWatcher(),
      saveBankDetailsSagaWatcher(),
      saveBvnSagaWatcher(),
      // deleteBankDetailsSagaWatcher(),
      getMerchantBankDetailsSagaWatcher(),
      deleteMerchantBankDetailsSagaWatcher(),
   ]);
}
