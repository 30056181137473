import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {MDBRow} from 'mdb-react-ui-kit';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import './index.scss';
import Loading from '../Loading/loading';
import Transactions from './transactions';
import Withdrawals from './withdrawals';
// import {ReactComponent as Vector} from '../../../_assets/icon/Vector.svg';
// import {ReactComponent as Prev} from '../../../_assets/icon/1arr-left.svg';
// import {ReactComponent as Next} from '../../../_assets/icon/1arr-right.svg';

// const options = [
//    {label: 'sort by', value: 0},
//    {label: 'January, 2021', value: 1},
//    {label: 'February, 2021', value: 2},
//    {label: 'March, 2021', value: 3},
//    {label: 'April, 2021', value: 4},
//    {label: 'May, 2021', value: 5},
//    {label: 'June, 2021', value: 6},
//    {label: 'July, 2021', value: 7},
//    {label: 'August, 2021', value: 8},
//    {label: 'September, 2021', value: 9},
//    {label: 'October, 2021', value: 10},
//    {label: 'November, 2021', value: 11},
//    {label: 'December, 2021', value: 12},
// ];

function History({merchant}) {
   const companyData = useSelector((s) => s.merchant.companyData);
   const IsRequestingCompanyInfo = useSelector((s) => s.merchant.IsRequestingCompanyInfo);
   const role = useSelector((s) => s.merchant.role);
   const companyInfo = useSelector((s) => s.merchant.companyInfo);
   // const [selectOpen, setSelectOpen] = useState(false);
   const [shown, setShown] = useState(1);
   const [loader, setLoader] = useState(false);

   useEffect(() => {
      if (IsRequestingCompanyInfo) {
         setLoader(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [IsRequestingCompanyInfo]);

   return (
      <MDBRow className="history-page m-0 w-100 mb-5">
         {loader === true && <Loading />}
         <div className="history" style={{marginLeft: '38px'}}>
            <h4>
               MERCHANT ID:{' '}
               {companyInfo?.merchant_id ||
                  companyInfo?.merchant?.merchant_id ||
                  'Account has not been verified by Admin'}
            </h4>
         </div>
         <div className="history w-100 d-flex justify-content-between">
            <ul className="d-block d-sm-flex">
               <li>
                  <div
                     onClick={() => setShown(1)}
                     className={classNames('w-100 d-block', {
                        active: shown === 1,
                     })}>
                     Transaction History
                  </div>
               </li>
               {/* companyData?.is_child === false */}
               {companyData?.is_child === false && role?.role !== 2 && (
                  <li>
                     <div
                        onClick={() => setShown(2)}
                        className={classNames('w-100 d-block', {
                           active: shown === 2,
                        })}>
                        Withdrawal History
                     </div>
                  </li>
               )}
            </ul>
            {/* <div className="" style={{marginTop: '-12px'}}>
            <Link to="/" className="d-flex menu align-items-center">
                  Sum Amount
               </Link>
            </div> */}
         </div>
         {/* <div className="search-filter w-100 d-flex justify-content-between">
            <div className="search "></div>
            <div className="filter d-flex">
               <div
                  className="sort-by d-flex menu align-items-center justify-content-between"
                  value={monthState}
                  onClick={(e) => {
                     setSelectOpen((prev) => !prev);
                  }}>
                  <span>{options[monthState]?.label}</span>
                  <Vector className="" style={{marginLeft: '20px'}} />
                  {selectOpen && (
                     <ul className="p-0 position-absolute sorted">
                        {options
                           .filter((item) => item.value !== 0)
                           .map((item) => (
                              <li onClick={() => setMonthState(item.value)}>{item.label}</li>
                           ))}
                     </ul>
                  )}
               </div>
            </div>
         </div> */}

         {shown === 1 && <Transactions companyData={companyData} />}
         {shown === 2 && <Withdrawals />}

         {/* <div className="pagination d-flex align-items-center mt-5">
            <Link to="/" className="arrow d-flex align-items-center justify-content-center">
               <Prev style={{width: '1.3rem'}} />
            </Link>
            <ul className="d-flex justify-content-center mx-4">
               <li className="active">
                  <Link to="/" className="active">
                     1
                  </Link>
               </li>
               <li>
                  <Link to="/history">2</Link>
               </li>
               <li>
                  <Link to="/history">3</Link>
               </li>
               <li>
                  <Link to="/history">4</Link>
               </li>
            </ul>
            <Link to="/" className="arrow d-flex align-items-center justify-content-center">
               <Next style={{width: '1.3rem'}} />
            </Link>
         </div> */}
      </MDBRow>
   );
}
export default History;
