import {combineReducers} from 'redux';
import {bankConstants, merchantConstants, appConstants} from '../../_constants';
import {updateObject} from '../../_helpers';

const IsRequestingBanks = (state = false, action) => {
   switch (action.type) {
      case bankConstants.REQUEST_BANKS:
         return true;
      case bankConstants.REQUEST_BANKS_ERROR:
         return false;
      case bankConstants.REQUEST_BANKS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingMerchantBankDetails = (state = false, action) => {
   switch (action.type) {
      case bankConstants.REQUEST_MERCHANT_BANK_DETAILS:
         return true;
      case bankConstants.REQUEST_MERCHANT_BANK_DETAILS_ERROR:
         return false;
      case bankConstants.REQUEST_MERCHANT_BANK_DETAILS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingAccountName = (state = false, action) => {
   switch (action.type) {
      case bankConstants.REQUEST_ACCOUNT_NAME:
         return true;
      case bankConstants.REQUEST_ACCOUNT_NAME_ERROR:
         return false;
      case bankConstants.REQUEST_ACCOUNT_NAME_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsSavingBankDetails = (state = false, action) => {
   switch (action.type) {
      case bankConstants.REQUEST_SAVE_BANK_DETAILS:
         return true;
      case bankConstants.REQUEST_SAVE_BANK_DETAILS_ERROR:
         return false;
      case bankConstants.REQUEST_SAVE_BANK_DETAILS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsSavingBvn = (state = false, action) => {
   switch (action.type) {
      case bankConstants.REQUEST_SAVE_BVN:
         return true;
      case bankConstants.REQUEST_SAVE_BVN_ERROR:
         return false;
      case bankConstants.REQUEST_SAVE_BVN_SUCCESS:
         return false;
      default:
         return state;
   }
};

const bankDetailsSaved = (state = false, action) => {
   switch (action.type) {
      case bankConstants.REQUEST_SAVE_BANK_DETAILS:
         return false;
      case bankConstants.REQUEST_SAVE_BANK_DETAILS_ERROR:
         return false;
      case bankConstants.REQUEST_SAVE_BANK_DETAILS_SUCCESS:
         return true;
      default:
         return state;
   }
};

const bvnSaved = (state = false, action) => {
   switch (action.type) {
      case bankConstants.REQUEST_SAVE_BVN:
         return false;
      case bankConstants.REQUEST_SAVE_BVN_ERROR:
         return false;
      // case merchantConstants.LOGIN_SUCCESS:
      //    return action.user.bvn_verified;
      case bankConstants.REQUEST_SAVE_BANK_DETAILS_SUCCESS:
         return false;
      case merchantConstants.CREATE_PIN_SUCCESS:
         return action.user.bvn_verified;
      case bankConstants.REQUEST_SAVE_BVN_SUCCESS:
         return true;
      default:
         return state;
   }
};

// const bvnVerified = (state = false, action) => {
//    switch (action.type) {
//       case merchantConstants.LOGIN_SUCCESS:
//          return action.user.bvn_verified;
//       case merchantConstants.CREATE_PIN_SUCCESS:
//          return action.user.bvn_verified;
//       case bankConstants.REQUEST_SAVE_BVN_SUCCESS:
//          return true;
//       default:
//          return state;
//    }
// };
const userBvn = (state = null, {type, user, bvn}) => {
   switch (type) {
      case merchantConstants.GET_SUCCESS:
         return user;
      case merchantConstants.LOGIN_SUCCESS:
         return user;
      case merchantConstants.CREATE_PIN_SUCCESS:
         return user;
      case merchantConstants.ADD_NEXT_OF_KIN_SUCCESS:
         return user;
      case bankConstants.REQUEST_SAVE_BVN_SUCCESS: {
         const user = {
            ...state,
            bvn: bvn,
         };
         return updateObject(state, user);
      }
      // case merchantConstants.UPDATE_MERCHANT_INFO_SUCCESS:
      //    return user;
      case merchantConstants.LOGOUT_SUCCESS:
         return null;
      case merchantConstants.TOKEN_HAS_EXPIRED:
         return null;
      default:
         return state;
   }
};

const banksList = (state = [], action) => {
   switch (action.type) {
      case bankConstants.REQUEST_BANKS_SUCCESS: {
         return action.banks.data;
      }
      default:
         return state;
   }
};

const accountName = (state = null, {type, accountName}) => {
   switch (type) {
      case bankConstants.REQUEST_ACCOUNT_NAME_SUCCESS:
         return updateObject(state, accountName.account_details);
      default:
         return state;
   }
};

const merchantBankDetailsList = (state = [], action) => {
   switch (action.type) {
      case bankConstants.REQUEST_MERCHANT_BANK_DETAILS_SUCCESS: {
         return action.merchantBankDetails.user_bank;
      }
      case bankConstants.REQUEST_SAVE_BANK_DETAILS_SUCCESS: {
         const newList = [...state, action.bankDetails.userbank];

         return newList;
      }
      case bankConstants.REQUEST_DELETE_BANK_DETAILS_SUCCESS: {
         return state.filter((bankDetails) => bankDetails._id !== action._id);
      }
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case bankConstants.REQUEST_SAVE_BANK_DETAILS_ERROR:
         if (error) return error;
         return state;
      case bankConstants.REQUEST_SAVE_BVN_ERROR:
         if (error) return error;
         return state;
      case bankConstants.REQUEST_ACCOUNT_NAME_ERROR:
         if (error) return error;
         return state;
      case bankConstants.REQUEST_SAVE_BANK_DETAILS:
         return null;
      case bankConstants.REQUEST_SAVE_BVN:
         return null;
      case bankConstants.REQUEST_ACCOUNT_NAME:
         return null;
      case appConstants.CLEAR_MESSAGES:
         return null;
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   banksList,
   IsRequestingBanks,
   IsRequestingAccountName,
   IsRequestingMerchantBankDetails,
   IsSavingBankDetails,
   IsSavingBvn,
   bankDetailsSaved,
   bvnSaved,
   userBvn,
   accountName,
   merchantBankDetailsList,
   errorMessage,
});

export default rootReducer;
