import {customerConstants} from '../_constants';

const createCustomer = (model) => ({
   type: customerConstants.REGISTER_CUSTOMER,
   data: model,
});

export const userActions = {
   createCustomer,
};
