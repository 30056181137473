import {appConstants, merchantConstants} from '../../_constants';
import {combineReducers} from 'redux';

const IsRegisteringMerchant = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_REGISTER_MERCHANT:
         return true;
      case merchantConstants.REGISTER_MERCHANT_SUCCESS:
         return false;
      case merchantConstants.REGISTER_MERCHANT_ERROR:
         return false;
      default:
         return state;
   }
};

const merchantCreated = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_REGISTER_MERCHANT:
         return false;
      case merchantConstants.REGISTER_MERCHANT_SUCCESS:
         return true;
      case merchantConstants.REGISTER_MERCHANT_ERROR:
         return false;
      default:
         return state;
   }
};

const IsCreatingMerchantCompany = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.IS_CREATING_MERCHANT_COMPANY:
         return true;
      case merchantConstants.CREATE_MERCHANT_COMPANY_SUCCESS:
         return false;
      case merchantConstants.CREATE_MERCHANT_COMPANY_FAILURE:
         return false;
      default:
         return state;
   }
};

const merchantCompanyCreated = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION:
         return false;
      case merchantConstants.IS_CREATING_MERCHANT_COMPANY:
         return false;
      case merchantConstants.CREATE_MERCHANT_COMPANY_SUCCESS:
         return true;
      case merchantConstants.CREATE_MERCHANT_COMPANY_FAILURE:
         return false;
      default:
         return state;
   }
};

const merchantCompanyId = (state = null, action) => {
   switch (action.type) {
      case merchantConstants.CREATE_MERCHANT_COMPANY_SUCCESS:
         return action.id;
      default:
         return state;
   }
};

const IsRegisteringMerchantSubUser = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_REGISTER_MERCHANT_SUB_USER:
         return true;
      case merchantConstants.REGISTER_MERCHANT_SUB_USER_SUCCESS:
         return false;
      case merchantConstants.REGISTER_MERCHANT_SUB_USER_ERROR:
         return false;
      default:
         return state;
   }
};

const merchantSubUserCreated = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_REGISTER_MERCHANT_SUB_USER:
         return false;
      case merchantConstants.REGISTER_MERCHANT_SUB_USER_SUCCESS:
         return true;
      case merchantConstants.REGISTER_MERCHANT_SUB_USER_ERROR:
         return false;
      default:
         return state;
   }
};

const IsAddingSubUserToMerchant = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_ADD_SUB_USER_TO_MERCHANT:
         return true;
      case merchantConstants.ADD_SUB_USER_TO_MERCHANT_SUCCESS:
         return false;
      case merchantConstants.ADD_SUB_USER_TO_MERCHANT_ERROR:
         return false;
      default:
         return state;
   }
};

const subUserAddedToMerchantSuccessfully = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_ADD_SUB_USER_TO_MERCHANT:
         return false;
      case merchantConstants.ADD_SUB_USER_TO_MERCHANT_SUCCESS:
         return true;
      case merchantConstants.ADD_SUB_USER_TO_MERCHANT_ERROR:
         return false;
      default:
         return state;
   }
};

const IsRequestingMerchant = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.GET_REQUEST:
         return true;
      case merchantConstants.GET_FAILURE:
         return false;
      case merchantConstants.GET_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsRequestingMerchantUsers = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_MERCHANT_USERS:
         return true;
      case merchantConstants.REQUEST_MERCHANT_USERS_FAILURE:
         return false;
      case merchantConstants.REQUEST_MERCHANT_USERS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsCreatingMerchantUser = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.IS_CREATING_MERCHANT_USER:
         return true;
      case merchantConstants.CREATE_MERCHANT_USER_SUCCESS:
         return false;
      case merchantConstants.CREATE_MERCHANT_USER_FAILURE:
         return false;
      case merchantConstants.IS_CREATING_MERCHANT_SUB_USER:
         return true;
      case merchantConstants.CREATE_MERCHANT_SUB_USER_SUCCESS:
         return false;
      case merchantConstants.CREATE_MERCHANT_SUB_USER_FAILURE:
         return false;
      default:
         return state;
   }
};

const merchantUserCreated = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.IS_CREATING_MERCHANT_USER:
         return false;
      case merchantConstants.CREATE_MERCHANT_USER_SUCCESS:
         return true;
      case merchantConstants.CREATE_MERCHANT_USER_FAILURE:
         return false;
      case merchantConstants.IS_CREATING_MERCHANT_SUB_USER:
         return false;
      case merchantConstants.CREATE_MERCHANT_SUB_USER_SUCCESS:
         return true;
      case merchantConstants.CREATE_MERCHANT_SUB_USER_FAILURE:
         return false;
      default:
         return state;
   }
};

const IsAddingNextOfKin = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.ADDING_NEXT_OF_KIN:
         return true;
      case merchantConstants.ADD_NEXT_OF_KIN_SUCCESS:
         return false;
      case merchantConstants.ADD_NEXT_OF_KIN_FAILURE:
         return false;
      default:
         return state;
   }
};

const AddedNextoFKin = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.ADDING_NEXT_OF_KIN:
         return false;
      case merchantConstants.ADD_NEXT_OF_KIN_SUCCESS:
         return true;
      case merchantConstants.ADD_NEXT_OF_KIN_FAILURE:
         return false;
      default:
         return state;
   }
};

const IsRequestingDashboard = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.GETTING_DASHBOARD:
         return false;
      case merchantConstants.GET_DASHBOARD_SUCCESS:
         return false;
      case merchantConstants.GET_DASHBOARD_FAILURE:
         return false;
      default:
         return state;
   }
};

const dashboardSuccess = (state = null, action) => {
   switch (action.type) {
      case merchantConstants.GET_DASHBOARD_SUCCESS:
         return action.message;
      default:
         return state;
   }
};

const walletBalance = (state = null, action) => {
   switch (action.type) {
      case merchantConstants.GET_DASHBOARD_SUCCESS:
         return action.wallet_balance;
      default:
         return state;
   }
};
const receivedToday = (state = null, action) => {
   switch (action.type) {
      case merchantConstants.GET_DASHBOARD_SUCCESS:
         return action.received_today;
      default:
         return state;
   }
};
const settledToday = (state = null, action) => {
   switch (action.type) {
      case merchantConstants.GET_DASHBOARD_SUCCESS:
         return action.settled_today;
      default:
         return state;
   }
};
const recentTransaction = (state = null, action) => {
   switch (action.type) {
      case merchantConstants.GET_DASHBOARD_SUCCESS:
         return action.recent_transaction;
      default:
         return state;
   }
};

const IsGettingDashBoardData = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.GETTING_DASHBOARD:
         return true;
      case merchantConstants.GET_DASHBOARD_SUCCESS:
         return false;
      case merchantConstants.GET_DASHBOARD_FAILURE:
         return false;
      default:
         return state;
   }
};
const IsUpdatingMerchantInfo = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.UPDATING_MERCHANT_INFO:
         return true;
      case merchantConstants.UPDATE_MERCHANT_INFO_SUCCESS:
         return false;
      case merchantConstants.UPDATE_MERCHANT_INFO_FAILURE:
         return false;
      default:
         return state;
   }
};

const merchantInfoUpdated = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.UPDATING_MERCHANT_INFO:
         return false;
      case merchantConstants.UPDATE_MERCHANT_INFO_SUCCESS:
         return true;
      case merchantConstants.UPDATE_MERCHANT_INFO_FAILURE:
         return false;
      default:
         return state;
   }
};

const changingPassword = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.CHANGING_PASSWORD:
         return true;
      case merchantConstants.CHANGE_PASSWORD_SUCCESS:
         return false;
      case merchantConstants.CHANGE_PASSWORD_FAILURE:
         return false;
      default:
         return state;
   }
};

const changingPin = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.CHANGING_PIN:
         return true;
      case merchantConstants.CHANGE_PIN_SUCCESS:
         return false;
      case merchantConstants.CHANGE_PIN_FAILURE:
         return false;
      default:
         return state;
   }
};

const IsResettingPin = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.RESETTING_PIN:
         return true;
      case merchantConstants.RESET_PIN_SUCCESS:
         return false;
      case merchantConstants.RESET_PIN_FAILURE:
         return false;
      default:
         return state;
   }
};
const changedPassword = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.CHANGING_PASSWORD:
         return false;
      case merchantConstants.CHANGE_PASSWORD_SUCCESS:
         return true;
      case merchantConstants.CHANGE_PASSWORD_FAILURE:
         return false;
      default:
         return state;
   }
};

const changedPin = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.CHANGING_PIN:
         return false;
      case merchantConstants.CHANGE_PIN_SUCCESS:
         return true;
      case merchantConstants.CHANGE_PIN_FAILURE:
         return false;
      default:
         return state;
   }
};

const resettedPin = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.RESETTING_PIN:
         return false;
      case merchantConstants.RESET_PIN_SUCCESS:
         return true;
      case merchantConstants.RESET_PIN_FAILURE:
         return false;
      default:
         return state;
   }
};

const companyId = (state = null, {type, companyId}) => {
   switch (type) {
      case merchantConstants.CREATE_PIN_SUCCESS:
         return companyId;
      default:
         return state;
   }
};

const data = (state = null, {type, user}) => {
   switch (type) {
      case merchantConstants.GET_SUCCESS:
         return user;
      case merchantConstants.GET_MERCHANT_SUB_USER_SUCCESS: {
         return user;
      }
      case merchantConstants.LOGIN_SUCCESS:
         return user;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_SUCCESS:
         return user;
      case merchantConstants.CREATE_PIN_SUCCESS:
         return user;
      case merchantConstants.ADD_NEXT_OF_KIN_SUCCESS:
         return user;
      // case bankConstants.REQUEST_SAVE_BVN_SUCCESS: {
      //    const user = {
      //       ...state,
      //       bvn: bvn,
      //    };
      //    return updateObject(state, user);
      // }
      // case merchantConstants.UPDATE_MERCHANT_INFO_SUCCESS:
      //    return user;
      case merchantConstants.LOGOUT_SUCCESS:
         return null;
      case merchantConstants.TOKEN_HAS_EXPIRED:
         return null;
      default:
         return state;
   }
};

const info = (state = null, {type, details}) => {
   switch (type) {
      // case merchantConstants.GET_SUCCESS:
      //    return details;
      // case merchantConstants.LOGIN_SUCCESS:
      //    return details;
      case merchantConstants.UPDATE_MERCHANT_INFO_SUCCESS:
         return details;
      case merchantConstants.LOGOUT_SUCCESS:
         return null;
      default:
         return state;
   }
};

const IsRequestingCompanyInfo = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION:
         return true;
      case merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION_SUCCESS:
         return false;
      case merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION_FAILURE:
         return false;
      default:
         return state;
   }
};

const companyInfoSuccess = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION:
         return false;
      case merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION_SUCCESS:
         return true;
      case merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION_FAILURE:
         return false;
      default:
         return state;
   }
};

const companyData = (state = null, {type, user}) => {
   switch (type) {
      case merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION_SUCCESS:
         return user;
      case merchantConstants.GET_MERCHANT_COMPANY_SUCCESS:
         return user;
      case merchantConstants.GET_MERCHANT_SUB_USER_SUCCESS:
         return user;
      case merchantConstants.LOGOUT_SUCCESS:
         return null;
      case merchantConstants.TOKEN_HAS_EXPIRED:
         return null;
      default:
         return state;
   }
};

const role = (state = null, {type, merchant}) => {
   switch (type) {
      case merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION_SUCCESS:
         return merchant;
      case merchantConstants.LOGOUT_SUCCESS:
         return null;
      case merchantConstants.TOKEN_HAS_EXPIRED:
         return null;
      default:
         return state;
   }
};

const companyInfo = (state = null, {type, details}) => {
   switch (type) {
      case merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION_SUCCESS:
         return details;
      case merchantConstants.MERCHANT_SUB_USER_LOGIN_SUCCESS:
         return details;
      case merchantConstants.GET_MERCHANT_SUB_USER_SUCCESS:
         return details;
      case merchantConstants.GET_MERCHANT_COMPANY_SUCCESS:
         return details;
      case merchantConstants.UPDATE_MERCHANT_INFO_SUCCESS:
         return details;
      // case merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION:
      //    return null;
      case merchantConstants.LOGOUT_SUCCESS:
         return null;
      default:
         return state;
   }
};

const userMerchants = (state = null, {type, userMerchants, merchantCompany}) => {
   switch (type) {
      case merchantConstants.GET_SUCCESS:
         return userMerchants;
      case merchantConstants.LOGIN_SUCCESS:
         return userMerchants;
      case merchantConstants.CREATE_PIN_SUCCESS:
         return userMerchants;
      case merchantConstants.CREATE_MERCHANT_COMPANY_SUCCESS: {
         const newList = [...state, merchantCompany];

         return newList;
      }
      case merchantConstants.LOGOUT_SUCCESS:
         return null;
      default:
         return state;
   }
};

const newUserMerchant = (state = [], {type, userMerchants}) => {
   switch (type) {
      case merchantConstants.CREATE_PIN_SUCCESS:
         return userMerchants.length > 0 ? userMerchants[0] : [];
      case merchantConstants.LOGOUT_SUCCESS:
         return null;
      default:
         return state;
   }
};

const newUserMerchantDetails = (state = [], {type, details}) => {
   switch (type) {
      case merchantConstants.CREATE_PIN_SUCCESS:
         return details;
      case merchantConstants.LOGOUT_SUCCESS:
         return null;
      default:
         return state;
   }
};

const IsChild = (state = false, {type, is_child}) => {
   switch (type) {
      case merchantConstants.GET_SUCCESS:
         return is_child;
      case merchantConstants.LOGIN_SUCCESS:
         return is_child;
      case merchantConstants.CREATE_PIN_SUCCESS:
         return is_child;
      case merchantConstants.LOGOUT_SUCCESS:
         return null;
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case merchantConstants.REGISTER_MERCHANT_ERROR:
         if (error) return error;
         return state;
      case merchantConstants.REQUEST_REGISTER_MERCHANT:
         return null;
      case merchantConstants.REGISTER_MERCHANT_SUB_USER_ERROR:
         if (error) return error;
         return state;
      case merchantConstants.REQUEST_REGISTER_MERCHANT_SUB_USER:
         return null;
      case merchantConstants.VERIFY_EMAIL_FAILURE:
         if (error) return error;
         return state;
      case merchantConstants.ADD_SUB_USER_TO_MERCHANT_ERROR:
         if (error) return error;
         return state;
      case merchantConstants.UPDATE_MERCHANT_INFO_FAILURE:
         if (error) return error;
         return state;
      case merchantConstants.ADD_NEXT_OF_KIN_FAILURE:
         if (error) return error;
         return state;
      case merchantConstants.CREATE_MERCHANT_COMPANY_FAILURE:
         if (error) return error;
         return state;
      case merchantConstants.CREATE_MERCHANT_USER_FAILURE:
         if (error) return error;
         return state;
      case merchantConstants.IS_CREATING_MERCHANT_USER:
         return null;
      case merchantConstants.CREATE_MERCHANT_SUB_USER_FAILURE:
         if (error) return error;
         return state;
      case merchantConstants.IS_CREATING_MERCHANT_SUB_USER:
         return null;
      case appConstants.CLEAR_MESSAGES:
         return null;
      default:
         return state;
   }
};

const IsAuthenticated = (state = false, action) => {
   switch (action.type) {
      case merchantConstants.CREATE_PIN_SUCCESS:
         return true;
      case merchantConstants.LOGIN_SUCCESS:
         return true;
      case merchantConstants.LOGOUT_SUCCESS:
         return false;
      default:
         return state;
   }
};

const merchantUsersList = (state = [], action) => {
   switch (action.type) {
      case merchantConstants.REQUEST_MERCHANT_USERS_SUCCESS: {
         return action.merchantUsers.data;
      }
      case merchantConstants.CREATE_MERCHANT_SUB_USER_SUCCESS: {
         const newList = [...state, action.merchantSubUser.data];

         return newList;
      }
      case merchantConstants.DELETE_MERCHANT_USER_SUCCESS: {
         return state.filter((user) => user._id !== action._id);
      }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   data,
   info,
   companyData,
   role,
   companyInfo,
   IsRequestingCompanyInfo,
   companyInfoSuccess,
   userMerchants,
   newUserMerchant,
   newUserMerchantDetails,
   IsChild,
   companyId,
   IsRegisteringMerchant,
   IsCreatingMerchantCompany,
   merchantCompanyCreated,
   merchantCompanyId,
   IsRequestingMerchant,
   IsRegisteringMerchantSubUser,
   merchantSubUserCreated,
   IsRequestingMerchantUsers,
   IsCreatingMerchantUser,
   merchantUserCreated,
   IsAddingSubUserToMerchant,
   subUserAddedToMerchantSuccessfully,
   IsUpdatingMerchantInfo,
   merchantInfoUpdated,
   merchantCreated,
   changingPassword,
   changedPassword,
   errorMessage,
   IsAuthenticated,
   IsAddingNextOfKin,
   AddedNextoFKin,
   changingPin,
   IsResettingPin,
   changedPin,
   resettedPin,
   IsRequestingDashboard,
   dashboardSuccess,
   walletBalance,
   settledToday,
   receivedToday,
   recentTransaction,
   IsGettingDashBoardData,
   merchantUsersList,
});

export default rootReducer;
