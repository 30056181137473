import {call, put, takeLatest, all} from 'redux-saga/effects';
import {appActions} from '../../_actions';
import {settlementConstants, merchantConstants} from '../../_constants';
import {createRequestWithToken, checkStatus, getObjectFromStorage} from '../../_helpers';

function* makeFullSettlement({data}) {
   yield put({type: settlementConstants.REQUEST_MAKE_FULL_SETTLEMENT});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      const merchantCompany = yield call(
         getObjectFromStorage,
         merchantConstants.MERCHANT_COMPANY_KEY,
      );
      // let settlementUri = `${settlementConstants.SETTLEMENT_URI}?type=merchant`;
      const settlementUri = `${settlementConstants.SETTLEMENT_URI}/${merchantCompany?.details?._id}`;

      const req = createRequestWithToken(settlementUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(merchant?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: settlementConstants.REQUEST_MAKE_FULL_SETTLEMENT_SUCCESS,
         settled: jsonResponse,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Settlement made successfully',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: settlementConstants.REQUEST_MAKE_FULL_SETTLEMENT_ERROR,
         error: errorMessage,
      });
   }
}

function* makePartialSettlement({data}) {
   yield put({type: settlementConstants.REQUEST_MAKE_PARTIAL_SETTLEMENT});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);

      // let settlementUri = `${settlementConstants.SETTLEMENT_URI}`;
      const merchantCompany = yield call(
         getObjectFromStorage,
         merchantConstants.MERCHANT_COMPANY_KEY,
      );
      // let settlementUri = `${settlementConstants.SETTLEMENT_URI}?type=merchant`;
      const settlementUri = `${settlementConstants.SETTLEMENT_URI}/${merchantCompany?.details?._id}`;

      const req = createRequestWithToken(settlementUri, {
         method: 'POST',
         body: JSON.stringify(data),
      })(merchant?.token);

      const response = yield call(fetch, req);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield put({
         type: settlementConstants.REQUEST_MAKE_PARTIAL_SETTLEMENT_SUCCESS,
         settled: jsonResponse,
      });
      yield put(
         appActions.setSnackBar({
            message: 'Settlement made successfully',
            variant: 'success',
         }),
      );
   } catch (error) {
      const errorMessage = yield call(error.response.json.bind(error.response));

      yield put({
         type: settlementConstants.REQUEST_MAKE_PARTIAL_SETTLEMENT_ERROR,
         error: errorMessage,
      });
   }
}

function* makeFullSettlementSagaWatcher() {
   yield takeLatest(settlementConstants.MAKE_FULL_SETTLEMENT, makeFullSettlement);
}

function* makePartialSettlementSagaWatcher() {
   yield takeLatest(settlementConstants.MAKE_PARTIAL_SETTLEMENT, makePartialSettlement);
}

export default function* rootSaga() {
   yield all([makeFullSettlementSagaWatcher(), makePartialSettlementSagaWatcher()]);
}
