import React, {useState, useEffect, useRef, useCallback} from 'react';
import classNames from 'classnames';
import {MDBContainer, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import FormControl from '@material-ui/core/FormControl';
import TextInput from '../../../_components/TextInput';
import Formsy from 'formsy-react';
import Modal from 'react-modal';
import Sule from '../../../_assets/images/sule.png';
import Lara from '../../../_assets/images/lara.png';
import Emeka from '../../../_assets/images/emeka.png';
import Kafilat from '../../../_assets/images/kafilat.png';
import Yetunde from '../../../_assets/images/yetunde.png';
import Users from './user';
import {ReactComponent as Vector} from '../../../_assets/icon/downarr.svg';
import {ReactComponent as Lock} from '../../../_assets/icon/lock.svg';
import {ReactComponent as Delete} from '../../../_assets/icon/delete.svg';
import {ReactComponent as Close} from '../../../_assets/icon/close.svg';
import {ReactComponent as Edit} from '../../../_assets/icon/edit.svg';
import {ReactComponent as Arrdown} from '../../../_assets/icon/1arrow-down.svg';
import {ReactComponent as Pen} from '../../../_assets/icon/pen.svg';
import {Link} from 'react-router-dom';

import './index.scss';
import BasicForm from './forms/base';
// import KinForm from './forms/kin';
import Security from './forms/security';

function Account() {
   const [canSubmit, setCanSubmit] = useState(false);
   const [gender, setGender] = useState(0);
   const [relationship, setRelationship] = useState(0);
   const [selectOpen, setSelectOpen] = useState(false);
   const [open, setOpen] = useState(false);
   const [shown, setShown] = useState(1);
   const [showInput, setShowInput] = useState(false);
   const [modalIsOpen, setModalIsOpen] = useState(false);
   const [secModalIsOpen, setSecModalIsOpen] = useState(false);
   const [codeError, setCodeError] = useState('');

   const clearError = () => {
      setCodeError('');
   };

   const setError = (itm) => {
      setCodeError(itm);
   };

   const onkeypress = useCallback(
      (e, value, prevRef, setState) => {
         if (e.keyCode === 8) {
            if (!value.length) {
               if (prevRef) {
                  prevRef.focus();
                  setState('');
               }
            }
         }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
   );

   const onClick = () => {
      setShowInput(true);
   };

   const onClick2 = () => {
      setShowInput(false);
   };

   const setModalIsOpenToTrue = () => {
      setModalIsOpen(true);
   };

   const setSecModalIsOpenToTrue = () => {
      setSecModalIsOpen(true);
      // setModalIsOpen(false);
   };

   const customStyles = {
      content: {
         // top: '40%',
         position: 'fixed',
         top: '50%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: '400px',
         maxWidth: '90%',
         height: '300px',
         maxHeight: '90%',
         backgroundColor: '#ffffff',
         borderRadius: '20px',
         padding: '40px 40px',
         overflowX: 'hidden',
         zIndex: '100',
      },
   };

   return (
      <>
         <div className="account-page m-0 w-100 mb-5">
            <div className="ms-3 account w-100 d-flex justify-content-between">
               <ul className="d-flex">
                  <li>
                     <div
                        onClick={() => setShown(1)}
                        className={classNames('w-100 d-block', {
                           active: shown === 1,
                        })}>
                        Company Info
                     </div>
                  </li>

                  <li>
                     <div
                        onClick={() => setShown(3)}
                        className={classNames('w-100 d-block', {
                           active: shown === 3,
                        })}>
                        User Management
                     </div>
                  </li>
                  <li>
                     <div
                        onClick={() => setShown(4)}
                        className={classNames('w-100 d-block', {
                           active: shown === 4,
                        })}>
                        Security
                     </div>
                  </li>
               </ul>
            </div>
            {shown === 1 && <BasicForm />}
            {shown === 3 && <Users />}
            {shown === 4 && <Security />}
         </div>
         <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            id="mode"
            onRequestClose={() => setModalIsOpen(false)}>
            <Formsy className="__form d-flex flex-column align-items-center">
               <Delete className="mb-3 delete" />
               <p> Are you sure you want to remove user</p>
               <div className="mt-5 d-flex">
                  <Link to="/">
                     <div className="mx-3 white">Cancel</div>
                  </Link>
                  <Link to="/">
                     <div className="blue">Delete</div>
                  </Link>
               </div>
            </Formsy>
         </Modal>
      </>
   );
}

export default Account;
