import {appConstants} from './app.constant';

const account = 'bank_details/resolve_account';
const addBank = 'bank_details/merchant';
const deleteBank = 'bank_details';
const banks = 'bank_details/merchant';

export const bankConstants = {
   GET_BANKS: 'GET_BANKS',
   GET_ACCOUNT_NAME: 'GET_ACCOUNT_NAME',
   SAVE_BANK_DETAILS: 'SAVE_BANK_DETAILS',
   SAVE_BVN: 'SAVE_BVN',

   DELETE_BANK_DETAILS: 'DELETE_BANK_DETAILS',
   GET_MERCHANT_BANK_DETAILS: 'GET_MERCHANT_BANK_DETAILS',
   BANK: 'bank',

   REQUEST_BANKS: 'REQUEST_BANKS',
   REQUEST_BANKS_ERROR: 'REQUEST_BANKS_ERROR',
   REQUEST_BANKS_SUCCESS: 'REQUEST_BANKS_SUCCESS',

   REQUEST_ACCOUNT_NAME: 'REQUEST_ACCOUNT_NAME',
   REQUEST_ACCOUNT_NAME_ERROR: 'REQUEST_ACCOUNT_NAME_ERROR',
   REQUEST_ACCOUNT_NAME_SUCCESS: 'REQUEST_ACCOUNT_NAME_SUCCESS',
   REQUEST_ACCOUNT_NAME_SUCCESS_WITHOUT_DATA: 'REQUEST_ACCOUNT_NAME_SUCCESS_WITHOUT_DATA',

   REQUEST_SAVE_BVN: 'REQUEST_SAVE_BVN',
   REQUEST_SAVE_BVN_ERROR: 'REQUEST_SAVE_BVN_ERROR',
   REQUEST_SAVE_BVN_SUCCESS: 'REQUEST_SAVE_BVN_SUCCESS',

   REQUEST_SAVE_BANK_DETAILS: 'REQUEST_SAVE_BANK_DETAILS',
   REQUEST_SAVE_BANK_DETAILS_ERROR: 'REQUEST_SAVE_BANK_DETAILS_ERROR',
   REQUEST_SAVE_BANK_DETAILS_SUCCESS: 'REQUEST_SAVE_BANK_DETAILS_SUCCESS',

   REQUEST_DELETE_BANK_DETAILS: 'REQUEST_DELETE_BANK_DETAILS',
   REQUEST_DELETE_BANK_DETAILS_ERROR: 'REQUEST_DELETE_BANK_DETAILS_ERROR',
   REQUEST_DELETE_BANK_DETAILS_SUCCESS: 'REQUEST_DELETE_BANK_DETAILS_SUCCESS',

   REQUEST_MERCHANT_BANK_DETAILS: 'REQUEST_MERCHANT_BANK_DETAILS',
   REQUEST_MERCHANT_BANK_DETAILS_ERROR: 'REQUEST_MERCHANT_BANK_DETAILS_ERROR',
   REQUEST_MERCHANT_BANK_DETAILS_SUCCESS: 'REQUEST_MERCHANT_BANK_DETAILS_SUCCESS',

   GET_ACCOUNT_NAME_URI: `${appConstants.BASE_URI}${account}`,
   // SAVE_BANK_DETAILS_URI: `${appConstants.BASE_URI}${addBank}`,
   SAVE_BANK_DETAILS_URI: (id) => `${appConstants.BASE_URI}${addBank}/${id}`,
   SAVE_BVN_URI: (id) => `${appConstants.BASE_URI}user/${id}/bvn`,
   GET_MERCHANT_BANK_DETAILS_URI: (id) => `${appConstants.BASE_URI}${banks}/${id}`,
   DELETE_BANK_DETAILS_URI: (id) => `${appConstants.BASE_URI}${deleteBank}/${id}`,
};
