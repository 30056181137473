import queryString from 'query-string';
import {matchPath, useLocation} from 'react-router-dom';

export const useLocationCode = () => {
   const {pathname} = useLocation();

   const merchanthomeempty = matchPath(pathname, '/merchant/home');
   const merchanthome = matchPath(pathname, '/merchant/home');
   const payment = matchPath(pathname, '/merchant/payment');
   const history = matchPath(pathname, '/merchant/history');
   const account = matchPath(pathname, '/merchant/account');
   const subuserhistory = matchPath(pathname, '/merchant/sub-user-history');
   // const logout = matchPath(pathname, '/logout');

   if (merchanthomeempty?.isExact) {
      return 1;
   } else if (payment?.isExact) {
      return 2;
   } else if (history?.isExact) {
      return 3;
   } else if (account?.isExact) {
      return 4;
   } else if (merchanthome?.isExact) {
      return 5;
   } else if (subuserhistory?.isExact) {
      return 6;
   } else {
      return 7;
   }
};

export const useQueryString = () => {
   const {search} = useLocation();

   if (search) {
      return {...queryString.parse(search)};
   }

   return {};
};
