import {appConstants, customerConstants} from '../../_constants';
import {combineReducers} from 'redux';
import {updateObject} from '../../_helpers';

const IsRegisteringCustomer = (state = false, action) => {
   switch (action.type) {
      case customerConstants.REQUEST_REGISTER_CUSTOMER:
         return true;
      case customerConstants.REGISTER_CUSTOMER_SUCCESS:
         return false;
      case customerConstants.REGISTER_CUSTOMER_ERROR:
         return false;
      default:
         return state;
   }
};

const customerCreated = (state = false, action) => {
   switch (action.type) {
      case customerConstants.REQUEST_REGISTER_CUSTOMER:
         return false;
      case customerConstants.REGISTER_CUSTOMER_SUCCESS:
         return true;
      case customerConstants.REGISTER_CUSTOMER_ERROR:
         return false;
      default:
         return state;
   }
};

const errorMessage = (state = null, {type, error}) => {
   switch (type) {
      case customerConstants.REGISTER_CUSTOMER_ERROR:
         return updateObject(state, error);
      case customerConstants.REQUEST_REGISTER_CUSTOMER:
         return null;
      case appConstants.CLEAR_MESSAGES:
         return null;
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRegisteringCustomer,
   customerCreated,
   errorMessage,
});

export default rootReducer;
