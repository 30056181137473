import React from 'react';
import {Route, Redirect} from 'react-router-dom';
// import NotFound from '../404';

export const PrivateRoute = ({component: Component, merchant, companyInfo, ...rest}) => {
   // const {IsAuthenticated} = useSelector((s) => s.merchant);
   // IsAuthenticated ||
   return (
      <Route
         {...rest}
         render={(props) =>
            merchant ? (
               <Component {...props} merchant={merchant} companyInfo={companyInfo} {...rest} />
            ) : (
               <Redirect to={{pathname: '/login', state: {from: props.location}}} />
            )
         }
      />
   );
};

export const SubUserPrivateRoute = ({component: Component, merchant, companyInfo, ...rest}) => {
   // const {IsAuthenticated} = useSelector((s) => s.merchant);
   // IsAuthenticated ||
   console.log('ROUTE', merchant)
   return (
      <Route
         {...rest}
         render={(props) =>
            merchant ? (
               <Component {...props} merchant={merchant} companyInfo={companyInfo} {...rest} />
            ) : (
               <Redirect to={{pathname: '/submerchantlogin', state: {from: props.location}}} />
            )
         }
      />
   );
};
