import React from 'react';
// import {useSelector} from 'react-redux';
import {MDBContainer, MDBCol} from 'mdb-react-ui-kit';
// import {Link} from 'react-router-dom';
// import BlueYellow from '..//../..//_assets/images/blueandyellow.svg';
// import {ReactComponent as Bluetick} from '..//..//..//_assets/icon/bluetick.svg';
import {ReactComponent as Load} from '..//..//..//_assets/icon/verify.svg';
import './index.scss';

function Loading({location, history}) {
   // const companyInfo = useSelector((s) => s.merchant.companyInfo);
   // const data = useSelector((s) => s.merchant.data);
   // const data = useSelector((s) => s.merchant.companyData);
   // console.log('companyInfo', companyInfo);

   return (
      <section className="load">
         <MDBContainer className="align-items-center">
            <MDBCol className="smallbox2">
               <Load className="img-fluid tick" />
               <br />
               <br />
               <br />
               <p>
                  Please wait
                  <br />
                  While we get your company details
               </p>
               <br />
            </MDBCol>
         </MDBContainer>
      </section>
   );
}
export default Loading;
