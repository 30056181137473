import React from 'react';
import PropTypes from 'prop-types';
// import {MDBRow} from 'mdbreact';
import {Link} from 'react-router-dom';
import './index.scss';

import {ReactComponent as Prev} from '../_assets/icon/1arr-left.svg';
import {ReactComponent as Next} from '../_assets/icon/1arr-right.svg';
// import {ReactComponent as Vector} from '../../../_assets/icon/Vector.svg';
// import {ReactComponent as Prev} from '../../../_assets/icon/1arr-left.svg';
// import {ReactComponent as Next} from '../../../_assets/icon/1arr-right.svg';
export default function TablePaginationActions(props) {
   const {count, page, rowsPerPage, totalPages} = props;

   const handleBackButtonClick = (event) => {
      if (page !== 0) {
         props.onChangePage(event, page - 1);
      }
   };

   const handleNextButtonClick = (event) => {
      props.onChangePage(event, page + 1);
   };

   const handlePageClick = (i) => {
      props.onChangePage(i, Number(i.target.innerHTML) - 1);
   };

   const buttons = [];

   for (var i = 0; i < totalPages; i++) {
      buttons.push(
         <li>
            <Link onClick={(i) => handlePageClick(i)} className={page === i ? 'active' : ''}>
               {i + 1}
            </Link>
         </li>,
      );
   }

   return (
      // <MDBRow className="loan-page m-0 w-100 mb-5">
      <div className="pagination d-flex align-items-center mt-3">
         <Link
            onClick={handleBackButtonClick}
            disabled={page === 0}
            className="arrow d-flex align-items-center justify-content-center">
            <Prev style={{width: '1.3rem'}} />
         </Link>
         <ul className="d-flex justify-content-center mx-4">{buttons}</ul>
         <Link
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            className="arrow d-flex align-items-center justify-content-center">
            <Next style={{width: '1.3rem'}} />
         </Link>
      </div>
      // </MDBRow>
   );
}

TablePaginationActions.propTypes = {
   classes: PropTypes.object.isRequired,
   count: PropTypes.number.isRequired,
   onChangePage: PropTypes.func.isRequired,
   page: PropTypes.number.isRequired,
   rowsPerPage: PropTypes.number.isRequired,
   theme: PropTypes.object.isRequired,
};
