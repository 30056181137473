import {call, put, takeLatest, all} from 'redux-saga/effects';
import {merchantConstants} from '../../_constants';
import {
   setObjectInStorage,
   getObjectFromStorage,
   clearObjectFromStorage,
   createRequest,
   createRequestWithToken,
   checkStatus,
   parseJSON,
} from '../../_helpers';

function* getMerchantCompanyInfoSaga({data}) {
   yield put({type: merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION});

   try {
      const merchant = yield call(getObjectFromStorage, merchantConstants.MERCHANT_KEY);
      const merchantUri = merchantConstants.GET_MERCHANT_COMPANY_INFORMATION_URI(data);

      const curriedReq = createRequestWithToken(merchantUri, {
         method: 'GET',
      })(merchant?.token);

      const response = yield call(fetch, curriedReq);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield call(setObjectInStorage, merchantConstants.MERCHANT_COMPANY_KEY, {
         user: jsonResponse.user_details,
         is_child: jsonResponse.user_details.is_child,
         details: jsonResponse.merchant_details,
      });
      yield put({
         type: merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION_SUCCESS,
         user: jsonResponse?.user_details,
         is_child: jsonResponse?.user_details.is_child,
         details: jsonResponse?.merchant_details,
         merchant: jsonResponse?.merchant,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);

         yield put({
            type: merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION_FAILURE,
            error: res.message,
         });

         return;
      }

      yield put({
         type: merchantConstants.REQUEST_MERCHANT_COMPANY_INFORMATION_FAILURE,
         error: error.message,
      });
   }
}

function* loginMerchantSaga({data}) {
   yield put({type: merchantConstants.LOGIN_REQUEST});

   try {
      data['role'] = merchantConstants.MERCHANT_ROLE_ID;
      const curriedReq = yield call(createRequest, merchantConstants.LOGIN_MERCHANT_URI, {
         method: 'POST',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, curriedReq);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));
      if (jsonResponse?.user.is_child) {
         yield call(setObjectInStorage, merchantConstants.MERCHANT_KEY, {
            token: jsonResponse.token,
            user: jsonResponse.user,
            is_child: true,
            details: jsonResponse.merchant_details,
         });
         yield put({
            type: merchantConstants.LOGIN_SUCCESS,
            user: jsonResponse.user,
            is_child: jsonResponse.user.is_child,
            details: jsonResponse.merchant_details,
         });
         return;
      }

      yield call(setObjectInStorage, merchantConstants.MERCHANT_KEY, {
         token: jsonResponse.token,
         user: jsonResponse.user,
         is_child: false,
         userMerchants: jsonResponse.user_merchants,
      });
      yield put({
         type: merchantConstants.LOGIN_SUCCESS,
         user: jsonResponse.user,
         is_child: false,
         userMerchants: jsonResponse.user_merchants,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);

         if (res.errors) {
            let errors = {};
            const serverError = res.errors[0].nestedErrors || res.errors;
            for (let i = 0; i < serverError.length; i++) {
               errors[serverError[i].param] = serverError[i].msg;
            }
            yield put({
               type: merchantConstants.LOGIN_FAILURE,
               error: errors,
            });

            return;
         }

         if (res.email_verified === false) {
            yield put({
               type: merchantConstants.LOGIN_FAILURE,
               error: res.message,
               unverifiedEmail: true,
               id: res.user_id,
            });

            return;
         }

         if (res.phone_number_verified === false) {
            yield put({
               type: merchantConstants.LOGIN_FAILURE,
               error: res.message,
               unverifiedPhone: true,
               id: res.user_id,
            });

            return;
         }

         if (res.pin_created === false) {
            yield put({
               type: merchantConstants.LOGIN_FAILURE,
               error: res.message,
               unsetTransactionPin: true,
               id: res.user_id,
            });

            return;
         }

         yield put({
            type: merchantConstants.LOGIN_FAILURE,
            error: res.message,
         });

         return;
      }

      yield put({
         type: merchantConstants.LOGIN_FAILURE,
         error: error.message,
      });
   }
}

function* loginSubUserMerchantSaga({data}) {
   yield put({type: merchantConstants.MERCHANT_SUB_USER_LOGIN_REQUEST});

   try {
      // data['role'] = merchantConstants.MERCHANT_ROLE_ID;
      const curriedReq = yield call(createRequest, merchantConstants.MERCHANT_SUB_USER_LOGIN_URI, {
         method: 'POST',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, curriedReq);

      yield call(checkStatus, response);
      const jsonResponse = yield call(response.json.bind(response));

      yield call(setObjectInStorage, merchantConstants.MERCHANT_SUB_USER_KEY, {
         token: jsonResponse.token,
         user: jsonResponse.user,
         details: jsonResponse.merchant,
         is_child: false,
      });
      yield put({
         type: merchantConstants.MERCHANT_SUB_USER_LOGIN_SUCCESS,
         user: jsonResponse.user,
         details: jsonResponse.merchant,
         is_child: false,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);

         if (res.errors) {
            let errors = {};
            const serverError = res.errors[0].nestedErrors || res.errors;
            for (let i = 0; i < serverError.length; i++) {
               errors[serverError[i].param] = serverError[i].msg;
            }
            yield put({
               type: merchantConstants.MERCHANT_SUB_USER_LOGIN_FAILURE,
               error: errors,
            });

            return;
         }

         yield put({
            type: merchantConstants.MERCHANT_SUB_USER_LOGIN_FAILURE,
            error: res.message,
         });

         return;
      }

      yield put({
         type: merchantConstants.MERCHANT_SUB_USER_LOGIN_FAILURE,
         error: error.message,
      });
   }
}

function* logoutMerchantSaga({data}) {
   yield put({type: merchantConstants.LOGOUT_REQUEST});
   try {
      if (data === true) {
         yield call(clearObjectFromStorage, merchantConstants.MERCHANT_SUB_USER_KEY);
      } else {
         yield call(clearObjectFromStorage, merchantConstants.MERCHANT_KEY);
         yield call(clearObjectFromStorage, merchantConstants.MERCHANT_COMPANY_KEY);
      }

      yield put({type: merchantConstants.LOGOUT_SUCCESS});
   } catch (error) {
      yield put({type: merchantConstants.LOGOUT_FAILURE});
   }
}

function* verifyPhoneSaga({data}) {
   yield put({type: merchantConstants.VERIFYING_PHONE});

   try {
      const req = yield call(createRequest, merchantConstants.VERIFY_PHONE_URI(data._id), {
         method: 'PATCH',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, req);
      yield call(checkStatus, response);
      const jsonResponse = yield call(parseJSON, response);

      yield put({
         type: merchantConstants.VERIFY_PHONE_SUCCESS,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);
         if (res.errors) {
            let errors = {};
            const serverError = res.errors[0].nestedErrors || res.errors;
            for (let i = 0; i < serverError.length; i++) {
               errors[serverError[i].param] = serverError[i].msg;
            }
         }
         yield put({
            type: merchantConstants.VERIFY_PHONE_FAILURE,
            error: res.message,
         });

         return;
      }
      yield put({
         type: merchantConstants.VERIFY_PHONE_FAILURE,
         error: error.message,
      });
   }
}

function* sendOtpSaga({data}) {
   yield put({type: merchantConstants.SENDING_OTP});

   try {
      const req = yield call(createRequest, merchantConstants.SEND_OTP_URI(data), {
         method: 'PATCH',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, req);
      yield call(checkStatus, response);
      const jsonResponse = yield call(parseJSON, response);

      yield put({
         type: merchantConstants.SEND_OTP_SUCCESS,
         id: jsonResponse.pin_id,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);
         if (res.errors) {
            let errors = {};
            const serverError = res.errors[0].nestedErrors || res.errors;
            for (let i = 0; i < serverError.length; i++) {
               errors[serverError[i].param] = serverError[i].msg;
            }
         }
         yield put({
            type: merchantConstants.SEND_OTP_FAILURE,
            error: res?.errors,
         });

         return;
      }
      yield put({
         type: merchantConstants.SEND_OTP_FAILURE,
         error: error?.errors,
      });
   }
}

function* reSendOtpSaga({data}) {
   yield put({type: merchantConstants.RESENDING_OTP});

   try {
      const req = yield call(createRequest, merchantConstants.RESEND_OTP_URI(data), {
         method: 'PATCH',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, req);
      yield call(checkStatus, response);
      const jsonResponse = yield call(parseJSON, response);

      yield put({
         type: merchantConstants.RESEND_OTP_SUCCESS,
         id: jsonResponse.pin_id,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);
         if (res.errors) {
            let errors = {};
            const serverError = res.errors[0].nestedErrors || res.errors;
            for (let i = 0; i < serverError.length; i++) {
               errors[serverError[i].param] = serverError[i].msg;
            }
         }
         yield put({
            type: merchantConstants.RESEND_OTP_FAILURE,
            error: res?.errors,
         });

         return;
      }
      yield put({
         type: merchantConstants.RESEND_OTP_FAILURE,
         error: error?.errors,
      });
   }
}

function* createPinSaga({data}) {
   yield put({type: merchantConstants.CREATING_PIN});

   try {
      const req = yield call(createRequest, merchantConstants.CREATE_PIN_URI(data), {
         method: 'PATCH',
         body: JSON.stringify(data),
      });

      const response = yield call(fetch, req);
      yield call(checkStatus, response);
      const jsonResponse = yield call(parseJSON, response);
      // yield put({
      //    type: merchantConstants.CREATE_PIN_SUCCESS,
      //    id: jsonResponse.pin_id,
      // });
      yield call(setObjectInStorage, merchantConstants.MERCHANT_KEY, {
         token: jsonResponse.token,
         user: jsonResponse.user,
         is_child: jsonResponse.user.is_child,
         // details: jsonResponse.merchant_details,
         details: jsonResponse.merchant_user,
      });
      yield put({
         type: merchantConstants.CREATE_PIN_SUCCESS,
         user: jsonResponse.user,
         is_child: jsonResponse?.user_merchants?.[0]?.is_child || jsonResponse.user.is_child,
         companyId: jsonResponse?.user_merchants?.[0]?.merchant_id?._id || null,
         userMerchants: jsonResponse?.user_merchants,
         // details: jsonResponse?.merchant_details || null,
         details: jsonResponse.merchant_user || null,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);
         if (res.errors) {
            let errors = {};
            const serverError = res.errors[0].nestedErrors || res.errors;
            for (let i = 0; i < serverError.length; i++) {
               errors[serverError[i].param] = serverError[i].msg;
            }
         }
         yield put({
            type: merchantConstants.CREATE_PIN_FAILURE,
            // error: res.message,
         });

         return;
      }
      yield put({
         type: merchantConstants.CREATE_PIN_FAILURE,
         error: error.message,
      });
   }
}

function* sendVerificationLinkSaga({data}) {
   yield put({type: merchantConstants.SENDING_VERIFICATION_EMAIL});

   try {
      const req = yield call(createRequest, merchantConstants.VERIFICATION_EMAIL_URI(data.id), {
         method: 'GET',
      });

      const response = yield call(fetch, req);
      yield call(checkStatus, response);

      yield put({
         type: merchantConstants.SEND_VERIFICATION_EMAIL_SUCCESS,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);
         if (res.errors) {
            let errors = {};
            const serverError = res.errors[0].nestedErrors || res.errors;
            for (let i = 0; i < serverError.length; i++) {
               errors[serverError[i].param] = serverError[i].msg;
            }
         }

         yield put({
            type: merchantConstants.SEND_VERIFICATION_EMAIL_FAILURE,
            error: res.message,
         });

         return;
      }

      yield put({
         type: merchantConstants.SEND_VERIFICATION_EMAIL_FAILURE,
         error: error.message,
      });
   }
}

function* verifyEmailSaga({data}) {
   yield put({type: merchantConstants.VERIFYING_EMAIL});

   try {
      const req = yield call(createRequest, merchantConstants.VERIFY_EMAIL_URI(data), {
         method: 'GET',
      });

      const response = yield call(fetch, req);
      yield call(checkStatus, response);
      const jsonResponse = yield call(parseJSON, response);
      // console.log('verify email success', jsonResponse);

      yield put({
         type: merchantConstants.VERIFY_EMAIL_SUCCESS,
         id: jsonResponse.user_id,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);
         if (res.errors) {
            let errors = {};
            const serverError = res.errors[0].nestedErrors || res.errors;
            for (let i = 0; i < serverError.length; i++) {
               errors[serverError[i].param] = serverError[i].msg;
            }
         }

         yield put({
            type: merchantConstants.VERIFY_EMAIL_FAILURE,
            error: res,
         });

         return;
      }

      yield put({
         type: merchantConstants.VERIFY_EMAIL_FAILURE,
         error: error.message,
      });
   }
}

function* resendEmailLinkSaga({data}) {
   yield put({type: merchantConstants.RESENDING_EMAIL_LINK});

   try {
      const req = yield call(createRequest, merchantConstants.RESEND_EMAIL_LINK_URI(data), {
         method: 'GET',
      });

      const response = yield call(fetch, req);
      yield call(checkStatus, response);
      const jsonResponse = yield call(parseJSON, response);

      yield put({
         type: merchantConstants.RESEND_EMAIL_LINK_SUCCESS,
         id: jsonResponse.user_id,
      });
   } catch (error) {
      if (error.response) {
         const res = yield call(parseJSON, error.response);

         yield put({
            type: merchantConstants.RESEND_EMAIL_LINK_FAILURE,
         });

         return;
      }

      yield put({
         type: merchantConstants.RESEND_EMAIL_LINK_FAILURE,
         error: error.message,
      });
   }
}

function* getMerchantCompanyInfoSagaWatcher() {
   yield takeLatest(merchantConstants.GET_MERCHANT_COMPANY_INFORMATION, getMerchantCompanyInfoSaga);
}

function* loginMerchantSagaWatcher() {
   yield takeLatest(merchantConstants.LOGIN, loginMerchantSaga);
}

function* loginSubUserMerchantSagaWatcher() {
   yield takeLatest(merchantConstants.MERCHANT_SUB_USER_LOGIN, loginSubUserMerchantSaga);
}

function* verifyEmailSagaWatcher() {
   yield takeLatest(merchantConstants.VERIFY_EMAIL, verifyEmailSaga);
}

function* sendVerificationLinkSagaWatcher() {
   yield takeLatest(merchantConstants.SEND_VERIFICATION_EMAIL, sendVerificationLinkSaga);
}

function* verifyPhoneSagaWatcher() {
   yield takeLatest(merchantConstants.VERIFY_PHONE, verifyPhoneSaga);
}

function* sendOtpSagaWatcher() {
   yield takeLatest(merchantConstants.SEND_OTP, sendOtpSaga);
}

function* reSendOtpSagaWatcher() {
   yield takeLatest(merchantConstants.RESEND_OTP, reSendOtpSaga);
}

function* logoutMerchantSagaWatcher() {
   yield takeLatest(merchantConstants.LOGOUT, logoutMerchantSaga);
}
function* resendEmailLinkSagaWatcher() {
   yield takeLatest(merchantConstants.RESEND_EMAIL_LINK, resendEmailLinkSaga);
}
function* createPinSagaWatcher() {
   yield takeLatest(merchantConstants.CREATE_PIN, createPinSaga);
}

export default function* rootSaga() {
   yield all([
      getMerchantCompanyInfoSagaWatcher(),
      loginMerchantSagaWatcher(),
      logoutMerchantSagaWatcher(),
      verifyEmailSagaWatcher(),
      sendVerificationLinkSagaWatcher(),
      verifyPhoneSagaWatcher(),
      sendOtpSagaWatcher(),
      reSendOtpSagaWatcher(),
      resendEmailLinkSagaWatcher(),
      createPinSagaWatcher(),
      loginSubUserMerchantSagaWatcher(),
   ]);
}
