import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ReactComponent as Contact} from '../../../_assets/icon/contact.svg';
import {ReactComponent as Dots} from '../../../_assets/icon/buygo-dots.svg';
import {merchantActions} from '../../../_actions';
import { capitalizeFirstLetter } from '../../../_helpers';

function CompanyTab({history}) {
   const dispatch = useDispatch();

   const userMerchants = useSelector((s) => s.merchant.userMerchants);
   const data = useSelector((s) => s.merchant.data);

   const handleMerchant = (row) => {
      if (!row.is_child) {
         dispatch(merchantActions.getDashBoard(row.merchant_id._id));
         dispatch(merchantActions.getMerchantCompanyInformation(row.merchant_id._id));
      } else {
         dispatch(merchantActions.getMerchantCompanyInformation(row.merchant_id._id));
      }
      history.push('/loader');
   };

   return (
      <>
         <div className="px-4 mt-3 ms-0 ms-md-5 py-5 company-tab">
            <p className="company-name" style={{color: '#000'}}>
               {/* {data?.firstname} Companies */}
               {capitalizeFirstLetter(data?.firstname)} Companies
            </p>
            <div className="row m-0">
               {userMerchants?.length > 0 &&
                  userMerchants.map((row) => {
                     return (
                        <div
                           onClick={() => handleMerchant(row)}
                           style={{cursor: 'pointer'}}
                           className="col-12 col-sm-5 col-xl-2 mt-4 me-3 tab p-4">
                           <div className="row mt-n2 float-end">{/* <Dots className="" /> */}</div>
                           <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                              <Contact />
                              <p className="col-12  text-truncate mt-4 text-center text-capitalize ">
                                 {row.merchant_id.merchant_name}
                              </p>
                              {/* <p style={{color: '#c4c4c4', fontWeight: '300'}}>Ifako</p> */}
                           </div>
                        </div>
                     );
                  })}
            </div>
         </div>
      </>
   );
}

export default CompanyTab;
