import {combineReducers} from 'redux';
import {transactionConstants} from '../../_constants';
import {updateObject} from '../../_helpers';

const IsRequestingAllTransactions = (state = false, action) => {
   switch (action.type) {
      case transactionConstants.REQUEST_ALL_TRANSACTIONS:
         return true;
      case transactionConstants.REQUEST_ALL_TRANSACTIONS_ERROR:
         return false;
      case transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS:
         return false;
      case transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const IsRequestingAllWithdrawals = (state = false, action) => {
   switch (action.type) {
      case transactionConstants.REQUEST_ALL_WITHDRAWALS:
         return true;
      case transactionConstants.REQUEST_ALL_WITHDRAWALS_ERROR:
         return false;
      case transactionConstants.REQUEST_ALL_WITHDRAWALS_SUCCESS:
         return false;
      case transactionConstants.REQUEST_ALL_WITHDRAWALS_SUCCESS_WITHOUT_DATA:
         return false;
      default:
         return state;
   }
};

const IsFilteringTransactions = (state = false, action) => {
   switch (action.type) {
      case transactionConstants.REQUEST_FILTER_TRANSACTIONS:
         return true;
      case transactionConstants.FILTER_TRANSACTIONS_ERROR:
         return false;
      case transactionConstants.FILTER_TRANSACTIONS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const IsFilteringWithdrawals = (state = false, action) => {
   switch (action.type) {
      case transactionConstants.REQUEST_FILTER_WITHDRAWALS:
         return true;
      case transactionConstants.FILTER_WITHDRAWALS_ERROR:
         return false;
      case transactionConstants.FILTER_WITHDRAWALS_SUCCESS:
         return false;
      default:
         return state;
   }
};

const emptyState = {
   title: '',
   state_id: '',
   industry_id: '',
   specialization_id: '',
   closing_date: '',
   isPublished: '',
};

const AllTransactionsList = (state = [], action) => {
   switch (action.type) {
      case transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS: {
         return action.allTransactions.transactions.docs;
      }
      case transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case transactionConstants.FILTER_TRANSACTIONS_SUCCESS: {
         return action.Transaction.data.docs;
      }
      case transactionConstants.FILTER_TRANSACTIONS_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const AllWithdrawalsList = (state = [], action) => {
   switch (action.type) {
      case transactionConstants.REQUEST_ALL_WITHDRAWALS_SUCCESS: {
         return action.allWithdrawals.transactions.docs;
      }
      case transactionConstants.REQUEST_ALL_WITHDRAWALS_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      case transactionConstants.FILTER_WITHDRAWALS_SUCCESS: {
         return action.Transaction.data.docs;
      }
      case transactionConstants.FILTER_WITHDRAWALS_SUCCESS_WITHOUT_DATA:
         return updateObject(state, emptyState);
      default:
         return state;
   }
};

const pagination = (state = {}, action) => {
   switch (action.type) {
      case transactionConstants.REQUEST_ALL_TRANSACTIONS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.allTransactions.transactions;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      case transactionConstants.FILTER_TRANSACTIONS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.Transaction.data;
         const result = {hasNextPage, nextPage, totalDocs, totalPages, limit, page};

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const withdrawalPagination = (state = {}, action) => {
   switch (action.type) {
      case transactionConstants.REQUEST_ALL_WITHDRAWALS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.allWithdrawals.transactions;
         const result = {
            hasNextPage,
            nextPage,
            totalDocs,
            totalPages,
            limit,
            page,
         };

         return updateObject(state, result);
      }
      case transactionConstants.FILTER_WITHDRAWALS_SUCCESS: {
         const {hasNextPage, nextPage, totalDocs, totalPages, limit, page} =
            action.Transaction.data;
         const result = {hasNextPage, nextPage, totalDocs, totalPages, limit, page};

         return updateObject(state, result);
      }
      default:
         return state;
   }
};

const rootReducer = combineReducers({
   IsRequestingAllTransactions,
   IsRequestingAllWithdrawals,
   AllTransactionsList,
   AllWithdrawalsList,
   IsFilteringTransactions,
   IsFilteringWithdrawals,
   pagination,
   withdrawalPagination,
});

export default rootReducer;
